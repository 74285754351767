import React from "react";
import { useHistory } from "react-router";

// Components
import VideoCameraIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import { Box, Button, Grow, MenuItem, MenuList, Popper, Stack, Tooltip } from "@mui/material";

// Assets
import { ReactComponent as ConversationIcon } from "images/icons/icon-conversation.svg";
import { ReactComponent as InterviewIcon } from "images/icons/icon-interview.svg";
import { ReactComponent as SpeechIcon } from "images/icons/icon-presentation.svg";
import { ReactComponent as UploadIcon } from "images/icons/icon-upload.svg";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { EventsRecordWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerInternalPath } from "utils/paths";
import { getDropDownButtonOptions } from "utils/Utilities";
import { UITestId } from "lib-fullstack/utils/enums";

export const iconMap = {
  speech: (): JSX.Element => <SpeechIcon />,
  interview: (): JSX.Element => <InterviewIcon />,
  conversation: (): JSX.Element => <ConversationIcon />,
  upload: (): JSX.Element => <UploadIcon />,
};
export let mouseExitTimeout: NodeJS.Timeout;
export default function SelectPracticeTypeDropdown({
  drawerOpen,
  handleUploadSpeechClick,
  setPracticeTypeDropdownOpen,
  practiceTypeDropdownOpen,
}: {
  drawerOpen: boolean;
  handleUploadSpeechClick: () => void;
  setPracticeTypeDropdownOpen: (open: boolean) => void;
  practiceTypeDropdownOpen: boolean;
}): JSX.Element {
  const { orgModuleAccess } = React.useContext(UserOrgContext);
  const siteConf = getStaticFullSiteConf();
  const history = useHistory();
  const uploadDisabled = [
    WebServerExternalPath.PRACTICE_CONVERSATION,
    WebServerExternalPath.PRACTICE_INTERVIEW,
    WebServerExternalPath.PRACTICE_SPEECH,
    WebServerExternalPath.PRACTICE,
    WebServerInternalPath.RECORD_SPEECH_DEPRECATED,
    WebServerInternalPath.PRACTICE_INTERVIEW_DEPRECATED,
    WebServerInternalPath.INTERVIEW_DEPRECATED,
    WebServerInternalPath.IMPROMPTU_PROMPT_DEPRECATED,
  ].includes(history.location.pathname);
  const options = getDropDownButtonOptions(isToastmasters(siteConf), orgModuleAccess);
  if (!isToastmasters(siteConf)) {
    options.push({
      value: "Upload",
      iconId: "upload",
      tooltip: uploadDisabled ? "Can't upload while practicing!" : undefined,
      disabled: uploadDisabled,
      onClick: () => {
        setPracticeTypeDropdownOpen(false);
        handleUploadSpeechClick();
      },
    });
  }

  const [open, setOpen] = React.useState(practiceTypeDropdownOpen);
  React.useEffect(() => {
    setOpen(practiceTypeDropdownOpen);
  }, [practiceTypeDropdownOpen]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (path?: string) => {
    setOpen(false);
    if (!path) return;
    handleDelayClose();
    switch (path) {
      case WebServerExternalPath.PRACTICE_INTERVIEW:
        Instrumentation.logRecordInterviewCheckpoint(EventsRecordWheres.SIDENAV);
        break;
      case WebServerExternalPath.PRACTICE_CONVERSATION:
        Instrumentation.logRecordConversationCheckpoint(EventsRecordWheres.SIDENAV);
        break;
      case WebServerExternalPath.PRACTICE_SPEECH:
        Instrumentation.logRecordSpeechCheckpoint(EventsRecordWheres.SIDENAV);
        break;
    }
    history.push(path);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (mouseExitTimeout) clearTimeout(mouseExitTimeout);
    setAnchorEl(event.currentTarget);
    setPracticeTypeDropdownOpen(true);
  };

  // delay closing ever so slightly so that if i drag across the corner it doesnt annoyingly close on accident
  const handleDelayClose = () => {
    mouseExitTimeout = setTimeout(() => {
      setPracticeTypeDropdownOpen(false);
    }, 100);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div onMouseLeave={handleDelayClose} onMouseEnter={handleOpen}>
      <Button
        disableElevation
        startIcon={<VideoCameraIcon fontSize="large" />}
        variant={isWhiteLabel() ? "contained" : "gradient"}
        sx={{
          backgroundColor: isWhiteLabel() ? getDynamicColor("primary") : "unset",
          borderRadius: "8px",
          fontWeight: 600,
          fontSize: 16,
          height: 48,
          mx: "12px",
          width: "calc(100% - 24px)",
          ...(!drawerOpen && {
            ".MuiButton-startIcon": {
              mx: 0,
            },
          }),
        }}
        data-testid={UITestId.PracticeButton}
      >
        {drawerOpen && "Practice"}
      </Button>
      <Popper
        style={{ zIndex: 10001 }}
        placement="right-start"
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={250}>
            <MenuList
              sx={{
                backgroundColor: getDynamicColor("light1"),
                color: getDynamicColor("purple3"),
                border: `1px solid ${getDynamicColor("dark2")}`,
                boxShadow: Y_SHADOWS.box_shadow_1,
                borderRadius: "12px",
                position: "relative",
                bottom: 24,
                right: 12,
                minWidth: 200,
                py: "0 !important",
              }}
            >
              {options.map((option, i) => {
                return (
                  <Tooltip title={option.tooltip} key={option.value}>
                    <div>
                      <MenuItem
                        aria-label={`Record ${option.value}`}
                        key={`recording-option-${i}`}
                        disabled={option.disabled}
                        onClick={() => {
                          if (!option.disabled) {
                            if (option.onClick) option.onClick();
                            handleClick(option.path);
                          }
                        }}
                        sx={{
                          py: 1.5,
                          pl: 2.5,
                          fontFamily: "poppins",
                          fontWeight: 400,
                          lineHeight: 1.3,
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Box
                            sx={{
                              position: "relative",
                              svg: {
                                height: "100%",
                                width: "100%",
                                display: "block",
                              },
                            }}
                          >
                            {iconMap[option.iconId]()}
                          </Box>
                          {option.value}
                        </Stack>
                      </MenuItem>
                    </div>
                  </Tooltip>
                );
              })}
            </MenuList>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
