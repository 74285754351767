import React from "react";

// Components
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, IconButton, Stack, SxProps, Button, ButtonProps } from "@mui/material";

// Utils
import YoodliTooltip from "./YoodliTooltip";
import { DynamicColorType, getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

export enum YoodliMenuItemType {
  Default = "default",
  Warning = "warning",
  Primary = "primary",
}

export enum YoodliMenuButtonType {
  Text = "text",
  Icon = "icon",
}

const YoodliMenuItemTypeColorMap: Record<YoodliMenuItemType, DynamicColorType> = {
  [YoodliMenuItemType.Default]: "purple3",
  [YoodliMenuItemType.Warning]: "redError",
  [YoodliMenuItemType.Primary]: "primary",
};

type YoodliMenuProps = {
  type: YoodliMenuButtonType;
  menuItems: {
    title: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
    type: YoodliMenuItemType;
    disabledTooltip?: string;
    keepOpen?: boolean;
  }[];
  icon?: JSX.Element;
  buttonSx?: SxProps;
  buttonText?: string;
  buttonProps?: ButtonProps;
};

export const YoodliMenu = ({
  type,
  menuItems,
  icon,
  buttonSx,
  buttonProps,
  buttonText,
}: YoodliMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const renderMenuButton = () => {
    switch (type) {
      case YoodliMenuButtonType.Icon:
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              mx: 2,
              width: "28px",
              height: "28px",
              color: getDynamicColor("primary"),
              border: `2px solid ${getDynamicColor("primary")}`,
              borderRadius: "32px",
              position: "relative",
              ...buttonSx,
            }}
          >
            {icon ?? <MoreHorizIcon />}
          </IconButton>
        );
      case YoodliMenuButtonType.Text:
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            {...buttonProps}
            sx={{
              ...buttonSx,
            }}
          >
            {buttonText ?? "More"}
          </Button>
        );
    }
  };

  return (
    <>
      {renderMenuButton()}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e: Event) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              background: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
      >
        <Stack
          width="fit-content"
          minWidth="150px"
          mt={1.5}
          py={2}
          gap={1}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ background: getDynamicColor("light1") }}
        >
          {menuItems.map((item, index) => (
            <YoodliTooltip
              key={index}
              title={item.disabled && item.disabledTooltip}
              sx={{ width: "100%" }}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  item.onClick();
                  if (!item.keepOpen) setAnchorEl(null);
                }}
                disabled={item.disabled}
                sx={{
                  fontFamily: "Poppins",
                  width: "100%",
                  color: getDynamicColor(YoodliMenuItemTypeColorMap[item.type]),
                }}
              >
                {item.title}
              </MenuItem>
            </YoodliTooltip>
          ))}
        </Stack>
      </Menu>
    </>
  );
};
