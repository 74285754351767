import { isSafari } from "react-device-detect";

// Utils
import { UploadProgressCallback } from "./resumableUpload";
import { SpeechUploader } from "./SpeechUploader";
// Utilities
import { UploadFileType } from "lib-fullstack/api/apiTypes";

/**
 * Uploads a raw video file as a speech
 */
export const uploadVideoFile = async (
  file: File,
  callback: UploadProgressCallback,
  speechId: string,
  extension: string
): Promise<string> => {
  const uploader = new SpeechUploader(file, speechId, UploadFileType.VIDEO, extension);
  await uploader.initializeServer();
  return await uploader.upload(null, callback);
};

/**
 * Uploads a speech recording
 */
export const uploadRecording = async (
  blob: Blob,
  durationMs: number,
  speechId: string
): Promise<string> => {
  const videoFileExtension = isSafari ? "mp4" : "webm";
  const uploader = new SpeechUploader(blob, speechId, UploadFileType.VIDEO, videoFileExtension);
  await uploader.initializeServer();
  return await uploader.upload(durationMs, null);
};

/**
 * Upload a data file
 */
export const uploadData = async (
  blob: Blob,
  speechId: string,
  fileType: UploadFileType
): Promise<string | null> => {
  const uploader = new SpeechUploader(blob, speechId, fileType);
  await uploader.initializeServer();
  return await uploader.upload(null, null);
};
