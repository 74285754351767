import React from "react";
import { useHistory, useLocation } from "react-router";

// Components
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import {
  Stack,
  Box,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalState,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliTabSelector } from "lib-frontend/components/YoodliComponents/YoodliTabSelector";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";

// Utils
import { EnrolledGroups } from "./EnrolledGroups";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramFtuxChecklist } from "./ProgramFtuxChecklist";
import { ProgramMembers } from "./ProgramMembers";
import { ProgramOverview } from "./ProgramOverview";
import { ProgramScenarios } from "./ProgramScenarios";
import { ProgramStatus } from "./ProgramStatus";
import { useMutation, useQueryClient, useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  updateOrgProgram,
  listScenarios,
  createOrgProgram,
  deleteOrgProgram,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getHubDataForOrg } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgProgramsQueryParams } from "lib-frontend/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ProgramState } from "lib-fullstack/utils/enums";
import { MOBILE_TOP_NAVBAR_HEIGHT } from "utils/Constants";

const PROGRAM_FTUX_CHECKLIST_DISMISSED = "programFtuxChecklistDismissed";

export enum ProgramInfoTab {
  Overview = "Overview",
  Scenarios = "Scenarios",
  Groups = "Groups",
}

type ProgramInfoProps = {
  program: ProgramResponse;
  handleBack: () => void;
  anyProgramPublished: boolean;
};

export const ProgramInfo = ({
  program,
  handleBack,
  anyProgramPublished,
}: ProgramInfoProps): JSX.Element => {
  const queryClient = useQueryClient();
  const isSmallScreen = useIsSmallScreen();
  const history = useHistory();
  const location = useLocation();
  const { showNotificationBanner, notifAnchorRef } = useNotification();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const [updatedName, setUpdatedName] = React.useState<string>(undefined);
  const [updatedDescription, setUpdatedDescription] = React.useState<string>(undefined);
  const [deleteModalState, setDeleteModalState] = React.useState<YoodliCtaModalState>(undefined);
  const [publishModalOpen, setPublishModalOpen] = React.useState<boolean>(false);
  const [ftuxChecklistDismissed, setFtuxChecklistDismissed] = React.useState<boolean>(
    !!localStorage.getItem(PROGRAM_FTUX_CHECKLIST_DISMISSED)
  );
  const [activeTab, setActiveTab] = useQueryParamState(
    OrgProgramsQueryParams.PROGRAM_TAB,
    ProgramInfoTab.Overview
  );

  const qp = new URLSearchParams(location.search);

  const hasEvaluators = program?.leaders.length > 0;
  const hasManualGradedScenarios = program?.plan_steps.some(
    (step) => step.completion_human_evaluation
  );

  const hubDataQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.HubData, defaultOrg?.id],
    queryFn: () => getHubDataForOrg(defaultOrg),
    refetchOnWindowFocus: false,
  });

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrg.id],
    queryFn: () => listScenarios(defaultOrg.id),
    refetchOnWindowFocus: false,
  });
  const filteredScenarios = scenariosQuery.data?.contentArray.filter(
    (scenario) => !scenario.isTemplate && scenario.enabled
  );

  const updateNameMutation = useMutation({
    mutationFn: () => updateOrgProgram(defaultOrg.id, program.id, { name: updatedName }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
      setUpdatedName(undefined);
    },
  });

  const updateDescriptionMutation = useMutation({
    mutationFn: () =>
      updateOrgProgram(defaultOrg.id, program.id, { description: updatedDescription }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
      setUpdatedDescription(undefined);
    },
  });

  const duplicateProgramMutation = useMutation({
    mutationFn: () =>
      createOrgProgram(defaultOrg.id, {
        name: `${program.name} (copy)`,
        program_id_to_copy: program.id,
      }),
    onSuccess: async () => {
      Instrumentation.logProgramCreated(defaultOrg.id, program.id, true);
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
      qp.set(OrgProgramsQueryParams.TAB, ProgramState.Draft);
      history.replace({ search: qp.toString() });
      handleBack();
    },
  });

  const deleteProgramMutation = useMutation({
    mutationFn: () => deleteOrgProgram(defaultOrg.id, program.id),
    onSuccess: () => {
      Instrumentation.logProgramDeleted(defaultOrg.id, program.id);
      setDeleteModalState(YoodliCtaModalState.Success);
    },
  });

  const updateProgramStateMutation = useMutation({
    mutationFn: (state: ProgramState) =>
      updateOrgProgram(defaultOrg.id, program.id, { state: state }),
    onSuccess: (_, state) => {
      if (state === ProgramState.Archived) {
        Instrumentation.logProgramArchived(defaultOrg.id, program.id);
      } else if (state === ProgramState.Published) {
        Instrumentation.logProgramPublished(defaultOrg.id, program.id);
      }
      qp.set(OrgProgramsQueryParams.TAB, state);
      history.replace({ search: qp.toString() });
      return queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
    },
  });

  const programStateCtaData: {
    text: string;
    handler: () => void;
    variant: "gradient" | "contained";
    disabled?: boolean;
  } =
    program?.state === ProgramState.Draft
      ? {
          text: "Publish",
          handler: () => setPublishModalOpen(true),
          variant: "gradient",
          disabled:
            !program.hub_ids.length ||
            !program.plan_steps.length ||
            updatedName?.length > 0 ||
            updatedDescription?.length > 0,
        }
      : program?.state === ProgramState.Archived
      ? {
          text: "Unarchive",
          handler: () => setPublishModalOpen(true),
          variant: "gradient",
          disabled:
            !program.hub_ids.length ||
            !program.plan_steps.length ||
            updatedName?.length > 0 ||
            updatedDescription?.length > 0,
        }
      : {
          text: "Archive",
          handler: () => updateProgramStateMutation.mutate(ProgramState.Archived),
          variant: "contained",
          disabled: updatedName?.length > 0 || updatedDescription?.length > 0,
        };

  const renderTab = () => {
    if (hubDataQuery.isPending || scenariosQuery.isPending) {
      return <CircularProgress />;
    }

    switch (activeTab) {
      case ProgramInfoTab.Overview:
        return (
          <ProgramOverview
            program={program}
            setActiveTab={setActiveTab}
            hubs={hubDataQuery.data}
            scenarios={filteredScenarios}
          />
        );
      case ProgramInfoTab.Scenarios:
        return (
          <ProgramScenarios
            program={program}
            scenarios={filteredScenarios}
            handleCopyPracticeLink={() => showNotificationBanner("Practice link copied!")}
          />
        );
      case ProgramInfoTab.Groups:
        return (
          <Stack gap={7}>
            <EnrolledGroups program={program} hubs={hubDataQuery.data} />
            <ProgramMembers program={program} />
          </Stack>
        );
    }
  };

  if (!program) {
    return;
  }

  return (
    <Stack
      gap={{ xs: 3, md: 6 }}
      sx={{
        minHeight: { xs: `calc(100vh - ${MOBILE_TOP_NAVBAR_HEIGHT})`, md: "100dvh" },
        background: getDynamicColor("light1"),
        pb: { xs: 2, md: 14 },
      }}
    >
      <Stack>
        <Box
          sx={{
            ...defaultHeaderSx,
            backgroundColor: getDynamicColor("purple3"),
            height: "85px",
            display: "flex",
            alignItems: "center",
            pl: 4,
          }}
          ref={notifAnchorRef}
        >
          <Button
            onClick={handleBack}
            startIcon={<ArrowBackIosIcon />}
            sx={{
              color: getDynamicColor("light1"),
            }}
          >
            Programs
          </Button>
        </Box>
        {!anyProgramPublished && !ftuxChecklistDismissed && (
          <ProgramFtuxChecklist
            program={program}
            handleCloseChecklist={() => {
              localStorage.setItem(PROGRAM_FTUX_CHECKLIST_DISMISSED, "true");
              setFtuxChecklistDismissed(true);
            }}
          />
        )}
      </Stack>
      <Stack gap={2} sx={{ pl: { xs: 3, md: 8 }, pr: { xs: 3, md: 6 }, maxWidth: "xxl" }}>
        <Stack
          gap={1}
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Stack gap={1} direction="row" sx={{ alignItems: "center", width: "100%" }}>
            {updatedName !== undefined ? (
              <YoodliTextfield
                disabled={updateNameMutation.isPending}
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
                placeholder="e.g. Hooli sales training"
                maxChars={100}
                fullWidth
              />
            ) : (
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 700,
                }}
              >
                {program.name}
              </Typography>
            )}
            <IconButton
              disabled={updateNameMutation.isPending}
              onClick={() => {
                if (updatedName !== undefined) {
                  if (updatedName !== program.name && updatedName.length > 0) {
                    updateNameMutation.mutate();
                  } else {
                    setUpdatedName(undefined);
                  }
                } else {
                  setUpdatedName(program.name);
                }
              }}
            >
              {updatedName !== undefined ? (
                <CheckIcon sx={{ color: getDynamicColor("primary"), width: 20, height: 20 }} />
              ) : (
                <EditOutlinedIcon
                  sx={{ color: getDynamicColor("primary"), width: 20, height: 20 }}
                />
              )}
            </IconButton>
          </Stack>
          <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
            {!isSmallScreen && (
              <Button
                onClick={programStateCtaData.handler}
                variant={programStateCtaData.variant}
                disabled={programStateCtaData.disabled}
                sx={{
                  "&:disabled": {
                    background: getDynamicColor("dark4"),
                    color: getDynamicColor("light1"),
                  },
                }}
              >
                {programStateCtaData.text}
              </Button>
            )}
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              menuItems={[
                isSmallScreen && {
                  title: programStateCtaData.text,
                  onClick: programStateCtaData.handler,
                  disabled: programStateCtaData.disabled,
                  type: YoodliMenuItemType.Default,
                },
                {
                  title: duplicateProgramMutation.isPending ? (
                    <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                      <CircularProgress size={16} />
                      <Typography>Duplicating...</Typography>
                    </Stack>
                  ) : (
                    "Duplicate"
                  ),
                  onClick: () => duplicateProgramMutation.mutate(),
                  type: YoodliMenuItemType.Default,
                  disabled: duplicateProgramMutation.isPending,
                  keepOpen: true,
                },
                {
                  title: "Delete",
                  onClick: () => setDeleteModalState(YoodliCtaModalState.Cta),
                  type: YoodliMenuItemType.Warning,
                },
              ].filter(Boolean)}
              buttonSx={{ border: "none" }}
            />
          </Stack>
        </Stack>

        <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
          {updatedDescription !== undefined ? (
            <YoodliTextfield
              disabled={updateDescriptionMutation.isPending}
              value={updatedDescription}
              onChange={(e) => setUpdatedDescription(e.target.value)}
              rows={3}
              multiline
              placeholder="Learn company best practices for demo-ing service offerings, troubleshooting with existing customers, and converting prospects into sales."
              maxChars={300}
              fullWidth
            />
          ) : (
            <Typography
              sx={{
                color: getDynamicColor(program.description.length ? "purple3" : "dark4"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {program.description.length ? program.description : "No description"}
            </Typography>
          )}
          <IconButton
            disabled={updateDescriptionMutation.isPending}
            onClick={() => {
              if (updatedDescription !== undefined) {
                if (updatedDescription !== program.description) {
                  updateDescriptionMutation.mutate();
                } else {
                  setUpdatedDescription(undefined);
                }
              } else {
                setUpdatedDescription(program.description);
              }
            }}
          >
            {updatedDescription !== undefined ? (
              <CheckIcon sx={{ color: getDynamicColor("primary"), width: 20, height: 20 }} />
            ) : (
              <EditOutlinedIcon sx={{ color: getDynamicColor("primary"), width: 20, height: 20 }} />
            )}
          </IconButton>
        </Stack>
        <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
          <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
            <CalendarIcon />
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: 10,
                fontWeight: 500,
              }}
            >
              Created {getHumanReadableDate(program.created_date)}
            </Typography>
          </Stack>
          <ProgramStatus state={program.state as ProgramState} />
        </Stack>
      </Stack>
      <Stack gap={5} sx={{ pl: { xs: 2, md: 8 }, pr: { xs: 2, md: 6 }, maxWidth: "xxl" }}>
        <YoodliTabSelector
          tabs={Object.values(ProgramInfoTab).map((tab) => ({ label: tab, value: tab }))}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab as ProgramInfoTab)}
        />
        {renderTab()}
      </Stack>
      <YoodliCtaModal
        open={!!deleteModalState}
        close={() => {
          if (deleteModalState === YoodliCtaModalState.Success) {
            void queryClient.invalidateQueries({
              queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
            });
            handleBack();
          } else {
            setDeleteModalState(undefined);
          }
        }}
        state={deleteModalState}
        ctaBody={{
          title: `Are you sure you want to delete ${program.name}?`,
          subtitle:
            "This will remove it from your library and any progress you made will not be saved",
        }}
        successBody={{
          title: "Program deleted",
          subtitle: "You have successfully deleted this program",
        }}
        buttons={
          {
            primary: {
              text: "Confirm delete",
              handler: () => deleteProgramMutation.mutate(),
            },
            secondary: { text: "Cancel", handler: () => setDeleteModalState(undefined) },
          } as CtaButtonHandlers
        }
      />
      <YoodliCtaModal
        open={publishModalOpen}
        close={() => setPublishModalOpen(false)}
        ctaBody={{
          title:
            program.state === ProgramState.Archived
              ? "Are you sure you want to unarchive this program?"
              : "Things to know before you publish",
          subtitle:
            program.state === ProgramState.Archived ? (
              "This will make the program published and available to all groups."
            ) : (
              <Stack gap={3}>
                {!hasEvaluators && hasManualGradedScenarios && (
                  <Stack gap={1} direction="row" sx={{ alignItems: "flex-start" }}>
                    <WarningAmberRounded sx={{ color: getDynamicColor("yellowWarningDark") }} />
                    <Typography>
                      You have enabled grading as a required step but haven't set any evaluators.
                      Members will skip the grading step if they don't have a grader assigned by an
                      integration
                    </Typography>
                  </Stack>
                )}
                <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
                  <ListItem sx={{ display: "list-item" }}>
                    Scenarios will automatically become available to all members in your added
                    groups
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    You may edit the title and description even after you have published
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    You will no longer be able to make changes to the completion criteria of this
                    program
                  </ListItem>
                </List>
                <Typography>Hit ‘Publish’ if you’re all set or ‘Cancel’ to go back </Typography>
              </Stack>
            ),
        }}
        buttons={
          {
            primary: {
              text: program.state === ProgramState.Archived ? "Yes, I'm sure" : "Publish",
              handler: () => updateProgramStateMutation.mutate(ProgramState.Published),
            },
            secondary: { text: "Cancel", handler: () => setPublishModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
