// Components
import { Stack, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type CreateHubProps = {
  hubName: string;
  setHubName: (name: string) => void;
};

export const CreateHub = ({ hubName, setHubName }: CreateHubProps): JSX.Element => {
  return (
    <Stack gap={1} sx={{ pt: 2, px: 2 }}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontSize: "16px",
          fontWeight: 700,
          fontFamily: "poppins",
          pb: 1,
        }}
      >
        Name your group
      </Typography>
      <YoodliTextfield
        autoFocus
        value={hubName}
        onChange={(e) => setHubName(e.target.value)}
        placeholder="Name of group"
        fullWidth
        maxChars={30}
      />
    </Stack>
  );
};
