// Components
import { Stack } from "@mui/material";
import { TEMPLATE_SUB_TYPE_DATA } from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

export type ScenarioDescriptors = {
  title: string;
  description: string;
};

type ScenarioPreviewProps = {
  templateSubType: ScenarioTemplateSubType;
  title: string;
  titleLabel?: string;
  description: string;
  descriptionLabel?: string;
  decriptionPlaceholder?: string;
  maxDescriptionLength?: number;
  handleUpdateScenarioDetails: (key: keyof ScenarioDescriptors, value: string) => void;
};

// TODO: rename this to better represent the details that a user can change (title/desc) instead of just a purely preview page
export const ScenarioPreview = ({
  templateSubType,
  title,
  titleLabel,
  description,
  descriptionLabel,
  decriptionPlaceholder,
  maxDescriptionLength,
  handleUpdateScenarioDetails,
}: ScenarioPreviewProps): JSX.Element => {
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        background: getDynamicColor("gradient.slantedLight"),
        borderRadius: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        py: 3,
        px: 2.5,
      }}
    >
      <YoodliLabeledInput
        label={titleLabel || "Scenario name"}
        inputEl={
          <YoodliTextfield
            autoFocus
            inputProps={{
              className: "blockEnterToNavigate",
              sx: {
                fontWeight: 700,
              },
            }}
            maxChars={200}
            InputLabelProps={{ shrink: true }}
            placeholder={TEMPLATE_SUB_TYPE_DATA[templateSubType].label}
            value={title}
            onChange={(e) => handleUpdateScenarioDetails("title", e.target.value)}
            sx={{
              backgroundColor: getDynamicColor("light1"),
            }}
          />
        }
      />
      <YoodliLabeledInput
        label={descriptionLabel || "Member-facing Description"}
        inputEl={
          <YoodliTextfield
            inputProps={{
              className: "blockEnterToNavigate",
            }}
            maxChars={maxDescriptionLength ?? 1000}
            fadeInCharCountPct={75}
            multiline
            minRows={4}
            InputLabelProps={{ shrink: true }}
            placeholder={
              decriptionPlaceholder ?? "Write a short member-facing description of your scenario."
            }
            value={description.slice(0, maxDescriptionLength ?? description.length)}
            onChange={(e) => handleUpdateScenarioDetails("description", e.target.value)}
            sx={{
              backgroundColor: getDynamicColor("light1"),
            }}
          />
        }
      />
    </Stack>
  );
};
