import React from "react";

// Components
import { Button, Stack, Typography } from "@mui/material";
import { VideosAndCoursesTab } from "components/Orgs/ManageContent/VideosAndCourses/VideosAndCoursesTab";
import { OrgLoading } from "components/Orgs/OrgLoading";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { MemberProgramInfo } from "./Programs/MemberProgramInfo";
import { MemberProgramLibrary } from "./Programs/MemberProgramLibrary";
import { OrgProgramsQueryKeys } from "./Programs/OrgPrograms";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getOrgContentMemberViewV2,
  listProgramsMemberView,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { MyLearningQueryParams } from "lib-frontend/utils/queryParams";
import { UITestId } from "lib-fullstack/utils/enums";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export enum MyLearningTabs {
  Programs = "Programs",
  Courses = "Courses",
}

export enum MyLearningSection {
  Default = "Default",
  ProgramInfo = "ProgramInfo", // NOTE (2024-08-19): This is used as a query param for CIO email template CTA (see sendReminder in programHandlers.ts)
}

export default function MyLearning(): JSX.Element {
  const userOrgContext = React.useContext(UserOrgContext);

  const [activeTab, setActiveTab] = useQueryParamState(
    MyLearningQueryParams.TAB,
    MyLearningTabs.Programs
  );
  const [section, setSection] = useQueryParamState(
    MyLearningQueryParams.SECTION,
    MyLearningSection.Default
  );
  const [programId, setProgramId] = useQueryParamState(MyLearningQueryParams.PROGRAM_ID, undefined);

  React.useEffect(() => {
    if (!programId && section === MyLearningSection.ProgramInfo) {
      setSection(MyLearningSection.Default);
    }
  }, [programId]);

  React.useEffect(() => {
    if (userOrgContext.defaultOrg) {
      Instrumentation.logLearningPageViewed(userOrgContext.defaultOrg.id);
    }
  }, [userOrgContext.defaultOrgLoading]);

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY, userOrgContext.orgId],
    queryFn: async () => getOrgContentMemberViewV2(userOrgContext.orgId),
  });
  const videosAndCourses = videosAndCoursesQueryResult.data;

  const programMemberViewQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.MemberView],
    queryFn: () => listProgramsMemberView(),
  });

  const renderTab = () => {
    switch (activeTab) {
      case MyLearningTabs.Programs:
        return (
          <MemberProgramLibrary
            programs={programMemberViewQuery.data}
            handleViewProgram={(programId) => {
              setSection(MyLearningSection.ProgramInfo);
              setProgramId(programId);
            }}
          />
        );
      case MyLearningTabs.Courses:
        return (
          <VideosAndCoursesTab
            contentView={videosAndCourses}
            selectedOrgId={userOrgContext.orgId}
            // This variable seems to have been just mysteriously not set but...compiled somehow??
            isHubAdmin={false}
          />
        );
    }
  };

  if (
    userOrgContext.loading ||
    videosAndCoursesQueryResult.isPending ||
    programMemberViewQuery.isPending ||
    userOrgContext.defaultOrgLoading
  ) {
    return <OrgLoading prompts={["Loading resources..."]} />;
  }

  switch (section) {
    case MyLearningSection.ProgramInfo:
      return (
        <MemberProgramInfo
          program={programMemberViewQuery.data.find((program) => program.id === programId)}
          handleBack={() => {
            setSection(MyLearningSection.Default);
            setProgramId(undefined);
          }}
        />
      );
    case MyLearningSection.Default:
    default:
      return (
        <Stack sx={{ background: getDynamicColor("light1"), minHeight: "100dvh" }}>
          <Stack
            gap={{ xs: 2, md: 5 }}
            sx={{
              ...defaultHeaderSx,
              px: { xs: 2, md: 6 },
              pt: 3,
            }}
          >
            <Typography
              data-testid={UITestId.MyLearningTitle}
              sx={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: 600,
                color: getDynamicColor("light1"),
              }}
            >
              My Learning
            </Typography>

            <Stack
              data-testid={UITestId.MyLearningTabs}
              direction="row"
              gap={10}
              sx={{ alignItems: "center" }}
            >
              {Object.values(MyLearningTabs).map((tab) => (
                <Button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  sx={{
                    py: 0.5,
                    px: 0,
                    color: getDynamicColor("light1"),
                    fontWeight: 600,
                    fontSize: "14px",
                    borderRadius: 0,
                    borderBottom: "2px solid",
                    borderColor: activeTab === tab ? getDynamicColor("light1") : "transparent",
                  }}
                >
                  {tab}
                </Button>
              ))}
            </Stack>
          </Stack>
          {renderTab()}
        </Stack>
      );
  }
}
