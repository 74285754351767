import React from "react";
import { useHistory } from "react-router";

// Components
import { Circle as CircleIcon } from "@mui/icons-material";
import { Button, Card, Link, Stack, Typography } from "@mui/material";
import UsageItem from "lib-frontend/components/UsageItem";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { EffectiveRole } from "lib-fullstack/utils/enums";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import { WebServerInternalPath } from "utils/paths";
import { getPlanData } from "utils/PricingUtils";

type CurrentPlanProps = {
  handleTogglePricingModal: (open?: boolean, copy?: string) => void;
  usedSpeeches: number;
  usagePlanType: UsagePlanType;
  usagePlanDuration: UsagePlanDuration;
  thresholdHit?: boolean;
  quota: number;
};

const CurrentPlan = ({
  handleTogglePricingModal,
  usedSpeeches,
  usagePlanType,
  usagePlanDuration,
  thresholdHit,
  quota,
}: CurrentPlanProps): JSX.Element => {
  const history = useHistory();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const isAnnual = [UsagePlanDuration.NONE, UsagePlanDuration.YEARLY].includes(usagePlanDuration);
  let copy: string | JSX.Element = `You're on ${getPlanData(usagePlanType, isAnnual)?.title}`;
  if (usagePlanType === UsagePlanType.ENTERPRISE) {
    copy = (
      <>
        You’re covered under an{" "}
        {defaultOrg?.effective_role == EffectiveRole.ORG_ADMIN ||
        defaultOrg?.effective_role == EffectiveRole.ORG_OWNER ? (
          <Link
            onClick={() => history.push(WebServerInternalPath.ORG_SETTINGS)}
            sx={{ cursor: "pointer" }}
          >
            Enterprise plan
          </Link>
        ) : (
          "Enterprise plan"
        )}
      </>
    );
  }
  const renderCurrentPlanCta = () => {
    const isFree = usagePlanType === UsagePlanType.FREE;
    if (usagePlanType === UsagePlanType.ENTERPRISE) {
      return null;
    } else if (usagePlanType === UsagePlanType.UNLIMITED) {
      return (
        <Button variant="text" onClick={() => handleTogglePricingModal(true, "Manage plan")}>
          Manage plan
        </Button>
      );
    } else {
      return (
        <Stack
          direction="row"
          gap={{ xs: 1, sm: 5, md: 6, lg: 7 }}
          alignItems="center"
          sx={{
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <UsageItem
            open
            showResetsInCopy={usagePlanType === UsagePlanType.PRO_V1}
            resetsInCopySx={{
              fontSize: { xs: 11, md: 13 },
              color: getDynamicColor(thresholdHit ? "redError" : "dark4"),
            }}
            numUsed={usedSpeeches}
            quota={quota}
            icon={
              <CircleIcon
                sx={{
                  width: "12px",
                  height: "12px",
                  color: getDynamicColor(thresholdHit ? "redError" : "purple3"),
                }}
              />
            }
            progressSize={22}
            progressColor={getDynamicColor(thresholdHit ? "redError" : "purple3")}
            textSx={{
              fontSize: { xs: 12, md: 14 },
              color: getDynamicColor(thresholdHit ? "redError" : "purple3"),
            }}
            wrapperSx={{
              gap: 1.5,
              flexBasis: { xs: "50%", sm: "auto" },
            }}
          />
          <Button
            variant="text"
            onClick={() => handleTogglePricingModal(true, isFree ? "See all plans" : "UPGRADE")}
            sx={{
              flexBasis: { xs: "50%", sm: "auto" },

              width: { xs: "100%", sm: "auto" },
              px: 3,
              fontSize: 14,
              svg: {
                height: 16,
                width: 16,
              },
            }}
          >
            See all plans
          </Button>
        </Stack>
      );
    }
  };
  return (
    <Stack gap={2}>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 2,
          px: { xs: 2, sm: 5 },
          py: { xs: 2, sm: 2.5 },
          minHeight: 84,
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.box_shadow_1,
          color: getDynamicColor("purple3"),
          backgroundColor: getDynamicColor(thresholdHit ? "redErrorLight" : "light1"),
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          {copy}
        </Typography>
        {renderCurrentPlanCta()}
      </Card>
    </Stack>
  );
};

export default CurrentPlan;
