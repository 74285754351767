import React from "react";

// Components
import { CheckRounded as CheckRoundedIcon } from "@mui/icons-material";
import { WarningRounded as WarningIcon } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  Stack,
  Typography,
  Link,
  CircularProgress,
  SxProps,
  Box,
  Divider,
} from "@mui/material";
import { YoodliMultiEmail } from "lib-frontend/components/YoodliComponents/YoodliMultiEmail";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import {
  YoodliSelect,
  YoodliSelectOption,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { HubsListSpan } from "./HubsListSpan";
import { OrgMembersQueryKeys } from "./Members/OrgMembers";
import OrgSectionWrapper from "./OrgSectionWrapper";
import { UpdateOrgSeatsControls } from "./UpdateOrgSeatsControls";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createOrgUserV2,
  upsetPaygOrgSubscription,
  getOrgEmailBranding,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgOwnerAdmin } from "lib-frontend/utils/orgUtils";
import { SupportPages } from "lib-frontend/utils/paths";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { UpsetPaygOrgSubscriptionResult } from "lib-fullstack/api/hubApiTypes";
import { AddOrgUserResult, OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { EMAIL_BRANDING_MESSAGE_MAX_LENGTH } from "lib-fullstack/utils/constants";
import { HubRole, OrgRole, OrgSubscriptionType } from "lib-fullstack/utils/enums";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

type InviteMemberControlsProps = {
  title: string;
  tooltipText?: string | JSX.Element;
  control: JSX.Element;
  containerSx?: SxProps;
};

export const MAX_EMAIL_INVITE_COUNT = 50;

const InviteMemberControls = ({
  title,
  tooltipText,
  control,
  containerSx,
}: InviteMemberControlsProps): JSX.Element => {
  return (
    <Stack gap={0.5} sx={{ width: { xs: "100%", md: "auto" }, ...containerSx }}>
      <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {tooltipText && (
          <YoodliTooltip title={tooltipText}>
            <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
          </YoodliTooltip>
        )}
      </Stack>
      {control}
    </Stack>
  );
};

type InvitesSentReviewProps = {
  failedEmails: string[];
  invitedEmails: string[];
  addedEmails: string[];
  roleStr: string;
  hubNames: string[];
};

export const InvitesSentReview = ({
  failedEmails,
  invitedEmails,
  addedEmails,
  roleStr,
  hubNames,
}: InvitesSentReviewProps): JSX.Element => (
  <Stack
    gap={1}
    sx={{
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      px: 2,
    }}
  >
    {failedEmails.length === 0 && (
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        <CheckCircleIcon sx={{ color: getDynamicColor("greenSuccess"), width: 24, height: 24 }} />
        <Typography
          sx={{
            color: getDynamicColor("greenSuccess"),
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Invites sent
        </Typography>
      </Stack>
    )}
    {failedEmails.length > 0 && (
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        <WarningIcon sx={{ color: getDynamicColor("warningOrangeDark"), width: 24, height: 24 }} />
        <Typography
          sx={{
            color: getDynamicColor("warningOrangeDark"),
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Some invites failed to send
        </Typography>
      </Stack>
    )}
    <Stack sx={{ gap: 2 }}>
      {" "}
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 600,
          maxWidth: "500px",
        }}
      >
        We successfully sent out invitations to be{" "}
        <Box component="span" sx={{ color: getDynamicColor("primary") }}>
          {roleStr}
        </Box>{" "}
        in <HubsListSpan hubsList={hubNames} />. Any existing or duplicate individuals were ignored.
        You can view and manage your invitations from the Members page.
      </Typography>
      <Stack sx={{ gap: 1 }}>
        {invitedEmails.length > 0 && (
          <Stack direction="row" sx={{ gap: 0.5, alignItems: "center", justifyContent: "center" }}>
            <CheckRoundedIcon
              sx={{
                color: getDynamicColor("purple3"),
                stroke: getDynamicColor("purple3"),
                strokeWidth: "1.5px",
                height: 16,
                width: 16,
              }}
            />
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
                maxWidth: "500px",
              }}
            >
              {invitedEmails.length} invitation
              {invitedEmails.length === 1 ? " was" : "s were"} sent.
            </Typography>
          </Stack>
        )}
        {addedEmails.length > 0 && (
          <Stack direction="row" sx={{ gap: 0.5, alignItems: "center", justifyContent: "center" }}>
            <CheckRoundedIcon
              sx={{
                color: getDynamicColor("purple3"),
                stroke: getDynamicColor("purple3"),
                strokeWidth: "1.5px",
                height: 16,
                width: 16,
              }}
            />
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
                maxWidth: "500px",
              }}
            >
              {addedEmails.length} member{addedEmails.length === 1 ? " was" : "s were"} directly
              added.
            </Typography>
          </Stack>
        )}
      </Stack>
      {failedEmails.length > 0 && (
        <>
          <Divider />
          <Stack>
            <Stack
              direction="row"
              sx={{ gap: 0.5, alignItems: "center", justifyContent: "center" }}
            >
              <WarningIcon
                sx={{ color: getDynamicColor("warningOrangeLight"), width: 16, height: 16 }}
              />
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                  maxWidth: "500px",
                }}
              >
                The following {failedEmails.length} invitation
                {failedEmails.length === 1 ? "" : "s"} failed to send.
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
              {failedEmails.map((email) => (
                <Typography sx={{ fontSize: "12px", fontFamily: "Poppins" }} key={email}>
                  {email}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  </Stack>
);

export enum InviteMemberRole {
  HUB_MEMBER = HubRole.MEMBER,
  HUB_ADMIN = HubRole.ADMIN,
  ORG_ADMIN = OrgRole.ADMIN,
}

type InviteMembersProps = {
  title: string;
  subtitle: string;
  backCopy: string;
  handleBack: () => void;
  org: OrgV2Response;
  selectedHubId?: string;
};

export const InviteMembers = ({
  title,
  subtitle,
  backCopy,
  handleBack,
  org,
  selectedHubId,
}: InviteMembersProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { invalidateDefaultOrgQuery } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const [pendingEmails, setPendingEmails] = React.useState<string[]>([]);
  const [role, setRole] = React.useState<InviteMemberRole>(InviteMemberRole.HUB_MEMBER);

  const [selectedHubIds, setSelectedHubIds] = React.useState<string[]>(
    selectedHubId ? [selectedHubId] : []
  );

  const [updatedOrgSeats, setUpdatedOrgSeats] = React.useState<number>(undefined);
  const [invitesSent, setInvitesSent] = React.useState<boolean>(false);
  const [welcomeMessage, setWelcomeMessage] = React.useState<string>("");

  const [invitedEmails, setInvitedEmails] = React.useState<string[]>([]);
  const [addedEmails, setAddedEmails] = React.useState<string[]>([]);
  const [failedEmails, setFailedEmails] = React.useState<string[]>([]);

  const seatsAvailable = org.license_count - org.license_used;

  const { showNotificationBanner } = useNotification();

  const orgEmailBrandingQuery = useApiQuery({
    queryKey: [OrgMembersQueryKeys.OrgEmailBranding, org.id],
    queryFn: () => getOrgEmailBranding(org.id),
    enabled: isOrgOwnerAdmin(org),
    refetchOnWindowFocus: false,
  });

  const inviteMemberMutation = useMutation({
    mutationFn: async () => {
      return await createOrgUserV2(org.id, {
        emails: pendingEmails,
        org_role: role === InviteMemberRole.ORG_ADMIN ? OrgRole.ADMIN : null,
        hub_role: role === InviteMemberRole.HUB_ADMIN ? HubRole.ADMIN : HubRole.MEMBER,
        hub_ids: initiallySelectDefaultHub ? [orgHubsDefaultFirst[0].id] : selectedHubIds,
        send_invite_email: true,
        welcome_message: welcomeMessage,
      });
    },
    onSuccess: (response) => {
      setInvitesSent(true);
      void invalidateDefaultOrgQuery();
      // If hub invite, log on Amplitude
      if (role !== InviteMemberRole.ORG_ADMIN) {
        const hubIds = initiallySelectDefaultHub ? [orgHubsDefaultFirst[0].id] : selectedHubIds;
        hubIds.forEach((hubId) => {
          Instrumentation.logHubInvitesSent(
            org.id,
            pendingEmails.length,
            role === InviteMemberRole.HUB_ADMIN ? HubRole.ADMIN : HubRole.MEMBER,
            false,
            hubId
          );
        });
      }
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgInvites, org.id],
      });
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, org.id],
      });
      if (response.results.length > 0) {
        const addedEmails = response.results
          .filter((resp) => resp.result === AddOrgUserResult.ADDED)
          .map((resp) => resp.email);
        const invitedEmails = response.results
          .filter((resp) => resp.result === AddOrgUserResult.INVITED)
          .map((resp) => resp.email);
        const failedEmails = response.results
          .filter((resp) => resp.result === AddOrgUserResult.INTERNAL_ERROR)
          .map((resp) => resp.email);
        setAddedEmails(addedEmails);
        setInvitedEmails(invitedEmails);
        setFailedEmails(failedEmails);
        if (failedEmails.length > 0) {
          showNotificationBanner(
            `Some invites failed to send. Please try again or contact support.`,
            {
              variant: YoodliNotificationBannerVariant.Danger,
            }
          );
        }
      }
    },
    onError: () => {
      showNotificationBanner("Failed to send invites. If this persists, please contact support.", {
        variant: YoodliNotificationBannerVariant.Danger,
      });
    },
  });

  const updateOrgSeatsMutation = useMutation({
    mutationFn: () => {
      return upsetPaygOrgSubscription(
        org.id,
        updatedOrgSeats,
        window.location.href,
        window.location.href
      );
    },
    onSuccess: async (result) => {
      if (result.result === UpsetPaygOrgSubscriptionResult.REDIRECT) {
        window.location.href = result.redirect_url;
      } else if (result.result === UpsetPaygOrgSubscriptionResult.SUBSCRIPTION_UPDATED) {
        const seatChangeNum = updatedOrgSeats - org.license_count;
        showNotificationBanner(
          `You have successfully ${seatChangeNum > 0 ? "added" : "removed"} ${Math.abs(
            seatChangeNum
          )} seats. Changes will be reflected at your next billing cycle.`,
          {
            variant: YoodliNotificationBannerVariant.Success,
          }
        );
        await invalidateDefaultOrgQuery();
        setUpdatedOrgSeats(undefined);
      } else {
        throw Error(result.error_info);
      }
    },
  });

  React.useEffect(() => {
    if (orgEmailBrandingQuery.isSuccess) {
      const orgEmailBranding = orgEmailBrandingQuery.data;
      if (orgEmailBranding?.welcome_message) {
        setWelcomeMessage(orgEmailBranding?.welcome_message);
      }
    }
  }, [orgEmailBrandingQuery.isPending]);

  const hubsEdited = React.useRef(undefined);
  const orgHubsDefaultFirst = React.useMemo(() => {
    return org?.hubs?.sort((a) => (a.org_default ? -1 : 1));
  }, [org?.hubs]);

  const initiallySelectDefaultHub = !selectedHubIds?.length && !hubsEdited.current;

  // if i havent edited the hubs value yet, and there are no selected hubs, select the default hub to start
  const hubsValue = React.useMemo(() => {
    return initiallySelectDefaultHub
      ? [
          {
            label: orgHubsDefaultFirst[0]?.name,
            value: orgHubsDefaultFirst[0]?.id,
          },
        ]
      : selectedHubIds.map((id) => ({
          label: org.hubs.find((h) => h.id === id)?.name ?? "Group",
          value: id,
        }));
  }, [initiallySelectDefaultHub, orgHubsDefaultFirst, selectedHubIds]);

  const parseInviteRole = (role: InviteMemberRole) => {
    switch (role) {
      case InviteMemberRole.HUB_MEMBER:
        return "Group Member";
      case InviteMemberRole.HUB_ADMIN:
        return "Group Admin";
      case InviteMemberRole.ORG_ADMIN:
        return "Org Admin";
    }
  };

  const renderWizardCtas = () => {
    if (updatedOrgSeats) {
      return (
        <Stack
          direction="row"
          rowGap={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            pl: { xs: 2, md: 8 },
            pr: { xs: 2, md: 6 },
            flexWrap: "wrap",
          }}
        >
          <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
            <UpdateOrgSeatsControls
              updatedOrgSeats={updatedOrgSeats}
              setUpdatedOrgSeats={setUpdatedOrgSeats}
              size={65}
              org={org}
              disabled={updateOrgSeatsMutation.isPending}
            />
            <Stack gap={1}>
              <Typography
                sx={{
                  color: getDynamicColor("dark4"),
                  fontFamily: "poppins",
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                {org.license_used}/{org.license_count} seats used
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Use the buttons to add or remove seats, or enter the total number of desired seats
              </Typography>
            </Stack>
          </Stack>
          {updatedOrgSeats === org.license_count ? (
            <Button onClick={() => setUpdatedOrgSeats(undefined)}>Cancel</Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => updateOrgSeatsMutation.mutate()}
              disabled={updateOrgSeatsMutation.isPending}
            >
              {updatedOrgSeats > org.license_count ? "Add" : "Remove"}{" "}
              {Math.abs(updatedOrgSeats - org.license_count)} seat
              {Math.abs(updatedOrgSeats - org.license_count) > 1 && "s"}
            </Button>
          )}
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          rowGap={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            px: { xs: 1, md: 7 },
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("dark5"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            You have {org.license_count - org.license_used} seat
            {org.license_count - org.license_used === 1 ? "" : "s"} available.{" "}
            {org.subscription_type === OrgSubscriptionType.PAYG && isOrgOwnerAdmin(org) && (
              <Link
                onClick={() => {
                  if (!inviteMemberMutation.isPending) {
                    setUpdatedOrgSeats(org.license_count);
                  }
                }}
                sx={{ cursor: "pointer" }}
              >
                Manage seats
              </Link>
            )}
          </Typography>
          {invitesSent ? (
            <Button variant="contained" onClick={handleBack}>
              Done
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={
                !pendingEmails.length ||
                inviteMemberMutation.isPending ||
                pendingEmails.length > seatsAvailable ||
                pendingEmails.length > MAX_EMAIL_INVITE_COUNT ||
                (!selectedHubIds?.length && !initiallySelectDefaultHub)
              }
              onClick={() => inviteMemberMutation.mutate()}
            >
              Send invites
            </Button>
          )}
        </Stack>
      );
    }
  };

  const renderContent = () => {
    if (invitesSent) {
      return (
        <InvitesSentReview
          failedEmails={failedEmails}
          addedEmails={addedEmails}
          invitedEmails={invitedEmails}
          roleStr={parseInviteRole(role)}
          hubNames={
            initiallySelectDefaultHub
              ? [orgHubsDefaultFirst[0].name]
              : selectedHubIds.map((id) => org.hubs.find((h) => h.id === id).name)
          }
        />
      );
    } else {
      return (
        <Stack gap={5} sx={{ px: { xs: 2, md: 8 }, maxWidth: "xl" }}>
          <Stack gap={0.5}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              {subtitle}
            </Typography>
          </Stack>
          <Stack gap={3}>
            {pendingEmails.length > seatsAvailable && (
              <Typography
                sx={{
                  color: getDynamicColor("redError"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                We found {pendingEmails.length} email{pendingEmails.length === 1 ? "" : "s"}, but
                you only have {seatsAvailable} seat{seatsAvailable === 1 ? "" : "s"} available!
                Please remove some emails or add seats to continue.
              </Typography>
            )}
            {pendingEmails.length > MAX_EMAIL_INVITE_COUNT && (
              <Typography
                sx={{
                  color: getDynamicColor("redError"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                You may only submit up to {MAX_EMAIL_INVITE_COUNT} invites at a time. Please remove
                some emails to continue.
              </Typography>
            )}
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: 3, md: 5 }}
              sx={{ alignItems: "center" }}
            >
              <InviteMemberControls
                title="Email(s)"
                control={
                  <YoodliMultiEmail
                    pendingEmails={pendingEmails}
                    setPendingEmails={setPendingEmails}
                    disabled={!!updatedOrgSeats}
                    maxEmailCount={MAX_EMAIL_INVITE_COUNT}
                  />
                }
                containerSx={{ width: "100%" }}
              />
            </Stack>
            {/* Only org admin+ may select a role for the invited members */}
            {isOrgOwnerAdmin(org) && (
              <Stack
                gap={3}
                direction={{ xs: "column", md: "row" }}
                sx={{ alignItems: "center", width: "100%" }}
              >
                <InviteMemberControls
                  title="Role"
                  tooltipText={
                    <Typography>
                      Group admin are given limited access to admin permissions and information like
                      members' usage.{" "}
                      <Link href={SupportPages.ORG_OVERVIEW_ROLES} target="_blank">
                        Learn more about roles
                      </Link>
                    </Typography>
                  }
                  control={
                    <YoodliSelect
                      value={role}
                      fullWidth={isSmallScreen}
                      onChange={(e) => setRole(e.target.value as InviteMemberRole)}
                      options={[
                        { label: "Group Member", value: InviteMemberRole.HUB_MEMBER.toString() },
                        { label: "Org Admin", value: InviteMemberRole.ORG_ADMIN.toString() },
                        { label: "Group Admin", value: InviteMemberRole.HUB_ADMIN.toString() },
                      ]}
                      disabled={!!updatedOrgSeats}
                    />
                  }
                />
                <InviteMemberControls
                  title="Group(s)"
                  containerSx={{
                    width: "100%",
                  }}
                  control={
                    <YoodliMultiSelect
                      isCheckboxSelect
                      value={hubsValue}
                      onChange={(e, vals: YoodliSelectOption[], reason, detail) => {
                        // if my first action it to remove/clear the field, allow clearing of the default hub by setting hubsEdited before mutating
                        if (hubsEdited.current === undefined) {
                          hubsEdited.current = reason === "removeOption" || reason === "clear";
                        }
                        if (vals.length === 0) {
                          setSelectedHubIds([]);
                          return;
                        }
                        if (selectedHubIds.includes(detail?.option?.value)) {
                          setSelectedHubIds(
                            selectedHubIds.filter((id) => id !== detail?.option?.value)
                          );
                        } else {
                          // if selecting a new hub but you havent made any edits yet, also include the default hub
                          setSelectedHubIds(
                            [
                              ...selectedHubIds,
                              !hubsEdited.current && selectedHubIds.length === 0
                                ? orgHubsDefaultFirst[0]?.id
                                : null,
                              detail?.option?.value,
                            ].filter(Boolean)
                          );
                        }
                        hubsEdited.current = true;
                      }}
                      placeholder="Select groups(s)"
                      options={orgHubsDefaultFirst.map((h) => ({ label: h.name, value: h.id }))}
                      disabled={!!updatedOrgSeats}
                    />
                  }
                />
              </Stack>
            )}
            {isOrgOwnerAdmin(org) && (
              <>
                {orgEmailBrandingQuery.isPending ? (
                  <CircularProgress sx={{ m: "auto" }} />
                ) : (
                  <YoodliTextfield
                    value={welcomeMessage}
                    onChange={(e) => setWelcomeMessage(e.target.value)}
                    label="Optional message"
                    multiline
                    minRows={isSmallScreen ? 6 : 8}
                    maxChars={EMAIL_BRANDING_MESSAGE_MAX_LENGTH}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      );
    }
  };

  return (
    <OrgSectionWrapper
      backCopy={backCopy}
      handleBack={handleBack}
      loading={updateOrgSeatsMutation.isPending || inviteMemberMutation.isPending}
      ctas={renderWizardCtas()}
      footerHeight={updatedOrgSeats ? "175px" : "105px"}
    >
      {renderContent()}
    </OrgSectionWrapper>
  );
};
