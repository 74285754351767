import firebase from "firebase/app";
import React from "react";

// Components
import { Divider, Stack, Typography } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  WizardState,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CustomScenarioCard } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CustomScenarioCard";

// Utils
import { TEMPLATE_SUB_TYPE_DATA } from "../../../../ConvoScenarios/convoScenarioUtils";
import { OrgScenarioTemplateGrid } from "./OrgScenarioTemplateGrid";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createScenario, listScenarios } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";

type ManageConvoScenariosProps = {
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  selectedOrg: OrgV2Response;
  wizardState: WizardState;
  setWizardState: (state: WizardState) => void;
  renderLoader: () => JSX.Element;
};

export const ManageConvoScenarios = ({
  handleTemplateSelected,
  selectedOrg,
  wizardState,
  setWizardState,
  renderLoader,
}: ManageConvoScenariosProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { defaultOrg, defaultOrgLoading } = React.useContext(UserOrgContext);
  const scenariosQuery = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrg?.id],
    queryFn: async () => await listScenarios(defaultOrg?.id),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg && !defaultOrgLoading,
    refetchOnWindowFocus: false,
  });

  const personasQueryState = queryClient.getQueryState([
    CustomizePracticeQueryKey.Personas,
    defaultOrg?.id,
  ]);

  const customScenarios = React.useMemo(() => {
    return (scenariosQuery.data?.contentArray as GetScenarioResponse[])?.filter((scenario) => {
      return !scenario.isTemplate && scenario.enabled;
    });
  }, [scenariosQuery.data]);

  const loadingScenarios =
    (scenariosQuery.isLoading || scenariosQuery.isFetching) && !scenariosQuery.isRefetching;

  const hasScenarioIdTypeChecker = (res: unknown): res is { scenarioId: string } => {
    return Object.prototype.hasOwnProperty.call(res, "scenarioId");
  };

  const handleSelectAndCreateTemplate = async (scenario: GetScenarioResponse) => {
    let title = `My ${TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.label}`;
    if (wizardState === "duplicate") {
      title = `${scenario.title} (Copy)`;
    }
    const res = await createScenario(
      selectedOrg?.id,
      firebase.auth().currentUser?.email,
      title,
      scenario.id
    ).catch((er) => {
      console.error(`Error creating convo scenario: ${er}`);
    });

    if (!hasScenarioIdTypeChecker(res)) {
      console.error("Error creating convo scenario: no scenarioId was returned");
      return;
    }
    handleTemplateSelected(scenario, res?.scenarioId);
    setWizardState("create");
    // dont wait here since we want the UI to update to the wizard immediately
    void scenariosQuery.refetch();
  };
  const handleSelectScenario = async (scenario: GetScenarioResponse, action: WizardState) => {
    if (action === "edit") {
      handleTemplateSelected(scenario);
    } else if (action === "duplicate") {
      await handleSelectAndCreateTemplate(scenario);
    }
    setWizardState(action);
  };

  const renderCustomScenarios = () => {
    if (!customScenarios?.length) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          You haven't created any custom scenarios yet. Choose a template below to get started!
        </Typography>
      );
    }
    return (
      <Stack direction="column" gap={2}>
        {customScenarios?.map((scenario) => {
          return (
            <CustomScenarioCard
              key={scenario.id}
              selectedOrgId={selectedOrg?.id}
              handleSelectScenario={handleSelectScenario}
              scenario={scenario}
              allHubs={selectedOrg?.hubs.sort((a, b) => a.name.localeCompare(b.name))}
              persona={(personasQueryState?.data as PersonaResponse[])?.find(
                (p) => p.id === scenario.defaultPersonaId
              )}
              personaLoading={personasQueryState?.status === "pending"}
            />
          );
        })}
      </Stack>
    );
  };
  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        p: { xs: 3, md: 0 },
        pb: { xs: 3, md: 1 },
        maxWidth: "xxl",
        overflow: "hidden",
      }}
      gap={3}
    >
      <Stack direction="column" justifyContent="flex-start" gap={2}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: { xs: 16, md: 18 },
            fontWeight: 700,
          }}
        >
          My Scenarios
        </Typography>

        {loadingScenarios ? renderLoader() : renderCustomScenarios()}
      </Stack>

      {!loadingScenarios && (
        <>
          <Divider
            sx={{
              width: "100vw",
              ml: { xs: -2, md: -6 },
              mt: 2,
              mb: 1,
            }}
          />
          <Stack direction="column" justifyContent="flex-start">
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Create New Scenario
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Build customized scenarios for your team to practice on calls. Choose a template to
              get started:
            </Typography>
          </Stack>
          <OrgScenarioTemplateGrid
            scenarios={scenariosQuery?.data?.contentArray}
            handleSelectTemplate={handleSelectAndCreateTemplate}
          />
        </>
      )}
    </Stack>
  );
};
