// Components
import { Box, Button } from "@mui/material";
import { SplashInfoScreen } from "lib-frontend/components/YoodliComponents/SplashInfoScreen";

// Assets
import { ReactComponent as AnalyticsIcon } from "images/icons/icon-analytics.svg";
import { ReactComponent as InsightIcon } from "images/icons/icon-insights.svg";
import { ReactComponent as TrophyIcon } from "images/icons/icon-star-trophy.svg";

type NoProgramsProps = {
  handleCreateProgram: () => void;
};

export const NoPrograms = ({ handleCreateProgram }: NoProgramsProps): JSX.Element => {
  return (
    <Box
      sx={{
        px: { xs: 3, md: 8 },
        pt: { xs: 2, md: 8, lg: 16 },
        maxWidth: "xxl",
      }}
    >
      <SplashInfoScreen
        title="Set your organization on the path to success with tailored programs"
        items={[
          {
            icon: <InsightIcon />,
            text: "Create structured learning pathways for your groups to follow",
          },
          {
            icon: <TrophyIcon />,
            text: "Set benchmarks for progress, completion and achievement",
          },
          {
            icon: <AnalyticsIcon />,
            text: "Follow group or individual progress with insights and in depth metrics",
          },
        ]}
        cta={
          <Button variant="contained" onClick={handleCreateProgram} sx={{ mx: "auto" }}>
            Get started
          </Button>
        }
      />
    </Box>
  );
};
