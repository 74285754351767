import firebase from "firebase/app";
import _ from "lodash";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// Components
import AuthInput from "../components/AuthInput";
import {
  Box,
  Stack,
  Button,
  Typography,
  Divider,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import AuthWrapper from "auth/components/AuthWrapper";
import OAuthButtonGroup from "auth/components/OAuthButtonGroup";
import TermsAndPrivacyAgreementCopy from "auth/components/TermsAndPrivacyAgreementCopy";

// Assets
import { ReactComponent as EmailIcon } from "../../images/icons/WorkEmail.svg";

// Utils
import authUrls from "../../auth/config/authUrls";
import {
  getSignInSignUpReturnPath,
  historyPush,
  mergeReturnPathAndSearchParameters,
} from "../../utils/Utilities";
import { signInWithOAuthProvider } from "../providers/OAuthProvider";
import {
  completeUserLogin,
  fetchUrlSearchParams,
  generateAuthTokenForPoodliAndClose,
} from "../utils";
import {
  validateEmail,
  validateStrongPassword,
  validateString,
  validatePassword,
} from "../utils/validator";
import { signUpWithEmail } from "auth/providers/EmailProvider";
import Cookies from "js-cookie";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  completeUserCreation,
  userAuthenticated,
  getSignInOptions,
  optimisticSendVerificationEmail,
} from "lib-frontend/modules/AxiosInstance";
import { USER_NAME_MAX_CHAR_LENGTH } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf, newSsoPathEnabled } from "lib-frontend/utils/LiveSiteDocs";
import { userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  AuthQueryParams,
  HubsInviteRequiredQueryParams,
  ReferralProgramQueryParams,
} from "lib-frontend/utils/queryParams";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { SAML20_FULLNAME_CLAIM_ATTRIBUTE } from "lib-fullstack/utils/auth";
import {
  YOODLI_INDEED_HUID,
  YOODLI_REFERRAL_PROGRAM,
  YOODLI_REFERRER,
} from "lib-fullstack/utils/constants";
import { UITestId } from "lib-fullstack/utils/enums";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { ReferralProgram } from "lib-fullstack/utils/referralProgramUtils";
import { WebServerInternalPath } from "utils/paths";
import { getReturnPath } from "utils/Utilities";

export const PasswordProgress = ({ score }: { score: number }): JSX.Element => {
  let strength: { color: string; text: string } = {
    color: getDynamicColor("redError"),
    text: "Weak",
  };

  if (score >= 20 && score <= 50)
    strength = {
      color: "#ffa500",
      text: "Medium",
    };

  if (score > 50)
    strength = {
      color: getDynamicColor("greenSuccess"),
      text: "Strong",
    };

  return (
    <Stack direction="row" alignItems="center" gap={1} pl={1} width="100%">
      <LinearProgress
        variant="determinate"
        value={score}
        sx={{
          width: "80%",
          backgroundColor: "#cccccc88",
          "& .MuiLinearProgress-bar": {
            backgroundColor: strength.color,
          },
        }}
      />
      <Typography fontSize="14px">{strength.text}</Typography>
    </Stack>
  );
};

export default function SignUp(): JSX.Element {
  const { invalidateDefaultOrgQuery } = React.useContext(UserOrgContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [signupLoading, setSignupLoading] = React.useState<boolean>(false);
  const [showUsernamePasswordSignUp, setShowUsernamePasswordSignUp] =
    React.useState<boolean>(false);
  const [showWorkEmailSignUp, setShowWorkEmailSignUp] = React.useState<boolean>(false);
  const [customSsoName, setCustomSsoName] = React.useState<string>("");
  const [ssoDisplayName, setSsoDisplayName] = React.useState<string>("SSO");
  const [showOnlyOrgSso, setShowOnlyOrgSso] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | JSX.Element>("");

  const [privacyAccepted, setPrivacyAccepted] = React.useState(false);
  const passwordStrength = validateStrongPassword(password);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const siteConf = getStaticFullSiteConf();

  const errors = {
    name: validateString(name),
    email: validateEmail(email),
    password: validatePassword(password),
    privacyAccepted: siteConf?.featureDiffs?.privacyPolicyConsentRequired && !privacyAccepted,
  };

  const hubId = params.get(AuthQueryParams.HUB_ID);
  const orgId = params.get(AuthQueryParams.ORG_ID);
  const inviteId = params.get(AuthQueryParams.INVITE_ID);
  const ignoreSso = params.get(AuthQueryParams.IGNORE_SSO) === "true";
  const isV2Invite = params.get(AuthQueryParams.V2) === "true";

  const hubInviteDetails = hubId && inviteId ? { hubId, inviteId } : undefined;

  const clientEnv = getClientEnvConfig();

  // if the user is already signed in, redirect them to the return path immediately
  React.useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user && user["uid"]) {
      const { signInReturnPath } = getSignInSignUpReturnPath(getReturnPath(location));
      historyPush(history, signInReturnPath, location.state);
    }

    if (
      HubsInviteRequiredQueryParams.every((param) =>
        Cookies.get(param, { domain: clientEnv.domain })
      )
    ) {
      for (const param of HubsInviteRequiredQueryParams) {
        params.set(param, Cookies.get(param, { domain: clientEnv.domain }) as string);
        Cookies.remove(param, { domain: clientEnv.domain });
      }
      historyPush(history, `${WebServerExternalPath.HOME_LOGGED_IN}?${params.toString()}`);
    }

    Instrumentation.logSignupPageLoaded(orgId, hubId);
  }, []);

  const handleWorkEmailSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const domain = email.split("@")[1];
    setSignupLoading(true);
    try {
      const options = await getSignInOptions(domain);
      if (options.sign_in_options.length > 0 && !ignoreSso) {
        setCustomSsoName(options.sign_in_options[0].firebase_provider);
        setSsoDisplayName(options.sign_in_options[0].display_name);
      } else {
        setShowUsernamePasswordSignUp(true);
        setShowWorkEmailSignUp(false);
      }
    } catch (error) {
      setSignupLoading(false);
      setShowUsernamePasswordSignUp(true);
      setShowWorkEmailSignUp(false);
    }
    setSignupLoading(false);
  };

  const handleSignUp = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_EMAIL_PASSWORD_OPTION_CLICKED);
    if (_.every(errors, (error) => !error) && name.length > 0) {
      try {
        const user = await signUpWithEmail(email, password, name);

        await resolveSignUp(user, true);
      } catch (error) {
        setLoading(false);
        if (error.code === "auth/email-already-in-use") {
          setError(
            <>
              Sorry, this email address may already be in use. Please try to{" "}
              <Link
                style={{ color: getDynamicColor("redError") }}
                to={{
                  pathname: authUrls.signin,
                  search: fetchUrlSearchParams(params),
                  state: location.state,
                }}
              >
                sign in
              </Link>{" "}
              or{" "}
              <Link
                style={{ color: getDynamicColor("redError") }}
                to={{
                  pathname: authUrls.reset_password,
                  search: fetchUrlSearchParams(params),
                  state: location.state,
                }}
              >
                reset your password
              </Link>
              .
            </>
          );
        } else {
          setError(error.message);
        }
        if (error.code?.startsWith("auth/")) {
          console.warn(error.code);
        } else {
          console.error(error.code);
        }
      }
    } else if (errors.privacyAccepted) {
      setError("Please consent to the above in order to continue!");
    } else if (name.length === 0 && !errors.name) {
      setError("Name field is required");
    }

    setLoading(false);
  };

  const resolveSignUp = async (user: firebase.User, isNewUser: boolean) => {
    const code = params.get("ot-auth-code"); // Sent from electron app redirect

    if (user?.uid) {
      // make sure the user doc service is updating - it should automatically, but this prevents race conditions
      userDocsService.resetLiveUserDocsOnAuthChange();

      const referralSlug = Cookies.get(YOODLI_REFERRER, { domain: clientEnv.domain }) ?? "";
      const indeedHuid =
        Cookies.get(YOODLI_INDEED_HUID, { domain: clientEnv.domain }) ??
        params.get(ReferralProgramQueryParams.INDEED_HUID) ??
        "";
      const referralProgram =
        Cookies.get(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain }) ??
        (indeedHuid ? ReferralProgram.INDEED : "");

      if (newSsoPathEnabled()) {
        await userAuthenticated(
          referralProgram,
          indeedHuid ? { hashedUserId: indeedHuid } : null,
          isV2Invite ? null : hubInviteDetails
        );
      } else {
        // Create user in db if one not found
        await completeUserCreation(
          user.uid,
          referralSlug,
          referralProgram,
          indeedHuid ? { hashedUserId: indeedHuid } : null,
          isV2Invite ? null : hubInviteDetails,
          isV2Invite ? orgId : null
        );
      }

      // Fetch user's default org if it exists
      await invalidateDefaultOrgQuery();

      const returnPaths = getSignInSignUpReturnPath(getReturnPath(location));
      const returnPath = isNewUser ? returnPaths.signUpReturnPath : returnPaths.signInReturnPath;

      if (referralSlug) {
        Cookies.remove(YOODLI_REFERRER, { domain: clientEnv.domain });
      }
      if (referralProgram) {
        Cookies.remove(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain });
      }
      if (indeedHuid) {
        Cookies.remove(YOODLI_INDEED_HUID, { domain: clientEnv.domain });
      }

      await completeUserLogin(user.uid);

      if (code && user?.emailVerified) {
        await generateAuthTokenForPoodliAndClose(code);
      } else {
        let returnPathForUser: string;
        if (user?.emailVerified) {
          returnPathForUser = returnPath;
        } else {
          returnPathForUser = mergeReturnPathAndSearchParameters(
            WebServerInternalPath.VERIFY_EMAIL,
            location.search
          );
          await optimisticSendVerificationEmail(
            clientEnv.url.WEB_SERVER +
              mergeReturnPathAndSearchParameters(returnPaths.signUpReturnPath, location.search)
          ).catch((error) => {
            if (error.message === "auth/internal-error") {
              console.log("Error sending email, too many attempts!");
            } else {
              console.error("Error sending email!", error);
            }
          });
        }

        // ensure user docs have re-resolved before we continue
        await userDocsService.awaitUserDocsResolved();

        // If email is not verified, send to verify email page
        // If email is verified, send to returnPath
        historyPush(history, returnPathForUser, { returnPath });
      }
    } else {
      const returnPaths = getSignInSignUpReturnPath(getReturnPath(location));

      const returnPath = isNewUser ? returnPaths.signUpReturnPath : returnPaths.signInReturnPath;

      // resolveSignUp did not return a user - direct back to signup page and maintain returnPath to try again
      historyPush(history, WebServerExternalPath.SIGN_UP, {
        returnPath,
      });
    }
  };

  const handleSignUpWithOAuthProvider = async (
    e: React.MouseEvent<HTMLButtonElement>,
    provider: firebase.auth.AuthProvider
  ) => {
    e.preventDefault();

    setLoading(true);
    try {
      const { user, isNewUser } = await signInWithOAuthProvider(provider);

      if (!newSsoPathEnabled()) {
        // in some cases, user names may be mapped differently in SAML than what identity platform expects
        // if that occurs, update the user's display name to our fallback "displayName" attribute
        // NOTE: remember to update the signIn verison of this logic in SignIn.tsx if you change this!
        if (provider instanceof firebase.auth.SAMLAuthProvider && !user.displayName && isNewUser) {
          try {
            const idTokenResult = await user.getIdTokenResult();
            let altName =
              idTokenResult.claims.firebase.sign_in_attributes?.[SAML20_FULLNAME_CLAIM_ATTRIBUTE] ??
              idTokenResult.claims.firebase.sign_in_attributes?.displayName ??
              idTokenResult.claims.firebase.sign_in_attributes?.name ??
              idTokenResult.claims.firebase.sign_in_attributes?.givenName;

            // do this not in a null coalescing operator to ensure we overwrite a blank (empty string) name
            if (!altName) {
              altName = user.email;
            }

            console.log(
              "Could not find a display name in SAML claims, using alt name: ",
              altName,
              idTokenResult.claims.firebase.sign_in_attributes
            );

            await user.updateProfile({
              displayName: altName,
            });
          } catch (error) {
            console.error(
              "Error settting a fallback user display name for SAML user",
              provider?.providerId,
              user?.uid,
              error
            );
          }
        }
      }

      await resolveSignUp(user, isNewUser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      if (error?.code?.startsWith?.("auth/")) {
        console.warn(error?.code);
      } else {
        console.error(error?.code ?? error);
      }
    }
  };

  const handleChangeName = (name) => {
    setName(name);
    setError("");
  };

  const handleChangeEmail = (email) => {
    setEmail(email);
    setError("");
  };

  const handleChangePassword = (password) => {
    setPassword(password);
    setError("");
  };

  const handlePrivacyChecked = (checked) => {
    setPrivacyAccepted(checked);
    setError("");
  };

  const expandWorkEmailOption = () => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_EMAIL_PASSWORD_OPTION_EXPANDED);
    setShowWorkEmailSignUp(true);
  };

  const handleSsoSignUp = async (e) => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_CUSTOM_SSO_OPTION_CLICKED);

    if (customSsoName.startsWith("saml")) {
      await handleSignUpWithOAuthProvider(e, new firebase.auth.SAMLAuthProvider(customSsoName));
    } else if (customSsoName.startsWith("oidc")) {
      await handleSignUpWithOAuthProvider(e, new firebase.auth.OAuthProvider(customSsoName));
    }
  };

  const getUsernamePasswordForm = () => (
    <>
      <Divider sx={{ flexShrink: "initial", width: "100%" }} />
      <Stack gap={2} width="100%">
        <Box>
          <Typography
            color={getDynamicColor("dark6")}
            fontWeight={600}
            fontSize="14px"
            fontFamily="poppins"
          >
            Sign up with work email
          </Typography>
        </Box>
        <form onSubmit={handleSignUp} style={{ width: "100%" }}>
          <Stack gap={1.5} alignItems="center">
            <AuthInput
              fullWidth
              autoComplete="email"
              value={email}
              placeholder="Work Email"
              label={email ? "Work Email" : undefined}
              type="email"
              errorText={errors.email}
              onChange={(e) => handleChangeEmail(e.target.value)}
              inputProps={{
                "data-testid": UITestId.WorkEmailTextField,
              }}
            />
            <AuthInput
              fullWidth
              autoFocus
              value={name}
              autoComplete="given-name"
              placeholder="Name"
              label={name ? "Name" : undefined}
              errorText={errors.name}
              type="text"
              onChange={(e) => handleChangeName(e.target.value)}
              inputProps={{
                maxLength: USER_NAME_MAX_CHAR_LENGTH,
                "data-testid": UITestId.NameTextField,
              }}
            />
            <AuthInput
              fullWidth
              value={password}
              autoComplete="new-password"
              placeholder="Password"
              label={password ? "Password" : undefined}
              type="password"
              errorText={errors.password}
              onChange={(e) => handleChangePassword(e.target.value)}
              inputProps={{
                "data-testid": UITestId.PasswordTextField,
              }}
            />
            {password.length > 0 && <PasswordProgress score={passwordStrength} />}
            <Stack width="100%" gap={1}>
              <Button
                variant="gradient"
                onClick={handleSignUp}
                disabled={[...Object.values(errors)].some((error) => !!error) || !name?.length}
                sx={{
                  borderRadius: "4px",
                  height: 48,
                  width: "100%",
                }}
                data-testid={UITestId.SignUpButton}
              >
                Sign up
              </Button>
              <input type="submit" style={{ display: "none" }} />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );

  const getWorkEmailForm = () => {
    // showing the email sign up form
    const ctaDisabled = [...Object.values(errors)].some((error) => !!error) || !email;
    if (showWorkEmailSignUp) {
      return (
        <>
          <Divider sx={{ flexShrink: "initial", width: "100%" }} />
          <Stack gap={2} width="100%">
            <Box>
              <Typography
                color={getDynamicColor("dark6")}
                fontWeight={600}
                fontSize="14px"
                fontFamily="poppins"
              >
                Sign up with work email
              </Typography>
            </Box>
            <form onSubmit={handleWorkEmailSubmit} style={{ width: "100%" }}>
              <Stack gap={1.5} alignItems="center">
                <AuthInput
                  fullWidth
                  autoComplete="email"
                  value={email}
                  placeholder="Work Email"
                  label={email ? "Work Email" : undefined}
                  disabled={!!customSsoName}
                  type="email"
                  errorText={errors.email}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                  inputProps={{
                    "data-testid": UITestId.WorkEmailTextField,
                  }}
                />
                <Stack width="100%" gap={1}>
                  {signupLoading ? (
                    <Stack display="flex" justifyContent="center" alignItems="center" height="48px">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    // no custom SSO option yet -- show regular sign up button
                    <>
                      {!customSsoName ? (
                        <Button
                          variant="gradient"
                          onClick={handleWorkEmailSubmit}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                          data-testid={UITestId.NextButton}
                        >
                          Next
                        </Button>
                      ) : (
                        // found SSO option -- changing sign up button to sign them up with SSO
                        <Button
                          variant="gradient"
                          onClick={handleSsoSignUp}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                        >
                          Sign up with {ssoDisplayName}
                        </Button>
                      )}
                    </>
                  )}
                  <input type="submit" style={{ display: "none" }} />
                </Stack>
              </Stack>
            </form>
          </Stack>
        </>
      );
    }
    if (!customSsoName) {
      return (
        <Button
          variant="contained"
          startIcon={
            <EmailIcon width="28px" style={{ borderRadius: "50%", backgroundColor: "#FOFOFF" }} />
          }
          onClick={expandWorkEmailOption}
          sx={{
            backgroundColor: getDynamicColor("light1"),
            width: "100%",
            "&:hover": {
              backgroundColor: getDynamicColor("dark2"),
            },
            fontWeight: 600,
            fontSize: 14,
            color: getDynamicColor("dark6"),
            height: 48,
          }}
          data-testid={UITestId.ContinueWithWorkEmailButton}
        >
          Continue with work email
        </Button>
      );
    }
  };

  return loading ? (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <AuthWrapper
      showUsernamePasswordSignUp={showUsernamePasswordSignUp}
      setCustomSsoName={setCustomSsoName}
      setSsoDisplayName={setSsoDisplayName}
      setShowOnlyOrgSso={setShowOnlyOrgSso}
      hubId={hubId}
      orgId={orgId}
    >
      <Stack
        direction="column"
        width="100%"
        gap={2}
        sx={{
          ...(!showOnlyOrgSso && {
            backgroundColor: getDynamicColor("light2"),
            filter: "drop-shadow(1px 2px 5px rgba(33, 37, 41, 0.16));",
          }),
          borderRadius: "8px",
          p: "20px",
          width: "min(385px, 100%)",
        }}
      >
        {error && (
          <Typography p={1} color="error" fontWeight={600} fontSize="12px">
            {error}
          </Typography>
        )}
        <OAuthButtonGroup
          buttonHandler={handleSignUpWithOAuthProvider}
          showOnlyOrgSso={showOnlyOrgSso}
          customSsoName={customSsoName}
          ssoDisplayName={ssoDisplayName}
          isSignUp
        />
        {showUsernamePasswordSignUp ? getUsernamePasswordForm() : getWorkEmailForm()}
      </Stack>
      <Typography
        fontWeight={600}
        fontSize="14px"
        fontFamily="poppins"
        color={getDynamicColor("dark5")}
      >
        Already have an account? &nbsp;
        <Link
          style={{ textDecoration: "none", color: getDynamicColor("primary") }}
          to={{
            pathname: authUrls.signin,
            search: fetchUrlSearchParams(params),
            state: location.state,
          }}
        >
          Sign In
        </Link>
      </Typography>
      {siteConf?.featureDiffs?.privacyPolicyConsentRequired ? (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(_, checked) => handlePrivacyChecked(checked)}
              value={privacyAccepted}
            />
          }
          label={
            <Typography fontSize="12px" textAlign="start">
              I agree to Yoodli's{" "}
              <a
                href={getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.PRIVACY)}
                target="_blank"
              >
                Privacy Policy
              </a>
              ,{" "}
              <a
                href={getLandingPageExternalUrl(
                  clientEnv,
                  LandingPageExternalPath.TERMS_OF_SERVICE
                )}
                target="_blank"
              >
                Terms of Use
              </a>
              , and{" "}
              <a
                href={getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.COPYRIGHT)}
                target="_blank"
              >
                Copyright Policy
              </a>
            </Typography>
          }
        />
      ) : (
        <TermsAndPrivacyAgreementCopy orgSignUp={!!inviteId} />
      )}
    </AuthWrapper>
  );
}
