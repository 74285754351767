// Components
import { Button, Stack } from "@mui/material";

// Utils
import { InvitesSentReview } from "../InviteMembers";

type InvitesSentProps = {
  onDone: () => void;
  failedEmails: string[];
  addedEmails: string[];
  invitedEmails: string[];
  roleStr: string;
  hubNames: string[];
};

export const InvitesSent = ({
  onDone,
  failedEmails,
  addedEmails,
  invitedEmails,
  roleStr,
  hubNames,
}: InvitesSentProps): JSX.Element => {
  return (
    <Stack
      sx={{
        height: "100%",
      }}
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <InvitesSentReview
        failedEmails={failedEmails}
        addedEmails={addedEmails}
        invitedEmails={invitedEmails}
        roleStr={roleStr}
        hubNames={hubNames}
      />
      <Button
        variant="contained"
        onClick={onDone}
        sx={{
          mt: 1,
        }}
      >
        Done
      </Button>
    </Stack>
  );
};
