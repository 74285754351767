// Components
import { WizardState } from "components/ConvoScenarios/convoScenarioUtils";
import {
  CreateConvoScenarioWizard,
  WizardType,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreateConvoScenarioWizard";
import { ManageConvoScenarios } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/ManageConvoScenarios";

// Utils
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response, OrgV2Response } from "lib-fullstack/api/orgApiTypes";

type ScenariosTabProps = {
  selectedOrg: OrgV2Response;
  templateSelected: GetScenarioResponse;
  wizardState: WizardState;
  setWizardState: React.Dispatch<React.SetStateAction<WizardState>>;
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  allHubs: HubV2Response[];
  renderLoader: () => JSX.Element;
  showBackHeader: boolean;
  setShouldBlockNav: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ScenariosTab = ({
  selectedOrg,
  templateSelected,
  wizardState,
  setWizardState,
  handleTemplateSelected,
  renderLoader,
  showBackHeader,
  setShouldBlockNav,
}: ScenariosTabProps): JSX.Element => {
  if (templateSelected && showBackHeader) {
    return (
      <CreateConvoScenarioWizard
        closeWizard={() => handleTemplateSelected(null)}
        wizardState={wizardState}
        templateSelected={templateSelected}
        handleTemplateSelected={handleTemplateSelected}
        setShouldBlockNav={setShouldBlockNav}
        wizardType={WizardType.Org}
      />
    );
  }
  return (
    <ManageConvoScenarios
      handleTemplateSelected={handleTemplateSelected}
      selectedOrg={selectedOrg}
      setWizardState={setWizardState}
      wizardState={wizardState}
      renderLoader={renderLoader}
    />
  );
};
