import { db } from "lib-fullstack";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

// Components
import { Box, Button, Stack, Typography } from "@mui/material";
import { getSpeechTypeStringFromEnum } from "components/VideoJournal/VideoJournalUtils";

// Assets
import { ReactComponent as NoSpeechIcon } from "../../images/icons/NoSpeechIcon.svg";
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { formatDateShort, getDisplayableTime } from "lib-fullstack/utils/dateUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { WebServerInternalPath } from "utils/paths";
import { queryThumbnails, getThumbnail } from "utils/thumbnailHandler";

type RecentSessionsProps = {
  dbLatestSpeeches: db.Doc<db.Speech>[];
};

export const RecentSessions = ({ dbLatestSpeeches }: RecentSessionsProps): JSX.Element => {
  const history = useHistory();

  const elementsToRender = dbLatestSpeeches.slice(0, 4);

  // Retrieve thumbnails for the target speeches asynchronously.
  // This is a hack that this component itself does not rerender automatically when the thumbnails are ready.
  // However, the parent (hope page) rerenders after retrieving other data and rerenders subcomponents,
  // and practically those thumbnails are ready until that time.
  queryThumbnails(elementsToRender).catch((err) => {
    // This failure has happened due to a) poor network connection and b) throttling by the server.
    // Output a warning, but not an error which would become Sentry report.
    // We have nothing to do with a) and we have server side alert for b).
    console.warn(`queryThumbnails failed, continue without thumbnails: ${err}`);
  });

  const renderThumbnail = (yoodli: db.Doc<db.Speech>) => {
    return (
      <Box
        sx={{
          border: `1px solid ${getDynamicColor("dark2")}`,
          borderRadius: "4px",
          height: "104px",
          backgroundColor: getDynamicColor("light1"),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {yoodli.data.lifecycleState === db.LifecycleState.REDACTED ? (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "40px",
              background: getDynamicColor("dark2"),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0.5,
            }}
          >
            <NoSpeechIcon />
          </Box>
        ) : (
          <img
            src={getThumbnail(yoodli.ref.id) ?? PlaceholderThumbnail}
            style={{
              alignSelf: "center",
              height: "100%",
              width: "100%",
              borderRadius: "4px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              // if the speech was recorded post canvas off, we need to mirror the thumbnail to match what was/is displayed
              transform:
                getThumbnail(yoodli.ref.id) &&
                (yoodli.data.noCanvasRecording || yoodli.data.mirrored)
                  ? "scaleX(-1)"
                  : null,
            }}
            alt="Speech video thumbnail"
          />
        )}
      </Box>
    );
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "end" }}>
        <Typography
          variant="poppins"
          component="h2"
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontWeight: 600,
          }}
        >
          Recent Sessions
        </Typography>
        <Link to={WebServerInternalPath.LIBRARY} style={{ textDecoration: "none" }}>
          <Button
            variant="text"
            sx={{
              fontSize: 14,
              whiteSpace: "nowrap",
            }}
          >
            See more
          </Button>
        </Link>
      </Stack>
      <Stack
        direction="row"
        gap={2}
        sx={{
          overflowX: "auto",
          display: "flex",
        }}
      >
        {elementsToRender.map((yoodli) => {
          const displayableTotalTimeS =
            yoodli.data.totalTimeS && !isNaN(yoodli.data.totalTimeS)
              ? getDisplayableTime(yoodli.data.totalTimeS)
              : "";
          return (
            <Stack
              sx={{
                minWidth: "208px",
                width: "208px",
                height: "170px",
                border: `1px solid ${getDynamicColor("dark2")}`,
                borderRadius: "2px",
                backgroundColor: getDynamicColor("light1"),
                p: 1,
                cursor: "pointer",
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: getDynamicColor("light2"),
                },
                mb: 1,
              }}
              gap={1}
              key={yoodli.ref.id}
              onClick={() => {
                history.push(`${WebServerExternalPath.SHARE}${yoodli.data.slug}`);
              }}
            >
              {renderThumbnail(yoodli)}
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 600,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  lineHeight: 1,
                  height: "16px",
                  px: "2px",
                }}
              >
                {yoodli.data.name}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontWeight: 600,
                    backgroundColor: getDynamicColor("light2"),
                    borderRadius: "20px",
                    px: "6px",
                    py: "2px",
                  }}
                >
                  {getSpeechTypeStringFromEnum(yoodli.data)}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: 600,
                    color: getDynamicColor("dark4"),
                    pr: "2px",
                  }}
                >
                  {formatDateShort(yoodli.data.recordedDate)} | {displayableTotalTimeS}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
