// Components
import { Box, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { HubRole } from "lib-fullstack/utils/enums";

type DoneProps = {
  emailsToInvite: string[];
  hub: HubV2Response;
  role: HubRole;
};

export const Done = ({ emailsToInvite, hub, role }: DoneProps): JSX.Element => {
  const parseHubRole = () => {
    return role === HubRole.ADMIN ? "Group admin" : "Group member";
  };

  return (
    <Stack gap={3} sx={{ maxWidth: "475px", mx: "auto", textAlign: "center", pt: 11, px: 3 }}>
      <Stack
        direction="row"
        gap={1}
        sx={{ alignItems: "center", fontSize: "30px", justifyContent: "center" }}
      >
        <Typography>🎉</Typography>
        <Typography
          sx={{
            background: getDynamicColor("gradient.default"),
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "poppins",
            fontWeight: 700,
          }}
        >
          You’re all set!
        </Typography>
      </Stack>
      {emailsToInvite.length > 0 ? (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          We successfully sent {emailsToInvite.length} invitations to be {parseHubRole()} in{" "}
          {
            <Box component="span" sx={{ color: getDynamicColor("primary") }}>
              {hub?.name}
            </Box>
          }
          . Any existing or duplicate individuals were ignored. You can view and manage your
          invitations from the Groups page.
        </Typography>
      ) : (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          Your new group{" "}
          <Box component="span" sx={{ color: getDynamicColor("primary") }}>
            {hub?.name}
          </Box>{" "}
          has been successfully created. You can invite new members from the Groups page.
        </Typography>
      )}
    </Stack>
  );
};
