import { db } from "lib-fullstack";
import { isMacOs, isWindows } from "react-device-detect";

// Components
import { Chunk } from "../components/VideoSummary/Transcript/TranscriptUtils";

// Utils
import { envConfig } from "./Constants";
import { SPEECH_SUBTYPES } from "./Enums";
import { History } from "history";
import { OrgModuleAccess } from "lib-frontend/contexts/UserOrgContext";
import { getUser } from "lib-frontend/modules/AxiosInstance";
import { getSiteId, getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { GetUserFieldType } from "lib-fullstack/api/apiTypes";
import { OBQ1Option } from "lib-fullstack/utils/enums";
import { formatDate } from "lib-fullstack/utils/dateUtils";
import { getDisplayableTime } from "lib-fullstack/utils/dateUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { getSubdomainFromHostname } from "lib-fullstack/workspaces/subdomain";
import { PUBLIC_WEBSITE_PATHS, WebServerInternalPath } from "utils/paths";

/* eslint-disable @typescript-eslint/no-explicit-any */
export function intersperseSpacer(arr: any[], el: (key: string) => JSX.Element): any[] {
  const res = [];
  let i = 0;
  if (i < arr.length) res.push(arr[i++]);
  while (i < arr.length) res.push(el, arr[i++]);
  return res;
}
/* eslint-enabled @typescript-eslint/no-explicit-any */

/**
 * @param {*} arr
 * @param {*} n
 * @returns array of length n with elements from arr
 *
 */
export const mapArrToN = (arr: unknown[], n: number): unknown[] =>
  Array.from({ length: n }, (_, i) => arr[i] ?? null);

// extract things like pathname, href, origin, etc. from a string href
export function getLocation(href: string): HTMLAnchorElement {
  const l = document.createElement("a");
  l.href = href;
  return l;
}

export function isYoodliSite(): boolean {
  return getSiteId() === "yoodli";
}

/**
 * Opens the passed in url in a new tab
 *
 * @param {*} url
 */
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/**
 * Returns the number of digits in the passed in value
 * @param {*} value
 * @returns number of digits in value
 */
export const getLength = (value: unknown): number => {
  return value.toString().length;
};

export function getEndUserTutorialSlug(): string {
  const siteConf = getStaticFullSiteConf();
  return siteConf?.endUserWelcomeSlugs?.[envConfig.envName] ?? envConfig.endUserTutorialSlug;
}

export function getEndUserDemoSlug(): string {
  const siteConf = getStaticFullSiteConf();
  return siteConf?.demoSlugs?.[envConfig.envName] ?? envConfig.demoSlug;
}

export function getCoachTutorialSlug(): string {
  const siteConf = getStaticFullSiteConf();
  return siteConf?.coachWelcomeSlugs?.[envConfig.envName] ?? envConfig.coachTutorialSlug;
}

export function getCoachDemoSlug(): string {
  const siteConf = getStaticFullSiteConf();
  return siteConf?.coachDemoSlugs?.[envConfig.envName] ?? envConfig.coachDemoSlug;
}

/**
 * Creates a new GameRecording object that will sent to the backend
 */
export const createGameRecordingObject = (type: string): { type: string } => {
  return { type };
};

/**
 * Retrieves the enum key for its respective value for the enum object passed in
 * @param enumObject
 * @param {} value
 * @returns enum object enum key
 */
export const getEnumKey = (enumObject: Record<string, unknown>, value: unknown): string => {
  return Object.keys(enumObject)[Object.values(enumObject).indexOf(value)];
};

/**
 * Generates a summary speech link for Router to push to
 */
export const generateSummaryLink = (userId: string, speechId: string): string => {
  return `${WebServerInternalPath.LIBRARY}${userId}${WebServerInternalPath.SUMMARY}${speechId}`;
};

export const generateSpeechSharePath = (speech: db.Speech & { documentId?: string }): string => {
  if (speech.slug) {
    return WebServerInternalPath.SHARE_SLUG.replace(":slug", speech.slug || "");
  }
  if (speech.recordedBy && speech.documentId) {
    return WebServerInternalPath.LIBRARY_USER_FEEDBACK_SPEECH.replace(
      ":userId",
      speech.recordedBy
    ).replace(":speechId", speech.documentId);
  }
  console.log(speech);
  throw Error("Could not generate share link for speech (no slug/user/doc IDs)");
};

export const generateSpeechShareFullLink = (speech: db.Speech): string => {
  try {
    return window.location.origin + generateSpeechSharePath(speech);
  } catch (err) {
    console.error(err);
    // Fallback
    return window.location.href;
  }
};

/**
 * Generates a speech link for Router to push to
 * @param {*} speech
 * @returns string of speech link
 * @deprecated
 */
export const generateSpeechPathFromSlug = (slug: string): string => {
  try {
    return window.location.origin + WebServerInternalPath.SHARE_SLUG.replace(":slug", slug);
  } catch (err) {
    console.error(err);
    // Fallback
    return window.location.href;
  }
};

/**
 * Generates a speech link for Router to push to
 * @param {*} speech
 * @returns string of speech link
 */
export const generateSpeechSubtypeLink = (speech: db.Doc<db.Speech>): string => {
  try {
    if (SPEECH_SUBTYPES[speech.data.subType] === SPEECH_SUBTYPES.JOB_INTERVIEW) {
      return window.location.origin + WebServerExternalPath.PRACTICE_INTERVIEW;
    } else {
      return window.location.origin + WebServerExternalPath.PRACTICE_SPEECH;
    }
  } catch (err) {
    return window.location.href;
  }
};

/**
 * Generates a speech link for Router to push to
 * @param {*} speech
 * @returns string of speech link
 * @deprecated
 */
export const generateFullPathToAYoodliWebsitePath = (websitePath: string): string => {
  try {
    return window.location.origin + websitePath;
  } catch (err) {
    console.error(err);
    // Fallback
    return window.location.href;
  }
};

/**
 * On "yoodli" site, home page is currently "/home" (where courses live).
 * If whitelabel site, home page is Video Journal because "/home" would be empty (most whitelabel sites don't have courses)
 * @returns string of home page path
 */
export const determineHomePagePath = (): string => {
  const { subdomain } = getSubdomainFromHostname();
  return subdomain === "app" ? WebServerExternalPath.HOME_LOGGED_IN : WebServerInternalPath.LIBRARY;
};

/**
 * @param {*} wordsMapping
 * @returns number of words in mapping
 */
export const wordCounterFromMapping = (wordsMapping: { [k: string]: number }): number => {
  let wordCounter = 0;

  if (!wordsMapping || Object.keys(wordsMapping).length === 0) {
    return wordCounter;
  }

  for (const [, value] of Object.entries(wordsMapping)) {
    wordCounter += value;
  }

  return wordCounter;
};

// why the heck are't these builtin e___e
/**
 * @param {*} setA
 * @param {*} setB
 * @returns set difference of setA and setB
 *
 */
export const setDifference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _difference = new Set(setA);
  setB.forEach((elem) => {
    _difference.delete(elem);
  });
  return _difference;
};

/**
 * @param {*} setA
 * @param {*} setB
 * @returns set union of setA and setB
 */
export const setUnion = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _union = new Set(setA);
  setB.forEach((elem) => {
    _union.add(elem);
  });
  return _union;
};

/**
 * @param {*} setA
 * @param {*} setB
 * @returns set intersection of setA and setB
 */
export const setIntersection = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _intersection = new Set<T>();
  setB.forEach((elem) => {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  });
  return _intersection;
};

/**
 * Gets transcripttext from words
 * @param {*} words
 * @returns string of transcript text
 */
export const getTranscriptText = (words: db.Word[]): string => {
  if (!words || words.length === 0) {
    return "";
  }
  return words.reduce((transcript, word) => {
    const punct = word.value.match(/[.,!?]/);
    if ((punct && punct.length > 0) || transcript.length === 0) {
      return transcript + word.value;
    } else {
      return transcript + " " + word.value;
    }
  }, "");
};

/**
 * copy Transcript to clipboard
 * @param {*} dbSpeech
 * @param {*} transcriptChunks
 * @param {*} words
 * @param {*} isDiarized
 * @returns string of transcript text including line breaks
 */
export const copyTranscript = (
  dbSpeech: db.Doc<db.Speech>,
  transcriptChunks: Chunk[],
  words: db.Word[],
  isDiarized: boolean
): string => {
  let transcript = "";
  transcript += `
  ${dbSpeech.data.name}
  \n
  ${formatDate(dbSpeech.data.recordingStartDate)}
  \n
  Link to video on ${getStaticFullSiteConf().title}: ${window.location.href}
  \n\n`;

  for (const chunk of transcriptChunks) {
    transcript += `${getDisplayableTime(chunk.startS)}:`;

    if (isDiarized) {
      transcript += ` ${dbSpeech.data.speakers[chunk.speakerId].name}`;
    } else {
      transcript += `\n`;
    }

    if (chunk.name) {
      transcript += `\n${chunk.name}\n`;
    }

    transcript += "\n";

    const chunkWords = words.slice(
      chunk.chunkStartToken,
      chunk.chunkStartToken + chunk.chunkTokenLength
    );

    if (chunkWords.length === 0) {
      transcript += "Other people spoke";
    }

    transcript += `${chunkWords.reduce((currChunk, word) => {
      const punct = word.value.match(/[.,!?]/);
      if ((punct && punct.length > 0) || currChunk.length === 0) {
        return currChunk + word.value;
      } else {
        return currChunk + " " + word.value;
      }
    }, "")}\n\n`;
  }
  return transcript;
};

/**
 * This function takes a video file and returns the duration of the video.
 * @param {*} file
 * @returns duration of video file
 */
export const getUploadDuration = (file: Blob): Promise<number> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        const duration = video.duration;
        window.URL.revokeObjectURL(video.src);
        video.remove();

        resolve(duration);
      };

      video.onerror = function () {
        window.URL.revokeObjectURL(video.src);
        video.remove();
        reject("Invalid file. Please select a video or audio file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

// TODO -> db runtype
export type DropdownButtonOptionArrayInfo = {
  value: string; // Usually a string, but could be a MuiIcon
  iconId: string;
  path?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
};

export const isValidCustomInterviewQuestion = (value: string): boolean => {
  const strValue = String(value)
    .toLowerCase()
    .replace(/[ .,?!]/g, "");

  return strValue?.length >= 3 && value?.length <= 350;
};

/**
 * This function returns the options for the dropdown button on the record page.
 * @returns DropdownButtonOptionArrayInfo[]
 */
export const getDropDownButtonOptions = (
  isToastmasters: boolean,
  orgModuleAccess: OrgModuleAccess
): DropdownButtonOptionArrayInfo[] => {
  const options: DropdownButtonOptionArrayInfo[] = [];
  const { interviewEnabled, presentationEnabled, roleplayEnabled } = orgModuleAccess;
  if (isToastmasters) {
    options.push({
      value: "Presentation",
      iconId: "speech",
      path: WebServerExternalPath.PRACTICE_SPEECH,
    });
    options.push({
      value: "Table Topics",
      iconId: "conversation",
      path: `${WebServerExternalPath.PRACTICE_SPEECH}?prompt=true`,
    });
  } else {
    if (roleplayEnabled) {
      options.push({
        value: "Roleplay",
        iconId: "conversation",
        path: WebServerExternalPath.PRACTICE_CONVERSATION,
      });
    }
    if (presentationEnabled) {
      options.push({
        value: "Presentation",
        iconId: "speech",
        path: WebServerExternalPath.PRACTICE_SPEECH,
      });
    }
    if (interviewEnabled) {
      options.push({
        value: "Interview",
        iconId: "interview",
        path: WebServerExternalPath.PRACTICE_INTERVIEW,
      });
    }
  }

  return options;
};

/**
 * This function returns whether or not the user is a coach.
 * @returns boolean of whether or not the user is a coach
 */
export function isCoach(): boolean {
  if (isWhiteLabel()) return false;

  const { onboardingAnswers } = getLiveUserDocMain();
  return onboardingAnswers?.eventsFocus === OBQ1Option.COACH;
}

/**
 * This function returns whether or not the coach checklist should be shown.
 * @returns boolean of whether or not the coach checklist should be shown
 */
export function shouldShowCoachChecklist(): boolean {
  if (!getEnabledFlag("coach-checklist") || window?.location?.href.includes("/summary/"))
    return false;
  return !isWhiteLabel();
}

/**
 * This function returns the download path for the Yoodli app.
 * @returns string of download path
 * @description
 * It will return the correct path for the user's operating system and architecture.
 */
export async function getPrivateYoodliDownloadPath(): Promise<string> {
  const basePath = "https://storage.googleapis.com/yoodli-poodli-releases/current/";

  let downloadPathPromise;
  if (isMacOs) {
    // Detecting Apple silicon is hard, and requires a special browser function
    // We default to assuming we're on intel hardware until proven otherwise
    let archTypePromise = Promise.resolve("intel");
    if (navigator.userAgentData) {
      archTypePromise = navigator.userAgentData
        .getHighEntropyValues?.(["architecture"])
        .then((values) => {
          return values?.architecture;
        });
    }

    downloadPathPromise = archTypePromise.then((arch) => {
      if (arch === "arm") {
        return basePath + "mac/arm64/Yoodli.dmg";
      } else {
        return basePath + "mac/x64/Yoodli.dmg";
      }
    });
  } else if (isWindows) {
    return basePath + "win/x64/Yoodli-Setup.exe";
  } else {
    throw "The desktop app is not yet supported on Linux";
  }

  return downloadPathPromise;
}

/**
 * Retrieve the sign-in or sign-up return path.
 * @param requestedReturnPath Return path requested, typically location?.state?.returnPath
 * @returns the signIn (returning user) and signUp (new user) return paths
 */
export function getSignInSignUpReturnPath(requestedReturnPath: string | null): {
  signInReturnPath: string;
  signUpReturnPath: string;
} {
  const [_, searchParams] = requestedReturnPath?.split("?") ?? [null, null];

  const signInReturnPath =
    requestedReturnPath && requestedReturnPath !== WebServerInternalPath.HOME
      ? requestedReturnPath
      : determineHomePagePath();

  // for new users, we change the return path to the onboarding path if the return path is not a public path such as the speech summary
  // as we want users to return there after signing up if they were already in that workflow.
  const signUpReturnPath =
    isWhiteLabel() ||
    (requestedReturnPath &&
      PUBLIC_WEBSITE_PATHS.filter((p) =>
        requestedReturnPath.toLowerCase().includes(p.toLowerCase())
      ).length > 0)
      ? signInReturnPath
      : searchParams
      ? `${WebServerInternalPath.ONBOARDING}?${searchParams}`
      : WebServerInternalPath.ONBOARDING;

  return { signInReturnPath, signUpReturnPath };
}

/**
 * Merge search parameters into a return path.
 * @param returnPath Return path requested, typically location?.state?.returnPath.
 * May include search parameters.
 * @param searchParams Current location search parameters, typically location.search.
 * @returns Return path with search parameters merged into it.
 */
export function mergeReturnPathAndSearchParameters(
  returnPath: string,
  searchParams: string
): string {
  if (returnPath === undefined) {
    return searchParams;
  } else if (searchParams === undefined) {
    return returnPath;
  }
  let result = returnPath;
  const splitPath = returnPath.split("?");
  if (splitPath.length === 1) {
    // Return path has no search parameters.
    if (searchParams) {
      // Search parameters are not empty.
      if (searchParams.startsWith("?")) {
        // Search parameters start with a question mark.
        result = returnPath + searchParams;
      } else {
        // Search parameters do not start with a question mark.
        result = returnPath + "?" + searchParams;
      }
    }
  } else {
    // Return path has search parameters.
    result =
      splitPath[0] +
      "?" +
      new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(splitPath[1])),
        ...Object.fromEntries(new URLSearchParams(searchParams)),
      }).toString();
  }
  return result;
}

export function truncateText(text: string, maxLen: number): string {
  return text.length > maxLen ? text.slice(0, maxLen).trim() + "..." : text;
}

export async function fetchBrandingLogo(): Promise<string | null> {
  const { default_org_logo_url } = await getUser([GetUserFieldType.DEFAULT_ORG_LOGO_URL]);

  await updateThisUserDocMain({ brandingLogoUrl: default_org_logo_url ?? db.value("remove") });
  return default_org_logo_url ?? null;
}

// Reorder an array
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const newList = structuredClone(list) as T[];
  const [removed] = newList.splice(startIndex, 1);
  newList.splice(endIndex, 0, removed);

  return newList;
};

export function getRedirectPathByOBQ1(obq1: OBQ1Option): string {
  switch (obq1) {
    case OBQ1Option.INTERVIEW:
      return WebServerExternalPath.PRACTICE_INTERVIEW;
    case OBQ1Option.SPEECH:
    case OBQ1Option.COACH:
      return WebServerExternalPath.PRACTICE_SPEECH;
    case OBQ1Option.MEETINGS:
      return WebServerExternalPath.PRACTICE_CONVERSATION;
    case OBQ1Option.SALES:
      return WebServerExternalPath.PRACTICE_SALES;
    case OBQ1Option.SKIPPED:
      return WebServerExternalPath.HOME_LOGGED_IN;
    default:
      return WebServerInternalPath.ONBOARDING;
  }
}

/**
 * Get the return location set in the location state.
 * @param location Object returned from useLocation().
 * @returns Current return path.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getReturnPath(location: any): string {
  // Uncomment this line to debug changes to returnPath.
  // console.trace(`location.state.returnPath: ${location?.state?.returnPath}`);
  return location?.state?.returnPath;
}

/**
 * Push a path and state onto the browser history.
 * @param history Object returned from useHistory().
 * @param path Path to push onto the history.
 * @param state Optional state to push onto the history.
 */
export function historyPush(history: History, path: string, state = {}): void {
  // Uncomment this line to debug changes to hisotry.
  // console.trace(`history.push: ${path} ${JSON.stringify(state)}`);
  if (state) {
    history.push(path, state);
  } else {
    history.push(path);
  }
}
