// Components
import { useMediaQuery, useTheme, Breakpoint } from "@mui/material";

export const useIsBreakpointScreen = (breakpoint: Breakpoint): boolean => {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up(breakpoint));
};

export function useIsLargeScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("xl"));
}

export function useIsMediumScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("lg"));
}

export function useIsSmallScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("md"));
}

export function useIsExtraSmallScreen(): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up("sm"));
}

export function useIsScreenSmallerThanPx(px: number): boolean {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up(px));
}
