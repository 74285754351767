import React from "react";

// Components
import { Box, Stack, Typography, Pagination, CircularProgress } from "@mui/material";
import { TableSortServer } from "components/VideoJournal/TableComponents/TableSortServer";
import { TextOverflowTooltip } from "lib-frontend/components/YoodliComponents/TextOverflowTooltip";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { MEMBER_COUNT_HEIGHT, OrgMembersQueryKeys } from "./OrgMembers";
import {
  keepPreviousData,
  useQuery as useApiQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
  PaginationState,
} from "@tanstack/react-table";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  actOrgInviteV2,
  deleteOrgInviteV2,
  listOrgInvitesV2,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetOrgInviteListSortOption, OrgInviteAction } from "lib-fullstack/api/orgApiTypes";
import { OrgInviteResponse } from "lib-fullstack/api/orgApiTypes";
import { MAX_LICENSED_USERS_RESPONSE, ORG_MEMBER_FETCH_LIMIT } from "lib-fullstack/utils/constants";
import { OrgRole } from "lib-fullstack/utils/enums";

type OrgMembersTableProps = {
  searchText: string;
  setSearchText: (str: string) => void;
  recalcTopContentHeight: () => void;
};

enum MenuAction {
  ResendInvite = "resend_invite",
  CancelInvite = "cancel_invite",
}

export type OrgInvitesTableRow = {
  email: string;
  effective_role: string;
  hubs: string[];
  date_invited: string;
  action: null;
};

const parseOrgRole = (role?: string): string => {
  switch (role) {
    case OrgRole.OWNER:
      return "Org owner";
    case OrgRole.ADMIN:
      return "Org admin";
    default:
      return "Member";
  }
};

const parseDateString = (dateStr: string) => {
  return new Date(dateStr).toLocaleDateString("en-us", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

export default function OrgInvitesTable({
  searchText,
  setSearchText,
  recalcTopContentHeight,
}: OrgMembersTableProps): JSX.Element {
  const queryClient = useQueryClient();
  const userOrgContext = React.useContext(UserOrgContext);
  const isSmallScreen = useIsSmallScreen();
  const columnHelper = createColumnHelper<OrgInvitesTableRow>();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: ORG_MEMBER_FETCH_LIMIT,
  });
  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgInviteListSortOption.EMAIL_ASC as string
  );
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();

  const anyFilterEnabled = React.useMemo(() => {
    return searchText?.length > 0;
  }, [searchText]);

  const invitesQueryResult = useApiQuery({
    queryKey: [
      OrgMembersQueryKeys.OrgInvites,
      userOrgContext.orgId,
      {
        start: paginationStartInd,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : (sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC,
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listOrgInvitesV2(userOrgContext.orgId, {
        start: paginationStartInd,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : (sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC,
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
  });

  const { showNotificationBanner } = useNotification();

  // redefine the invites list whenever the data changes
  const invitesList = React.useMemo(
    () => invitesQueryResult.data?.invites ?? [],
    [invitesQueryResult.data?.invites]
  );

  React.useEffect(() => {
    recalcTopContentHeight();
  }, [invitesList]);

  const getHubNameFromId = (hubId: string) => {
    const hubList = userOrgContext.defaultOrg.hubs;
    const hubArr = hubList.filter((hub) => hubId === hub.id);
    if (hubArr.length === 0) {
      return hubId;
    }
    return hubArr[0].name;
  };

  const resendInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await actOrgInviteV2(userOrgContext.defaultOrg.id, email, OrgInviteAction.RESEND);
    },
    onSuccess: (_, email: string) => {
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgInvites, userOrgContext.orgId],
      });
      showNotificationBanner(
        <>
          Resent invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Success }
      );
    },
    onError: (e: Error, email: string) => {
      console.log("Error in resending invite: ", e);
      showNotificationBanner(
        <>
          Failed to resend invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Danger }
      );
    },
  });

  const cancelInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await deleteOrgInviteV2(userOrgContext.defaultOrg.id, email);
    },
    onSuccess: (_, email) => {
      void userOrgContext.invalidateDefaultOrgQuery();
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, userOrgContext.orgId],
      });
      return queryClient
        .invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgInvites, userOrgContext.orgId],
        })
        .then(() => {
          showNotificationBanner(
            <>
              Cancelled invite to <strong>{email}</strong>
            </>,
            { variant: YoodliNotificationBannerVariant.Success }
          );
        });
    },
    onError: (e: Error, email) => {
      console.log("Error in cancelling invite: ", e);
      showNotificationBanner(
        <>
          Failed to cancel invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Danger }
      );
    },
  });

  const handleMenuItemClick = async (row: Row<OrgInvitesTableRow>, action: MenuAction) => {
    const user = invitesList.find((user) => user.email === row.original.email);
    switch (action) {
      case MenuAction.ResendInvite:
        await resendInviteMutation.mutateAsync(user.email);
        break;
      case MenuAction.CancelInvite:
        await cancelInviteMutation.mutateAsync(user.email);
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("email", {
        id: "email",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Invites</Typography>,
        cell: (info) => (
          <Stack direction="row" gap={2} sx={{ alignItems: "center", pr: 0.5 }}>
            <YoodliAvatar
              name={info.getValue()}
              sx={{
                ml: { xs: 3, md: 5 },
                my: 2.5,
              }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "poppins",
                fontSize: "14px",
                color: getDynamicColor("purple3"),
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: { xs: "150px", md: "300px" },
              }}
            >
              {info.getValue()}
            </Typography>
          </Stack>
        ),
      }),
      ...(!isSmallScreen
        ? [
            columnHelper.accessor("date_invited", {
              id: "date_invited",
              header: () => <>Invited on</>,
              cell: (info) => (
                <Typography
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {parseDateString(info.getValue())}
                </Typography>
              ),
            }),
            columnHelper.accessor("effective_role", {
              id: "effective_role",
              header: () => <>Role</>,
              cell: (info) => (
                <Typography
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {info.getValue()}
                </Typography>
              ),
              filterFn: "arrIncludesSome",
            }),
            columnHelper.accessor("hubs", {
              id: "hubs",
              header: () => <>Groups</>,
              cell: (info) => (
                <TextOverflowTooltip
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "300px",
                    whiteSpace: "nowrap",
                    color: getDynamicColor("dark4"),
                  }}
                  title={info
                    .getValue()
                    .map((hubId) => getHubNameFromId(hubId))
                    .join(", ")}
                  textValue={info
                    .getValue()
                    .map((hubId) => getHubNameFromId(hubId))
                    .join(", ")}
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor("action", {
        id: "action",
        header: () => <div style={{ width: "36px" }} />,
        cell: (info) => (
          <YoodliMenu
            type={YoodliMenuButtonType.Icon}
            menuItems={[
              {
                title: "Resend invite",
                onClick: async () => {
                  await handleMenuItemClick(info.row, MenuAction.ResendInvite);
                },
                type: YoodliMenuItemType.Default,
              },
              {
                title: "Cancel invite",
                onClick: async () => {
                  await handleMenuItemClick(info.row, MenuAction.CancelInvite);
                },
                type: YoodliMenuItemType.Default,
              },
            ]}
          />
        ),
      }),
    ],
    [invitesList]
  );

  const filteredColumns = columns.filter(Boolean);
  const rowData = React.useMemo(
    () =>
      (invitesList ?? []).map((user) => ({
        effective_role: parseOrgRole(user.effective_role),
        hubs: (user as OrgInviteResponse).hubs.map((hub) => hub.hub_id),
        email: user.email,
        date_invited: user.date_invited,
        action: null,
      })),
    [invitesList]
  );
  const table = useReactTable({
    data: rowData,
    columns: filteredColumns,
    state: {
      globalFilter: searchText,
    },
    initialState: {
      pagination,
    },
    rowCount: invitesQueryResult.data?.total,
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
  });

  const firstItem = parseInt(paginationStartInd) + 1;
  const lastItem = Math.min(
    parseInt(paginationStartInd) + pagination.pageSize,
    table.getRowCount()
  );

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page - 1, 0) };
    });
  };
  return (
    <Stack
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      {invitesQueryResult.isPending ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Box
          sx={{
            minHeight: invitesList?.length > 4 ? 400 : "unset",
            overflowY: "auto",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: 2,
          }}
        >
          <table
            style={{
              width: "100%",
              boxShadow: Y_SHADOWS.dark_elevation,
              borderCollapse: "collapse",
              borderRadius: 2,
              borderSpacing: "0px",
            }}
          >
            <thead
              style={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "8px",
                height: "40px",
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Box
                  component="tr"
                  key={headerGroup.id}
                  sx={{
                    width: "100%",
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        minHeight: "40px",
                        borderBottom: "1px solid lightgrey",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: getDynamicColor("light1"),
                        borderRadius: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                          color: getDynamicColor("dark5"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 600,
                          mr: 1,
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {["email", "date_invited"].includes(header.id) && (
                          <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                        )}
                      </Stack>
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box
                    component="tr"
                    key={row.id}
                    sx={{
                      width: "max-content",
                      borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                      "&:last-of-type": {
                        borderBottomLeftRadius: 2,
                        borderBottomRightRadius: 2,
                        borderBottom: "unset",
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </Box>
                );
              })}
              {table.getRowCount() === 0 && !anyFilterEnabled && (
                <tr>
                  <td colSpan={columns.length}>
                    <Stack
                      direction="column"
                      sx={{
                        mx: "auto",
                        width: "100%",
                        color: getDynamicColor("dark4"),
                        minHeight: "250px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>No pending invites</Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        Invite members to join this organization using the buttons above
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      )}
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <Pagination
            sx={{ justifyContent: "center" }}
            count={table.getPageCount()}
            showFirstButton
            showLastButton
            page={pagination.pageIndex + 1}
            onChange={(_, page) => handlePageChange(page)}
          />
        )}
        {table.getRowCount() > 0 && (
          <Typography
            sx={{
              mt: 0.5,
              color: getDynamicColor("dark4"),
              fontSize: "12px",
              textAlign: "center",
              height: MEMBER_COUNT_HEIGHT,
            }}
          >
            {`Displaying invites ${firstItem} - ${lastItem} of ${table.getRowCount()}`}
          </Typography>
        )}
        {invitesList.length === MAX_LICENSED_USERS_RESPONSE &&
          userOrgContext.defaultOrg.license_used > invitesList.length && (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "center",
                pt: 3.5,
                pb: 6.5,
              }}
            >
              First {invitesList.length} of {userOrgContext.defaultOrg.license_used} invited members
            </Typography>
          )}
      </Stack>
    </Stack>
  );
}
