import React from "react";
import { useQuery as useApiQuery } from "@tanstack/react-query";
// Components
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  ExpandCircleDownRounded as ExpandCircleDownRoundedIcon,
  MoreHorizRounded as MoreHorizRoundedIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Menu,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { HubOption } from "components/Orgs/BulkInviteByCSVWizard/SelectHub";
import IconMenu from "lib-frontend/components/IconMenu";

// Utils
import { TemplateSubTypeChip } from "../../../../ConvoScenarios/TemplateSubTypeChip";
import { useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createReport,
  deleteScenario,
  listReports,
  patchScenario,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";
import { OrgScenario, ScenarioTemplateSubType } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ReportParameter, ReportState, ReportType } from "lib-fullstack/utils/enums";
import { getDownloadableReport, getReportProcessing } from "components/Orgs/Reports/reportsUtils";
import { usePrevious } from "lib-frontend/hooks";

type CustomScenarioCardProps = {
  scenario: GetScenarioResponse;
  selectedOrgId: string;
  allHubs: HubV2Response[];
  handleSelectScenario: (scenario: GetScenarioResponse, action: "edit" | "duplicate") => void;
  persona?: PersonaResponse;
  personaLoading?: boolean;
};

const SCENARIO_REPORT_QUERY_KEY = "scenarioReportQueryKey";

export const CustomScenarioCard = ({
  scenario,
  selectedOrgId,
  allHubs,
  handleSelectScenario,
  persona,
  personaLoading,
}: CustomScenarioCardProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const isExtraSmallScreen = useIsExtraSmallScreen();
  const [scenarioHubIds, setScenarioHubIds] = React.useState<string[]>(scenario.activeHubs ?? []);
  const [savingNewHubs, setSavingNewHubs] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  // hello
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);

  const [reportProcessing, setReportProcessing] = React.useState<boolean>(false);
  const scenarioReportQuery = useApiQuery({
    queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`, defaultOrg?.id],
    queryFn: async () => {
      const reports = await listReports(defaultOrg?.id, {
        reportType: ReportType.OrgScenarioEngagement,
        states: [ReportState.Rendered, ReportState.Created],
        parameters: JSON.stringify({
          [ReportParameter.ScenarioId]: scenario?.id,
        }),
      });
      return reports;
    },
    refetchInterval: reportProcessing ? 2000 : 0,
    enabled: !!defaultOrg?.id && !!scenario?.id,
  });
  const downloadableReport = React.useMemo(() => {
    return getDownloadableReport(scenarioReportQuery.data);
  }, [scenarioReportQuery.data]);

  const prevProcessing = usePrevious(reportProcessing);
  React.useEffect(() => {
    const processing = !!getReportProcessing(scenarioReportQuery.data);
    setReportProcessing(processing);

    // if i move from processing ot not processing, a report must have just come back, so download it
    if (prevProcessing && !processing && downloadableReport?.downloadUrl) {
      void handleDownloadReport(downloadableReport.downloadUrl);
    }
  }, [scenarioReportQuery.data, downloadableReport]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseHubsMenu = async () => {
    if (scenarioHubIds.sort().join() !== scenario.activeHubs.sort().join()) {
      try {
        setSavingNewHubs(true);
        // save the scenarioHubIds to the scenario in db
        await patchScenario(selectedOrgId, scenario.id, { activeHubs: scenarioHubIds });

        await queryClient.refetchQueries({
          queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrg?.id],
        });
      } catch (e) {
        console.error(`Error updating scenario activeHubs with id ${scenario.id}: ${e}`);
      }
    }
    setAnchorEl(null);
    // set a timeout for this so as he menu animates out the user doesnt see the word "Done" pop back in form the spinner
    setTimeout(() => {
      setSavingNewHubs(false);
    }, 200);
  };

  const handleDeleteScenario = async () => {
    setIsDeleting(!isDeleting);
    try {
      if (
        window.confirm(
          `Are you sure you want to delete the scenario "${scenario.title}"? This action cannot be undone.`
        )
      ) {
        await deleteScenario(selectedOrgId, scenario.id);
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
        });
        await queryClient.refetchQueries({
          queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrg?.id],
        });
      }
    } catch (e) {
      console.error(`Error deleting scenario with id ${scenario.id}: ${e}`);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDownloadReport = async (downloadUrl?: string) => {
    if (downloadUrl) {
      // download report
      window.location.assign(downloadUrl);
    } else {
      const params = {
        [ReportParameter.ScenarioId]: scenario?.id,
        [ReportParameter.OrgId]: defaultOrg?.id,
      };
      setReportProcessing(true);
      await createReport(defaultOrg?.id, {
        type: ReportType.OrgScenarioEngagement,
        parameters: JSON.stringify(params),
      });
      await queryClient.invalidateQueries({
        queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`],
      });
    }
  };

  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);
  const renderScenarioOptionsMenu = () => {
    const menuItems = [
      {
        title: "Edit",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          handleSelectScenario(scenario, "edit");
        },
      },
      {
        title: practiceLinkCopied ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>Link copied</Typography>
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
                height: 20,
                width: 20,
              }}
            />
          </Stack>
        ) : (
          "Copy practice link"
        ),
        onClick: () => {
          if (practiceLinkCopiedTimeout.current) {
            clearTimeout(practiceLinkCopiedTimeout.current);
          }

          copyToMyClipboard(
            getScenarioPracticePath(scenario.id, scenario.scenarioTypeId, selectedOrgId, true)
          );
          setPracticeLinkCopied(true);
          practiceLinkCopiedTimeout.current = setTimeout(() => {
            setPracticeLinkCopied(false);
          }, 3000);
        },
        disableCloseOnClick: true,
      },
      {
        title: "Duplicate",
        onClick: () => {
          handleSelectScenario(scenario, "duplicate");
        },
      },
      {
        title: reportProcessing ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgress size={16} />
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
              }}
            >
              Report processing
            </Typography>
          </Stack>
        ) : (
          "Download report"
        ),
        onClick: async () => {
          await handleDownloadReport(downloadableReport?.downloadUrl);
        },
      },
      {
        title: "Delete",
        onClick: async () => {
          void handleDeleteScenario();
        },
        disabled: isDeleting || scenario.usedByPrograms,
      },
    ];
    return (
      <IconMenu
        title="Manage File"
        hideCaret
        hideTooltip
        disableDrag
        openOverride={!!reportProcessing}
        menuItems={menuItems}
        iconButtonSx={{
          height: 28,
          width: 28,
          border: `1px solid ${getDynamicColor(isDeleting ? "dark3" : "primary")}`,
          borderRadius: 100,
          svg: {
            height: 30,
            width: 30,
            color: getDynamicColor(isDeleting ? "dark3" : "primary"),
          },
        }}
        menuItemSx={{
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "poppins",
          px: 2,
          py: 1.5,
          color: getDynamicColor("primary"),
        }}
        minWidth={"140px"}
        paperSx={{
          borderRadius: "12px",
          minWidth: 190,
        }}
        icon={
          <MoreHorizRoundedIcon
            sx={{
              color: getDynamicColor("primary"),
            }}
          />
        }
      />
    );
  };
  const renderPersona = () => {
    if (personaLoading) {
      return <CircularProgress size={16} />;
    } else if (persona) {
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 20,
              width: 20,
            }}
          />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {persona?.name}
          </Typography>
        </Stack>
      );
    }
    return null;
  };
  return (
    <Stack
      alignItems={{ xs: "flex-start", sm: "center" }}
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      gap={1}
      sx={{
        fontFamily: "poppins",
        p: 2.5,
        border: `1px solid ${getDynamicColor("dark2")}`,
        boxShadow: Y_SHADOWS.box_shadow_1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
          }}
        >
          <Typography
            onClick={() => handleSelectScenario(scenario, "edit")}
            sx={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.3,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {scenario.title}
          </Typography>
          {scenario.description?.length > 100 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                mb: "0px !important",
                color: getDynamicColor("purple3"),
              }}
              onChange={(e, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                }}
              >
                {scenario.description}
              </AccordionDetails>
            </Accordion>
          ) : scenario.description?.length > 0 ? (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.4,
              }}
            >
              {scenario.description}
            </Typography>
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <TemplateSubTypeChip
              templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
            />
            <Typography
              sx={{
                fontSize: 12,
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {(scenario as OrgScenario).createdByEmail} (
              {getHumanReadableDate((scenario as OrgScenario).createdAt)})
            </Typography>
            {renderPersona()}
          </Stack>
        </Stack>
        {isExtraSmallScreen && renderScenarioOptionsMenu()}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "space-between", sm: "flex-end" }}
        gap={2}
        sx={{
          width: { xs: "100%", sm: "unset" },
        }}
      >
        <Button
          id="hub-menu-button"
          aria-controls={open ? "hub-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={
            <ExpandCircleDownRoundedIcon
              sx={{
                height: 20,
                width: 20,
                color: getDynamicColor("primary"),
              }}
            />
          }
          sx={{
            justifyContent: "space-between",
            height: 40,
            borderRadius: "8px",
            border: `1px solid ${getDynamicColor("dark2")}`,
            fontSize: 14,
            fontWeight: 500,
            minWidth: { xs: "100%", sm: 210 },
            color: getDynamicColor(scenarioHubIds.length === 0 ? "dark4" : "purple3"),
            whiteSpace: "nowrap",
          }}
        >
          {scenarioHubIds.length === 0
            ? "Not assigned"
            : `Assigned to ${scenarioHubIds.length} group${scenarioHubIds.length === 1 ? "" : "s"}`}
        </Button>
        <Menu
          id="hub-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleCloseHubsMenu}
          MenuListProps={{
            "aria-labelledby": "hub-menu-button",
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: { xs: "100%", sm: 190 },

                borderRadius: "8px",
                boxShadow: Y_SHADOWS.box_shadow_1,
                border: `1px solid ${getDynamicColor("dark2")}`,
              },
            },
          }}
        >
          <Stack
            direction="column"
            gap={1}
            alignItems="flex-start"
            sx={{
              height: "100%",

              px: 2,
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Button
              sx={{
                fontSize: 12,
                position: "relative",
                top: 4,
                left: -4,
              }}
              onClick={() => {
                if (scenarioHubIds.length > 0) {
                  setScenarioHubIds([]);
                } else {
                  setScenarioHubIds(allHubs.map((hub) => hub.id));
                }
              }}
            >
              {scenarioHubIds?.length === 0 ? "Select " : "Deselect "}all
            </Button>
            {allHubs.map((hub) => (
              <HubOption
                key={hub.id}
                hubName={hub.name}
                checked={scenarioHubIds.includes(hub.id)}
                onChange={(e) => {
                  setScenarioHubIds(
                    e.target.checked
                      ? [...scenarioHubIds, hub.id]
                      : scenarioHubIds.filter((id) => id !== hub.id)
                  );
                }}
                sx={{
                  mr: 0,
                }}
              />
            ))}
            <Button
              onClick={handleCloseHubsMenu}
              variant="outlined_small"
              sx={{
                mx: "auto",
                mb: 1,
                px: 2,
                fontSize: 14,
                borderWidth: 1,
                // static height and width so the loader doesn't change the size of the button
                width: 72,
                height: 30,
              }}
            >
              {savingNewHubs ? <CircularProgress size={16} /> : "Done"}
            </Button>
          </Stack>
        </Menu>

        {!isExtraSmallScreen && renderScenarioOptionsMenu()}
      </Stack>
    </Stack>
  );
};
