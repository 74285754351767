import React from "react";

// Components
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Box, Button, Stack, Typography, CircularProgress } from "@mui/material";
import { TEMPLATE_SUB_TYPE_DATA } from "components/ConvoScenarios/convoScenarioUtils";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ScenarioTemplateSubType, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { getScenarioContextRephrase } from "utils/GPTUtils";

export type AdditionalContextDetails = {
  userProvidedContext: string;
  templateDefiningPromptDetails: string;
};

type ScenarioAdditionalContextProps = {
  additionalContext: AdditionalContextDetails;
  handleSetAdditionalContext: (key: keyof AdditionalContextDetails, value: string) => void;
  templateSubType: ScenarioTemplateSubType;
  scenarioId: ScenarioTypeIdEnum;
  userProvidedContextTitle?: string;
  placeholderCopy?: string;
  showPromptDetails: boolean;
};

const MAX_CONTEXT_LENGTH = 5000; // the max length of the context
const WARNING_CONTEXT_LENGTH = 1000; // the length at which to begin showing the warning copy
export const ScenarioAdditionalContext = ({
  additionalContext: { userProvidedContext = "", templateDefiningPromptDetails = "" },
  handleSetAdditionalContext,
  templateSubType,
  scenarioId,
  userProvidedContextTitle,
  showPromptDetails,
  placeholderCopy,
}: ScenarioAdditionalContextProps): JSX.Element => {
  const [editingPromptDetails, setEditingPromptDetails] = React.useState(false);
  const [tempPromptDetails, setTempPromptDetails] = React.useState(templateDefiningPromptDetails);
  const [loading, setLoading] = React.useState(false);

  const promptDetailsValue = editingPromptDetails
    ? tempPromptDetails
    : templateDefiningPromptDetails;
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          {userProvidedContextTitle && (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              {userProvidedContextTitle}
            </Typography>
          )}
          <Button
            sx={{
              fontSize: 12,
              color: getDynamicColor("dark4"),
              ml: "auto",
            }}
            onClick={async () => {
              setLoading(true);
              const rephrase = await getScenarioContextRephrase(
                userProvidedContext,
                scenarioId,
                templateDefiningPromptDetails
              );
              handleSetAdditionalContext("userProvidedContext", rephrase);
              setLoading(false);
            }}
          >
            Rephrase
          </Button>
        </Stack>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <YoodliTextfield
            autoFocus
            value={userProvidedContext}
            onChange={(e) => handleSetAdditionalContext("userProvidedContext", e.target.value)}
            multiline
            minRows={2}
            placeholder={placeholderCopy ?? ""}
            maxChars={MAX_CONTEXT_LENGTH}
            sx={{
              width: "100%",
            }}
            InputProps={{
              sx: { fontSize: 16 },
            }}
            inputProps={{
              className: "blockEnterToNavigate",
            }}
            disabled={loading}
          />
          {userProvidedContext?.length >= WARNING_CONTEXT_LENGTH && (
            <Typography
              sx={{
                fontWeight: 500,
                color: getDynamicColor("dark4"),
                fontSize: 14,
                mt: 1,
              }}
            >
              Note: providing too much context may confuse the AI and lead to inconsistent behaviors
            </Typography>
          )}
        </Box>
      </Stack>
      {showPromptDetails && (
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <YoodliTooltip
              placement="bottom-start"
              title={`By editing these details, you may change the context of the scenario. Please ensure that your description is as close to the original context as possible.${
                editingPromptDetails ? " Hit “Reset” to restore defaults" : ""
              }`}
            >
              <InfoIcon
                sx={{
                  height: 20,
                  width: 20,
                  color: getDynamicColor("primary"),
                }}
              />
            </YoodliTooltip>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 700,
                color: getDynamicColor("purple3"),
                ":first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {TEMPLATE_SUB_TYPE_DATA[templateSubType]?.label.toLowerCase()} details
            </Typography>
          </Stack>
          <YoodliTextfield
            disabled={!editingPromptDetails}
            value={promptDetailsValue}
            onChange={(e) => setTempPromptDetails(e.target.value)}
            multiline
            minRows={4}
            placeholder="e.g. Yoodli is an AI platform for sales training"
            sx={{
              width: "100%",
            }}
            InputProps={{
              sx: { fontSize: 16 },
            }}
            inputProps={{
              className: "blockEnterToNavigate",
            }}
          />
          {editingPromptDetails ? (
            <Stack
              direction="row"
              gap={1}
              sx={{
                ml: "auto",
              }}
            >
              <Button
                onClick={() => {
                  setEditingPromptDetails(false);
                  setTempPromptDetails(templateDefiningPromptDetails);
                }}
                sx={{
                  fontSize: 12,
                }}
              >
                Reset
              </Button>
              <Button
                onClick={() => {
                  handleSetAdditionalContext("templateDefiningPromptDetails", tempPromptDetails);
                  setEditingPromptDetails(false);
                }}
                sx={{
                  fontSize: 12,
                }}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditingPromptDetails(true)}
              sx={{
                fontSize: 12,
                ml: "auto",
                color: getDynamicColor("dark4"),
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};
