import { db } from "lib-fullstack";
import React from "react";

// Components
import { Add as AddIcon, Remove as RemoveIcon, Check as CheckIcon } from "@mui/icons-material";
import { Box, Button, Collapse, Skeleton, Stack, Typography } from "@mui/material";
import {
  GoalLabels,
  GoalTalkTime,
  MAX_CHAR_LENGTH_TALKING_POINT,
  MAX_TALKING_POINTS,
} from "components/ConvoScenarios/convoScenarioUtils";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as LockIcon } from "images/icons/icon-lock-locked.svg";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { useScrollToTop } from "hooks/useScrollToTop";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { DefaultGoal, GoalKind } from "lib-fullstack/db";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";
import { LandingPageExternalPath } from "lib-fullstack/utils/paths";

const GOAL_OPTIONS = {
  [db.ScenarioTypeIdEnum.SALES_CALL]: [
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.COLD_CALL]: [
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: [
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
    DefaultGoal.HitTalkingPoints,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: [
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
    DefaultGoal.HitTalkingPoints,
  ],
  [db.ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: [
    DefaultGoal.ActiveListening,
    DefaultGoal.HitTalkingPoints,
  ],
  [db.ScenarioTypeIdEnum.MEDIA_TRAINING]: [
    DefaultGoal.ActiveListening,
    DefaultGoal.HitTalkingPoints,
  ],
  [db.ScenarioTypeIdEnum.GENERIC]: Object.values(DefaultGoal), // all goals
  [db.ScenarioTypeIdEnum.PITCH]: [DefaultGoal.HitTimeTarget, DefaultGoal.HitTalkingPoints],
  [db.ScenarioTypeIdEnum.SMALL_TALK]: [DefaultGoal.ActiveListening, DefaultGoal.HitTalkingPoints],
  [db.ScenarioTypeIdEnum.PATIENT_COUNSELING]: [
    DefaultGoal.ActiveListening,
    DefaultGoal.HitTalkingPoints,
  ],
  [db.ScenarioTypeIdEnum.NETWORKING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
  ],
};

type GoalSelectorProps = {
  goals: (DefaultGoal | string)[];
  handleUpdateGoals: (goals: (DefaultGoal | string)[]) => void;
  talkingPoints: string[];
  handleUpdateTalkingPoints: (talkingPoints: string[]) => void;
  handleUpdateCustomGoalType: (type: GoalKind) => void;
  goalTalkTime: GoalTalkTime;
  handleUpdateGoalTalkTime: (goalTalkTime: GoalTalkTime) => void;
  editingGoalTalkTime: boolean;
  setEditingGoalTalkTime: (editingDefaultGoalTalkTime: boolean) => void;
  scenarioTypeId: db.ScenarioTypeIdEnum;
  customGoals: CustomGoalItemWithUsage[];
  customGoalsLoading: boolean;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleCustomGoalDeletion: (goalId: string) => Promise<string>;
  customGoalDeletionLoading: boolean;
  handleEditCustomGoal: (goal: CustomGoalItemWithUsage) => Promise<void>;
  wizardType: WizardType;
};

const GOAL_SUBTITLE_STYLES = {
  fontWeight: 500,
  fontSize: 12,
  color: getDynamicColor("dark4"),
  pl: 4,
  position: "relative",
  top: -4,
};

export type GoalItem = {
  name: string;
  id: DefaultGoal | string;
};

const customGoalOptions = [
  {
    label: "Binary goal",
    subLabel: "Create a goal that can either be achieved or failed",
    value: GoalKind.BinaryGoal,
  },
  {
    label: "Rated goal",
    subLabel: "Create a goal that is rated on a scale",
    value: GoalKind.ScoreGoal,
  },
  {
    label: "Compound goal",
    subLabel: "Create a goal made up of multiple sub-goals",
    value: GoalKind.CompoundGoal,
  },
];

const NUM_LOADING_SKELETON_GOALS = 4;

export const GoalSelector = ({
  goals,
  handleUpdateGoals,
  talkingPoints,
  handleUpdateTalkingPoints,
  handleUpdateCustomGoalType,
  goalTalkTime,
  handleUpdateGoalTalkTime,
  editingGoalTalkTime,
  setEditingGoalTalkTime,
  scenarioTypeId,
  customGoals,
  customGoalsLoading,
  setCurrStepIndex,
  handleCustomGoalDeletion,
  customGoalDeletionLoading,
  handleEditCustomGoal,
  wizardType,
}: GoalSelectorProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  useScrollToTop();
  const disableMouseEnterTimer = React.useRef<NodeJS.Timeout | null>(null);
  const mouseEnterDisabled = React.useRef(false);
  const [hoveringIconGoalId, setHoveringIconGoalId] = React.useState<DefaultGoal | string | null>(
    null
  );
  const [tooltipCopy, setTooltipCopy] = React.useState<string>("");
  const [talkTimeMinutes, setTalkTimeMinutes] = React.useState(goalTalkTime.minutes);
  const [talkTimeSeconds, setTalkTimeSeconds] = React.useState(goalTalkTime.seconds);
  const [goalOptions, setGoalOptions] = React.useState<(CustomGoalItemWithUsage | GoalItem)[]>(
    GOAL_OPTIONS[scenarioTypeId].map((goal: DefaultGoal) => {
      return { name: GoalLabels[goal], id: goal };
    })
  );
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [selectedCustomGoal, setSelectedCustomGoal] =
    React.useState<CustomGoalItemWithUsage>(undefined);
  const [deleteGoalModalOpen, setDeleteGoalModalOpen] = React.useState<boolean>(false);
  const [talkToSalesModalOpen, setTalkToSalesModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!customGoalsLoading && customGoals) {
      setGoalOptions([
        ...customGoals.map((goal: CustomGoalItemWithUsage) => {
          return {
            name: goal.name,
            id: goal.id,
            goalKind: goal.goalKind,
            userDescription: goal.userDescription,
            usage: goal.usage,
          } as CustomGoalItemWithUsage;
        }),
        ...GOAL_OPTIONS[scenarioTypeId].map((goal: DefaultGoal) => {
          return { name: GoalLabels[goal], id: goal } as GoalItem;
        }),
      ]);
    }
  }, [scenarioTypeId, customGoalsLoading, customGoals]);

  const goalsExtraDetails = {
    [DefaultGoal.HitTalkingPoints]: (
      <>
        {!talkingPoints.length && (
          <Typography sx={GOAL_SUBTITLE_STYLES}>
            Add at least one talking point below to add this goal
          </Typography>
        )}
        {/* prevent clicks inside the draggable items from closing the goal */}
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "relative",
            zIndex: 3,
            cursor: "default",
            marginTop: "16px",
          }}
        >
          <WizardAddDraggableItems
            items={talkingPoints}
            handleUpdateItems={handleUpdateTalkingPoints}
            inputPlaceholder="e.g.  Used by Fortune 500 CEOs"
            maxItems={MAX_TALKING_POINTS}
            maxInputLength={MAX_CHAR_LENGTH_TALKING_POINT}
            itemLabel="Talking points"
            wrapperSx={{ pb: 0, gap: 0 }}
            controlRowSx={{ pt: 2 }}
          />
        </div>
      </>
    ),
    [DefaultGoal.HitTimeTarget]: (
      <Stack gap={2}>
        <Typography sx={GOAL_SUBTITLE_STYLES}>
          Set how long the pitch should be (up to 60 minutes max)
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            columnGap: 3,
            rowGap: 1,
          }}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <YoodliTextfield
              type="number"
              value={isNaN(talkTimeMinutes) ? "" : talkTimeMinutes}
              disabled={!editingGoalTalkTime}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value < 0) {
                  return;
                }
                setTalkTimeMinutes(value);
              }}
              sx={{
                width: 80,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: getDynamicColor(editingGoalTalkTime ? "purple3" : "dark4"),
              }}
            >
              minutes
            </Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <YoodliTextfield
              type="number"
              value={isNaN(talkTimeSeconds) ? "" : talkTimeSeconds}
              disabled={!editingGoalTalkTime}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value < 0) {
                  return;
                }
                setTalkTimeSeconds(value);
              }}
              sx={{
                width: 80,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: getDynamicColor(editingGoalTalkTime ? "purple3" : "dark4"),
              }}
            >
              seconds
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            disabled={
              !(talkTimeMinutes > 0 || talkTimeSeconds > 0) ||
              isNaN(talkTimeMinutes) ||
              isNaN(talkTimeSeconds)
            }
            onClick={(e) => {
              e.stopPropagation();
              setEditingGoalTalkTime(!editingGoalTalkTime);
              if (editingGoalTalkTime) {
                handleUpdateGoalTalkTime({
                  minutes: talkTimeMinutes,
                  seconds: talkTimeSeconds,
                });
              }
            }}
            sx={{
              fontSize: 14,
              width: 66,
            }}
          >
            {editingGoalTalkTime ? "Save" : "Edit"}
          </Button>
        </Stack>
      </Stack>
    ),
  };

  const getTooltipCopy = (goalId: string, isSelected: boolean): string => {
    if (blockIconHover[goalId]) {
      return "Added!";
    }
    if (isSelected) {
      return "Remove";
    }
    return "Add";
  };
  const renderAddRemoveIcons = (goalId: DefaultGoal | string, isSelected: boolean) => {
    if (hoveringIconGoalId === goalId && isSelected && !blockIconHover[goalId]) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };
  const getIconBackgroundColor = (goalId: DefaultGoal | string, isSelected: boolean) => {
    if (hoveringIconGoalId === goalId && isSelected && !blockIconHover[goalId]) {
      return getDynamicColor("dark4");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    return getDynamicColor("primary");
  };

  return (
    <Stack gap={2}>
      {customGoalsLoading &&
        Array(NUM_LOADING_SKELETON_GOALS)
          .fill(0)
          .map((_, ind) => (
            <Skeleton
              component="div"
              variant="rectangular"
              key={ind}
              sx={{
                borderRadius: "4px",
                backgroundColor: getDynamicColor("dark1"),
                p: 2,
                transition: "background-color 0.2s",
                position: "relative",
              }}
              height="62px"
              animation="wave"
            />
          ))}
      {!customGoalsLoading &&
        goalOptions.map((goal: GoalItem | CustomGoalItemWithUsage) => {
          const isSelected = goals.includes(goal.id);
          return (
            <Stack
              alignItems="flex-start"
              justifyContent="center"
              key={goal.id}
              direction="column"
              sx={{
                border: `1px solid ${getDynamicColor("dark2")}`,
                borderRadius: "4px",
                p: 2,
                transition: "background-color 0.2s",
                fontFamily: "poppins",
                position: "relative",
              }}
              onClick={() => {
                if (disableMouseEnterTimer.current) {
                  clearTimeout(disableMouseEnterTimer.current);
                }
                mouseEnterDisabled.current = true;
                disableMouseEnterTimer.current = setTimeout(() => {
                  mouseEnterDisabled.current = false;
                }, 300);
                setHoveringIconGoalId(null);
              }}
            >
              <Stack direction="row" gap={1.5} alignItems="center" justifyContent="flex-start">
                <YoodliTooltip
                  title={tooltipCopy}
                  enterDelay={500}
                  enterNextDelay={300}
                  sx={{ alignSelf: "flex-start" }}
                >
                  <Box
                    sx={{
                      height: 28,
                      width: 28,
                      cursor: "pointer",
                      alignSelf: "flex-start",
                    }}
                    onMouseEnter={() => {
                      setHoveringIconGoalId(goal.id);
                      setTooltipCopy(getTooltipCopy(goal.id, isSelected));
                    }}
                    onMouseLeave={() => {
                      setHoveringIconGoalId(null);
                      setTooltipCopy(getTooltipCopy(goal.id, isSelected));
                      setBlockIconHover({ ...blockIconHover, [goal.id]: false });
                    }}
                    onClick={() => {
                      if (isSelected) {
                        handleUpdateGoals(goals.filter((g) => g !== goal.id));
                        setBlockIconHover({ ...blockIconHover, [goal.id]: false });
                      } else {
                        handleUpdateGoals([...goals, goal.id]);
                        setBlockIconHover({ ...blockIconHover, [goal.id]: true });
                      }
                    }}
                  >
                    <Box
                      sx={{
                        height: 20,
                        width: 20,
                        left: 4,
                        top: 4,
                        borderRadius: "50%",
                        position: "relative",
                        backgroundColor: getIconBackgroundColor(goal.id, isSelected),
                        color: getDynamicColor("light1"),
                        transition: "background-color 0.2s ease-out",
                        svg: {
                          position: "absolute",
                          display: "block",
                          top: 1,
                          left: 1,
                          height: 18,
                          width: 18,
                          strokeWidth: 0.5,
                          stroke: getDynamicColor("light1"),
                        },
                      }}
                    >
                      {renderAddRemoveIcons(goal.id, isSelected)}
                    </Box>
                  </Box>
                </YoodliTooltip>
                <Stack sx={{ fontSize: 14 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {goal.name}
                  </Typography>
                  <Typography sx={{ color: getDynamicColor("dark5") }}>
                    {customGoals?.find((customGoal) => goal.id === customGoal.id)?.userDescription}
                  </Typography>
                  {(goal as CustomGoalItemWithUsage).usage > 0 && (
                    <Typography
                      sx={{
                        pt: 1.5,
                        color: getDynamicColor("dark4"),
                        fontSize: "12px",
                        textTransform: "uppercase",
                      }}
                    >
                      Used by {(goal as CustomGoalItemWithUsage).usage} scenario
                      {(goal as CustomGoalItemWithUsage).usage > 1 && "s"}
                    </Typography>
                  )}
                  {!Object.values(DefaultGoal).includes(goal.id as DefaultGoal) && (
                    <Box sx={{ position: "absolute", top: 0, right: 0, p: 0.5, m: 1, zIndex: 1 }}>
                      <YoodliMenu
                        type={YoodliMenuButtonType.Icon}
                        buttonSx={{
                          border: "none",
                          mx: 0,
                          p: 0,
                        }}
                        menuItems={[
                          {
                            title: "Edit",
                            type: YoodliMenuItemType.Primary,
                            onClick: () => {
                              handleEditCustomGoal(goal as CustomGoalItemWithUsage)
                                .then(() => {
                                  handleUpdateCustomGoalType(
                                    (goal as CustomGoalItemWithUsage).goalKind as GoalKind
                                  );
                                  setCurrStepIndex((prev) => prev + 1);
                                })
                                .catch((e) => console.error("Error editing custom goal: ", e));
                            },
                          },
                          {
                            title: "Delete",
                            type: YoodliMenuItemType.Warning,
                            disabled: (goal as CustomGoalItemWithUsage).usage > 0,
                            disabledTooltip: "Goal is in use",
                            onClick: () => {
                              setSelectedCustomGoal(goal as CustomGoalItemWithUsage);
                              setDeleteGoalModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
              {!!goalsExtraDetails[goal.id] && (
                <Collapse
                  in={isSelected}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pt: 1.5,
                      width: "100%",
                    }}
                  >
                    {goalsExtraDetails[goal.id]}
                  </Box>
                </Collapse>
              )}
            </Stack>
          );
        })}
      {!customGoalsLoading && defaultOrg?.subscription_type === OrgSubscriptionType.PREPAID && (
        <YoodliSelect
          disabled={wizardType === WizardType.User}
          sx={{
            "& .yoodliSelectIcon": {
              right: "unset",
              left: "18px",
            },
            border: `1px solid ${getDynamicColor("dark3")}`,
          }}
          value={""}
          renderValue={() => {
            return (
              <Stack
                onClick={() => {
                  if (wizardType === WizardType.User) {
                    setTalkToSalesModalOpen(true);
                  }
                }}
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    py: "3px",
                    pl: "38px",
                    fontWeight: 600,
                    fontSize: "14px",
                    color: getDynamicColor("dark4"),
                  }}
                >
                  Create a custom goal
                </Typography>
                {wizardType === WizardType.User && <LockIcon />}
              </Stack>
            );
          }}
          displayEmpty
          options={customGoalOptions.map((option, i) => {
            return {
              ...option,
              subLabelSx: {
                textTransform: "none",
              },
              optionSx: {
                borderBottom:
                  i < customGoalOptions.length - 1
                    ? `1px solid ${getDynamicColor("dark2")}`
                    : "none",
              },
            };
          })}
          onChange={(e) => {
            handleUpdateCustomGoalType(e.target.value as GoalKind);
            setCurrStepIndex((prev) => prev + 1);
          }}
        />
      )}
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to delete{" "}
              <strong style={{ color: getDynamicColor("primary") }}>
                {selectedCustomGoal?.name}
              </strong>
              ?
            </>
          ),
          subtitle:
            "You will no longer be able to access this goal from any scenario. This action cannot be undone.",
        }}
        open={deleteGoalModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        loading={customGoalDeletionLoading}
        hideCloseButton={true}
        close={() => setDeleteGoalModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Delete goal",
              handler: async () => {
                await handleCustomGoalDeletion(selectedCustomGoal?.id).catch((e) =>
                  console.error(e)
                );
              },
            },
            secondary: { text: "Cancel", handler: () => setDeleteGoalModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
      <YoodliCtaModal
        open={talkToSalesModalOpen}
        close={() => setTalkToSalesModalOpen(false)}
        ctaBody={{
          title: "Want more customization?",
          subtitle:
            "You can add more customization options with an organization. Contact our sales team to get started",
        }}
        buttons={{
          primary: {
            text: "Talk to sales",
            handler: () => {
              window.open(LandingPageExternalPath.CONTACT_SALES, "_blank");
            },
            buttonSx: {
              fontSize: "14px",
              border: "none",
              background: getDynamicColor("primary"),
              ml: "auto",
              color: getDynamicColor("light1"),
              "&:hover": {
                background: getDynamicColor("primaryHover"),
                border: "none",
              },
            },
          },
        }}
      />
    </Stack>
  );
};
