// Components
import { Box, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OBQ1Option } from "lib-fullstack/utils/enums";

type HubInviteAuthImageProps = {
  defaultOnboarding: OBQ1Option;
};

export default function HubInviteAuthImage({
  defaultOnboarding,
}: HubInviteAuthImageProps): JSX.Element {
  let onboardingText;
  switch (defaultOnboarding) {
    case OBQ1Option.MEETINGS:
      onboardingText = "meeting";
      break;
    case OBQ1Option.SPEECH:
    case OBQ1Option.SKIPPED:
      onboardingText = "speech";
      break;
    case OBQ1Option.INTERVIEW:
      onboardingText = "interview";
      break;
    case OBQ1Option.SALES:
      onboardingText = "sales call";

      break;
    default:
      console.error("Invalid default onboarding option");
      onboardingText = "speech";
      break;
  }

  return (
    <Box
      width="100%"
      height="100%"
      minHeight="100vh"
      sx={{ backgroundColor: getDynamicColor("purple3") }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        gap={2}
        sx={{
          textAlign: "center",
          alignItems: "center",
          p: 2.5,
          maxWidth: "500px",
        }}
      >
        <Stack
          sx={{
            color: getDynamicColor("light1"),
            fontWeight: 700,
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "24px",
            }}
          >
            Ace your next {onboardingText}
          </Typography>
        </Stack>
        <img
          src={`https://storage.googleapis.com/yoodli-public/auth-assets/graphic-${onboardingText.replace(
            / /g,
            ""
          )}.webp`}
          width="468px"
        />
      </Stack>
    </Box>
  );
}
