import React from "react";

// Components
import { Button, Link, Stack, Typography, Box, CircularProgress } from "@mui/material";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { UpdateOrgSeatsControls } from "./UpdateOrgSeatsControls";
import { useMutation } from "@tanstack/react-query";
import { upsetPaygOrgSubscription } from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgOwnerAdmin } from "lib-frontend/utils/orgUtils";
import { UpsetPaygOrgSubscriptionResult } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

type TrialEndedProps = {
  org: OrgV2Response;
};

export const TrialEnded = ({ org }: TrialEndedProps): JSX.Element => {
  const [updatedOrgSeats, setUpdatedOrgSeats] = React.useState<number>(undefined);

  const updateOrgSeatsMutation = useMutation({
    mutationFn: () => {
      return upsetPaygOrgSubscription(
        org.id,
        updatedOrgSeats,
        window.location.href,
        window.location.href
      );
    },
    onSuccess: (result) => {
      if (result.result === UpsetPaygOrgSubscriptionResult.REDIRECT) {
        window.location.href = result.redirect_url;
      } else {
        throw Error(result.error_info);
      }
    },
  });

  if (updateOrgSeatsMutation.isPending) {
    return <CircularProgress sx={{ m: "auto" }} />;
  }

  const renderContent = () => {
    if (isOrgOwnerAdmin(org)) {
      return (
        <>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Your {org.license_used} org member{org.license_used > 1 ? "s have" : " has"} lost access
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            Your free trial has ended. Don’t worry, we saved your progress! Upgrade now to restore
            your organization settings and give your members access to your content.
          </Typography>
          {updatedOrgSeats ? (
            <Stack direction={{ xs: "column", md: "row" }} gap={3} sx={{ alignItems: "center" }}>
              <UpdateOrgSeatsControls
                updatedOrgSeats={updatedOrgSeats}
                setUpdatedOrgSeats={setUpdatedOrgSeats}
                org={org}
                size={60}
              />

              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Use the buttons to add or remove seats, or enter the total number of desired seats
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={3}
              sx={{ pt: 1, pb: 3, alignItems: "center" }}
            >
              <Button
                onClick={() => setUpdatedOrgSeats(org.license_used)}
                startIcon={
                  <Box sx={{ width: 17, height: 14 }}>
                    <UpgradeIcon />
                  </Box>
                }
                variant="gradient"
                sx={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  letterSpacing: 0.6,
                }}
              >
                UPGRADE
              </Button>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                $20 per seat per month
              </Typography>
            </Stack>
          )}
          <Stack
            gap={0.5}
            direction={{ xs: "column", md: "row" }}
            sx={{ fontSize: "12px", fontWeight: 500, alignItems: "center" }}
          >
            {updatedOrgSeats ? (
              <>
                <Button onClick={() => setUpdatedOrgSeats(undefined)}>Cancel</Button>
                <Button
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={() => updateOrgSeatsMutation.mutate()}
                >
                  Add {updatedOrgSeats - org.license_count} seats
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ fontFamily: "poppins", color: getDynamicColor("purple3") }}>
                  Want to delete this organization?
                </Typography>
                <Link
                  href={WebServerExternalPath.SUPPORT}
                  target="_blank"
                  sx={{ fontFamily: "poppins" }}
                >
                  Contact sales
                </Link>
              </>
            )}
          </Stack>
        </>
      );
    } else {
      return (
        <>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            You have lost access to this organization
          </Typography>
          {isOrgOwnerAdmin && (
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              Your free trial has ended. Please contact your organization administrator to restore
              access to your content.
            </Typography>
          )}
        </>
      );
    }
  };
  return (
    <Stack
      gap={2.5}
      sx={{
        border: `1px solid ${getDynamicColor("dark2")}`,
        boxShadow: Y_SHADOWS.dark_elevation,
        px: 6,
        pt: 6,
        pb: 5,
        mx: 4.5,
        maxWidth: "xxl",
      }}
    >
      {renderContent()}
    </Stack>
  );
};
