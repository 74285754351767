import { db } from "lib-fullstack";
import React from "react";

// Components
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

// Utils
import { IOSSwitch } from "../Settings";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { updateThisUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";

enum SpeechVisibility {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export const WhenRecordSpeech = (): JSX.Element => {
  const userDoc = useUserDocsState()[db.UserDocTypes.MAIN];

  const [defaultSpeechVisibility, setDefaultSpeechVisibility] = React.useState<SpeechVisibility>(
    userDoc.defaultLinkSharingPublic ? SpeechVisibility.PUBLIC : SpeechVisibility.PRIVATE
  );

  const handleRealTimeAlert = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      realTimeAlertsOn: e.target.checked,
    }).catch(console.error);

  const handleMirror = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      mirrorVideo: e.target.checked,
    }).catch(console.error);

  const handleDefaultSpeechVisibility = (newVisibility: SpeechVisibility) => {
    setDefaultSpeechVisibility(newVisibility);
    const visibilityIsPublic = newVisibility === SpeechVisibility.PUBLIC;
    updateThisUserDocMain({
      defaultLinkSharingPublic: visibilityIsPublic,
    }).catch(console.error);
  };

  const renderSwitches = () => (
    <Grid container>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mt: { xs: 4, md: 2 } }}
      >
        <Grid item flexWrap={"wrap"} xs={10} lg={6}>
          <Typography className="font-size-label">Show real-time alerts</Typography>
        </Grid>
        <Grid item xs={1} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch checked={userDoc.realTimeAlertsOn} onChange={handleRealTimeAlert} />
              }
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mt: { xs: 4, md: 2 } }}
      >
        <Grid item flexWrap={"wrap"} xs={10} lg={6}>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item sx={{ paddingRight: "0.5em" }}>
              <Typography className="font-size-label">Mirror my video</Typography>
            </Grid>
            <Grid item sx={{ height: "24px" }}>
              <Tooltip title="Mirroring video gives you the view of yourself you're used to seeing in, well, a mirror">
                <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch checked={userDoc.mirrorVideo} onChange={handleMirror} />}
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderDefaultSpeechVisibilitySelector = () => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        mt: { xs: 2, md: 3 },
        width: "100%",
      }}
    >
      <Typography
        sx={{
          width: { xs: "unset", lg: "49%" },
        }}
      >
        Default visibility (applies to new Yoodlis)
      </Typography>
      <Box
        sx={{
          width: { xs: "unset", lg: "51%" },
        }}
      >
        <Select
          value={defaultSpeechVisibility}
          onChange={(e) => handleDefaultSpeechVisibility(e.target.value as SpeechVisibility)}
          MenuProps={{
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            sx: {
              ".MuiPaper-root": {
                maxHeight: { xs: "50vh", md: "min(800px, 90vh)" },
              },
            },
          }}
          sx={{
            minWidth: 140,
          }}
        >
          {Object.values(SpeechVisibility).map((visibility) => (
            <MenuItem key={visibility} value={visibility}>
              {visibility}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Stack>
  );

  return (
    <div style={{ zIndex: 1 }}>
      <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
        When I Record a Speech...
      </Typography>
      {renderSwitches()}
      {renderDefaultSpeechVisibilitySelector()}
    </div>
  );
};
