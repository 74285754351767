import firebase from "firebase/app";

// Utils
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

// If 500 error, that is on our end, otherwise give error string to user
// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
const OAuthProviderErrors = {
  "auth/account-exists-with-different-credential": "Email already exists.",
  "auth/cancelled-popup-request":
    "You may have closed the login window before completing the login process.",
  "auth/operation-not-allowed": "You are not authorized to complete that operation.",
  "auth/popup-blocked":
    "Your browser may be blocking popup windows. Please allow popups in your browser settings to sign in with this provider.",

  "auth/popup-closed-by-user":
    "You may have closed the login window before completing the login process.",
  "auth/auth-domain-config-required": "Internal server error",

  "auth/operation-not-supported-in-this-environment": "Internal server error",

  "auth/unauthorized-domain": "Internal server error",
};

export async function signInWithOAuthProvider(
  provider: firebase.auth.AuthProvider
): Promise<{ user: firebase.User; isNewUser: boolean }> {
  try {
    const userInfo = await firebase.auth().signInWithPopup(provider);
    if (userInfo.additionalUserInfo.isNewUser) {
      Instrumentation.logUserSignUpCheckpoint();
    } else {
      Instrumentation.logUserSignInCheckpoint();
    }

    return {
      user: userInfo.user,
      isNewUser: userInfo.additionalUserInfo.isNewUser,
    };
  } catch (error) {
    const code: keyof typeof OAuthProviderErrors = error.code;

    // If any other error, throw it to the user with custom error message
    if (code !== "auth/account-exists-with-different-credential")
      throw { ...error, message: OAuthProviderErrors[code] };

    throw error;
  }
}
