// Components
import { Button, Stack } from "@mui/material";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { toTitleCase } from "lib-frontend/utils/Utilities";

export enum TableTabLabel {
  Members = "members",
  Invites = "invites",
}

export type TableTab = {
  label: TableTabLabel;
  count?: number;
};

enum SearchFilter {
  Members = "member emails",
  Invites = "invites",
}

type TableActionBarProps = {
  tableTabs: TableTab[];
  selectedTabLabel: TableTabLabel;
  setSelectedTabLabel: (tabLabel: TableTabLabel) => void;
  searchText: string;
  setSearchText: (str: string) => void;
};

export const TableActionBar = ({
  tableTabs,
  selectedTabLabel,
  setSelectedTabLabel,
  searchText,
  setSearchText,
}: TableActionBarProps): JSX.Element => {
  const renderTableTabs = () => (
    <Stack direction="row" alignItems="center" gap={{ xs: 1, md: 3, lg: 6 }}>
      {Object.values(tableTabs).map((tab) => (
        <Button
          key={tab.label}
          onClick={() => {
            setSelectedTabLabel(tab.label);
            setSearchText("");
          }}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color:
              selectedTabLabel === tab.label
                ? getDynamicColor("primary")
                : getDynamicColor("purple3"),
            px: 1,
            borderRadius: 0,
            borderBottom:
              selectedTabLabel === tab.label ? `2px solid ${getDynamicColor("primary")}` : "none",
            top: selectedTabLabel === tab.label ? 0 : -1,
            transition: "none",
            textTransform: "capitalize",
          }}
        >
          {tab.label} {typeof tab.count === "number" ? `(${tab.count})` : ""}
        </Button>
      ))}
    </Stack>
  );

  const renderSearchFilter = (searchLabel: SearchFilter) => (
    <YoodliSearchBar
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      clearSearch={() => setSearchText("")}
      placeholder={`Search ${toTitleCase(searchLabel)}`}
    />
  );

  return (
    <Stack gap={1} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
      {renderTableTabs()}
      {selectedTabLabel === TableTabLabel.Members
        ? renderSearchFilter(SearchFilter.Members)
        : renderSearchFilter(SearchFilter.Invites)}
    </Stack>
  );
};
