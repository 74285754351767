// Utils
import { UploadProgressCallback, uploadBlobToResumableUploadUrl } from "./resumableUpload";
import { InitiateResumableUpload } from "lib-frontend/modules/AxiosInstance";
import { injectSeekMetadata } from "lib-frontend/utils/VideoUtils";
import { UploadFileType } from "lib-fullstack/api/apiTypes";

// Differentiator of instances for logging purpose.
let uploaderCounter = 0;

/**
 * Uploader to upload a blob (on memory or file) for a speech.
 * Blobs may be media (video), log, and transcript history.
 */
export class SpeechUploader {
  private logPrepend: string;
  private blob: Blob | Uint8Array;
  private speechId: string;
  private fileType: UploadFileType;
  private videoFileExtension: string | undefined;
  private uploadSessionUrl: string;
  private uploadGsUrl: string;

  /**
   * Creates an uploader instance
   * @param blob Data to upload
   * @param speechId speech ID which this data is related to
   * @param fileType File type to upload
   * @param videoFileExtension Required for video uploads, ignored for other types
   */
  constructor(
    blob: Blob | Uint8Array,
    speechId: string,
    fileType: UploadFileType,
    videoFileExtension?: string
  ) {
    this.blob = blob;
    this.speechId = speechId;
    this.fileType = fileType;
    this.videoFileExtension = videoFileExtension ?? undefined;
    this.logPrepend = `Uploader[${uploaderCounter++}][${this.speechId}][${this.fileType}]`;
  }

  /**
   * Request the server to initiate a resumable upload session
   */
  public async initializeServer(): Promise<void> {
    const uploadInfo = await InitiateResumableUpload(
      this.speechId,
      this.fileType,
      this.videoFileExtension
    );
    this.uploadSessionUrl = uploadInfo.url;
    this.uploadGsUrl = uploadInfo.gsUrl;
  }

  /**
   * Upload the blob. Promise is resolved when the upload is fully finished.
   * @param durationOverrideMs Duration to fix up webm blob data.
   * @param callback Callback to update the UI with upload progress.
   * If the callback returns false, the upload will be cancelled.
   * Note: it is not used by UI yet.
   * @returns GS path, null for failure or cancel.
   */
  public async upload(
    durationOverrideMs: number | null,
    callback: UploadProgressCallback | null
  ): Promise<string | null> {
    let blobToUpload: Blob | Uint8Array;
    if (this.fileType === UploadFileType.VIDEO && durationOverrideMs) {
      if (this.blob instanceof Blob) {
        blobToUpload = await injectSeekMetadata(this.blob as Blob, durationOverrideMs);
      } else {
        throw new Error(
          `Unable to inject seek metadata for blob type Invalid blob type ${typeof this.blob}.`
        );
      }
    } else {
      blobToUpload = this.blob;
    }

    try {
      await uploadBlobToResumableUploadUrl(
        blobToUpload,
        this.uploadSessionUrl,
        callback,
        this.logPrepend
      );
    } catch (error) {
      console.error(`${this.logPrepend} Failed: ${error}`);
      return null;
    }

    return this.uploadGsUrl;
  }
}
