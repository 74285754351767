import React from "react";
import { useHistory } from "react-router";

// Components
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import OpenYoodliNotifier from "components/Onboarding/OnboardingCarousel/OpenYoodliNotifier";

// Utils
import PoodliDownloadInstructions from "./PoodliDownloadInstructions";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import { PoodliDownloadedWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerInternalPath } from "utils/paths";

type PoodliDownloadModalProps = {
  show: boolean;
  handleClose: () => void;
  downloadAgain: (where: PoodliDownloadedWheres) => void;
  where: PoodliDownloadedWheres;
};

const PoodliDownloadModal = ({
  show,
  handleClose,
  downloadAgain,
  where,
}: PoodliDownloadModalProps): JSX.Element => {
  const history = useHistory();
  const userDocs = useUserDocsState();

  React.useEffect(() => {
    if (
      show &&
      !getLiveUserDocMain()?.poodliStatuses?.poodliOnboardingComplete &&
      getLiveUserDocMain()?.poodliStatuses?.poodliOnboardingStarted
    ) {
      // Direct the user the "Finish setup on desktop app" page only if the modal is open and poodli onboarding is started but not complete:
      history.push(WebServerInternalPath.SETUP_DESKTOP_APP);
    }
  }, [userDocs]);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 12000,
      }}
    >
      <Stack
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          borderRadius: "16px",
          backgroundColor: getDynamicColor("light2"),
          padding: "16px",
        }}
        justifyContent={"center"}
        alignItems={"flex-end"}
      >
        <OpenYoodliNotifier right={60} top={28} />

        <Stack direction="row">
          <IconButton onClick={handleClose} sx={{ position: "absolute", top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <PoodliDownloadInstructions downloadAgain={downloadAgain} where={where} />
      </Stack>
    </Modal>
  );
};

export default PoodliDownloadModal;
