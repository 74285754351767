import React from "react";
import { Prompt } from "react-router-dom";

// Components
import { WarningAmberRounded as WarningIcon } from "@mui/icons-material";
import { Button, Dialog, Stack, Typography } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export type RouteLeavingGuardProps = {
  when: boolean;
  navigate: (path: string) => void;
  shouldBlockNavigation: (nextLocation) => boolean;
  title: string;
  body: string;
  cancelText?: string;
  okText?: string;
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  onNavigate?: () => void;
};
export function RouteLeavingGuard({
  when,
  navigate,
  shouldBlockNavigation,
  title,
  body,
  cancelText,
  okText,
  modalVisible,
  setModalVisible,
}: RouteLeavingGuardProps): JSX.Element {
  const { modalStyles } = useNavDrawerOffset();

  const [lastLocation, setLastLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  React.useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        closeModal();
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const showModal = (location) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = (callback = () => null) => {
    setModalVisible(false);
    callback?.();
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        setConfirmedNavigation(true);
        // Navigate to the previous blocked location with your navigate function
        navigate(`${lastLocation.pathname}${lastLocation.search}`);
      }
    });
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog
        open={modalVisible}
        onClose={() => closeModal()}
        PaperProps={{
          sx: {
            ...modalStyles,
            borderRadius: "12px",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            backgroundColor: getDynamicColor("light1"),
            width: "min(100%, 600px)",
            p: 4,
            px: { xs: 3, lg: 4 },
            gap: 2,
          },
        }}
      >
        <Stack direction="row" gap={1}>
          <WarningIcon />
          <Typography
            component="h2"
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: { xs: 14, lg: 16 },
            fontFamily: "poppins",
            fontWeight: 400,
            lineHeight: 1.3,
            maxWidth: 500,
          }}
        >
          {body}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 2,
            width: "100%",
          }}
        >
          <Button onClick={handleConfirmNavigationClick} variant="outlined">
            {okText ?? "Ok"}
          </Button>
          <Button onClick={() => closeModal()} variant="contained">
            {cancelText ?? "Cancel"}
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}
