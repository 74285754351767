import { db } from "lib-fullstack";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Components
import { GOOGLE_SCOPE, initAuthFlow } from "components/Calendar/calendarUtils";

// Utils
import { useGoogleLogin } from "@react-oauth/google";
import {
  setCredentials,
  addGoogleCalendar,
  syncCalendarEvents,
} from "lib-frontend/modules/AxiosInstance";
import {
  POODLI_CAL_CONNECT_QUERY_PARAM,
  launchPoodliWithCalConnectError,
} from "lib-frontend/utils/Utilities";
import { getClientEnvConfig } from "lib-fullstack/client_env";

type PoodliCalConnectProviderProps = {
  googleAuthProviderReady: boolean;
  setPoodliCalConnectError: (val: boolean) => void;
  handleCalConnectComplete: () => void;
};

export default function PoodliCalConnectProvider({
  googleAuthProviderReady,
  setPoodliCalConnectError,
  handleCalConnectComplete,
}: PoodliCalConnectProviderProps): JSX.Element {
  const history = useHistory();
  const googleLogin = useGoogleLogin({
    onError: (errorResponse) => {
      handleCalConnectError(errorResponse.error);
    },
    flow: "auth-code",
    scope: GOOGLE_SCOPE,
    ux_mode: "redirect",
    state: "google",
  });

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get("code");
    const state = new URLSearchParams(history.location.search).get("state");
    const error = new URLSearchParams(history.location.search).get("error");
    if (code && state === "google" && googleAuthProviderReady) {
      const redirectUri = `${getClientEnvConfig().url.WEB_SERVER}${history.location.pathname}`;
      const scope = new URLSearchParams(history.location.search).get("scope");
      if (!scope.includes(GOOGLE_SCOPE)) {
        setCredentials(code, redirectUri)
          .then(() => {
            googleLogin();
          })
          .catch((err) => {
            handleCalConnectError(err);
          });
      } else {
        addGoogleCalendar(code, db.CalendarMode.POODLI, redirectUri)
          .then(async () => {
            handleCalConnectComplete();
            await syncCalendarEvents();
          })
          .catch((err) => {
            handleCalConnectError(err);
          });
      }
    } else if (error && state === "google") {
      handleCalConnectError(error);
    }
  }, [googleAuthProviderReady]);

  useEffect(() => {
    if (
      history.location.search.includes(POODLI_CAL_CONNECT_QUERY_PARAM) &&
      googleAuthProviderReady
    ) {
      const code = new URLSearchParams(history.location.search).get(POODLI_CAL_CONNECT_QUERY_PARAM);
      if (code === "google") {
        googleLogin();
      } else if (code === "outlook") {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        initAuthFlow(history.location.pathname, db.CalendarMode.POODLI);
      }
    }
  }, [googleAuthProviderReady]);

  const handleCalConnectError = (error: string) => {
    console.error(error);
    setPoodliCalConnectError(true);
    launchPoodliWithCalConnectError(error);
  };

  return <></>;
}
