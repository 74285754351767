import React from "react";

// Components
import { CheckRounded as CheckRoundedIcon } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";

// Assets
import { ReactComponent as PDFIcon } from "images/icons/pdf-icon.svg";
import { ReactComponent as TrashIcon } from "images/icons/trash-outline.svg";

// Utils
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createPersonaProfilePicture,
  deletePersonaProfilePicture,
  listPersonaProfilePictures,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import ImageResizer from "lib-frontend/utils/ImageResizer";
import { uploadBlobToResumableUploadUrl } from "lib-frontend/utils/resumableUpload";
import { UpdatePersonaRequest } from "lib-fullstack/api/scenarioApiTypes";
import { asyncMap } from "lib-fullstack/utils/asyncMap";

type PersonaProfilePictureSelectorProps = {
  persona: UpdatePersonaRequest;
  handleUpdateNewPersona: (key: keyof UpdatePersonaRequest, value: string | string[]) => void;
};

const MAX_PROFILE_PICTURES = 50;

export const PersonaProfilePictureSelector = ({
  persona,
  handleUpdateNewPersona,
}: PersonaProfilePictureSelectorProps): JSX.Element => {
  const fileInputRef = React.useRef(null);
  const imageResizer = React.useRef<ImageResizer>();
  if (!imageResizer.current) {
    imageResizer.current = new ImageResizer(
      () => {
        throw Error("Image resizer not initialized");
      },
      {
        maxDim: 264,
      }
    );
  }

  const [hoveringProfilePictureId, setHoveringProfilePictureId] = React.useState<string | null>(
    null
  );
  const [deletingProfilePictureId, setDeletingProfilePictureId] = React.useState<string | null>(
    null
  );
  const { defaultOrg } = React.useContext(UserOrgContext);

  const queryClient = useQueryClient();

  const profilePicturesQuery = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.ProfilePictures, defaultOrg?.id],
    queryFn: () => listPersonaProfilePictures(defaultOrg?.id),
    enabled: !!defaultOrg?.id,
  });

  const createProfilePictureMutation = useMutation({
    mutationFn: (file: File) => {
      // when i create a profile picture from here, i dont want to associate it with a persona until the user clicks "save"
      return createPersonaProfilePicture(defaultOrg.id, file.name.split(".").pop(), null)
        .then((result) => {
          return {
            upload_url: result.upload_url,
            profile_picture_id: result.profile_picture_id,
            file,
          };
        })
        .catch((er) => {
          throw new Error(`Error creating new persona profile picture: ${er}`);
        });
    },
    onSuccess: async ({ upload_url, file, profile_picture_id }) => {
      await uploadBlobToResumableUploadUrl(file, upload_url, null, `upload (${file.name})`);
      handleUpdateNewPersona("profile_picture_id", profile_picture_id);
      return queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.ProfilePictures, defaultOrg?.id],
      });
    },
  });

  const deleteProfilePictureMutation = useMutation({
    mutationFn: async (profilePictureId: string) => {
      setDeletingProfilePictureId(profilePictureId);
      await deletePersonaProfilePicture(defaultOrg.id, profilePictureId).catch((er) => {
        throw new Error(`Error deleting persona profile picture: ${er}`);
      });
      return profilePictureId;
    },
    onSuccess: (profilePictureId: string) => {
      setDeletingProfilePictureId(null);
      setProfilePictures((prev) =>
        prev.filter((profilePicture) => profilePicture.id !== profilePictureId)
      );
      return queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.ProfilePictures, defaultOrg?.id],
      });
    },
  });

  // use local state to manage a custom sort order even after adding new photos/deleting old ones
  const [profilePictures, setProfilePictures] = React.useState(
    profilePicturesQuery.data?.profilePictures ?? []
  );
  const initialProfilePictures = React.useRef(null);
  React.useEffect(() => {
    if (profilePicturesQuery?.data?.profilePictures?.length && !initialProfilePictures.current) {
      // on mount sort selected to the start, if it exists
      let pics = profilePicturesQuery?.data?.profilePictures.sort((a, b) => {
        return b.usedByNPersonas - a.usedByNPersonas;
      });
      if (persona.profile_picture_id) {
        const selectedProfilePicture = pics.find(
          (profilePicture) => profilePicture.id === persona.profile_picture_id
        );
        if (selectedProfilePicture) {
          pics = pics.filter((profilePicture) => profilePicture.id !== persona.profile_picture_id);
          pics.unshift(selectedProfilePicture);
        }
      }
      setProfilePictures(pics);
      initialProfilePictures.current = pics;
    }
  }, [profilePicturesQuery.data?.profilePictures]);

  React.useEffect(() => {
    if (initialProfilePictures.current) {
      // check if there are any profile pictures that exist in query data but not in state, and if so, unshift them ot state
      const newProfilePictures = profilePicturesQuery.data?.profilePictures.filter(
        (profilePicture) =>
          !initialProfilePictures.current.find(
            (initialProfilePicture) => initialProfilePicture.id === profilePicture.id
          )
      );
      if (newProfilePictures.length) {
        setProfilePictures((prev) => [...newProfilePictures, ...prev]);
      }
    }
  }, [profilePicturesQuery.data?.profilePictures]);

  const resizeAndUploadProfilePicture = async (file: File) => {
    const resizedFile = await imageResizer.current.resizeFile(file);
    createProfilePictureMutation.mutate(resizedFile);
  };
  const handleUploadClick = () => {
    // Trigger the hidden file input element when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDoFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await asyncMap(
      Array.from(e.target.files),
      async (file) => {
        await resizeAndUploadProfilePicture(file);
      },
      10,
      5
    );
  };

  if (profilePicturesQuery.isPending) {
    // decrease opacity so this loader doesn't draw too much attention when loading the create/edit persona page
    return (
      <CircularProgress
        sx={{
          mt: 2,
          opacity: 0.25,
        }}
      />
    );
  }

  return (
    <Stack
      gap={2}
      sx={{
        p: {
          fontSize: 14,
          color: getDynamicColor("dark5"),
        },
        mt: 1,
      }}
    >
      <Stack gap={0.5}>
        <Typography
          sx={{
            fontWeight: 700,
          }}
        >
          Avatar
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
          }}
        >
          Choose an avatar to represent your persona or upload your own.
        </Typography>
      </Stack>

      <Grid
        container
        spacing={2}
        sx={{
          zIndex: 0,
          position: "relative",
        }}
      >
        <Grid
          item
          xs={4}
          sm
          sx={{
            minWidth: { xs: "auto", sm: 120 },
            minHeight: { xs: "auto", sm: 120 },
            maxWidth: { xs: "100%", sm: 120 },
            maxHeight: { xs: "100%", sm: 120 },
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              backgroundImage:
                profilePictures?.length >= MAX_PROFILE_PICTURES
                  ? "none"
                  : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23524FFCFF' stroke-width='3' stroke-dasharray='6' stroke-dashoffset='95' stroke-linecap='butt'/%3e%3c/svg%3e")`,
              border:
                profilePictures?.length >= MAX_PROFILE_PICTURES
                  ? `1px solid ${getDynamicColor("dark4")}`
                  : "none",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: getDynamicColor(
                profilePictures?.length >= MAX_PROFILE_PICTURES ? "dark4" : "primary"
              ),
            }}
            onClick={handleUploadClick}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg, image/png, image/webp"
              style={{
                display: "none",
              }}
              onChange={handleDoFileUpload}
            />
            {createProfilePictureMutation?.isPending ? (
              <CircularProgress />
            ) : (
              <>
                <PDFIcon />
                <Typography
                  sx={{
                    fontSize: {
                      xs: profilePictures?.length >= MAX_PROFILE_PICTURES ? 10 : 12,
                      md: profilePictures?.length >= MAX_PROFILE_PICTURES ? 12 : 14,
                    },
                    fontWeight: 400,
                    lineHeight: 1.3,
                    px: { xs: 0, sm: 1 },
                    textAlign: "center",
                    color: getDynamicColor(
                      profilePictures?.length >= MAX_PROFILE_PICTURES ? "dark4" : "primary"
                    ),
                  }}
                >
                  {profilePictures?.length >= MAX_PROFILE_PICTURES
                    ? `You’ve reached the max of ${MAX_PROFILE_PICTURES} images`
                    : "Upload from device"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        {profilePictures?.map((profilePicture) => {
          const isSelected = persona.profile_picture_id === profilePicture.id;
          const isDeleting = deletingProfilePictureId === profilePicture.id;
          return (
            <Grid
              item
              key={profilePicture.id}
              xs={4}
              sm
              sx={{
                minWidth: { xs: "auto", sm: 120 },
                minHeight: { xs: "auto", sm: 120 },
                maxWidth: { xs: "100%", sm: 120 },
                maxHeight: { xs: "100%", sm: 120 },
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  borderRadius: "8px",
                  cursor: "pointer",
                  outline: isSelected ? `2px solid ${getDynamicColor("greenSuccess")}` : "none",
                }}
                onClick={() => {
                  if (isSelected) {
                    handleUpdateNewPersona("profile_picture_id", null);
                  } else if (!isDeleting) {
                    handleUpdateNewPersona("profile_picture_id", profilePicture.id);
                  }
                }}
                onMouseEnter={() => setHoveringProfilePictureId(profilePicture.id)}
                onMouseLeave={() => setHoveringProfilePictureId(null)}
              >
                {isDeleting && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 3,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: getDynamicColor("dark2"),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <CircularProgress size={48} />
                  </Box>
                )}
                {isSelected && !isDeleting && (
                  <Box
                    sx={{
                      backgroundColor: getDynamicColor("greenSuccess"),
                      position: "absolute",
                      zIndex: 3,
                      top: -8,
                      right: -8,
                      borderRadius: "50%",
                      height: 22,
                      width: 22,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckRoundedIcon
                      sx={{
                        color: getDynamicColor("light1"),
                        stroke: getDynamicColor("light1"),
                        strokeWidth: 1.5,
                        height: 14,
                        width: 14,
                      }}
                    />
                  </Box>
                )}

                {hoveringProfilePictureId === profilePicture.id && !isSelected && (
                  <Stack
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: "8px",
                      backgroundColor: getDynamicColor(
                        "light1",
                        hoveringProfilePictureId === profilePicture.id ? 0.6 : 0
                      ),
                      transition: "background-color 0.2s",
                      zIndex: 2,
                    }}
                  >
                    {profilePicture.isCustom && !profilePicture.usedByNPersonas && (
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 2,
                          top: 4,
                          right: 4,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          p: 0.5,
                          borderRadius: "50%",
                          transition: "background-color 0.2s",
                          "&:hover": {
                            backgroundColor: getDynamicColor("dark1"),
                          },
                        }}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await deleteProfilePictureMutation.mutateAsync(profilePicture.id);
                        }}
                      >
                        <TrashIcon />
                      </Box>
                    )}
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: 12,
                        px: 2,
                        py: 0.5,
                        position: "relative",
                        top: { xs: 4, sm: 0 },
                      }}
                    >
                      Select
                    </Button>
                    {profilePicture.usedByNPersonas > 0 && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          letterSpacing: "1px",
                          lineHeight: 1.2,
                          fontWeight: 500,
                          fontFamily: "inter",
                          textAlign: "center",
                          maxWidth: 100,
                          color: getDynamicColor("purple3"),
                        }}
                      >
                        USED BY {profilePicture.usedByNPersonas} PERSONA
                        {profilePicture.usedByNPersonas > 1 ? "S" : ""}
                      </Typography>
                    )}
                  </Stack>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    backgroundColor: getDynamicColor("dark2"),
                    transition: `${
                      persona.profile_picture_id && !isSelected ? "opacity 0.2s, " : ""
                    }border 0.2s`,
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={profilePicture.signedUrl}
                    alt="profile picture"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: getDynamicColor("dark2"),
                      borderRadius: "8px",
                      objectFit: "contain",
                      position: "relative",
                      zIndex: 1,
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: hoveringProfilePictureId === profilePicture.id && !isSelected ? 4 : 1,
                      left: hoveringProfilePictureId === profilePicture.id && !isSelected ? 4 : 1,
                      height: "calc(100% - 1px)",
                      width: "calc(100% - 1px)",
                      backgroundColor: getDynamicColor("primary"),
                      borderRadius: "8px",
                      zIndex: 0,
                      transition: "top 0.2s, left 0.2s",
                      pointerEvents: "none",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
