import React from "react";

// Components
import { Info as InfoIcon } from "@mui/icons-material";
import { Stack, Typography, Box, Divider } from "@mui/material";
import { LogDashboardAmplitudeEventType } from "components/Dashboard/Dashboard";

// Utils
import { HoistedAnalytic } from "../../DashboardTypes";
import AverageComparison from "./AverageComparison";
import FocusArea from "./FocusArea";
import LearnMore from "./LearnMore";
import NextSteps from "./NextSteps";
import TopUsed from "./TopUsed";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { DashboardAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

export const DetailTitle = ({ title }: { title: string }): JSX.Element => {
  return (
    <Typography
      variant="poppins"
      sx={{
        fontWeight: 700,
        fontSize: 12,
        mr: 0.5,
      }}
    >
      {title}
    </Typography>
  );
};

type AnalyticDetailProps = {
  activeAnalytic?: HoistedAnalytic;
  numDays: number;
  logDashboardAmplitudeEvent: LogDashboardAmplitudeEventType;
};
const AnalyticDetail = ({
  activeAnalytic,
  numDays,
  logDashboardAmplitudeEvent,
}: AnalyticDetailProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const [isHoveringLabel, setIsHoveringLabel] = React.useState(false);
  const dividerMargin = 2;

  const latestSlug = React.useMemo((): string => {
    if (activeAnalytic?.data?.length || !activeAnalytic?.data[0].slugs?.length) return "";
    return activeAnalytic?.data[0]?.slugs[0];
  }, [activeAnalytic]);

  let setIsHoveringTimeout: NodeJS.Timeout;
  let setNotHoveringTimeout: NodeJS.Timeout;
  const handleSetIsHoveringLabel = () => {
    setIsHoveringTimeout = setTimeout(() => {
      logDashboardAmplitudeEvent(
        DashboardAnalyticsEvents.ANALYTIC_TOOLTIP_DISPLAYED,
        {
          hoisted_analytic_name: activeAnalytic?.identifier,
        },
        true
      );
      setIsHoveringLabel(true);
    }, 150);
    if (setNotHoveringTimeout) clearTimeout(setNotHoveringTimeout);
    return () => clearTimeout(setIsHoveringTimeout);
  };

  const handleSetNotHoveringLabel = () => {
    setNotHoveringTimeout = setTimeout(() => {
      setIsHoveringLabel(false);
    }, 300);
    if (setIsHoveringTimeout) clearTimeout(setIsHoveringTimeout);
    return () => clearTimeout(setNotHoveringTimeout);
  };

  return (
    <Stack
      sx={{
        cursor: "default",
      }}
    >
      {!isSmallScreen && (
        <>
          <Stack
            direction="row"
            gap={4}
            alignItems="center"
            justifyContent={isSmallScreen ? "space-between" : "flex-start"}
            sx={{
              mb: 3,
              position: "relative",
            }}
          >
            <Stack
              direction="row"
              gap={1.5}
              alignItems="center"
              onMouseEnter={() => handleSetIsHoveringLabel()}
              onMouseLeave={() => handleSetNotHoveringLabel()}
            >
              <Typography
                component="h2"
                variant="poppins"
                sx={{
                  fontWeight: 600,
                  fontSize: 20,
                  whiteSpace: "nowrap",
                }}
              >
                {activeAnalytic.label}
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  height: 20,
                }}
              >
                <InfoIcon
                  sx={{
                    fill: getDynamicColor("primary"),
                    height: 20,
                    width: 20,
                  }}
                />
                <Stack
                  gap={2}
                  sx={{
                    opacity: isHoveringLabel ? 1 : 0,
                    pointerEvents: isHoveringLabel ? "auto" : "none",
                    transform: isHoveringLabel ? `scale(1)` : `scale(0.95)`,
                    transition: `all 0.2s ease-in-out`,
                    position: "absolute",
                    left: 36,
                    top: 0,
                    border: `1px solid ${getDynamicColor("dark3")}`,
                    backgroundColor: getDynamicColor("light2"),
                    borderRadius: 3,
                    p: 2.5,
                    zIndex: 1800,
                    boxShadow: Y_SHADOWS.box_shadow_1,
                    width: 240,
                  }}
                >
                  <Typography
                    variant="poppins"
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    {activeAnalytic.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {activeAnalytic.definition}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            {activeAnalytic.focusArea && (
              <FocusArea
                focusArea={activeAnalytic.focusArea}
                logDashboardAmplitudeEvent={logDashboardAmplitudeEvent}
              />
            )}
          </Stack>
        </>
      )}
      <AverageComparison analytic={activeAnalytic} numDays={numDays} />
      {activeAnalytic.nextSteps && (
        <>
          <Divider sx={{ my: dividerMargin }} />
          <NextSteps
            nextSteps={activeAnalytic.nextSteps}
            value={activeAnalytic.currAvg}
            analyticThreshold={activeAnalytic.threshold}
          />
        </>
      )}
      {(activeAnalytic.topUsedWords || activeAnalytic.learnMore) && (
        <Divider sx={{ my: dividerMargin }} />
      )}
      {activeAnalytic.topUsedWords && (
        <TopUsed topUsedWords={activeAnalytic.topUsedWords} numDays={numDays} />
      )}
      {activeAnalytic.learnMore && (
        <LearnMore
          learnMore={activeAnalytic.learnMore}
          latestSlug={latestSlug}
          analyticId={activeAnalytic.identifier}
        />
      )}
    </Stack>
  );
};

export default AnalyticDetail;
