// Components
import {
  HomeOutlined as HomeIcon,
  Addchart as DashboardIcon,
  PeopleOutlined as MembersIcon,
  SettingsOutlined as SettingsIcon,
  VideoLibraryOutlined as VideoLibraryOutlinedIcon,
} from "@mui/icons-material";
import { OrgSettingsTabs } from "components/Orgs/ManageContent/OrgManageContent";
import { SHARED } from "components/VideoJournal/VideoJournalUtils";

// Assets
import { ReactComponent as ContentIcon } from "images/icons/icon-content.svg";
import { ReactComponent as ExercisesIcon } from "images/icons/icon-exercise.svg";
import { ReactComponent as HubsIcon } from "images/icons/icon-hubs.svg";
import { ReactComponent as OverviewIcon } from "images/icons/icon-overview.svg";
import { ReactComponent as ProgramsIcon } from "images/icons/icon-programs.svg";

// Utils
import { WebServerInternalPath } from "./paths";
import { OrgSettingsQueryParams, VideoLibraryQueryParams } from "lib-frontend/utils/queryParams";
import { HubRole, OrgRole, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

enum NavItemLabel {
  HOME = "Home",
  OVERVIEW = "Overview",
  MEMBERS = "Members",
  DASHBOARD = "Dashboard",
  HUBS = "Groups",
  SHARED_WITH_ME = "Shared with me",
  CUSTOMIZE = "Customize",
  MY_YOODLIS = "My Yoodlis",
  MY_RECORDINGS = "My Recordings",
  MY_LEARNING = "My Learning",
  ORG_SETTINGS = "Org settings",
  EXERCISES = "Exercises",
  PROGRAMS = "Programs",
}

type NavItem = {
  text: NavItemLabel;
  icon: React.ReactElement;
  path: string;
  dataTestId: string;
  pageTitle?: string;
  tooltip?: string;
};

type NavRole = OrgRole.ADMIN | HubRole.ADMIN | HubRole.MEMBER | "none";

export const navItems: Record<NavRole, NavItem[]> = {
  [OrgRole.ADMIN]: [
    {
      text: NavItemLabel.OVERVIEW,
      icon: <OverviewIcon />,
      path: WebServerInternalPath.ORG_OVERVIEW,
      dataTestId: UITestId.NavItemOrgOverview,
    },
    {
      text: NavItemLabel.CUSTOMIZE,
      icon: <ContentIcon />,
      path: `${WebServerInternalPath.ORG_CONTENT}?${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.CUSTOMIZE_PRACTICE}`,
      dataTestId: UITestId.NavItemOrgCustomize,
    },
    {
      text: NavItemLabel.MEMBERS,
      icon: <MembersIcon />,
      path: WebServerInternalPath.ORG_MEMBERS,
      dataTestId: UITestId.NavItemOrgMembers,
    },
    {
      text: NavItemLabel.HUBS,
      icon: <HubsIcon />,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.SHARED_WITH_ME,
      icon: <VideoLibraryOutlinedIcon />,
      path: `${WebServerInternalPath.LIBRARY}?${VideoLibraryQueryParams.TAB}=${SHARED}&${VideoLibraryQueryParams.PAGE}=1`,
      dataTestId: UITestId.NavItemSharedWithMe,
    },
    {
      text: NavItemLabel.PROGRAMS,
      icon: <ProgramsIcon />,
      path: WebServerInternalPath.ORG_PROGRAMS,
      dataTestId: UITestId.NavItemOrgPrograms,
    },
    {
      text: NavItemLabel.ORG_SETTINGS,
      icon: <SettingsIcon />,
      path: WebServerInternalPath.ORG_SETTINGS,
      dataTestId: UITestId.NavItemOrgSettings,
    },
  ],
  [HubRole.ADMIN]: [
    {
      text: NavItemLabel.HUBS,
      icon: <HubsIcon />,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.SHARED_WITH_ME,
      icon: <VideoLibraryOutlinedIcon />,
      path: `${WebServerInternalPath.LIBRARY}?${VideoLibraryQueryParams.TAB}=${SHARED}`,
      dataTestId: UITestId.NavItemSharedWithMe,
    },
  ],
  [HubRole.MEMBER]: [
    {
      text: NavItemLabel.HOME,
      icon: <HomeIcon />,
      path: WebServerExternalPath.HOME_LOGGED_IN,
      dataTestId: UITestId.NavItemMemberHome,
    },
    {
      text: NavItemLabel.DASHBOARD,
      icon: <DashboardIcon />,
      path: WebServerExternalPath.DASHBOARD,
      dataTestId: UITestId.NavItemMemberDashboard,
    },
    {
      text: NavItemLabel.MY_RECORDINGS,
      icon: <VideoLibraryOutlinedIcon />,
      path: WebServerInternalPath.LIBRARY,
      dataTestId: UITestId.NavItemMemberLibrary,
    },
    {
      text: NavItemLabel.MY_LEARNING,
      icon: <ProgramsIcon />,
      path: WebServerExternalPath.MY_LEARNING,
      dataTestId: UITestId.NavItemMemberLearning,
    },
    {
      text: NavItemLabel.EXERCISES,
      icon: <ExercisesIcon />,
      path: WebServerInternalPath.EXERCISES,
      dataTestId: UITestId.NavItemMemberExercises,
    },
  ],
  ["none"]: [
    {
      text: NavItemLabel.HOME,
      icon: <HomeIcon />,
      path: WebServerExternalPath.HOME_LOGGED_IN,
      dataTestId: UITestId.NavItemHome,
    },
    {
      text: NavItemLabel.DASHBOARD,
      icon: <DashboardIcon />,
      path: WebServerExternalPath.DASHBOARD,
      dataTestId: UITestId.NavItemDashboard,
    },
    {
      text: NavItemLabel.MY_YOODLIS,
      icon: <VideoLibraryOutlinedIcon />,
      path: WebServerInternalPath.LIBRARY,
      dataTestId: UITestId.NavItemLibrary,
    },
    {
      text: NavItemLabel.EXERCISES,
      icon: <ExercisesIcon />,
      path: WebServerInternalPath.EXERCISES,
      dataTestId: UITestId.NavItemExercises,
    },
  ],
};
