import { FALLBACK_SUBDOMAIN } from "../utils/constants";

/**
 * Determines the active `subdomain` and (optional) `prSlug` from the full
 *  hostname. If there is no match, it defaults to FALLBACK_SUBDOMAIN (i.e, "app").
 *  prSlug (the preview URL identifier) is only returned for *.pr.yoodli.ai URLs.
 * Examples:
 *  * https://yoodli.ai                         --> {subdomain: "app"}
 *  * https://app.yoodli.ai                     --> {subdomain: "app"}
 *  * https://example.yoodli.ai                 --> {subdomain: "example"}
 *  * https://dev.yoodli.ai                     --> {subdomain: "app"}
 *  * https://app.dev.yoodli.ai                 --> {subdomain: "app"}
 *  * https://example.dev.yoodli.ai             --> {subdomain: "example"}
 *  * https://staging.yoodli.ai                 --> {subdomain: "app"}
 *  * https://app.staging.yoodli.ai             --> {subdomain: "app"}
 *  * https://example.staging.yoodli.ai         --> {subdomain: "example"}
 *  * https://localhost:3000/                   --> {subdomain: "app"} (due to fallback)
 *  * https://localhost:3001/                   --> {subdomain: "app"} (due to fallback)
 *  * https://localhost:3003/                   --> {subdomain: "app"} (due to fallback)
 *  * https://my-cool-pr_app.pr.yoodli.ai       --> {subdomain: "app", prSlug: "my-cool-pr"}
 *  * https://my-cool-pr_example.pr.yoodli.ai   --> {subdomain: "example, prSlug: "my-cool-pr"}
 */
export function getSubdomainFromHostname(hostname?: string): {
  subdomain: string;
  prSlug?: string;
} {
  const inBrowser = typeof window !== "undefined";

  hostname = hostname ?? (inBrowser ? window.location.hostname : "");
  hostname = hostname.toLowerCase();

  const match = hostname.match(/^([^.]*)\.([^.]*)\.?yoodli\.ai$|^localhost(?::\d+)?$/);
  let subdomain = match?.[1] ?? FALLBACK_SUBDOMAIN;
  if (["dev", "staging"].includes(subdomain)) {
    subdomain = FALLBACK_SUBDOMAIN;
  }

  let prSlug: string;

  if (hostname.endsWith(".pr.yoodli.ai")) {
    const split = subdomain.split("_");
    if (split.length > 2) {
      throw new Error(`Too many underscores in ${hostname}`);
    } else if (split.length < 2) {
      throw new Error(`Missing underscore in ${hostname}`);
    }
    [prSlug, subdomain] = split;
  }

  if (!match && hostname !== "yoodli.ai") {
    console.log("Subdomain match failed on " + (inBrowser ? hostname : "non-browser platform."));
  }

  return { subdomain, prSlug };
}
