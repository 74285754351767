import React from "react";

// Components
import {
  ExpandCircleDownRounded as ExpandCircleDownRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  SvgIconTypeMap,
  TooltipProps,
  Stack,
  Divider,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// Utils
import YoodliTooltip from "./YoodliTooltip";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export type YoodliSelectOption = {
  // the main option label
  label: string;
  // the subLabel underneath the main label
  subLabel?: string;
  // tag for the option in the top right
  labelTag?: string | JSX.Element;
  // actual behind the scenes unique value (uid, key, etc.)
  value: string;
  // styles for the entire MenuItem element
  optionSx?: SxProps;
  // styles for the main label (this affects the menu options as well as the selected option)
  labelSx?: SxProps;
  // styles that only affect the selected label (outside the menu dropdown, in the select input box)
  selectedLabelSx?: SxProps;
  // styles for the subLabel, if it exists
  subLabelSx?: SxProps;
  // styles for the labelTag, if it exists
  labelTagSx?: SxProps;
  // show a divider between this and the previous element
  showDivider?: boolean;
  // display this JSX element instead of the default option (used in MultiSelect)
  JSXOverride?: JSX.Element;
};
export interface YoodliSelectProps {
  options?: YoodliSelectOption[];
  loading?: boolean;
  hideLoader?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  yoodliVariant?: YoodliSelectVariant;
  tooltip?: Omit<TooltipProps, "children"> & { key?: string };
  hideTooltipWhenOpen?: boolean;
}

export enum YoodliSelectVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LIGHT = "light",
  UNDERLINE = "underline",
}

type YoodliSelectVariantProps = {
  selectSx?: SxProps;
  inputSx?: SxProps;
  menuSx?: SxProps;
  menuListSx?: SxProps;
  optionSx?: SxProps;
  selectedLabelSx?: SxProps;
  optionLabelSx?: SxProps;
  subLabelSx?: SxProps;
  iconSx?: SxProps;
  icon?: OverridableComponent<SvgIconTypeMap<object, "svg">> & { muiName: string };
};

export const SELECT_VALUE_STYLES: SxProps = {
  fontWeight: 700,
  fontSize: { xs: 14, md: 16 },
};
const primaryTheme: YoodliSelectVariantProps = {
  selectSx: {
    minWidth: { xs: "100%", md: 250 },
    backgroundColor: getDynamicColor("light1"),
  },
  inputSx: {
    padding: 2,
    fontSize: 16,
    fontWeight: 500,
  },
  selectedLabelSx: {
    color: getDynamicColor("purple3"),
  },
  menuSx: {
    mt: 1,
  },
  menuListSx: {
    p: 1.5,
  },
  optionSx: {
    p: 1.5,
    fontSize: 16,
    fontWeight: 500,
  },
  icon: ExpandCircleDownRoundedIcon,
};

export const setBorderColor = (isDisabled: boolean, isOpen: boolean): string => {
  if (isDisabled) {
    return `1px solid ${getDynamicColor("dark4")}`;
  }
  if (isOpen) {
    return `1px solid ${getDynamicColor("primary")}`;
  }
  return `1px solid ${getDynamicColor("dark4")}`;
};

export const YoodliSelect = (props: SelectProps & YoodliSelectProps): JSX.Element => {
  const YoodliSelectThemes: Record<YoodliSelectVariant, YoodliSelectVariantProps> =
    React.useMemo(() => {
      return {
        [YoodliSelectVariant.PRIMARY]: primaryTheme,
        [YoodliSelectVariant.LIGHT]: {
          ...primaryTheme,
          selectSx: {
            ...primaryTheme.selectSx,
            color: getDynamicColor("light1"),
            backgroundColor: "transparent",
            border: "none",
            minWidth: "auto",
          },

          selectedLabelSx: {
            color: getDynamicColor("light1"),
          },
          iconSx: {
            color: getDynamicColor("light1"),
            svg: {
              color: getDynamicColor("light1"),
              fill: getDynamicColor("light1"),
            },
          },
        },
        // stubbed out possible themes?
        [YoodliSelectVariant.SECONDARY]: {
          selectSx: {
            minWidth: { xs: "100%", md: 150 },
          },
          inputSx: {
            fontSize: 14,
            fontWeight: 400,
            p: 1,
          },
          menuSx: {
            mt: 0.5,
          },
          menuListSx: {
            p: 1,
          },
          optionSx: {
            p: 1,
            fontSize: 12,
            fontWeight: 400,
          },
          icon: ExpandMoreRoundedIcon,
        },
        [YoodliSelectVariant.UNDERLINE]: {
          selectSx: {
            color: getDynamicColor("light1"),
            backgroundColor: "transparent",
            border: "none",
            minWidth: "auto",
          },
          inputSx: {
            fontSize: 14,
            fontWeight: 400,
            p: 0,
            pr: 0,
          },
          menuSx: {
            mt: 0.5,
          },
          menuListSx: {
            p: 1,
          },
          optionSx: {
            p: 1,
            fontSize: 12,
            fontWeight: 400,
          },
          optionLabelSx: {
            mb: 0,
            fontSize: 12,
            fontWeight: 400,
          },
          labelSx: {
            mb: 0,
          },
          icon: ExpandMoreRoundedIcon,
        },
      };
    }, []);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  const tooltipShowingTimeout = React.useRef<NodeJS.Timeout | null>(null);

  // custom props are not supported on MuiSelect, so copy props and remove them before spreading
  const selectProps = { ...props };
  delete selectProps.loading;
  delete selectProps.yoodliVariant;
  delete selectProps.hideLoader;
  delete selectProps.hideTooltipWhenOpen;
  delete selectProps.tooltip;
  const theme: YoodliSelectVariant = props.yoodliVariant ?? YoodliSelectVariant.PRIMARY;
  const mergedSelectSx: SxProps = { ...YoodliSelectThemes[theme]?.selectSx, ...props?.sx };
  const mergedMenuSx: SxProps = { ...YoodliSelectThemes[theme]?.menuSx, ...props?.MenuProps?.sx };
  const mergedMenuListSx: SxProps = {
    ...YoodliSelectThemes[theme]?.menuListSx,
    ...props?.MenuProps?.MenuListProps?.sx,
  };

  const tooltipMouseEnterLeaveProps = {
    onMouseEnter: () => {
      if (tooltipShowingTimeout.current) {
        clearTimeout(tooltipShowingTimeout.current);
        tooltipShowingTimeout.current = null;
      }
      tooltipShowingTimeout.current = setTimeout(
        () => setTooltipOpen(true),
        props.tooltip?.enterDelay ?? 0
      );
    },
    onMouseLeave: () => {
      if (tooltipShowingTimeout.current) {
        clearTimeout(tooltipShowingTimeout.current);
        tooltipShowingTimeout.current = null;
      }
      setTooltipOpen(false);
    },
  };

  const IconComponent = (props: {
    yoodliVariant: YoodliSelectVariant;
    expanded: boolean;
    disabled: boolean;
    iconSx?: SxProps;
  }): JSX.Element => {
    const renderIcon = () => {
      const Element = YoodliSelectThemes[props.yoodliVariant]?.icon ?? ExpandCircleDownRoundedIcon;
      return (
        <Element
          sx={{
            color: `${props.disabled ? getDynamicColor("dark4") : getDynamicColor("primary")}`,
            transform: `${props.expanded ? "rotate(180deg)" : "rotate(0)"}`,
            ...props.iconSx,
          }}
        />
      );
    };
    return (
      <Box
        className="yoodliSelectIcon"
        sx={{
          transition: "transform 0.5s ease",
          height: 24,
          width: 24,
          position: "absolute",
          right: 12,
          pointerEvents: "none",
        }}
      >
        {renderIcon()}
      </Box>
    );
  };

  return (
    <YoodliTooltip
      sx={{
        width: props.fullWidth ? "100%" : "auto",
      }}
      {...props.tooltip}
      {...tooltipMouseEnterLeaveProps}
      open={tooltipOpen && (props.hideTooltipWhenOpen ? !isOpen : true)}
      PopperProps={{
        ...props.tooltip?.PopperProps,
        sx: {
          ...props.tooltip?.sx,
          zIndex: 10000,
        },
      }}
    >
      <Select
        {...selectProps}
        open={isOpen}
        disabled={props.disabled || props.loading}
        onOpen={(e) => {
          selectProps?.onOpen?.(e);
          setIsOpen(true);
        }}
        onClose={(e) => {
          selectProps?.onClose?.(e);
          setIsOpen(false);
        }}
        sx={{
          fontSize: 16,
          color:
            props.value === "placeholder" ? getDynamicColor("dark4") : getDynamicColor("purple3"),
          fontWeight: 500,
          fontFamily: "poppins",
          borderRadius: "4px",
          border: setBorderColor(props.disabled, isOpen),
          fieldset: {
            border: "none",
          },
          width: "100%",
          flexGrow: 1,
          ".MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "1px 2px 5px 0px rgba(33, 37, 41, 0.16)",
          },
          pl: "0px !important",
          "&.Mui-disabled": { color: `${getDynamicColor("dark4")} !important` },

          ...mergedSelectSx,
        }}
        inputProps={{
          ...selectProps.inputProps,
          sx: {
            border: "none",
            fontFamily: "poppins",
            pr: "48px !important",
            pl: 0,
            // styles for the selected menu item
            ".selected": {
              p: 0,
              ".label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ...YoodliSelectThemes[theme]?.selectedLabelSx,
              },
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            // don't display the potential divider on the selected item
            ".MuiDivider-root": {
              display: "none",
            },
            "&.Mui-disabled": {
              color: `${getDynamicColor("dark4")} !important`,
              WebkitTextFillColor: `${getDynamicColor("dark4")} !important`,
            },
            ...props?.inputProps?.sx,
            ...YoodliSelectThemes[theme]?.inputSx,
          },
        }}
        IconComponent={
          props.IconComponent ??
          (() => (
            <IconComponent
              expanded={isOpen}
              yoodliVariant={theme}
              disabled={selectProps.disabled}
              iconSx={YoodliSelectThemes[theme]?.iconSx}
            />
          ))
        }
        MenuProps={{
          disablePortal: true,
          elevation: 0,
          sx: {
            cursor: "default !important",
            ...mergedMenuSx,
          },
          MenuListProps: {
            sx: {
              borderRadius: "8px",
              ...mergedMenuListSx,
            },
          },
          ...props?.MenuProps,
        }}
        startAdornment={
          <>
            {!props?.hideLoader && props?.loading && (
              <Box sx={{ pl: 2, m: "auto" }}>
                <CircularProgress
                  size={14}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: getDynamicColor("dark4"),
                  }}
                />
              </Box>
            )}
          </>
        }
      >
        {props.children ??
          props.options.map((option) => {
            const mergedOptionLabelSx: SxProps = {
              ...YoodliSelectThemes[theme]?.optionLabelSx,
              ...option.labelSx,
            };
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  all: "initial",
                  width: "100%",
                  "&.Mui-selected": {
                    ".actualMenuItem": {
                      color: getDynamicColor("primary"),
                      backgroundColor: `${getDynamicColor("light2")}`,
                    },
                    ".subLabel": {
                      color: getDynamicColor("dark5"),
                    },
                  },
                }}
                disableRipple
                disableTouchRipple
              >
                {option.showDivider && (
                  <Divider
                    sx={{
                      my: 1,
                      position: "relative",
                      zIndex: 2,
                    }}
                  />
                )}
                <Box
                  // this is the menu item that has the hover effects and the click listener
                  className={`actualMenuItem ${option.value === props.value ? "selected" : ""}`}
                  onMouseEnter={() => setTooltipOpen(false)}
                  onMouseLeave={() => setTooltipOpen(false)}
                  onClick={() => setTooltipOpen(false)}
                  sx={{
                    cursor: "pointer",
                    fontFamily: "poppins",
                    color: `${
                      props.value === option.value
                        ? getDynamicColor("primary")
                        : getDynamicColor("purple3")
                    }`,
                    "&:hover": {
                      backgroundColor: getDynamicColor("dark1"),
                    },
                    ...SELECT_VALUE_STYLES,
                    ...({ ...YoodliSelectThemes[theme]?.optionSx, ...option.optionSx } as SxProps),
                    fontSize: "inherit",
                  }}
                >
                  {option.JSXOverride ? (
                    option.JSXOverride
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        gap={1}
                        sx={{
                          flexGrow: 1,
                          ...mergedOptionLabelSx,
                        }}
                      >
                        <Typography
                          className="label"
                          sx={{
                            flexGrow: 1,
                            mb: 0.5,
                            ...mergedOptionLabelSx,
                          }}
                        >
                          {option.label}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: 12,
                            color: getDynamicColor("dark4"),
                            textAlign: "right",
                            ...option.labelTagSx,
                          }}
                        >
                          {option.labelTag}
                        </Typography>
                      </Stack>
                      {option.subLabel && (
                        <Typography
                          className="subLabel"
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: getDynamicColor("dark4"),
                            textTransform: "uppercase",
                            letterSpacing: "1px",
                            ...option.subLabelSx,
                          }}
                        >
                          {option.subLabel}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </MenuItem>
            );
          }) ??
          []}
      </Select>
    </YoodliTooltip>
  );
};
