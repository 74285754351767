import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Utils
import * as Sentry from "@sentry/react";
import { createAuthToken } from "lib-frontend/modules/AxiosInstance";
import { getScopedUserId } from "lib-frontend/utils/AccountUtils";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import {
  Instrumentation,
  setGAUserIdAndProperties,
} from "lib-frontend/utils/ProductAnalyticsUtils";
import { HubsInviteRequiredQueryParams } from "lib-frontend/utils/queryParams";
import { delay } from "lib-fullstack/utils/helperFunctions";

export function useURLQueryString(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export async function generateAuthToken(code: string): Promise<void> {
  const token = await firebase.auth().currentUser.getIdToken();

  await createAuthToken(code, token);
}

export async function completeUserLogin(userId: string): Promise<void> {
  let dbUser = await db.get(db.users(getSiteId()), userId);

  for (let i = 0; i < 3; i++) {
    // Potential race condition, poll db again
    if (!dbUser) {
      await delay(250);
      dbUser = await db.get(db.users(getSiteId()), userId);
    } else {
      break;
    }
  }

  if (!dbUser) {
    console.warn("completeUserLogin: dbUser not found!");
  }

  await setGAUserIdAndProperties();

  Sentry.setUser({
    id: getScopedUserId(),
  });
}

export async function generateAuthTokenForPoodliAndClose(code: string): Promise<void> {
  const finalPromises = [];
  finalPromises.push(generateAuthToken(code));
  finalPromises.push(Instrumentation.flush());
  await Promise.all(finalPromises);
  window.close();
}

export function fetchUrlSearchParams(params: URLSearchParams): string {
  const searchParams = new URLSearchParams();
  if (params.get("ot-auth-code")) {
    searchParams.set("ot-auth-code", params.get("ot-auth-code"));
  }
  if (HubsInviteRequiredQueryParams.some((param) => params.has(param))) {
    HubsInviteRequiredQueryParams.forEach((param) => {
      if (params.has(param)) {
        searchParams.set(param, params.get(param));
      }
    });
  }
  return searchParams.toString();
}
