import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";

// Utils
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { getUser, getOrgV2, listOrgsV2, patchUser } from "lib-frontend/modules/AxiosInstance";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgInviteQueryParams } from "lib-frontend/utils/queryParams";
import { GetUserFieldType } from "lib-fullstack/api/apiTypes";
import { OrgV2Response, OrgV2ListItemResponse } from "lib-fullstack/api/orgApiTypes";
import { EffectiveRole, OrgCustomerType } from "lib-fullstack/utils/enums";

/**
 * Expose user context that can be fetched
 */
export interface IUserOrgContext {
  // org related context
  loading: boolean;
  userInOrg: boolean;
  defaultOrgLogo: string | null;
  orgId: string;
  userInCoachOrganization: boolean;
  userInEnterpriseOrganization: boolean;
  userInGccOrg: boolean;
  isOrgOwnerAdmin: boolean;

  userOrgProfile: UserOrgProfile;
  setUserOrgProfile: (profile: UserOrgProfile) => void;
  userOrgRole: EffectiveRole;
  setUserOrgRole: (role: EffectiveRole) => void;

  // modules enabled/disabled by org, if applicable
  orgModuleAccess: OrgModuleAccess;

  // Admin context
  defaultOrgLoading: boolean;
  defaultOrgRefetching: boolean;
  defaultOrg: OrgV2Response;
  adminOrgList: OrgV2ListItemResponse[];
  fullOrgList: OrgV2ListItemResponse[];
  orgListLoading: boolean;

  // helpers to request refreshes of the context on changes
  invalidateUserOrgQuery: () => Promise<void>;
  invalidateDefaultOrgQuery: () => Promise<void>;
  invalidateOrgListQuery: () => Promise<void>;
}

// react-query keys for invalidation
// not exported as the relevant refresh functions on the context should be used instead
const userOrgContextQueryKey = "userOrgContext";
enum UserOrgContextSubQueryKeys {
  USER_INFO = "userInfo",
  DEFAULT_ORG = "defaultOrg",
  ORGS_LIST = "orgsList",
}

export enum UserOrgProfile {
  ORGANIZATION = "Admin",
  PERSONAL = "Member",
}

export type OrgModuleAccess = {
  interviewEnabled: boolean;
  presentationEnabled: boolean;
  roleplayEnabled: boolean;
  desktopAppEnabled: boolean;
  zoodliForUsersEnabled: boolean;
};

const defaultOrgModuleAccess: OrgModuleAccess = {
  interviewEnabled: true,
  presentationEnabled: true,
  roleplayEnabled: true,
  desktopAppEnabled: true,
  zoodliForUsersEnabled: true,
};

/**
 * Context to use to get information on a user's organization and hub memberships and content
 */
export const UserOrgContext = React.createContext<IUserOrgContext>({
  loading: true,
  orgId: null,
  defaultOrgLogo: null,
  userInOrg: undefined,
  userInCoachOrganization: false,
  userInEnterpriseOrganization: false,
  userInGccOrg: false,
  isOrgOwnerAdmin: false,

  orgModuleAccess: defaultOrgModuleAccess,

  defaultOrgLoading: true,
  defaultOrgRefetching: false,
  defaultOrg: null,
  adminOrgList: null,
  fullOrgList: null,
  orgListLoading: true,

  userOrgProfile: UserOrgProfile.ORGANIZATION,
  setUserOrgProfile: () => {
    // empty to provide a safe default to call
    return;
  },

  userOrgRole: EffectiveRole.HUB_MEMBER,
  setUserOrgRole: () => {
    // empty to provide a safe default to call
    return;
  },

  invalidateUserOrgQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },
  invalidateDefaultOrgQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },
  invalidateOrgListQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },
});

const GCC_ORG_IDS = ["kg21", "t9sT", "twdL"];

/**
 * React element to provide the user org context in the tree
 */
export function UserOrgDataProvider({ children }: React.PropsWithChildren): JSX.Element {
  const queryClient = useQueryClient();

  const [userOrgProfile, setUserOrgProfile] = React.useState<UserOrgProfile>(
    (getLiveUserDocMain()?.lastUserOrgProfile as UserOrgProfile) ?? UserOrgProfile.ORGANIZATION
  );

  const [userOrgRole, setUserOrgRole] = React.useState<EffectiveRole>(
    (getLiveUserDocMain()?.lastUserOrgRole as EffectiveRole) ?? EffectiveRole.HUB_MEMBER
  );

  // if the user id changes under the hood, update the state to trigger new queries to update state
  React.useEffect(
    () =>
      firebase.auth().onAuthStateChanged(() => {
        console.log("UserOrgDataProvider: auth state changed invalidation");
        void queryClient.invalidateQueries({ queryKey: [userOrgContextQueryKey] });
      }),
    []
  );

  // Fetch the user's org context
  // This is the core query that all other queries depend on
  // In OHR we'll be able to simplify this to use use the default org fetch + a content member view fetch
  const userQueryResults = useApiQuery({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.USER_INFO },
    ],
    queryFn: async () =>
      getUser([
        GetUserFieldType.SHOW_ORG_ADMIN_UX,
        GetUserFieldType.DEFAULT_ORG_ID,
        GetUserFieldType.FEATURE_FLAGS,
        GetUserFieldType.DEFAULT_ORG_LOGO_URL,
      ]),
    enabled: !!firebase.auth().currentUser?.uid && !!firebase.auth().currentUser?.emailVerified,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    const updateBrandingLogo = async () => {
      await updateThisUserDocMain({
        brandingLogoUrl: userQueryResults.data?.default_org_logo_url ?? db.value("remove"),
      });
    };
    if (userQueryResults.data?.default_org_logo_url !== getLiveUserDocMain().brandingLogoUrl) {
      updateBrandingLogo().catch((e) => {
        console.error(`Failed to update branding logo: ${e}`);
      });
    }
  }, [userQueryResults.data?.default_org_logo_url]);

  // if the path includes an overrideOrgId, update the user's default_org_id and invalidate the context
  React.useEffect(() => {
    const overrideOrgId = new URLSearchParams(window.location.search).get(
      OrgInviteQueryParams.OVERRIDE_ORG_ID
    );
    const currOrgId = userQueryResults.data?.default_org_id;
    // if the user is not loaded yet, don't do anything
    if (!currOrgId) {
      return;
    }
    if (overrideOrgId && overrideOrgId !== currOrgId) {
      const updateUserDefaultOrg = async () => {
        await patchUser({ default_org_id: overrideOrgId });
        // remove the overrideOrgId from the URL after updating the user's default_org_id
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(OrgInviteQueryParams.OVERRIDE_ORG_ID);
        const paramsToAdd = searchParams.toString()?.length ? `?${searchParams.toString()}` : "";
        const newUrl = `${window.location.pathname}${paramsToAdd}${window.location.hash}`;
        window.history.replaceState({}, "", newUrl);
        console.log("UserOrgDataProvider: overrideOrgId changed invalidation");
        Instrumentation.logDefaultOrgChanged(overrideOrgId, currOrgId);
        void queryClient.invalidateQueries({ queryKey: [userOrgContextQueryKey] });
      };
      updateUserDefaultOrg().catch((er) => {
        console.error(
          `Failed to update user default org from ${currOrgId} to ${overrideOrgId}: ${er}`
        );
      });
    }
  }, [userQueryResults.data?.default_org_id]);

  const handleSetUserOrgProfile = React.useCallback((profile: UserOrgProfile) => {
    updateThisUserDocMain({ lastUserOrgProfile: profile }).catch((er) => {
      console.log(`Failed to update userdoc with latest userOrgProfile: ${er}`);
    });
    setUserOrgProfile(profile);
  }, []);

  const handleSetUserOrgRole = React.useCallback((role: EffectiveRole) => {
    updateThisUserDocMain({ lastUserOrgRole: role }).catch((er) => {
      console.log(`Failed to update userdoc with lastUserOrgRole: ${er}`);
    });
    setUserOrgRole(role);
  }, []);

  const updateUserDocRole = (orgRes: OrgV2Response) => {
    let role = orgRes.effective_role;
    // change owner roles to admin (for mapping the nav items as they are the same for the two roles)
    if (role === EffectiveRole.ORG_OWNER) {
      role = EffectiveRole.ORG_ADMIN;
    }
    if (role && role !== getLiveUserDocMain()?.lastUserOrgRole) {
      updateThisUserDocMain({ lastUserOrgRole: role }).catch((er) => {
        console.log(`Failed to update userdoc with lastUserOrgRole: ${er}`);
      });
    }
  };

  // Full default org query
  const orgQueryResultsEnabled =
    userQueryResults.isSuccess &&
    !userQueryResults.isFetching &&
    !!userQueryResults.data.default_org_id;
  const orgQueryResults = useApiQuery({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.DEFAULT_ORG },
    ],
    queryFn: async () => {
      const orgRes = await getOrgV2(userQueryResults.data.default_org_id);
      // whenever the default org changes, update the role in user doc to the current valid effective role
      updateUserDocRole(orgRes);
      return orgRes;
    },
    enabled: orgQueryResultsEnabled,

    refetchOnWindowFocus: false,
  });

  // Org list query
  const orgsListQueryResults = useApiQuery({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.ORGS_LIST },
    ],
    queryFn: async () => listOrgsV2(),
    enabled: userQueryResults.isSuccess,
    refetchOnWindowFocus: false,
  });

  // TODO: potentially sort on backend
  const sortedOrgsList = React.useMemo(
    () => orgsListQueryResults.data?.orgs.sort((a, b) => a.name.localeCompare(b.name)),
    [orgsListQueryResults.data]
  );

  // Helper functions to allow elements further down the stack to easily request refreshes of the context
  // While react-query allows this via invalidation, it requires everything down the stack to be "smart"
  // so we encapsulate some of the complexities here
  const invalidateUserOrgQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing org membership");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.USER_INFO }],
    });
  }, [queryClient]);

  const invalidateDefaultOrgQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing default org");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.DEFAULT_ORG }],
    });
  }, [queryClient]);

  const invalidateOrgListQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing org list");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.ORGS_LIST }],
    });
  }, [queryClient]);

  // Construct the context value
  const userOrgContextValue: IUserOrgContext = {
    loading: userQueryResults.isPending,
    defaultOrgLogo:
      userQueryResults.data?.default_org_logo_url || getLiveUserDocMain().brandingLogoUrl,
    orgId: userQueryResults.data?.default_org_id ?? "default",
    userInOrg: !userQueryResults?.isFetchedAfterMount
      ? undefined
      : userQueryResults.isSuccess && !!userQueryResults.data.default_org_id,
    userInCoachOrganization:
      orgQueryResults.isSuccess && orgQueryResults.data.customer_type === OrgCustomerType.COACH,
    userInEnterpriseOrganization:
      orgQueryResults.isSuccess &&
      orgQueryResults.data.customer_type === OrgCustomerType.ENTERPRISE,
    userInGccOrg: GCC_ORG_IDS.includes(userQueryResults.data?.default_org_id),
    isOrgOwnerAdmin: userQueryResults.isSuccess && userQueryResults.data.show_org_admin_ux,

    orgModuleAccess: {
      interviewEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.interview_enabled
        : true,
      zoodliForUsersEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.zoodli_for_users_enabled
        : true,
      presentationEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.presentation_enabled
        : true,
      roleplayEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.roleplay_enabled
        : true,
      desktopAppEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.desktop_app_enabled
        : true,
    },

    defaultOrgRefetching: orgQueryResults.isRefetching,
    defaultOrgLoading:
      orgQueryResultsEnabled &&
      (orgQueryResults.isPending || orgQueryResults.isRefetching || userQueryResults.isRefetching),
    defaultOrg: userQueryResults?.data?.default_org_id ? orgQueryResults.data : undefined,
    adminOrgList: sortedOrgsList?.filter((org) =>
      [EffectiveRole.ORG_OWNER, EffectiveRole.ORG_ADMIN].includes(org.effective_role)
    ),
    fullOrgList: orgsListQueryResults.data?.orgs,
    orgListLoading: orgsListQueryResults.isPending,
    userOrgProfile,
    setUserOrgProfile: handleSetUserOrgProfile,
    userOrgRole,
    setUserOrgRole: handleSetUserOrgRole,

    invalidateDefaultOrgQuery,
    invalidateOrgListQuery,
    invalidateUserOrgQuery,
  };

  return <UserOrgContext.Provider value={userOrgContextValue}>{children}</UserOrgContext.Provider>;
}
