import React from "react";
import { useHistory } from "react-router";

// Components
import { Box, Button, Stack, Typography } from "@mui/material";

// Utils
import EmptyVideoView from "./EmptyVideoView";
import VideoCollection from "../VideoCollection";
import SectionOptionsMenu from "../SectionOptionsMenu";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DemoVideoResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";
import { WebServerInternalPath } from "utils/paths";

type DemoVideosSectionProps = {
  setModalStatus?: (modalStatus: HubModalStatus) => void;
  demoVideos: DemoVideoResponse[];
  selectedOrgId: string;
  isHubAdmin?: boolean;
  showManageContentButton?: boolean;
};

export default function DemoVideosSection({
  setModalStatus,
  demoVideos,
  selectedOrgId,
  isHubAdmin,
  showManageContentButton,
}: DemoVideosSectionProps): JSX.Element {
  const history = useHistory();
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState<HTMLElement | null>(null);
  const menuItems = React.useMemo(
    () => [
      {
        title: "Upload videos",
        onClick: () => {
          setDropdownAnchorEl(null);
          setModalStatus(HubModalStatus.UPLOAD_DEMO_VIDEO);
        },
      },
      {
        title: "Manage videos",
        onClick: () => {
          setDropdownAnchorEl(null);
          setModalStatus(HubModalStatus.EDIT_DEMO_VIDEOS);
        },
      },
    ],

    []
  );

  const videoCount = `${demoVideos?.length} video${demoVideos?.length > 1 ? "s" : ""}`;
  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Typography
          sx={{
            fontSize: { xs: 16, md: 18 },
            fontWeight: 700,
            fontFamily: "Poppins",
            minHeight: 28,
          }}
        >
          Welcome videos
          <Box
            component="span"
            sx={{ fontSize: { xs: 14, md: 16 }, ml: 2, color: getDynamicColor("dark4") }}
          >
            {demoVideos?.length > 0 && videoCount}
          </Box>
        </Typography>
        <Stack>
          {isHubAdmin && (
            <SectionOptionsMenu
              menuItems={menuItems}
              anchorEl={dropdownAnchorEl}
              setAnchorEl={setDropdownAnchorEl}
            />
          )}
          {showManageContentButton && (
            <Button
              variant="outlined"
              onClick={() => {
                history.push(WebServerInternalPath.ORG_CONTENT);
              }}
            >
              Manage content
            </Button>
          )}
        </Stack>
      </Stack>
      {demoVideos?.length ? (
        <VideoCollection videos={demoVideos} selectedOrgId={selectedOrgId} courseId={undefined} />
      ) : (
        <EmptyVideoView
          isHubAdmin={isHubAdmin}
          handleAddVideo={() => setModalStatus(HubModalStatus.UPLOAD_DEMO_VIDEO)}
        />
      )}
    </Stack>
  );
}
