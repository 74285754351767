import { noop } from "lodash";
import React from "react";

// Components
import { Box, CircularProgress, Stack, Divider, Typography } from "@mui/material";
import { FOCUS_ANALYTICS } from "components/Dashboard/DashboardTypes";
import AnalyticChart from "components/Dashboard/ReportSnapshot/AnalyticChart";
import AverageComparison from "components/Dashboard/ReportSnapshot/AnalyticDetail/AverageComparison";
import LearnMore from "components/Dashboard/ReportSnapshot/AnalyticDetail/LearnMore";
import NextSteps from "components/Dashboard/ReportSnapshot/AnalyticDetail/NextSteps";
import TopUsed from "components/Dashboard/ReportSnapshot/AnalyticDetail/TopUsed";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { DashboardSuccess } from "lib-fullstack/api/apiTypes";
import { GetProgramUserResponse, ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { AggregateAnalyticEnum, DashboardAnalyticId } from "lib-fullstack/db";
import { DAY_IN_MS } from "lib-fullstack/utils/constants";
import { fetchAggAnalytics, mapAnalyticsToMetadata } from "utils/DashboardUtils";

const AGG_ANALYTICS_QUERY_KEY = "aggAnalytics";
const MAX_DAY_RANGE = 180;

type ProgramUserDashboardProps = {
  program: ProgramResponse;
  user: GetProgramUserResponse;
};

export const ProgramUserDashboard = ({ program, user }: ProgramUserDashboardProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();

  const [selectedAnalytic, setSelectedAnalytic] = React.useState<string>(
    AggregateAnalyticEnum.FILLER
  );

  // Calculate the day range to fetch analytics
  // Set the end time to the user's completion date if it exists, otherwise set it to the current date
  // Set the start time to the program's created date, with a max range of 180 days
  const endTime = user.completion_date ? new Date(user.completion_date) : new Date();
  let startTime = new Date(program.created_date);
  let daysDifference = Math.floor((endTime.getTime() - startTime.getTime()) / DAY_IN_MS);
  if (daysDifference > MAX_DAY_RANGE) {
    startTime = new Date(endTime.getTime() - MAX_DAY_RANGE * DAY_IN_MS);
    daysDifference = MAX_DAY_RANGE;
  }

  const dashboardQuery = useApiQuery({
    queryKey: [AGG_ANALYTICS_QUERY_KEY, selectedAnalytic, user.user_id],
    queryFn: () =>
      fetchAggAnalytics(
        [selectedAnalytic] as DashboardAnalyticId[],
        startTime.toISOString(),
        endTime.toISOString(),
        user.user_id
      ) as Promise<DashboardSuccess>,
  });

  const analyticOptions = React.useMemo(
    () =>
      Object.values(FOCUS_ANALYTICS).map((analytic) => ({
        value: analytic.identifier,
        label: analytic.label,
      })),
    []
  );

  const activeAnalytic = mapAnalyticsToMetadata(dashboardQuery.data?.analytics)[0];

  const latestSlug = React.useMemo((): string => {
    if (activeAnalytic?.data?.length || !activeAnalytic?.data?.[0]?.slugs?.length) return "";
    return activeAnalytic?.data[0]?.slugs[0];
  }, [activeAnalytic]);

  if (dashboardQuery.isLoading) {
    return <CircularProgress />;
  }

  if (!dashboardQuery.data.ok) {
    return;
  }

  return (
    <Stack gap={2}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 700,
        }}
      >
        Skill progress during program
      </Typography>
      {isMediumScreen && (
        <YoodliSelect
          options={analyticOptions}
          value={selectedAnalytic}
          onChange={(e) => setSelectedAnalytic(e.target.value as string)}
          sx={{
            height: "50px",
          }}
        />
      )}
      <Stack
        sx={{
          borderRadius: "4px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          px: { xs: 3, lg: 5 },
          py: { xs: 3, lg: 4 },
        }}
      >
        <Stack
          gap={2}
          direction={{ xs: "column-reverse", lg: "row" }}
          sx={{ justifyContent: "space-between" }}
        >
          <Stack gap={3}>
            {!isMediumScreen && (
              <YoodliSelect
                options={analyticOptions}
                value={selectedAnalytic}
                onChange={(e) => setSelectedAnalytic(e.target.value as string)}
                fullWidth={false}
                sx={{
                  maxWidth: "170px",
                  height: "50px",
                }}
              />
            )}
            <Stack gap={2}>
              <AverageComparison analytic={activeAnalytic} numDays={daysDifference} />
              {activeAnalytic.nextSteps && (
                <>
                  <Divider />
                  <NextSteps
                    nextSteps={activeAnalytic.nextSteps}
                    value={activeAnalytic.currAvg}
                    analyticThreshold={activeAnalytic.threshold}
                  />
                </>
              )}
              {(activeAnalytic.topUsedWords || activeAnalytic.learnMore) && <Divider />}
              {activeAnalytic.topUsedWords && (
                <TopUsed topUsedWords={activeAnalytic.topUsedWords} numDays={daysDifference} />
              )}
              {activeAnalytic.learnMore && (
                <LearnMore
                  learnMore={activeAnalytic.learnMore}
                  latestSlug={latestSlug}
                  analyticId={activeAnalytic.identifier}
                />
              )}
            </Stack>
          </Stack>
          {activeAnalytic.data.length ? (
            <Box sx={{ height: { xs: "270px", lg: "400px" }, width: "100%" }}>
              <AnalyticChart activeAnalytic={activeAnalytic} setHoveringThresholdLegend={noop} />
            </Box>
          ) : (
            <Typography
              sx={{
                m: "auto",
                color: getDynamicColor("purple3"),
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "poppins",
              }}
            >
              No data available
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
