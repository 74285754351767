import React from "react";

// Components
import { Box, CircularProgress } from "@mui/material";

// Utils
import { ManageCoachBotTab } from "./ManageCoachBotTab";
import {
  COACHBOT_CONTENT_POLLING_INTERVAL,
  CoachBotUploadedFile,
} from "./SelfServeCoachBotWizard/CoachBotTypes";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePrevious, useStateRef } from "lib-frontend/hooks";
import {
  deleteCoachBotContent,
  getCoachBot,
  getCoachBotContent,
  patchCoachBotContent,
} from "lib-frontend/modules/AxiosInstance";
import {
  CoachBotWithScenarioInfo,
  GetAllCoachBotContentResponse,
  GetCoachBotResponse,
} from "lib-fullstack/api/hubApiTypes";
import { AnalyticProcessingState } from "lib-fullstack/db";
import { useInterval } from "usehooks-ts";
import { CoachBotSectionStatus } from "./CoachBotLibrary";
import { useQueryClient } from "@tanstack/react-query";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";

type CoachBotWrapperProps = {
  setShouldBlockNav: (shouldBlock: boolean) => void;
  selectedBot: CoachBotWithScenarioInfo;
  setDeleteCoachBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSectionStatus: React.Dispatch<React.SetStateAction<CoachBotSectionStatus>>;
};

export const CoachBotWrapper = ({
  setShouldBlockNav,
  selectedBot,
  setDeleteCoachBotOpen,
  setSectionStatus,
}: CoachBotWrapperProps): JSX.Element => {
  const { defaultOrg, defaultOrgRefetching } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const [coachBotContent, setCoachBotContent] =
    React.useState<GetAllCoachBotContentResponse | null>(null);
  const [coachBot, setCoachBot] = React.useState<GetCoachBotResponse>(undefined);
  const [coachBotId, setCoachBotId] = React.useState<string>(undefined);
  // keep a local state copy so i can optimistically update this on success instead of re fetching the dfata every time
  const [coachBotLocal, setCoachBotLocal] =
    React.useState<GetAllCoachBotContentResponse>(undefined);

  const processingComplete = React.useRef<boolean>(false);
  const readyToPollContent = !processingComplete.current && defaultOrg?.id && coachBot?.botId;
  const [
    newlyUploadedCoachBotContent,
    setNewlyUploadedCoachBotContent,
    newlyUploadedCoachBotContentRef,
  ] = useStateRef<CoachBotUploadedFile[]>([]);
  const fetchAndSetCoachBotContent = async (isFromInterval?: boolean) => {
    try {
      const coachBotContent = await getCoachBotContent(defaultOrg?.id, coachBot?.botId);
      const stillIncompleteUploads = newlyUploadedCoachBotContentRef.current.filter(
        (content) => content.uploadStatus !== "done"
      );
      // filter out anything from botContent that exists in newly uploaded content but hasnt completed uploading to GCS yet
      const filteredBotContent = coachBotContent?.botContent.filter(
        (content) => !stillIncompleteUploads.some((x) => x.id === content.id)
      );
      // if im fetching from the interval (i.e. polling the bot content), i ONLY care about checking processing state
      // where-as, when i call it after a newlyUploadedFile completes, i want to filter the "done" files from the results
      const botContent = isFromInterval ? coachBotContent?.botContent : filteredBotContent;
      setCoachBotContent({ botContent });

      const contentStillProcessing = coachBotContent?.botContent?.some(
        (content) =>
          content.status === AnalyticProcessingState.PENDING ||
          content.status === AnalyticProcessingState.PROCESSING
      );

      // strip out any completed uploads, since they now will be in the coachbot content returned from the server
      if (
        !isFromInterval &&
        newlyUploadedCoachBotContentRef.current.find((x) => x.uploadStatus === "done")
      ) {
        setNewlyUploadedCoachBotContent(stillIncompleteUploads);
      }

      processingComplete.current = !contentStillProcessing;
      if (processingComplete.current) {
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrg?.id],
        });
      }
    } catch (er) {
      throw new Error(`Error fetching coachbot content for org ${defaultOrg?.id}: ${er}`);
    }
  };
  let uploadedContentTimeout: NodeJS.Timeout;
  const prevNewlyUploadedCoachBotContent = usePrevious(newlyUploadedCoachBotContent);
  React.useEffect(() => {
    // if a file has recently completed, manually fetch content again which will remove it from newlyUploadedContent
    // and add it to the coachbot content, along with it's processing state etc.
    if (
      newlyUploadedCoachBotContent.filter((x) => x.uploadStatus === "done").length >
      prevNewlyUploadedCoachBotContent?.filter((x) => x.uploadStatus === "done").length
    ) {
      if (uploadedContentTimeout) {
        clearTimeout(uploadedContentTimeout);
      }
      // whenever i remove a newlyUploadedContent, i want to start polling again
      // but wait 1 second so the user can see "100% complete" before it disappears
      uploadedContentTimeout = setTimeout(
        () =>
          fetchAndSetCoachBotContent().catch((er) => {
            console.error(`Error fetching coachbot content for org ${defaultOrg?.id}: ${er}`);
          }),
        1000
      );
    }
  }, [newlyUploadedCoachBotContent]);

  React.useEffect(() => {
    if (defaultOrg?.id && coachBot?.botId) {
      fetchAndSetCoachBotContent().catch((er) => {
        console.error(er);
      });
    }
    if (coachBotId !== coachBot?.botId) {
      setCoachBotId(coachBot?.botId);
    }
  }, [coachBot?.botId]);

  // if i have a local coachBotId but no actual coachBot object, im creating a new bot and should block nav until completion
  // i.e. until the actual coachBot object exists
  React.useEffect(() => {
    setShouldBlockNav(!!coachBotId && !coachBot?.botId);
  }, [coachBotId, coachBot?.botId]);
  // poll the coachbot content every few seconds to determine if it is finished processing or not (solely for UI purposes on this page)
  useInterval(
    () =>
      fetchAndSetCoachBotContent(true).catch((er) => {
        console.error(er);
      }),
    // Delay in milliseconds or null to stop it, once content finishes processing
    readyToPollContent ? COACHBOT_CONTENT_POLLING_INTERVAL : null
  );
  React.useEffect(() => {
    const fetchCoachBotData = async () => {
      try {
        const coachBot = await getCoachBot(defaultOrg?.id, selectedBot.botId);
        if (coachBot?.botId) {
          setCoachBot(coachBot);
        }
      } catch (er) {
        throw new Error(er);
      }
    };
    // refetch coachBot is i change orgs, or if i move from showing to not showing the creation wizard
    if (defaultOrg?.id) {
      fetchCoachBotData().catch((er) =>
        console.error(`Error fetching coachbot data for org ${defaultOrg?.id}: ${er}`)
      );
    }
  }, [defaultOrg?.id, selectedBot]);

  React.useEffect(() => {
    if (defaultOrgRefetching) {
      setSectionStatus(CoachBotSectionStatus.Library);
    }
  }, [defaultOrgRefetching]);

  React.useEffect(() => {
    setCoachBotLocal({ ...coachBotLocal, ...coachBot });
  }, [coachBot]);

  const handleDeleteCoachBotContent = async (contentId: string) => {
    try {
      await deleteCoachBotContent(defaultOrg?.id, coachBot?.botId, contentId);
      // optimistically update the local state on success to avoid having to refetch everything
      setCoachBotContent((prev) => {
        const updatedContent = !prev
          ? []
          : prev.botContent.filter((content) => content.id !== contentId);
        return { ...prev, botContent: updatedContent };
      });
    } catch (er) {
      console.error(`Error deleting coachbot content for org ${defaultOrg?.id}: ${er}`);
    }
  };

  const handleUpdateCoachBotContentFilename = async (contentId: string, filename: string) => {
    try {
      if (filename.length > 0) {
        await patchCoachBotContent(defaultOrg?.id, coachBotId, contentId, { filename });
        // optimistically update the local state on success to avoid having to refetch everything
        setCoachBotContent((prev) => {
          const updatedContent = !prev
            ? []
            : prev.botContent.map((content) => {
                if (content.id === contentId) {
                  return { ...content, filename };
                }
                return content;
              });
          return { ...prev, botContent: updatedContent };
        });
      }
    } catch (er) {
      console.error(`Error updating coachbot content for org ${defaultOrg?.id}: ${er}`);
    }
  };
  if (!coachBot) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ManageCoachBotTab
      orgId={defaultOrg?.id}
      coachBot={coachBot}
      setCoachBot={setCoachBot}
      scenarioInfo={selectedBot.scenarioInfo}
      handleDeleteCoachBotContent={handleDeleteCoachBotContent}
      handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilename}
      newlyUploadedCoachBotContent={newlyUploadedCoachBotContent}
      setNewlyUploadedCoachBotContent={setNewlyUploadedCoachBotContent}
      newlyUploadedCoachBotContentRef={newlyUploadedCoachBotContentRef}
      coachBotContent={coachBotContent}
      setCoachBotContent={setCoachBotContent}
      setDeleteCoachBotOpen={setDeleteCoachBotOpen}
      setSectionStatus={setSectionStatus}
    />
  );
};
