import React from "react";
import { Route as _Route } from "react-router";
import { RouteProps, useLocation } from "react-router-dom";

// Utils
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { CUSTOM_TITLE_PAGES } from "utils/paths";

/**
 * TODO: This component is no longer needed but is still being used to avoid breaking changes pre deploy 04/20/2023
 */
export default function YoodliRoute(
  props: RouteProps & {
    allowPublic?: boolean;
    pageTitle?: string;
  }
): React.ReactElement {
  const { pageTitle } = props;
  const location = useLocation();
  const { renderKey } = location?.state ?? {};

  let shouldSetTitle = true;
  // loop through the custom_titles so i can check partial matches of path into location.pathname
  // rather than location.pathname into path, which fails due to "/:slug" vs "/5dfsb3g"
  CUSTOM_TITLE_PAGES.forEach((path) => {
    if (location.pathname.includes(path)) {
      shouldSetTitle = false;
    }
  });
  // dont set the title here on pages with custom titles
  if (shouldSetTitle) {
    const _pageTitle =
      (pageTitle ? `${pageTitle} - ` : "") + (getStaticFullSiteConf().title ?? "Yoodli");
    document.title = _pageTitle;
  }

  return <_Route {...props} key={renderKey} />;
}
