import React from "react";
import { isChrome } from "react-device-detect";

// Custom hook for checking if the browser is chrome in nextjs
// This is to avoid hydration errors where the server doesnt match the client
export function useIsChrome(): boolean {
  const [stateIsChrome, setStateIsChrome] = React.useState(false);
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setStateIsChrome(isChrome);
    }
  }, []);
  return stateIsChrome;
}
