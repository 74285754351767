import React from "react";

// Components
import AddIcon from "@mui/icons-material/Add";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";
import { YoodliMultiEmail } from "lib-frontend/components/YoodliComponents/YoodliMultiEmail";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { GoalImprovementCard } from "./GoalImprovement/GoalImprovementCard";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramInfoTab } from "./ProgramInfo";
import { UserActivity } from "./UserActivity";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { ProgramResponse, UpdateProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import { ProgramState } from "lib-fullstack/utils/enums";

type OverviewSectionProps = {
  title: string | JSX.Element;
  subtitle?: string;
  content: JSX.Element;
  cta?: JSX.Element;
  tag?: JSX.Element;
  halfWidthTitle?: boolean;
};

export const OVERVIEW_SECTION_TITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "14px",
  fontFamily: "poppins",
  fontWeight: 700,
  width: { xs: "fit-content", lg: "100%" },
};

export const OVERVIEW_SECTION_SUBTITLE_SX = {
  color: getDynamicColor("purple3"),
  fontSize: "12px",
  fontFamily: "poppins",
};

const OverviewSection = ({
  title,
  subtitle,
  content,
  cta,
  halfWidthTitle,
  tag,
}: OverviewSectionProps): JSX.Element => {
  return (
    <Stack
      gap={{ xs: 3, md: 5 }}
      direction={halfWidthTitle ? "row" : "column"}
      sx={{
        p: 3,
        flex: 1,
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        justifyContent: "space-between",
        minHeight: { xs: "120px", md: "175px" },
      }}
    >
      <Stack
        sx={{
          width: halfWidthTitle ? "50%" : "100%",
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography component="div" sx={OVERVIEW_SECTION_TITLE_SX}>
            {title}
          </Typography>
          {cta}
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          {subtitle && <Typography sx={OVERVIEW_SECTION_SUBTITLE_SX}>{subtitle}</Typography>}
          {tag}
        </Stack>
      </Stack>
      {content}
    </Stack>
  );
};

type ProgramOverviewProps = {
  setActiveTab: (tab: ProgramInfoTab) => void;
  program: ProgramResponse;
  hubs: HubData[];
  scenarios: GetScenarioResponse[];
};

export const ProgramOverview = ({
  setActiveTab,
  program,
  hubs,
  scenarios,
}: ProgramOverviewProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const queryClient = useQueryClient();
  const { defaultOrg } = React.useContext(UserOrgContext);
  const [addingEvaluators, setAddingEvaluators] = React.useState(false);
  const [pendingEvaluatorEmails, setPendingEvaluatorEmails] = React.useState<string[]>([]);
  const [failedEvaluatorEmails, setFailedEvaluatorEmails] = React.useState<string[]>([]);

  React.useLayoutEffect(() => {
    Instrumentation.logProgramAdminOverviewViewed(defaultOrg.id, program.id);
  }, []);

  const updateProgramEvaluatorsMutation = useMutation({
    mutationFn: async (leader_emails: string[]) =>
      await updateOrgProgram(defaultOrg.id, program.id, { leader_emails }),
    onSuccess: async (ret: UpdateProgramResponse) => {
      if (ret.failed_leader_emails && ret.failed_leader_emails.length > 0) {
        setFailedEvaluatorEmails(ret.failed_leader_emails);
      } else {
        await queryClient.invalidateQueries({
          queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
        });
        if (addingEvaluators) {
          setAddingEvaluators(false);
          setPendingEvaluatorEmails([]);
        }
      }
    },
  });

  const getCombinedEmails = (newEmails: string[]): string[] => {
    const currLeaderSet = new Set(program?.leaders.map((leader) => leader.email));
    for (const email of newEmails) {
      currLeaderSet.add(email);
    }
    return Array.from(currLeaderSet);
  };

  const renderGroupsContent = () => {
    if (program.hub_ids.length) {
      const hubsToShow = program.hub_ids
        .slice(0, 3)
        .map((hubId) => hubs.find((hub) => hub.id === hubId));
      return (
        <Stack gap={2}>
          {hubsToShow.map((hub) => (
            <React.Fragment key={hub.id}>
              <Divider />
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {hub.name}
                </Typography>
                <Typography
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                  }}
                >
                  {hub.numMembers} member{hub.numMembers !== 1 ? "s" : ""}
                </Typography>
              </Stack>
            </React.Fragment>
          ))}
          {program.hub_ids.length > 3 && (
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontSize: "12px",
                fontFamily: "poppins",
                fontWeight: 700,
              }}
            >
              + {program.hub_ids.length - 3} more
            </Typography>
          )}
        </Stack>
      );
    } else {
      return (
        <Button
          onClick={() => setActiveTab(ProgramInfoTab.Groups)}
          startIcon={<AddIcon />}
          sx={{ m: "auto", fontSize: "14px" }}
        >
          Enroll groups
        </Button>
      );
    }
  };

  const renderScenariosContent = () => {
    if (program.plan_steps.length) {
      const scenariosToShow = program.plan_steps
        .slice(0, 3)
        .map((step) => scenarios.find((s) => s.id === step.scenario_id));

      return (
        <Stack gap={2}>
          {scenariosToShow.map((scenario, index) => (
            <React.Fragment key={`${scenario.id}-${index}`}>
              <Divider />
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {scenario.title}
                </Typography>
                <TemplateSubTypeChip
                  templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
                />
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => setActiveTab(ProgramInfoTab.Scenarios)}
        startIcon={<AddIcon />}
        sx={{ m: "auto", fontSize: "14px" }}
      >
        Add scenarios
      </Button>
    );
  };

  const renderUserStatsContent = () => {
    return program.user_activity.length ? (
      <Box sx={{ my: "auto" }}>
        <UserActivity userActivity={program.user_activity} />
      </Box>
    ) : (
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 600,
          textAlign: "center",
          m: "auto",
        }}
      >
        You will see user stats here once enrolled group members start their programs
      </Typography>
    );
  };

  const getFailedEvaluatorErrorMessage = () => {
    const plural = failedEvaluatorEmails.length > 1;
    const emailStr =
      failedEvaluatorEmails.length <= 2
        ? failedEvaluatorEmails.join(" and ")
        : `${failedEvaluatorEmails.slice(0, -1).join(", ")}, and ${failedEvaluatorEmails.slice(
            -1
          )}`;
    return `${emailStr} ${plural ? " were" : " was"} not ${
      plural ? "" : " a "
    } valid evaluator email${plural ? "s" : ""}. Please check that
      these emails are from users in your organization and try again.`;
  };

  const renderEvaluatorsContent = () => {
    if (addingEvaluators) {
      return (
        <Stack>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <YoodliMultiEmail
                pendingEmails={pendingEvaluatorEmails}
                setPendingEmails={setPendingEvaluatorEmails}
                errorEmails={failedEvaluatorEmails}
                setErrorEmails={setFailedEvaluatorEmails}
                maxEmailCount={5}
              />
            </Box>
            <Button
              disabled={pendingEvaluatorEmails.length === 0}
              variant="contained"
              onClick={() => {
                updateProgramEvaluatorsMutation.mutate(getCombinedEmails(pendingEvaluatorEmails));
              }}
              startIcon={
                updateProgramEvaluatorsMutation.isPending && (
                  <CircularProgress size="14px" sx={{ color: getDynamicColor("light1") }} />
                )
              }
            >
              Add now
            </Button>
          </Stack>
          {failedEvaluatorEmails.length > 0 && (
            <Typography
              sx={{
                color: getDynamicColor("redError"),
                fontFamily: "Poppins",
                fontSize: "12px",
                mt: 0.5,
              }}
            >
              {getFailedEvaluatorErrorMessage()}
            </Typography>
          )}
        </Stack>
      );
    }
    if (program?.leaders.length > 0) {
      return (
        <Stack gap={2}>
          {program.leaders.map((leader, index) => (
            <React.Fragment key={`${leader.id}-${index}`}>
              <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                {!isSmallScreen && (
                  <Grid item xs={5}>
                    <Typography
                      sx={{
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {leader.display_name}
                    </Typography>
                  </Grid>
                )}
                <Grid item sm={5}>
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {leader.email}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() =>
                      updateProgramEvaluatorsMutation.mutate(
                        program?.leaders
                          .map((l) => l.email)
                          .filter((email) => email !== leader.email)
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => setAddingEvaluators(true)}
        startIcon={<AddIcon />}
        sx={{ m: "auto", fontSize: "14px" }}
      >
        Add evaluators
      </Button>
    );
  };

  return (
    <Stack gap={{ xs: 2, md: 4 }}>
      <Stack direction="row" gap={4}>
        <OverviewSection
          title="Groups"
          subtitle={program.hub_ids.length ? "Enrolled in this program" : undefined}
          content={renderGroupsContent()}
          cta={
            program.hub_ids.length ? (
              <Button
                onClick={() => setActiveTab(ProgramInfoTab.Groups)}
                sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
              >
                View all
              </Button>
            ) : undefined
          }
        />
        {!isSmallScreen && (
          <OverviewSection
            title="User stats"
            subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
            content={renderUserStatsContent()}
            cta={
              program.hub_ids.length ? (
                <Button
                  onClick={() => setActiveTab(ProgramInfoTab.Groups)}
                  sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                >
                  View all
                </Button>
              ) : undefined
            }
          />
        )}
      </Stack>
      {program?.state !== ProgramState.Draft && <GoalImprovementCard programId={program.id} />}
      <OverviewSection
        title="Scenarios"
        subtitle={program.plan_steps.length ? "Included in this program" : undefined}
        content={renderScenariosContent()}
        cta={
          program.plan_steps.length ? (
            <Button
              onClick={() => setActiveTab(ProgramInfoTab.Scenarios)}
              sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
            >
              View all
            </Button>
          ) : undefined
        }
        tag={
          program.state !== ProgramState.Draft && (
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
              <DoNotDisturbIcon sx={{ width: 12, height: 12 }} />
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontSize: "10px",
                  fontFamily: "poppins",
                  fontWeight: 600,
                }}
              >
                No longer editable
              </Typography>
            </Stack>
          )
        }
      />
      {isSmallScreen && (
        <OverviewSection
          title="User stats"
          subtitle={program.user_activity.length ? "Overview of overall usage" : undefined}
          content={renderUserStatsContent()}
        />
      )}
      <OverviewSection
        title={
          <Stack
            direction="row"
            sx={{ gap: 2, alignItems: "center", justifyContent: "space-between" }}
          >
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <Typography>Evaluators</Typography>
              <YoodliTooltip title="These are human reviewers who can adjust AI scores on member attempts.">
                <InfoOutlined sx={{ width: 18, height: 18, cursor: "pointer" }} />
              </YoodliTooltip>
            </Stack>
            {!addingEvaluators && program?.leaders.length > 0 && (
              <Button variant="contained" onClick={() => setAddingEvaluators(true)}>
                Add new
              </Button>
            )}
          </Stack>
        }
        content={renderEvaluatorsContent()}
      />
    </Stack>
  );
};
