import React from "react";
import { useHistory } from "react-router";

// Components
import { Stack, Typography, Button, Divider } from "@mui/material";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubData } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgHubsQueryParams } from "lib-frontend/utils/queryParams";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { WebServerInternalPath } from "utils/paths";

type EnrolledGroupsProps = {
  program: ProgramResponse;
  hubs: HubData[];
};

export const EnrolledGroups = ({ program, hubs }: EnrolledGroupsProps): JSX.Element => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const [selectedGroups, setSelectedGroups] = React.useState<string[]>(
    program.hub_ids.length === 0 ? [] : undefined
  );

  const updateProgramGroupsMutation = useMutation({
    mutationFn: () => updateOrgProgram(defaultOrg.id, program.id, { hub_ids: selectedGroups }),
    onSuccess: async () => {
      if (selectedGroups.some((group) => !program.hub_ids.includes(group))) {
        Instrumentation.logProgramGroupAdded(defaultOrg.id, program.id);
      }
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
      setSelectedGroups(selectedGroups.length === 0 ? [] : undefined);
    },
  });

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={1}
        sx={{ justifyContent: "space-between", alignItems: "center", height: "37px" }}
      >
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "poppins",
          }}
        >
          Enroll groups
        </Typography>
        {selectedGroups ? (
          (program.hub_ids.length !== 0 || selectedGroups.length !== 0) && (
            <Button
              disabled={updateProgramGroupsMutation.isPending}
              onClick={() => {
                if (selectedGroups !== program.hub_ids) {
                  updateProgramGroupsMutation.mutate();
                } else {
                  setSelectedGroups(undefined);
                }
              }}
              variant="contained"
              sx={{ fontSize: "14px" }}
            >
              Save
            </Button>
          )
        ) : (
          <Button
            onClick={() => setSelectedGroups(program.hub_ids)}
            variant="outlined"
            sx={{ fontSize: "14px" }}
          >
            Modify
          </Button>
        )}
      </Stack>
      {selectedGroups ? (
        <YoodliMultiSelect
          isCheckboxSelect
          value={
            selectedGroups?.map((id) => ({
              label: defaultOrg.hubs.find((h) => h.id === id)?.name ?? "",
              value: id,
            })) ?? []
          }
          onChange={(_, vals: YoodliSelectOption[], _reason, detail) => {
            if (vals?.length === 0) {
              setSelectedGroups([]);
              return;
            }
            if (selectedGroups.includes(detail?.option?.value)) {
              setSelectedGroups(selectedGroups.filter((id) => id !== detail?.option?.value));
            } else {
              setSelectedGroups([...selectedGroups, detail?.option?.value]);
            }
          }}
          placeholder="Choose groups to enroll"
          options={defaultOrg.hubs.map((hub) => ({
            label: hub.name,
            value: hub.id,
            labelTag: `${hubs.find((h) => h.id === hub.id).numMembers} member${
              hubs.find((h) => h.id === hub.id).numMembers === 1 ? "" : "s"
            }`,
          }))}
        />
      ) : (
        <Stack
          gap={2}
          sx={{
            borderRadius: "8px",
            border: `1px solid ${getDynamicColor("dark3")}`,
            py: 3,
            px: { xs: 1, md: 2 },
          }}
        >
          {program.hub_ids?.map((groupId, index) => (
            <React.Fragment key={index}>
              <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                  sx={{
                    width: { xs: "100px", md: "200px" },
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {defaultOrg.hubs.find((h) => h.id === groupId)?.name}
                </Typography>
                <Typography
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                  }}
                >
                  {hubs.find((h) => h.id === groupId).numMembers} member
                  {hubs.find((h) => h.id === groupId).numMembers === 1 ? "" : "s"}
                </Typography>
                <Button
                  onClick={() =>
                    history.push(
                      `${WebServerInternalPath.ORG_GROUPS}?${OrgHubsQueryParams.GROUP_ID}=${groupId}`
                    )
                  }
                  sx={{ fontSize: "12px" }}
                >
                  View group
                </Button>
              </Stack>
              {index !== program.hub_ids.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
