import firebase from "firebase/app";
import { analyticsApiTypes, apiTypes, db } from "lib-fullstack";
import { DateTime } from "luxon";
import { isElectron } from "react-device-detect";

// Utils
import { genericAddRecallZoomBot, genericRemoveRecallZoomBot } from "../api/recall";
import { getSiteId } from "../utils/LiveSiteDocs";
import axios, { AxiosResponse } from "axios";
import DOMPurify from "isomorphic-dompurify";
import {
  AdminGetHubSpotDealByIdResponse,
  AdminGetHubSpotCompanyByIdResponse,
  AdminGetOrgFieldType,
  AdminGetOrgListResponse,
  AdminGetOrgListSortOption,
  AdminGetOrgResponse,
  AdminGetUserListResponse,
  AdminUpdateOrgRequest,
  AdminUpdateOrgResponse,
  AdminCreateHubSpotYoodliInstanceRequest,
  AdminCreateHubSpotYoodliInstanceResponse,
} from "lib-fullstack/api/adminApiTypes";
import {
  AnalyticsRequest,
  ConcisenessResponse,
  SentenceStartersResponse,
} from "lib-fullstack/api/analyticsApiTypes";
import {
  CreateSpeechRequestData,
  CreateSpeechResponse,
  CreateSupportMessageResponse,
  DownloadSpeechResponse,
  GetUserFieldType,
  GetUserResponse,
  PatchUserRequest,
  UpdateSpeechStatesRequest,
  UploadFileType,
  UploadSpeechRequest,
  UploadSpeechResponse,
  GetSpeechQueryParams,
  GetSpeechResponse,
  UpdateSpeechShareRequest,
} from "lib-fullstack/api/apiTypes";
import { UserAuthenticatedRequest } from "lib-fullstack/api/authApiTypes";
import { CompleteUserCreationRequest } from "lib-fullstack/api/authApiTypes";
import { GetSignInOptionsResponse, GetOrgAccessInfoResponse } from "lib-fullstack/api/authApiTypes";
import {
  BotContentFile,
  ContentsViewResponse,
  CourseResponse,
  CourseVideoResponse,
  CreateCoachBotContentResponse,
  CreateCoachBotResponse,
  CreateCourseVideoResponse,
  CreateDemoVideoResponse,
  CreateOrgLogoResponse,
  CreateScenarioResponse,
  DemoVideoResponse,
  GetAllCoachBotContentResponse,
  ListScenariosResponse,
  ListScenariosMemberViewResponse,
  GetBotBrandingResponse,
  GetCoachBotResponse,
  HubInviteResponse,
  HubUpdateRequest,
  InterviewBankResponse,
  OrgEmailBrandingResponse,
  OrgSignUpNoticeResponse,
  OrgUserUpsetRequest,
  PatchCoachBotContentRequest,
  PatchCoachBotRequest,
  PatchScenarioRequest,
  UpdateCourseVideoRequest,
  UpdateDemoVideoRequest,
  UpsetOrgEmailBrandingRequest,
  UpsetPaygOrgSubscriptionResponse,
  GetScenarioLimitedResponse,
  ListCoachBotsResponse,
} from "lib-fullstack/api/hubApiTypes";
import {
  AddOrgUsersRequest,
  AddOrgUsersResponse,
  CreateOrgV2Request,
  GetHubMemberListQueryParams,
  GetOrgInviteListQueryParams,
  GetOrgMemberListQueryParams,
  HubV2Response,
  OrgInviteAction,
  OrgInviteListResponse,
  OrgMemberListResponse,
  OrgV2ListResponse,
  OrgV2Response,
  UpdateOrgV2Request,
} from "lib-fullstack/api/orgApiTypes";
import {
  PlanCheckoutResponse,
  PlanDataRedactionConfigRequest,
  PlanDataRedactionConfigResponse,
  PlanSettingsActionResponse,
} from "lib-fullstack/api/planApiTypes";
import {
  CreateProgramRequest,
  EvaluateSpeechRequest,
  GetProgramUserResponse,
  ListProgramUsersQueryParams,
  ListProgramUsersResponse,
  ProgramMemberViewItem,
  ProgramResponse,
  SendProgramReminderRequest,
  UpdateProgramRequest,
  UpdateProgramResponse,
} from "lib-fullstack/api/programApiTypes";
import {
  CreateReportRequest,
  CreateReportResponse,
  GetReportResponse,
  ListReportsQueryParams,
  ListReportsResponse,
} from "lib-fullstack/api/reportApiTypes";
import {
  PersonasMemberViewResponse,
  PersonaResponse,
  PersonasResponse,
  CreatePersonaRequest,
  UpdatePersonaRequest,
  CreatePersonaProfilePictureResponse,
  CustomGoalsResponse,
  PersonaProfilePicturesResponse,
  CreateCustomGoalRequest,
  CustomGoalResponse,
  UpdateCustomGoalRequest,
  CustomGoalsMemberViewResponse,
  CopyScenarioRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { IEnvSpec, ReactAppEnvironment, getClientEnvConfig } from "lib-fullstack/client_env";
import { CalendarAction } from "lib-fullstack/utils/calendar";
import { HubRole, ApiErrorCode, OBQ1Option } from "lib-fullstack/utils/enums";
import {
  PricingExperiment,
  UsagePlanDuration,
  UsagePlanType,
} from "lib-fullstack/utils/pricingTypes";
import { ReferralProgram } from "lib-fullstack/utils/referralProgramUtils";

let clientEnv = getClientEnvConfig();

let AxiosInstance = getAxiosInstance(clientEnv);

function getBaseUrl(clientEnvParam: IEnvSpec<ReactAppEnvironment>): string {
  if (isElectron) {
    // Electron page is loaded from file:// and dooes not have window.location.
    // It always points to app site, and it may not work in white label sites.
    return `${clientEnvParam.url.WEB_SERVER}${clientEnvParam.url.DB}`;
  }

  // since nextjs includes lib-frontend and is a combo of server side a client side
  // make sure this logic block only runs client side
  if (typeof window !== "undefined") {
    // For TMI whitelabel site only, use the toastmasters.* URL instead of the app.* URL.
    if (window?.location?.hostname.includes("toastmasters")) {
      return `${window?.location?.protocol}//${window?.location?.host}${clientEnvParam.url.DB}`;
    }

    // if on pagekite, use that instead of the web_server constant
    if (clientEnv.envName === "local" && window?.location?.hostname?.includes("pagekite.me")) {
      return `${window?.location?.protocol}//${window?.location?.hostname}${clientEnvParam.url.DB}`;
    }
  }

  return `${clientEnvParam.url.WEB_SERVER}${clientEnvParam.url.DB}`;
}

function getAxiosInstance(clientEnvParam: IEnvSpec<ReactAppEnvironment>) {
  clientEnv = getClientEnvConfig({ reactAppEnvironment: clientEnvParam.envName });
  return axios.create({
    baseURL: getBaseUrl(clientEnv),
    headers: {
      "content-type": "application/json",
    },
  });
}

export function initAxiosInstance(clientEnvParam: IEnvSpec<ReactAppEnvironment>): void {
  AxiosInstance = getAxiosInstance(clientEnvParam);
}

/**
 * API headers for instances where only the logged in user can only make the API request
 */
export async function AuthorizationAPIHeaders(): Promise<{
  "Content-Type": string;
  Authorization?: string;
}> {
  const token = await firebase.auth().currentUser?.getIdToken();
  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
}

/**
 * Create speech doc in the database.
 * @returns ref if succeed. null if the limit reaches. Throws on other errors.
 */
export async function createSpeech(
  speechData: CreateSpeechRequestData
): Promise<CreateSpeechResponse | null> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const response = await AxiosInstance.post(
      "/speech",
      { data: speechData },
      { headers: headers }
    );
    return response.data as CreateSpeechResponse;
  } catch (error) {
    if (error.response?.data?.code === ApiErrorCode.QuotaExceeded) {
      return null;
    }
    throw error;
  }
}

/**
 * Delete speech in the database.
 */
export async function deleteSpeech(speechId: string, reason: db.DeletedReason): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  await AxiosInstance.delete(`/speech/${speechId}?reason=${reason}`, { headers: headers });
}

/**
 * Create comment doc in the database.
 * @returns ref if succeed. null if the limit reaches. Throws on other errors.
 */
export async function createComment(
  speechOwnerId: string,
  speechId: string,
  commentData: db.SpeechComment
): Promise<CreateSpeechResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const response = await AxiosInstance.post(
      `/speech/${speechId}/comments`,
      {
        speechOwnerId: speechOwnerId,
        data: commentData,
      },
      { headers: headers }
    );
    return response.data as CreateSpeechResponse;
  } catch (error) {
    if (error.response?.data?.code === ApiErrorCode.QuotaExceeded) {
      return null;
    }
    throw error;
  }
}

/**
 * Kicks off an async-transcription for a media for the specified user.
 */
export async function InitiateAsyncTranscription(documentId: string): Promise<AxiosResponse> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/speech/async-transcribe",
    { documentId, siteId: getSiteId() },
    {
      headers: headers,
    }
  );
}

/**
 * Fetches the path to a document using only its slug (short name)
 */
export async function getPathForSlug(slug: string): Promise<string | undefined> {
  try {
    return (await AxiosInstance.get(`/slug/${slug}`)).data.docPath;
  } catch {
    // API call ends up with an exception from 404 response, which means no document found.
    return undefined;
  }
}

/**
 * Gets a signed download URL for a speech
 */
export async function GetSpeechDownloadRedirectorUrl(speechId: string): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const response = await AxiosInstance.get<unknown, AxiosResponse<DownloadSpeechResponse>>(
    `/speech/${speechId}/download`,
    {
      headers: headers,
    }
  );
  return response.data.url;
}

export async function InitiateResumableUpload(
  speechId: string,
  type: UploadFileType,
  fileExtension: string | undefined
): Promise<UploadSpeechResponse> {
  const headers = await AuthorizationAPIHeaders();
  const response = await AxiosInstance.post<
    UploadSpeechRequest,
    AxiosResponse<UploadSpeechResponse>
  >(
    `/speech/${speechId}/upload`,
    {
      type: type,
      fileExtension: fileExtension,
    },
    {
      headers: headers,
    }
  );
  return response.data;
}

export async function getSpeechStorage(
  speechId: string,
  ownerId: string
): Promise<apiTypes.SpeechStorageResponse> {
  const headers = await AuthorizationAPIHeaders();
  const response = await AxiosInstance.get<unknown, AxiosResponse<apiTypes.SpeechStorageResponse>>(
    `/speech/${speechId}/storage?owner_id=${ownerId}`,
    {
      headers: headers,
    }
  );
  return response.data;
}

export async function RecalculateAnalyticsOnLoad(
  speechRef: firebase.firestore.DocumentReference
): Promise<void> {
  if (!firebase.auth().currentUser) {
    return;
  }
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/recalculate-analytics-on-load",
    { speechRefPath: speechRef.path },
    {
      headers: headers,
    }
  );
}

export async function addRecallZoomBot(
  userRef: db.Ref<db.User>,
  zoomUrl: string,
  joinMethod: db.RecallBotJoinMethod
): Promise<AxiosResponse> {
  const headers = await AuthorizationAPIHeaders();

  return genericAddRecallZoomBot(userRef, zoomUrl, joinMethod, AxiosInstance, headers);
}

export async function removeRecallZoomBot(botId: string): Promise<void> {
  const headers = await AuthorizationAPIHeaders();

  return genericRemoveRecallZoomBot(botId, AxiosInstance, headers);
}

export async function addGoogleCalendar(
  authCode: string,
  mode: db.CalendarMode,
  redirectUri?: string
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post<apiTypes.AddCalendarRequest, void>(
      "/calendar/addGoogleCalendar",
      {
        authCode: authCode,
        siteId: getSiteId(),
        mode: mode,
        redirectUri: redirectUri,
      } as apiTypes.AddCalendarRequest,
      { headers: headers }
    );
  } catch (err) {
    console.log(err);
  }
}

export async function sendRequestAccessEmail(
  speechAuthorId: string,
  currentUserName: string,
  returnUrl: string
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const siteId = getSiteId();
  return await AxiosInstance.post<apiTypes.RequestAccessRequest, void>(
    "/auth/request-speech-access",
    {
      speechAuthorId,
      currentUserName,
      siteId,
      returnUrl,
    } as apiTypes.RequestAccessRequest,
    { headers: headers }
  ).catch((err) => {
    console.log("Error sending request access email :", err);
  });
}

export async function addOutlookCalendar(
  code: string,
  redirectPathname: string,
  mode: db.CalendarMode
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return await AxiosInstance.post<apiTypes.AddCalendarRequest, void>(
    "/calendar/addOutlookCalendar",
    {
      authCode: code,
      redirectPathname: redirectPathname,
      siteId: getSiteId(),
      mode: mode,
    } as apiTypes.AddCalendarRequest,
    { headers: headers }
  );
}

export async function setCredentials(authCode: string, redirectUri?: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post<apiTypes.AddCalendarRequest, void>(
      "/calendar/setCredentials",
      {
        authCode: authCode,
        siteId: getSiteId(),
        redirectUri: redirectUri,
      } as apiTypes.AddCalendarRequest,
      { headers: headers }
    );
  } catch (err) {
    console.log(err);
  }
}

export async function getZoomAppInstallLink(): Promise<apiTypes.ZoomAppOAuthInstallLinkResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<
    apiTypes.ZoomAppOAuthInstallLinkRequest,
    AxiosResponse<apiTypes.ZoomAppOAuthInstallLinkResponse>
  >("/zoomapp/install_link", {
    data: { siteId: getSiteId() },
    headers: headers,
  });
  if (ret.status != 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data as apiTypes.ZoomAppOAuthInstallLinkResponse;
}

export async function postZoomAppFinalize(
  code: string,
  verifier: string
): Promise<apiTypes.ZoomAppOAuthFinalizeResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<
    apiTypes.ZoomAppOAuthFinalizeRequest,
    AxiosResponse<apiTypes.ZoomAppOAuthFinalizeResponse>
  >("/zoomapp/finalize", { siteId: getSiteId(), code, verifier }, { headers: headers });
  if (ret.status != 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data as apiTypes.ZoomAppOAuthFinalizeResponse;
}

export async function syncCalendarEvents(): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/calendar/syncCalendarEvents",
    {
      siteId: getSiteId(),
    } as apiTypes.SyncCalendarEventsRequest,
    { headers: headers }
  );
}

export async function disconnectCalendar(calendarRef: db.Ref<db.Calendar>): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/calendar/deleteCalendar",
    {
      calendarRefPath: db.getRefPath(calendarRef),
    } as apiTypes.DeleteCalendarRequest,
    { headers: headers }
  );
}

export async function changeDefaultJoin(
  calendarRef: db.Ref<db.Calendar>,
  defaultJoin: db.BotAutoJoinPreference
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/calendar/changeDefaultJoin",
    {
      calendarRefPath: db.getRefPath(calendarRef),
      defaultJoin: defaultJoin,
    } as apiTypes.ChangeDefaultJoinRequest,
    { headers: headers }
  );
}

export async function changeCalendarMode(
  calendarRef: db.Ref<db.Calendar>,
  mode: db.CalendarMode
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/calendar/changeCalendarMode",
    {
      calendarRefPath: db.getRefPath(calendarRef),
      mode: mode,
    } as apiTypes.ChangeCalendarModeRequest,
    { headers: headers }
  );
}

export async function changeJoinOverride(
  eventRef: db.Ref<db.CalendarEvent>,
  joinOverride: boolean
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/calendar/changeJoinOverride",
    {
      eventRefPath: db.getRefPath(eventRef),
      joinOverride: joinOverride,
    },
    { headers: headers }
  );
}

//#region Sign-in Options

export async function ssoSigninWithJWT(
  req: Omit<apiTypes.ToastmastersSsoSigninWithJWTRequest, "siteId">
): Promise<apiTypes.ToastmastersSsoSigninWithJWTResponse> {
  const ret = await AxiosInstance.post("/auth/sso_sign_in", {
    siteId: getSiteId(),
    ...req,
  } as apiTypes.ToastmastersSsoSigninWithJWTRequest);

  if (ret.status != 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data as apiTypes.ToastmastersSsoSigninWithJWTResponse;
}

export async function getSignInOptions(emailDomain: string): Promise<GetSignInOptionsResponse> {
  const ret = await AxiosInstance.get<GetSignInOptionsResponse>(
    `/auth/sign_in_options?email_domain=${emailDomain}`
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function getOrgAccessInfo(options: {
  orgId?: string;
  hubId?: string;
}): Promise<GetOrgAccessInfoResponse> {
  try {
    let path: string;
    if (options.orgId) {
      path = `/auth/org_access_info?org_id=${options.orgId}`;
    } else if (options.hubId) {
      path = `/auth/org_access_info?hub_id=${options.hubId}`;
    } else {
      throw new Error("orgId or hubId is required");
    }
    const ret = await AxiosInstance.get(path);
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgAccessInfo request, status " + err.response?.status);
  }
}
//#endregion SSO Sign-in Options

export async function createAuthToken(code: string, idToken: string): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  await AxiosInstance.post(
    "/auth/create-auth-token",
    {
      code,
      idToken,
    } as apiTypes.createAuthTokenRequest,
    { headers: headers }
  );
}

export async function updateUserDashboardOptions(
  userRef: db.Ref<db.User>,
  dayRange: db.DashboardRange,
  hoistedAnalytics: (db.AggregateAnalyticTypes | db.UnitCountAnalyticEnum)[]
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  await AxiosInstance.post(
    "/dashboard/update-options",
    {
      dayRange,
      hoistedAnalytics,
    },
    { headers: headers }
  );
  await db.ts.update<db.UserDocMain>(userRef, {
    dashboardOptions: {
      ...(dayRange && { dayRange }),
      ...(hoistedAnalytics && { hoistedAnalytics }),
    },
  });
}

export async function completeUserCreation(
  userId: string,
  referralSlug?: string,
  referralProgram?: string,
  referralProgramExtraData?: db.ReferralProgramExtraData,
  hubInviteDetails?: {
    inviteId: string;
    hubId: string;
  },
  orgInviteV2Id?: string
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const homeTimezone = DateTime.local().zoneName;

  await AxiosInstance.post(
    "/auth/complete_user_creation",
    {
      userId,
      homeTimezone,
      referralSlug,
      referralProgram,
      referralProgramExtraData,
      hubInviteDetails,
      siteId: getSiteId(),
      orgInviteV2Id: orgInviteV2Id,
    } as CompleteUserCreationRequest,
    { headers: headers }
  );
}

export async function userAuthenticated(
  referralProgram?: ReferralProgram,
  referralProgramExtraData?: db.ReferralProgramExtraData,
  hubInviteDetails?: {
    inviteId: string;
    hubId: string;
  }
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const body: UserAuthenticatedRequest = {
    home_timezone: DateTime.local().zoneName,
  };
  if (referralProgram) {
    body.referral_program = referralProgram;
  }
  if (referralProgramExtraData) {
    body.referral_program_extra_data = referralProgramExtraData;
  }
  if (hubInviteDetails) {
    body.invited_hub = { invite_id: hubInviteDetails.inviteId, hub_id: hubInviteDetails.hubId };
  }
  await AxiosInstance.post("/users/me/authenticated", body, { headers: headers });
}

export async function requestDashboardAnalytics(
  timezone: string, // e.g. "America/Los_Angeles", wherever the user is
  startTime: string, // utc-converted datestring in format "2021-01-01T00:00:00.000Z"
  endTime: string, // utc-converted datestring in format "2021-01-01T00:00:00.000Z"
  analytics: (db.AggregateAnalyticTypes | db.UnitCountAnalyticEnum)[], // whatever analytics the user has hoisted
  targetUserId: string | null,
  tagFilter?: { key: db.TagType; value: string }
): Promise<apiTypes.DashboardResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<apiTypes.DashboardResponse>(
    "/dashboard/analytics",
    {
      timezone: timezone,
      startTime: startTime,
      endTime: endTime,
      analytics: analytics,
      targetUserId: targetUserId,
      tagFilter: tagFilter,
    },
    { headers: headers }
  );
  if (ret.status != 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Request analytics for the homepage report card.
 * @param timezone e.g. "America/Los_Angeles", wherever the user is
 * @param startTime utc-converted datestring in format "2021-01-01T00:00:00.000Z"
 * @param endTime utc-converted datestring in format "2021-01-01T00:00:00.000Z"
 * @returns
 */
export async function requestReportCard(
  startTime: string,
  endTime: string
): Promise<apiTypes.ReportCardResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<apiTypes.ReportCardResponse>(
    "/reportCard/analytics",
    {
      startTime,
      endTime,
    },
    { headers: headers }
  );
  if (ret.status != 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Same as sendVerificaitonEmail, this sends a verification email but *only* awaits
 * the request to be sent, and does not wait for the response
 */
export async function optimisticSendVerificationEmail(continueUrl: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    void AxiosInstance.post(
      "/auth/send_verification_email",
      { continueUrl: continueUrl, siteId: getSiteId() },
      { headers: headers }
    );
  } catch (err) {
    throw Error(`Failed to start sending verification email:  ${err}`);
  }
}

export async function sendVerificationEmail(continueUrl: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      "/auth/send_verification_email",
      { continueUrl: continueUrl, siteId: getSiteId() },
      { headers: headers }
    );
    if (!ret.data.success) {
      throw Error(ret.data.error);
    }
    return ret.data;
  } catch (err) {
    if (err.message === "auth/internal-error") {
      throw Error(err.message);
    }
    throw Error(`Bad api request, status ${err.response.status}, error ${err.message}`);
  }
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post(
      "/auth/send_password_reset_email",
      { email: email, siteId: getSiteId() },
      { headers: headers }
    );
  } catch (err) {
    throw Error(`Bad api request, status ${err.response.status}, error ${err.message}`);
  }
}

export async function getUser(fields: GetUserFieldType[]): Promise<GetUserResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<GetUserResponse>(`/users/me?fields=${fields.join(",")}`, {
    headers: headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export const patchUser = async (request: PatchUserRequest): Promise<boolean> => {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch<void>(`/users/me`, request, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return true;
};

// #region Support

export const createSupportMessage = async (
  subject: string,
  message: string
): Promise<CreateSupportMessageResponse> => {
  const headers = await AuthorizationAPIHeaders();
  // sanitize the message before even sending it to the server, since it gets rendered to intercom in html
  // intercom does already sanitize input, but better to be double sure than rely on a black box
  const sanitizedMessage = DOMPurify.sanitize(message);
  const ret = await AxiosInstance.post(
    "/support/tickets",
    {
      subject,
      message: sanitizedMessage,
    },
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
};
// #endregion Support

// #region CoachBot
export const createCoachBot = async (
  orgId: string,
  coachBotName: string,
  creatorEmail: string,
  isActive?: boolean
): Promise<CreateCoachBotResponse> => {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<CreateCoachBotResponse>(
    `/orgs/${orgId}/coachbot`,
    { name: coachBotName, creatorEmail, isActive: isActive ?? false },
    { headers: headers }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
};

export const updateCoachBot = async (
  orgId: string,
  botId: string,
  options: PatchCoachBotRequest
): Promise<boolean> => {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch<void>(`/orgs/${orgId}/coachbot/${botId}`, options, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return true;
};

export const getCoachBot = async (
  orgId: string,
  coachbotId: string
): Promise<GetCoachBotResponse> => {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.get<GetCoachBotResponse>(
      `/orgs/${orgId}/coachbot/${coachbotId}`,
      {
        headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBot request, status " + err.response?.status);
  }
};

export async function getAllCoachBots(orgId: string): Promise<ListCoachBotsResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.get<ListCoachBotsResponse>(`/orgs/${orgId}/coachbot`, {
      headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getAllCoachBot request, status " + err.response?.status);
  }
}

export async function deleteCoachbot(orgId: string, coachbotId: string): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.delete(`/orgs/${orgId}/coachbot/${coachbotId}`, {
      headers,
    });
    return ret?.status;
  } catch (err) {
    throw Error("Bad deleteCoachbot request, status " + err.response?.status);
  }
}

export async function createCoachBotContent(
  orgId: string,
  botId: string,
  files: BotContentFile[]
): Promise<CreateCoachBotContentResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.post<CreateCoachBotContentResponse>(
      `/orgs/${orgId}/coachbot/${botId}/contents`,
      {
        files,
      },
      {
        headers: headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad createCoachBotContent request, status " + err.response?.status);
  }
}

export async function deleteCoachBotContent(
  orgId: string,
  botId: string,
  contentId: string
): Promise<CreateCoachBotContentResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.delete<CreateCoachBotContentResponse>(
      `/orgs/${orgId}/coachbot/${botId}/contents/${contentId}`,
      {
        headers: headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad deleteCoachBotContent request, status " + err.response?.status);
  }
}

export const patchCoachBotContent = async (
  orgId: string,
  botId: string,
  contentId: string,
  options: PatchCoachBotContentRequest
): Promise<boolean> => {
  const headers = await AuthorizationAPIHeaders();
  try {
    await AxiosInstance.patch<void>(
      `/orgs/${orgId}/coachbot/${botId}/contents/${contentId}`,
      options,
      {
        headers: headers,
      }
    );
    return true;
  } catch (err) {
    throw Error("Bad patchCoachBotContent request, status " + err.response?.status);
  }
};

export const getCoachBotContent = async (
  orgId: string,
  botId: string
): Promise<GetAllCoachBotContentResponse> => {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.get<GetAllCoachBotContentResponse>(
      `/orgs/${orgId}/coachbot/${botId}/contents`,
      {
        headers: headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBotContent request, status " + err.response?.status);
  }
};

export async function getCoachBotBranding(
  orgId: string,
  botId: string
): Promise<GetBotBrandingResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.get<GetBotBrandingResponse>(
      `/orgs/${orgId}/coachbot/${botId}/branding`,
      {
        headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBotBranding request, status " + err.response?.status);
  }
}
// #endregion CoachBot

// #region Scenarios
export async function listScenarios(orgId = "default"): Promise<ListScenariosResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<ListScenariosResponse>(`/orgs/${orgId}/scenarios`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function listUserScenarios(): Promise<ListScenariosResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<ListScenariosResponse>("/users/me/scenarios", {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function getScenarioLimited(
  orgId: string,
  scenarioId: string
): Promise<GetScenarioLimitedResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<GetScenarioLimitedResponse>(
    `/orgs/${orgId}/scenarios/${scenarioId}`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function getUserScenarioLimited(
  scenarioId: string
): Promise<GetScenarioLimitedResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<GetScenarioLimitedResponse>(
    `/users/me/scenarios/${scenarioId}`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function listScenariosMemberView(
  orgId = "default"
): Promise<ListScenariosMemberViewResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<ListScenariosMemberViewResponse>(
    `/orgs/${orgId}/scenarios_member_view`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
// no user scenario member view since the above route returns both org and user scenarios

// TODO 2024-04-30: had to change this route for the release since we accidentally duplicated the post route for fetching and creating
// will need to revert post deploy (Issue #9400)
export async function createScenario(
  orgId: string,
  creatorEmail: string,
  title: string,
  scenarioIdToCopy: string
): Promise<CreateScenarioResponse> {
  const headers = await AuthorizationAPIHeaders();
  const body = {
    title,
    creatorEmail,
    scenarioIdToCopy,
  };
  const ret = await AxiosInstance.post<CreateScenarioResponse>(`/orgs/${orgId}/scenarios`, body, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function createUserScenario(
  creatorEmail: string,
  title: string,
  scenarioIdToCopy: string
): Promise<CreateScenarioResponse> {
  const headers = await AuthorizationAPIHeaders();
  const body = {
    title,
    creatorEmail,
    scenarioIdToCopy,
  };
  const ret = await AxiosInstance.post<CreateScenarioResponse>(`/users/me/scenarios`, body, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function patchScenario(
  orgId: string,
  scenarioId: string,
  body: PatchScenarioRequest
): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch(`/orgs/${orgId}/scenarios/${scenarioId}`, body, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.status;
}
export async function patchUserScenario(
  scenarioId: string,
  body: PatchScenarioRequest
): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch(`/users/me/scenarios/${scenarioId}`, body, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.status;
}

export async function deleteScenario(orgId: string, scenarioId: string): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/orgs/${orgId}/scenarios/${scenarioId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.status;
}
export async function deleteUserScenario(scenarioId: string): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/users/me/scenarios/${scenarioId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.status;
}
// #endregion Scenarios

// #region Personas
export async function createPersona(orgId: string, persona: CreatePersonaRequest): Promise<string> {
  const headers = await AuthorizationAPIHeaders();

  const ret = await AxiosInstance.post<PersonaResponse>(
    `/orgs/${orgId}/personas`,
    { ...persona },
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}
export async function createUserPersona(persona: CreatePersonaRequest): Promise<string> {
  const headers = await AuthorizationAPIHeaders();

  const ret = await AxiosInstance.post<PersonaResponse>(
    "/users/me/personas",
    { ...persona },
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function updatePersona(
  orgId: string,
  personaId: string,
  body: UpdatePersonaRequest
): Promise<string> {
  const headers = await AuthorizationAPIHeaders();

  const ret = await AxiosInstance.patch<PersonaResponse>(
    `/orgs/${orgId}/personas/${personaId}`,
    body,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}
export async function updateUserPersona(
  personaId: string,
  body: UpdatePersonaRequest
): Promise<string> {
  const headers = await AuthorizationAPIHeaders();

  const ret = await AxiosInstance.patch<PersonaResponse>(`/users/me/personas/${personaId}`, body, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function deletePersona(orgId: string, personaId: string): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete<PersonaResponse>(`/orgs/${orgId}/personas/${personaId}`, {
    headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}
export async function deleteUserPersona(personaId: string): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete<PersonaResponse>(`/users/me/personas/${personaId}`, {
    headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function getAllPersonasAdmin(orgId: string): Promise<PersonasResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonasResponse>(`/orgs/${orgId}/personas`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function getAllPersonasUser(): Promise<PersonasResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonasResponse>("/users/me/personas", {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

// use this for user personas member view (aka in recorder view). it gets org and user ones.
export async function getAllPersonasMember(orgId: string): Promise<PersonasMemberViewResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonasMemberViewResponse>(
    `/orgs/${orgId}/persona_member_view`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function getPersonaById(orgId: string, personaId: string): Promise<PersonaResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonaResponse>(`/orgs/${orgId}/personas/${personaId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function getUserPersonaById(personaId: string): Promise<PersonaResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonaResponse>(`/users/me/personas/${personaId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function getPublicPersonaProfilePicture(profilePictureId: string): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<string>(`/persona_profile_picture/${profilePictureId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function listPersonaProfilePictures(
  orgId: string
): Promise<PersonaProfilePicturesResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonaProfilePicturesResponse>(
    `/orgs/${orgId}/persona_profile_pictures`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function listUserPersonaProfilePictures(): Promise<PersonaProfilePicturesResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<PersonaProfilePicturesResponse>(
    `/users/me/persona_profile_pictures`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function createPersonaProfilePicture(
  orgId: string,
  extension: string,
  personaId: string | null
): Promise<CreatePersonaProfilePictureResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<CreatePersonaProfilePictureResponse>(
    `/orgs/${orgId}/persona_profile_pictures`,
    { extension, persona_id: personaId },
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}
export async function createUserPersonaProfilePicture(
  extension: string,
  personaId: string | null
): Promise<CreatePersonaProfilePictureResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<CreatePersonaProfilePictureResponse>(
    `/users/me/persona_profile_pictures`,
    { extension, persona_id: personaId },
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function deletePersonaProfilePicture(
  orgId: string,
  profilePictureId: string
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(
    `/orgs/${orgId}/persona_profile_pictures/${profilePictureId}`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
}
export async function deleteUserPersonaProfilePicture(profilePictureId: string): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/users/me/persona_profile_pictures/${profilePictureId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
}

// #endregion Personas

// #region CustomGoals
export async function getAllCustomGoals(orgId: string): Promise<CustomGoalsResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<CustomGoalsResponse>(`/orgs/${orgId}/custom_goals`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function createCustomGoal(
  orgId: string,
  customGoal: CreateCustomGoalRequest
): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post<CustomGoalResponse>(
    `/orgs/${orgId}/custom_goals`,
    { ...customGoal },
    { headers }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function deleteCustomGoal(orgId: string, goalId: string): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete<CustomGoalResponse>(
    `/orgs/${orgId}/custom_goals/${goalId}`,
    {
      headers,
    }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function getCustomGoalbyId(
  orgId: string,
  goalId: string
): Promise<CustomGoalResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<CustomGoalResponse>(`/orgs/${orgId}/custom_goals/${goalId}`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function updateCustomGoal(
  orgId: string,
  goalId: string,
  body: UpdateCustomGoalRequest
): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch<CustomGoalResponse>(
    `/orgs/${orgId}/custom_goals/${goalId}`,
    body,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret?.data?.id;
}

export async function listCustomGoalsMemberView(
  orgId = "default"
): Promise<CustomGoalsMemberViewResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<CustomGoalsMemberViewResponse>(
    `/orgs/${orgId}/custom_goals_member_view`,
    {
      headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

// #endregion CustomGoals

export async function inviteUserToHub(
  hubId: string,
  email: string,
  role: HubRole,
  ignoreDuplicateInvites?: boolean
): Promise<HubInviteResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await AxiosInstance.post(
      `/hubs/${hubId}/invites`,
      { email, role, ignoreDuplicateInvites },
      { headers: headers }
    );
    if (Math.floor(ret.status / 100) !== 2) {
      throw Error("Bad api request, status " + ret.status);
    }
    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error) {
      if (err?.response?.data?.code === ApiErrorCode.QuotaExceeded) {
        throw Error("Sorry, you've reached the maximum number of members you can invite!");
      }

      throw Error("Sorry, an unexpected error has occurred!");
    }
    throw err;
  }
}

export async function acceptInviteToHub(hubId: string, inviteId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post(
      `/hubs/${hubId}/invites/${inviteId}`,
      { action: "accept" },
      { headers: headers }
    );
  } catch (err) {
    if (err.response?.data?.code) {
      if (err.response?.data?.code === ApiErrorCode.InviteNotFound) {
        throw Error("We encountered an erroring accepting the invite, please try again");
      } else {
        throw err;
      }
    }
    throw Error("Bad acceptInviteToHub request, status " + err.response?.status);
  }
}

export async function createDemoVideo(
  orgId: string,
  title: string,
  description: string,
  extension: string
): Promise<CreateDemoVideoResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      `/orgs/${orgId}/demo_videos`,
      { title: title, description: description, extension: extension },
      { headers: headers }
    );

    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error) {
      if (err?.response?.data?.code === ApiErrorCode.QuotaExceeded) {
        throw Error("Sorry, you've reached the maximum number of demo videos you can create!");
      }

      throw Error(`Sorry, an unexpected error has occurred! Error: ${err?.response?.data?.error}`);
    }
    throw err;
  }
}

export async function updateDemoVideo(
  orgId: string,
  videoId: string,
  updates: UpdateDemoVideoRequest
): Promise<DemoVideoResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.put(`/orgs/${orgId}/demo_videos/${videoId}`, updates, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function getOrgContentView(orgId: string): Promise<ContentsViewResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/orgs/${orgId}/contents_view`, { headers: headers });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function deleteDemoVideo(orgId: string, videoId: string): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/orgs/${orgId}/demo_videos/${videoId}`, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return;
}

export async function getDemoVideo(
  orgId: string,
  videoId: string,
  generateUrl: boolean
): Promise<DemoVideoResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(
    `/orgs/${orgId}/demo_videos/${videoId}?generate_url=${generateUrl}`,
    {
      headers: headers,
    }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function createOrgCourse(
  orgId: string,
  title: string,
  description: string,
  availableHubs: string[]
): Promise<CourseResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post(
    `/orgs/${orgId}/courses`,
    { title: title, description: description, available_hubs: availableHubs },
    { headers: headers }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function updateOrgCourse(
  orgId: string,
  courseId: string,
  title: string,
  description: string,
  availableHubs: string[],
  videoIds?: string[]
): Promise<CourseResponse> {
  const headers = await AuthorizationAPIHeaders();
  const body = { title: title, description: description, available_hubs: availableHubs };
  if (videoIds) {
    body["video_ids"] = videoIds;
  }
  const ret = await AxiosInstance.put(`/orgs/${orgId}/courses/${courseId}`, body, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function createCourseVideo(
  orgId: string,
  courseId: string,
  title: string,
  description: string,
  extension: string
): Promise<CreateCourseVideoResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      `/orgs/${orgId}/courses/${courseId}/videos`,
      { title: title, description: description, extension: extension },
      { headers: headers }
    );

    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error) {
      if (err?.response?.data?.code === ApiErrorCode.QuotaExceeded) {
        throw Error("Sorry, you've reached the maximum number of course videos you can create!");
      }

      throw Error(`Sorry, an unexpected error has occurred! Error: ${err?.response?.data?.error}`);
    }
    throw err;
  }
}

export async function updateCourseVideo(
  orgId: string,
  courseId: string,
  videoId: string,
  updates: UpdateCourseVideoRequest
): Promise<CourseVideoResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.put(
    `/orgs/${orgId}/courses/${courseId}/videos/${videoId}`,
    updates,
    {
      headers: headers,
    }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function getCourseVideo(
  orgId: string,
  courseId: string,
  videoId: string,
  generateUrl: boolean
): Promise<CourseVideoResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(
    `/orgs/${orgId}/courses/${courseId}/videos/${videoId}?generate_url=${generateUrl}`,
    {
      headers: headers,
    }
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return ret.data;
}

export async function deleteCourseVideo(
  orgId: string,
  courseId: string,
  videoId: string
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/orgs/${orgId}/courses/${courseId}/videos/${videoId}`, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return;
}

export async function deleteCourse(orgId: string, courseId: string): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.delete(`/orgs/${orgId}/courses/${courseId}`, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }

  return;
}

export async function getBillingSettingsUrl(
  successUrl: string,
  cancelUrl: string
): Promise<PlanSettingsActionResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      "/users/me/plan/settings",
      {
        action: "update_payment_method",
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      {
        headers: headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Error fetching billing settings url" + err);
  }
}

export async function checkoutPlan(
  planType: UsagePlanType,
  planDuration: UsagePlanDuration,
  finalizeSchedule: boolean,
  pExpVersion: PricingExperiment,
  successUrl: string,
  cancelUrl: string
): Promise<PlanCheckoutResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      "/users/me/plan/checkout",
      {
        plan_type: planType,
        plan_duration: planDuration,
        finalize_schedule: finalizeSchedule,
        p_exp_version: pExpVersion,
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      {
        headers: headers,
      }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad api request, status " + err.response.status);
  }
}

export async function revertScheduledPlanChange(): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post(
      "/users/me/plan/revert",
      {},
      {
        headers: headers,
      }
    );
  } catch (err) {
    throw Error("Bad api request, status " + err.response.status);
  }
}

export async function syncPlan(): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post("/users/me/plan/sync", {}, { headers: headers });
  } catch (err) {
    throw Error("Bad api request, status " + err.response.status);
  }
}

export async function createOrgLogo(
  orgId: string,
  extension: string
): Promise<CreateOrgLogoResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      `/orgs/${orgId}/logo`,
      { extension: extension },
      { headers: headers }
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad createOrgLogo request, status " + err.response.status);
  }
}

export async function deleteOrgLogo(orgId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/orgs/${orgId}/logo`, { headers: headers });
  } catch (err) {
    throw Error("Bad deleteOrgLogo request, status " + err.response.status);
  }
}

export async function listHubInterviewBanks(hubId: string): Promise<InterviewBankResponse[]> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/hubs/${hubId}/interview_bank`, { headers: headers });
    return ret.data.interview_banks;
  } catch (err) {
    throw Error("Bad listHubInterviewBanks request, status " + err.response.status);
  }
}

export async function createOrgInterviewBank(
  orgId: string,
  name: string,
  interviewQuestions: string[],
  availableHubs: string[]
): Promise<InterviewBankResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      `/orgs/${orgId}/interview_bank`,
      { name: name, interview_questions: interviewQuestions, available_hubs: availableHubs },
      { headers: headers }
    );
    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error) {
      throw Error(`Sorry, an unexpected error has occurred! Error: ${err?.response?.data?.error}`);
    }
    throw err;
  }
}

export async function deleteOrgInterviewBank(orgId: string, bankId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/orgs/${orgId}/interview_bank/${bankId}`, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad deleteOrgInterviewBank request, status " + err.response.status);
  }
}

export async function updateOrgInterviewBank(
  orgId: string,
  bankId: string,
  name: string,
  interviewQuestions: string[],
  availableHubs: string[]
): Promise<InterviewBankResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.put(
      `/orgs/${orgId}/interview_bank/${bankId}`,
      { name: name, interview_questions: interviewQuestions, available_hubs: availableHubs },
      { headers: headers }
    );
    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error) {
      throw Error(`Sorry, an unexpected error has occurred! Error: ${err?.response?.data?.error}`);
    }
    throw err;
  }
}

export async function listOrgInterviewBanks(orgId: string): Promise<InterviewBankResponse[]> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/interview_bank`, { headers: headers });
    return ret.data.interview_banks;
  } catch (err) {
    throw Error("Bad listOrgInterviewBanks request, status " + err.response?.status);
  }
}

export async function getUserDataRedactionConfig(): Promise<PlanDataRedactionConfigResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/users/me/plan/data_redaction_config`, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getUserDataRedactionConfig request, status " + err.response?.status);
  }
}

export async function updateUserDataRedactionConfig(
  updates: PlanDataRedactionConfigRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.put(`/users/me/plan/data_redaction_config`, updates, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateUserDataRedactionConfig request, status " + err.response?.status);
  }
}

export async function updateOrgPaymentMethod(
  orgId: string,
  successUrl: string,
  cancelUrl: string
): Promise<string> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.put(
      `/orgs/${orgId}/subscription/payment_info`,
      {
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      {
        headers: headers,
      }
    );
    return ret.data.redirect_url;
  } catch (err) {
    throw Error("Bad updateOrgPaymentMethod request, status " + err.response?.status);
  }
}

export async function upsetPaygOrgSubscription(
  orgId: string,
  numSeats: number,
  successUrl: string,
  cancelUrl: string
): Promise<UpsetPaygOrgSubscriptionResponse> {
  const headers = await AuthorizationAPIHeaders();

  const response = await AxiosInstance.put(
    `/orgs/${orgId}/subscription`,
    {
      num_seats: numSeats,
      success_url: successUrl,
      cancel_url: cancelUrl,
    },
    { headers: headers }
  );

  return response.data;
}

export async function getOrgEmailBranding(orgId: string): Promise<OrgEmailBrandingResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/email_branding`, { headers: headers });
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgEmailBranding request, status " + err.response?.status);
  }
}

export async function upsetOrgEmailBranding(
  orgId: string,
  updates: UpsetOrgEmailBrandingRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.put(`/orgs/${orgId}/email_branding`, updates, { headers: headers });
  } catch (err) {
    throw Error("Bad updateOrgEmailBranding request, status " + err.response?.status);
  }
}

export async function upsetOrgSignUpNotice(orgId: string, message: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.put(
      `/orgs/${orgId}/sign_up_notice`,
      { message: message },
      { headers: headers }
    );
  } catch (err) {
    throw Error("Bad upsetOrgSignUpNotice request, status " + err.response?.status);
  }
}

export async function getOrgSignUpNotice(orgId: string): Promise<OrgSignUpNoticeResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/sign_up_notice`, { headers: headers });
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgSignUpNotice request, status " + err.response?.status);
  }
}

export async function deleteOrgSignUpNotice(orgId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/orgs/${orgId}/sign_up_notice`, { headers: headers });
  } catch (err) {
    throw Error("Bad deleteOrgSignUpNotice request, status " + err.response?.status);
  }
}

export async function createHubV2(orgId: string, name: string): Promise<HubV2Response> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(
      `/v2/orgs/${orgId}/hubs`,
      { name: name },
      { headers: headers }
    );
    return ret.data;
  } catch (err) {
    if (err?.response?.data?.error && err?.response?.data?.code === ApiErrorCode.QuotaExceeded) {
      throw Error("Sorry, you've reached the maximum number of hubs allowed in your organization!");
    }
    throw Error("Bad createHubV2 request, status " + err.response?.status);
  }
}

export async function listOrgsV2(): Promise<OrgV2ListResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get("/v2/orgs", { headers: headers });
    return ret.data;
  } catch (err) {
    throw Error("Bad listOrgsV2 request, status " + err.response?.status);
  }
}

export async function getOrgV2(orgId: string): Promise<OrgV2Response> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}`, { headers: headers });
    return ret.data;
  } catch (err) {
    throw Error("Bad listOrgsV2 request, status " + err.response?.status);
  }
}

export async function getHubV2(orgId: string, hubId: string): Promise<HubV2Response> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/hubs/${hubId}`, { headers: headers });
    return ret.data;
  } catch (err) {
    throw Error("Bad getHubV2 request, status " + err.response?.status);
  }
}

export async function updateHubV2(
  orgId: string,
  hubId: string,
  updates: HubUpdateRequest
): Promise<HubV2Response> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.patch(`/v2/orgs/${orgId}/hubs/${hubId}`, updates, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getHubV2 request, status " + err.response?.status);
  }
}

export async function deleteHubV2(orgId: string, hubId: string, transfer: boolean): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/v2/orgs/${orgId}/hubs/${hubId}`, {
      headers: headers,
      params: { transfer: transfer },
    });
  } catch (err) {
    throw Error("Bad deleteHubV2 request, status " + err.response?.status);
  }
}

export async function getHubContentAdminViewV2(
  orgId: string,
  hubId: string
): Promise<ContentsViewResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/hubs/${hubId}/content_admin_view`, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getHubContentAdminView request, status " + err.response?.status);
  }
}

export async function listHubUsersV2(
  orgId: string,
  hubId: string,
  queryParams: GetHubMemberListQueryParams
): Promise<OrgMemberListResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/hubs/${hubId}/users`, {
      headers: headers,
      params: queryParams,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad listHubUsersV2 request, status " + err.response?.status);
  }
}

export async function updateHubUserV2(
  orgId: string,
  hubId: string,
  userId: string,
  role: HubRole
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.patch(
      `/v2/orgs/${orgId}/hubs/${hubId}/users/${userId}`,
      { role: role },
      { headers: headers }
    );
  } catch (err) {
    throw Error("Bad updateHubUserV2 request, status " + err.response?.status);
  }
}

export async function deleteHubUserV2(
  orgId: string,
  hubId: string,
  userId: string,
  allowRemoveLastHub: boolean
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(
      `/v2/orgs/${orgId}/hubs/${hubId}/users/${userId}?allow_remove_last_hub=${allowRemoveLastHub}`,
      {
        headers: headers,
      }
    );
  } catch (err) {
    if (err.response?.data.code === ApiErrorCode.IsLastHub) {
      throw err;
    }
    throw Error("Bad deleteHubUserV2 request, status " + err.response?.status);
  }
}

export async function updateOrgV2(orgId: string, updates: UpdateOrgV2Request): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.patch(`/v2/orgs/${orgId}`, updates, { headers: headers });
  } catch (err) {
    throw Error("Bad updateOrgV2 request, status " + err.response?.status);
  }
}

export async function listOrgUsersV2(
  orgId: string,
  queryParams: GetOrgMemberListQueryParams
): Promise<OrgMemberListResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/users`, {
      headers: headers,
      params: queryParams,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad listOrgUsersV2 request, status " + err.response?.status);
  }
}

export async function deleteOrgUserV2(orgId: string, userId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/v2/orgs/${orgId}/users/${userId}`, { headers: headers });
  } catch (err) {
    throw Error("Bad deleteOrgUserV2 request, status " + err.response?.status);
  }
}

export async function listOrgInvitesV2(
  orgId: string,
  queryParams: GetOrgInviteListQueryParams
): Promise<OrgInviteListResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/invites`, {
      headers: headers,
      params: queryParams,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad listOrgInvitesV2 request, status " + err.response?.status);
  }
}

export async function createOrgUserV2(
  orgId: string,
  body: AddOrgUsersRequest
): Promise<AddOrgUsersResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(`/v2/orgs/${orgId}/users`, body, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad createOrgUserV2 request, status " + err.response?.status);
  }
}

export async function deleteOrgInviteV2(orgId: string, email: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/v2/orgs/${orgId}/invites/${email}`, { headers: headers });
  } catch (err) {
    throw Error("Bad deleteOrgInviteV2 request, status " + err.response?.status);
  }
}

export async function actOrgInviteV2(
  orgId: string,
  email: string,
  action: OrgInviteAction
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post(
      `/v2/orgs/${orgId}/invites/${email}`,
      { action: action },
      {
        headers: headers,
      }
    );
  } catch (err) {
    if (err.response?.data?.code) {
      if (err.response?.data?.code === ApiErrorCode.InviteNotFound) {
        throw Error("We encountered an erroring accepting the invite, please try again");
      } else {
        throw err;
      }
    }
    throw Error("Bad actOrgInviteV2 request, status " + err.response?.status);
  }
}

export async function createOrgV2(body: CreateOrgV2Request): Promise<OrgV2Response> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post("/v2/orgs", body, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad createOrgV2 request, status " + err.response?.status);
  }
}

export async function updateOrgUserV2(
  orgId: string,
  userId: string,
  body: OrgUserUpsetRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.patch(`/v2/orgs/${orgId}/users/${userId}`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateOrgUserV2 request, status " + err.response?.status);
  }
}

export async function getInterviewBankMemberView(orgId: string): Promise<InterviewBankResponse[]> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/interview_bank_member_view`, {
      headers: headers,
    });
    return ret.data.interview_banks;
  } catch (err) {
    throw Error("Bad getInterviewBankMemberView request, status " + err.response?.status);
  }
}

export async function getOrgContentMemberViewV2(orgId: string): Promise<ContentsViewResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/v2/orgs/${orgId}/content_member_view`, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgContentMemberView request, status " + err.response?.status);
  }
}

export async function createOrgProgram(
  orgId: string,
  body: CreateProgramRequest
): Promise<ProgramResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(`/orgs/${orgId}/programs`, body, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad createOrgProgram request, status " + err.response?.status);
  }
}

export async function listOrgPrograms(orgId: string): Promise<ProgramResponse[]> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/programs`, {
      headers: headers,
    });
    return ret.data.programs;
  } catch (err) {
    throw Error("Bad listOrgPrograms request, status " + err.response?.status);
  }
}

export async function updateOrgProgram(
  orgId: string,
  programId: string,
  body: UpdateProgramRequest
): Promise<UpdateProgramResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.patch(`/orgs/${orgId}/programs/${programId}`, body, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad updateOrgProgram request, status " + err.response?.status);
  }
}

export async function listOrgProgramUsers(
  orgId: string,
  programId: string,
  queryParams: ListProgramUsersQueryParams
): Promise<ListProgramUsersResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/programs/${programId}/users`, {
      headers: headers,
      params: queryParams,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgProgramUsers request, status " + err.response?.status);
  }
}

export async function deleteOrgProgram(orgId: string, programId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.delete(`/orgs/${orgId}/programs/${programId}`, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad deleteOrgProgram request, status " + err.response?.status);
  }
}

export async function sendOrgProgramReminder(
  orgId: string,
  programId: string,
  body: SendProgramReminderRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await AxiosInstance.post(`/orgs/${orgId}/programs/${programId}/reminder`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad sendOrgProgramReminder request, status " + err.response?.status);
  }
}

export async function getSpeech(
  speechId: string,
  params: GetSpeechQueryParams
): Promise<GetSpeechResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/speech/${speechId}`, {
      headers: headers,
      params: params,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getSpeech request, status " + err.response?.status);
  }
}

export async function getOrgProgramUser(
  orgId: string,
  programId: string,
  userId: string
): Promise<GetProgramUserResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/programs/${programId}/users/${userId}`, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getOrgProgramUser request, status " + err.response?.status);
  }
}

export async function listProgramsMemberView(): Promise<ProgramMemberViewItem[]> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/users/me/programs`, {
      headers: headers,
    });
    return ret.data.programs;
  } catch (err) {
    throw Error("Bad listProgramsMemberView request, status " + err.response?.status);
  }
}

// NON-AXIOS API CALLS, USES FETCH
/**
 * Updates analytics for sync transcript, if relevant.
 * Usually takes a few seconds to return so should not be awaited
 */
export async function updateAnalyticsSync(speechId: string, userId: string): Promise<void> {
  const requestUri = `${clientEnv.url.PYTHON_SERVER}/analytics_api/authed/run_sync_analytics`;

  await fetch(requestUri, {
    method: "POST",
    headers: await AuthorizationAPIHeaders(),
    body: JSON.stringify({ speechId, siteId: getSiteId(), userId }),
  });
  return;
}

export async function checkTalkingPoints(
  requestInfo: analyticsApiTypes.TalkingPointsCheckRequest
): Promise<number[]> {
  const requestUri = `${clientEnv.url.PYTHON_SERVER}/analytics_api/authed/check_talking_points`;
  const headers = await AuthorizationAPIHeaders();
  const ret = await fetch(requestUri, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestInfo),
  });
  if (ret.status !== 200) {
    throw Error("Bad api request, status " + ret.status);
  }
  return (await ret.json()) as number[];
}

export async function fetchConciseness(
  speechRef: db.Ref<db.Speech>,
  analyticsSpeaker: string
): Promise<ConcisenessResponse> {
  const payload: AnalyticsRequest = {
    path: db.getRefPath(speechRef),
    speakerId: analyticsSpeaker === "all" ? analyticsSpeaker : parseInt(analyticsSpeaker),
  };
  const requestUri = `${clientEnv.url.PYTHON_SERVER}/analytics_api/authed/get_conciseness`;
  const concisenessResponse = await fetch(requestUri, {
    method: "POST",
    headers: await AuthorizationAPIHeaders(),
    body: JSON.stringify(payload),
  });

  if (concisenessResponse.status !== 200) {
    const resp = await concisenessResponse.text();
    throw Error(`Conciseness API encountered error: ${resp}`);
  }

  const concisenessJson = await concisenessResponse.json();

  return concisenessJson;
}

export async function fetchSentenceStarters(
  speechRef: db.Ref<db.Speech>,
  analyticsSpeaker: string
): Promise<SentenceStartersResponse> {
  const sentenceStarterPayload: AnalyticsRequest = {
    path: db.getRefPath(speechRef),
    speakerId: analyticsSpeaker === "all" ? analyticsSpeaker : parseInt(analyticsSpeaker),
  };
  const requestUri = `${clientEnv.url.PYTHON_SERVER}/analytics_api/authed/get_sentence_starters`;

  const sentenceStarterResponse = await fetch(requestUri, {
    method: "POST",
    headers: await AuthorizationAPIHeaders(),
    body: JSON.stringify(sentenceStarterPayload),
  });

  if (sentenceStarterResponse.status !== 200) {
    const resp = await sentenceStarterResponse.text();
    throw Error(`Sentence Starter API encountered error: ${resp}`);
  }

  const sentenceStarterJson = await sentenceStarterResponse.json();
  return sentenceStarterJson;
}

export async function updateSpeechStates(
  speechId: string,
  updates: UpdateSpeechStatesRequest
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch(`/speech/${speechId}/states`, updates, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
}

export async function updateSpeechShare(
  speechId: string,
  request: UpdateSpeechShareRequest
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post(`/speech/${speechId}/share`, request, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
}

export async function getUserSpeech(
  userId: string,
  speechId: string,
  params: GetSpeechQueryParams
): Promise<GetSpeechResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/users/${userId}/speeches/${speechId}`, {
      headers: headers,
      params: params,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getSpeech request, status " + err.response?.status);
  }
}

export async function updateSpeechEvaluation(
  userId: string,
  speechId: string,
  request: EvaluateSpeechRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    await AxiosInstance.post(`/users/${userId}/speeches/${speechId}/evaluate`, request, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateSpeechEvaluation request, status " + err.response?.status);
  }
}

export async function getScreenshotUploadUrl(): Promise<apiTypes.ScreenshotUploadResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post(
    `/screenshot_upload_url`,
    {},
    {
      headers: headers,
    }
  );
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function onOnboardingComplete(
  obq1Answer: OBQ1Option,
  calendarAction: CalendarAction
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  return AxiosInstance.post(
    "/onboarding-complete",
    { obq1Answer, calendarAction },
    {
      headers: headers,
    }
  );
}

export async function sendJoinAffiliateProgramEmail(): Promise<boolean> {
  const headers = await AuthorizationAPIHeaders();

  const response = await AxiosInstance.post(
    "/referrals/send_join_affiliate_program_email",
    {},
    { headers: headers }
  );

  return response.status === 200;
}

//#region Admin Config

/**
 * Get a paginated list of organizations.
 * @param params Start, limit, and sort order.
 * @returns List of organizations.
 */
export async function adminGetOrgList(params?: {
  start?: number;
  limit?: number;
  sort?: AdminGetOrgListSortOption;
}): Promise<AdminGetOrgListResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/admin/v1/orgs`, {
    params: params,
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Get a specific org by ID.
 * @param orgId Organization ID.
 * @param params Fields to include in the response.
 * @returns Information about the org.
 */
export async function adminGetOrgById(
  orgId: string,
  params: {
    fields: AdminGetOrgFieldType[];
  }
): Promise<AdminGetOrgResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/admin/v1/orgs/${orgId}`, {
    params: {
      fields: params.fields.join(","),
    },
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Update an existing organization.
 * @param orgId Organization ID.
 * @param request Updates to perform on the organization.
 * @returns Response indicating success/failure.
 */
export async function adminUpdateOrgData(
  orgId: string,
  request: AdminUpdateOrgRequest
): Promise<AdminUpdateOrgResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.patch(`/admin/v1/orgs/${orgId}`, request, {
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Get a list of users from a site.
 * @param params Email address to search for.
 * @returns List of users.
 */
export async function adminGetUserList(params: {
  email: string;
}): Promise<AdminGetUserListResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/admin/v1/users`, {
    params: params,
    headers: headers,
  });

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Get information about a HubSpot deal.
 * @param dealId HubSpot deal ID.
 * @returns Information about the deal.
 */
export async function adminGetHubSpotDealById(
  dealId: string
): Promise<AdminGetHubSpotDealByIdResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/admin/v1/hubSpot/deals/${dealId}`, {
    headers: headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Get information about a HubSpot company.
 * @param companyId HubSpot company ID.
 * @returns Information about the deal.
 */
export async function adminGetHubSpotCompanyById(
  companyId: string
): Promise<AdminGetHubSpotCompanyByIdResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get(`/admin/v1/hubSpot/companies/${companyId}`, {
    headers: headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

/**
 * Create a Yoodli instance in HubSpot.
 * @param request Information about the Yoodli instance.
 * @returns Information about the newly-created instance.
 */
export async function adminCreateHubSpotYoodliInstance(
  request: AdminCreateHubSpotYoodliInstanceRequest
): Promise<AdminCreateHubSpotYoodliInstanceResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.post(`/admin/v1/hubSpot/instances`, request, {
    headers: headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function adminListScenarios(orgId: string): Promise<ListScenariosResponse> {
  const headers = await AuthorizationAPIHeaders();
  const ret = await AxiosInstance.get<ListScenariosResponse>(`/admin/v1/orgs/${orgId}/scenarios`, {
    headers,
  });
  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
}

export async function adminCopyScenario(
  orgId: string,
  scenarioId: string,
  orgToCopyToId: string
): Promise<string> {
  const headers = await AuthorizationAPIHeaders();
  const request: CopyScenarioRequest = {
    orgToCopyToId,
  };
  let ret: AxiosResponse;
  try {
    ret = await AxiosInstance.post(
      `/admin/v1/orgs/${orgId}/scenarios/${scenarioId}/copy`,
      request,
      {
        headers,
      }
    );
  } catch (err) {
    return "Error: " + err.response?.data?.error;
  }
  if (Math.floor(ret.status / 100) !== 2) {
    return "Error: " + ret.statusText;
  }
  return ret.data;
}

//#endregion Admin Config

// #region Dashboard and downloadable reports

export async function createReport(
  orgId: string,
  body: CreateReportRequest
): Promise<CreateReportResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.post(`/orgs/${orgId}/reports`, body, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad createReport request, status " + err.response?.status);
  }
}

export async function listReports(
  orgId: string,
  params: ListReportsQueryParams
): Promise<ListReportsResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/reports`, {
      headers: headers,
      params: params,
    });
    const sortedReports = ret.data.reports?.sort((a, b) => {
      return b.creationDate.localeCompare(a.creationDate);
    });
    return { reports: sortedReports };
  } catch (err) {
    throw Error("Bad listReports request, status " + err.response?.status);
  }
}

export async function getReportById(orgId: string, reportId: string): Promise<GetReportResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await AxiosInstance.get(`/orgs/${orgId}/reports/${reportId}`, {
      headers: headers,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad getReportById request, status " + err.response?.status);
  }
}

// #endregion Dashboard and downloadable reports

export default AxiosInstance;
