import React from "react";

// Components
import { Stack, useTheme, useMediaQuery, Typography, Box, CircularProgress } from "@mui/material";

// Assets
import { ReactComponent as PoweredByYoodliGraphic } from "../../images/graphics/graphic-powered-by-yoodli.svg";
import YoodliLogoText from "../../images/logos/yoodli_logo.svg";

// Utils
import AuthImage from "./AuthImage";
import HubInviteAuthImage from "./HubInviteAuthImage";
import { getOrgAccessInfo } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { OBQ1Option } from "lib-fullstack/utils/enums";
import {
  REFERRAL_PROGRAM_AUTH_IMAGES,
  ReferralProgram,
} from "lib-fullstack/utils/referralProgramUtils";

type AuthWrapperProps = {
  showUsernamePasswordSignUp?: boolean;
  setCustomSsoName: (customSsoName: string) => void;
  setSsoDisplayName?: (ssoDisplayName: string) => void;
  setShowOnlyOrgSso?: (showOrgSso: boolean) => void;
  orgId?: string;
  hubId?: string;
};

export default function AuthWrapper({
  children,
  showUsernamePasswordSignUp,
  setCustomSsoName,
  setSsoDisplayName,
  setShowOnlyOrgSso,
  orgId,
  hubId,
}: React.PropsWithChildren & AuthWrapperProps): JSX.Element {
  const siteConf = getStaticFullSiteConf();
  const theme = useTheme();
  const isSmallScreen = !useMediaQuery(theme.breakpoints.up(1000));

  const [loading, setLoading] = React.useState<boolean>(true);
  const [orgLogo, setOrgLogo] = React.useState<string>(undefined);
  const [orgName, setOrgName] = React.useState<string>(undefined);
  const [defaultOnboarding, setDefaultOnboarding] = React.useState<OBQ1Option>(undefined);
  const [customAuthImage, setCustomAuthImage] = React.useState<string>(undefined);

  const decodedOrgName = decodeURI(orgName);
  React.useEffect(() => {
    const fetchSignupInfo = async () => {
      const response = await getOrgAccessInfo({ orgId: orgId, hubId: hubId });
      setOrgName(response.org_name);
      if (response?.org_logo_url) {
        setOrgLogo(response.org_logo_url);
      }
      if (response?.default_onboarding) {
        setDefaultOnboarding(response.default_onboarding as OBQ1Option);
      }
      if (response?.org_name.includes("Spencer Stuart")) {
        setCustomAuthImage(REFERRAL_PROGRAM_AUTH_IMAGES[ReferralProgram.SPENCER_STUART]);
      }
      if (response?.sign_in_options?.length > 0) {
        setShowOnlyOrgSso(true);
        setCustomSsoName(response.sign_in_options[0].firebase_provider);
        setSsoDisplayName(response.sign_in_options[0].display_name);
      }
    };

    if (hubId || orgId) {
      fetchSignupInfo()
        .catch((err) => console.error("Error fetching signup info", err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [hubId, orgId]);

  const renderImage = () => {
    if (isSmallScreen) {
      return null;
    }
    if (customAuthImage) {
      return (
        <AuthImage image={<img src={customAuthImage} width="468px" />} lightBackground={false} />
      );
    } else if (defaultOnboarding && defaultOnboarding !== OBQ1Option.COACH) {
      return <HubInviteAuthImage defaultOnboarding={defaultOnboarding} />;
    } else {
      return (
        <AuthImage
          lightBackground={false}
          image={
            <img
              src={`https://storage.googleapis.com/yoodli-public/auth-assets/graphic-howyoodliworks.webp`}
              width="468px"
            />
          }
        />
      );
    }
  };
  return loading ? (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      minHeight="100dvh"
      sx={{
        backgroundColor: getDynamicColor("light1"),
      }}
    >
      <Stack
        textAlign="center"
        gap={3}
        alignItems="center"
        justifyContent="center"
        width="100%"
        sx={{
          py: { xs: showUsernamePasswordSignUp ? 4 : 2, sm: 2 },
          mb: orgLogo || siteConf?.logo?.url ? "76px" : 0,
        }}
      >
        <Stack display="flex" justifyContent="center" alignItems="center" gap={3} width="100%">
          <img
            src={orgLogo ?? siteConf?.logo?.url ?? YoodliLogoText}
            width={100}
            alt="Yoodli logo"
          />
          {orgName && (
            <Stack
              sx={{ fontFamily: "poppins", color: getDynamicColor("purple3"), textAlign: "center" }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>Join:</Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                {decodedOrgName}
              </Typography>
            </Stack>
          )}
          {children}
        </Stack>
        {(orgLogo || siteConf?.logo?.url) && (
          <Stack position="absolute" bottom={0} mb={2}>
            <PoweredByYoodliGraphic />
          </Stack>
        )}
      </Stack>
      {renderImage()}
    </Stack>
  );
}
