import React from "react";

// Components
import { MoreHorizRounded as MoreHorizRoundedIcon } from "@mui/icons-material";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  getDemeanorColor,
} from "components/ConvoScenarios/convoScenarioUtils";
import IconMenu from "lib-frontend/components/IconMenu";

// Utils
import { useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deletePersona } from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";

type PersonaCardProps = {
  persona: PersonaResponse;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetNewPersona: (persona: PersonaResponse) => void;
};

export const PersonaCard = ({
  persona,
  setIsEditing,
  handleSetNewPersona,
}: PersonaCardProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { defaultOrg } = React.useContext(UserOrgContext);
  const [deletingPersonaId, setDeletingPersonaId] = React.useState<string>(null);

  const handleDeletePersona = async (persona) => {
    setDeletingPersonaId(persona.id);
    try {
      if (
        window.confirm(
          `Are you sure you want to delete the persona "${persona.name}"? This action cannot be undone.`
        )
      ) {
        await deletePersona(defaultOrg.id, persona.id);
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg.id],
        });
      }
    } catch (e) {
      console.error(`Error deleting scenario with id ${persona.id}: ${e}`);
    } finally {
      setDeletingPersonaId(null);
    }
  };
  const handleEditPersona = (persona: PersonaResponse) => {
    setIsEditing(true);
    handleSetNewPersona(persona);
  };
  const renderScenarioOptionsMenu = (persona: PersonaResponse) => {
    const menuItems = [
      {
        title: "Edit",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          handleEditPersona(persona);
        },
      },
      {
        title: "Delete",
        onClick: async () => {
          await handleDeletePersona(persona);
        },
        disabled: deletingPersonaId === persona.id || persona.used_by_n_scenarios > 0,
        disabledTooltip: persona.used_by_n_scenarios > 0 ? "Persona is in use" : "",
      },
    ];
    return (
      <Box
        sx={{
          ml: "auto",
        }}
      >
        <IconMenu
          title="Manage File"
          hideCaret
          hideTooltip
          disableDrag
          menuItems={menuItems}
          iconButtonSx={{
            height: 28,
            width: 28,
            svg: {
              height: 30,
              width: 30,
              color: getDynamicColor(deletingPersonaId === persona.id ? "dark3" : "primary"),
            },
          }}
          menuItemSx={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "poppins",
            px: 2,
            py: 1.5,
            color: getDynamicColor("primary"),
          }}
          minWidth={"140px"}
          paperSx={{
            borderRadius: "12px",
            minWidth: 180,
          }}
          icon={
            <MoreHorizRoundedIcon
              sx={{
                color: getDynamicColor("primary"),
              }}
            />
          }
        />
      </Box>
    );
  };
  return (
    <Card
      sx={{
        borderRadius: "8px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        p: { xs: 2, sm: 3 },
        pb: { xs: 1.5, sm: 2.5 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 2,
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={1.5}
        sx={{
          display: "-webkit-box",
        }}
      >
        <Avatar
          alt={persona.name}
          src={persona.profile_picture_signed_url}
          key={persona.profile_picture_signed_url}
          sx={{
            height: 40,
            width: 40,
            img: { objectFit: "contain" },
          }}
        />
        <Stack
          direction="column"
          sx={{
            color: getDynamicColor("purple3"),
            flexGrow: 1,
          }}
        >
          <Typography
            onClick={() => handleEditPersona(persona)}
            sx={{
              fontWeight: 700,
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {persona.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            {persona.job_title}
          </Typography>
        </Stack>
        {renderScenarioOptionsMenu(persona)}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            fontSize: 10,
            letterSpacing: "1px",
            color: getDynamicColor("purple3"),
            backgroundColor: getDemeanorColor(persona.demeanor),
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 1,
            py: 0.5,
            width: "fit-content",
          }}
        >
          {persona.demeanor.toUpperCase()}
        </Box>
        {persona.used_by_n_scenarios > 0 && (
          <Typography
            sx={{
              fontSize: 10,
              letterSpacing: "1px",
              fontWeight: 400,
              fontFamily: "inter",
              textAlign: "right",
            }}
          >
            USED BY {persona.used_by_n_scenarios} SCENARIOS
          </Typography>
        )}
      </Stack>
    </Card>
  );
};
