import React from "react";
import { useHistory } from "react-router";
// React Slick Carousel
import Slider from "react-slick";

// Components
import { ArrowBackIos } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { MyLearningSection } from "components/Orgs/MyLearning";
import { MemberProgramItem } from "components/Orgs/Programs/MemberProgramItem";
import { OrgProgramsQueryKeys } from "components/Orgs/Programs/OrgPrograms";

// Utils
import { ContentCarouselWrapper } from "./HomeStyles";
import { UseQueryResult, useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listScenariosMemberView } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { MyLearningQueryParams } from "lib-frontend/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { ProgramRecordState, PlanStepState, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { ProgramMemberNextStepCtaWheres } from "lib-fullstack/utils/productAnalyticEvents";

const SlickButtonFix = ({
  currentSlide: _currentSlide,
  slideCount: _slideCount,
  children,
  ...props
}) => <span {...props}>{children}</span>;

type ProgramsCarouselProps = {
  programMemberViewQuery: UseQueryResult<ProgramMemberViewItem[]>;
};

export const ProgramsCarousel = ({
  programMemberViewQuery,
}: ProgramsCarouselProps): JSX.Element => {
  const history = useHistory();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrg?.id],
    queryFn: () => listScenariosMemberView(defaultOrg.id),
    enabled: !!defaultOrg,
  });

  const activePrograms = React.useMemo(() => {
    return (
      programMemberViewQuery?.data?.sort((a, b) => {
        // sorting such that completed programs are at the end but the order is otherwise unchanged.
        if (a.state === ProgramRecordState.Completed && b.state !== ProgramRecordState.Completed) {
          return 1;
        }
        if (a.state !== ProgramRecordState.Completed && b.state === ProgramRecordState.Completed) {
          return -1;
        }
        return 0;
      }) ?? []
    );
  }, [programMemberViewQuery?.data]);

  const isSmallScreen = useIsSmallScreen();
  const sliderRef = React.useRef<Slider>(null);
  const settings = {
    dots: true,
    arrows: !isSmallScreen,
    slidesToShow: 1,
    initialSlide: 0,
    infinite: activePrograms.length > 1,
    prevArrow: (
      <SlickButtonFix currentSlide={0} slideCount={0}>
        <IconButton
          onClick={() => sliderRef.current.slickPrev()}
          sx={{
            zIndex: 20,
          }}
        >
          <ArrowBackIos />
        </IconButton>
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix currentSlide={0} slideCount={0}>
        <IconButton
          onClick={() => sliderRef.current.slickNext()}
          sx={{
            zIndex: 20,
          }}
        >
          <ArrowBackIos
            sx={{
              transform: "rotate(180deg)",
            }}
          />
        </IconButton>
      </SlickButtonFix>
    ),
  };

  const customSlickStyles = {
    ".slick-list": {
      p: "0px !important",
      mx: { xs: 0, md: "32px !important" },
      mb: { xs: 1, md: "initial" },
    },
    ".slick-slider": {
      marginInline: "-24px", //  space between slides * (-1)
      paddingBottom: 2,
    },
    ".slick-dots": {
      bottom: -16,
    },
    ".slick-slide > div": {
      marginInline: "12px",
    },
    ".slick-arrow": {
      color: `${getDynamicColor("dark5")} !important`,
      borderRadius: "50%",
      backgroundColor: "transparent",
      height: 36,
      width: 36,
      cursor: "pointer",
      svg: {
        height: 20,
        width: 20,
      },
      "&:hover": {
        backgroundColor: getDynamicColor("dark1"),
        color: getDynamicColor("dark5"),
      },
      "&:before": {
        content: '""',
        display: "none",
      },
    },
    ".slick-prev": {
      left: 0,
      svg: {
        position: "relative",
        left: 4,
      },
    },
    ".slick-next": {
      right: 0,
      svg: {
        position: "relative",
        right: 4,
      },
    },
  };

  if (
    programMemberViewQuery.isLoading ||
    scenariosQuery.isLoading ||
    programMemberViewQuery?.data?.length === 0 ||
    !defaultOrg
  ) {
    return;
  }

  const practiceProgram = (program: ProgramMemberViewItem) => {
    Instrumentation.logProgramMemberNextStepCtaClicked(
      defaultOrg.id,
      program.id,
      ProgramMemberNextStepCtaWheres.HOME
    );
    const programStepToPractice = program.plan_step_results.find(
      (step) => step.state !== PlanStepState.Completed
    );
    const scenario = scenariosQuery.data?.contentArray.find(
      (s) => s.id === programStepToPractice.scenario_id
    );
    history.push(
      getScenarioPracticePath(scenario.id, scenario.scenarioTypeId, defaultOrg.id, false)
    );
  };

  const goToProgramDetails = (program: ProgramMemberViewItem) => {
    history.push(
      `${WebServerExternalPath.MY_LEARNING}?section=${MyLearningSection.ProgramInfo}&programId=${program.id}`
    );
  };

  const getCta = (program: ProgramMemberViewItem) => {
    switch (program.state) {
      case ProgramRecordState.NotStarted:
        return (
          <Button
            data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
            variant="contained"
            onClick={() => practiceProgram(program)}
            sx={{ fontSize: "12px" }}
          >
            Start
          </Button>
        );
      case ProgramRecordState.InProgress:
        return (
          <Stack direction="row" gap={2}>
            <Button
              data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
              variant="contained"
              onClick={() => practiceProgram(program)}
              sx={{ fontSize: "12px" }}
            >
              Continue
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `${WebServerExternalPath.MY_LEARNING}?${MyLearningQueryParams.SECTION}=${MyLearningSection.ProgramInfo}&${MyLearningQueryParams.PROGRAM_ID}=${program.id}`
                )
              }
              sx={{
                fontSize: "12px",
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              View report
            </Button>
          </Stack>
        );
      case ProgramRecordState.Completed:
        return (
          <Button
            data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
            variant="contained"
            onClick={() => goToProgramDetails(program)}
            sx={{ fontSize: "12px" }}
          >
            Practice Again
          </Button>
        );
    }
  };

  return (
    <Stack
      gap={2}
      sx={{
        pb: 3,
        ...customSlickStyles,
      }}
    >
      <Stack direction="row" gap={1} sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          data-testid={UITestId.ProgramCarouselTitle}
          variant="poppins"
          component="h2"
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontWeight: 600,
          }}
        >
          Your programs
        </Typography>
        <Button onClick={() => history.push(WebServerExternalPath.MY_LEARNING)}>
          See programs
        </Button>
      </Stack>
      <ContentCarouselWrapper>
        <Slider ref={sliderRef} {...settings}>
          {activePrograms.map((program) => (
            <MemberProgramItem key={program.id} program={program} cta={getCta(program)} />
          ))}
        </Slider>
      </ContentCarouselWrapper>
    </Stack>
  );
};
