import { db } from "lib-fullstack";

// Assets
import { ReactComponent as AuthorityIcon } from "images/icons/icon-authority.svg";
import { ReactComponent as ListeningIcon } from "images/icons/icon-listening.svg";
import { ReactComponent as PresenceIcon } from "images/icons/icon-presence.svg";
import { ReactComponent as UsageIcon } from "images/icons/icon-usage.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  MostSentenceStarter,
  PoodliAlertCounts,
  CorrelationResult,
} from "lib-fullstack/api/apiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { AggregateAnalyticEnum, DashboardAnalyticId } from "lib-fullstack/db";
import { GraphData } from "lib-fullstack/utils/aggregate";
import { CONCISENESS_THRESHOLD } from "lib-fullstack/utils/analyticThresholds";
import { roundToPrecision, secondsToHms } from "lib-fullstack/utils/helperFunctions";

const clientEnv = getClientEnvConfig();
const baseUrl = `${clientEnv.url.WEB_SERVER}`;

export type FocusArea = {
  label: string;
  identifier: FocusAreasEnum;
  icon: JSX.Element;
  color: string;
  analytics: DashboardAnalyticId[];
  definition: string;
};
export enum FocusAreasEnum {
  AUTHORITY = "authority",
  PRESENCE = "presence",
  LISTENING = "listening",
  USAGE = "usage",
}

export type FocusAreas = {
  [key in FocusAreasEnum]?: FocusArea;
};

export type TopUsedWordType = { word: string; count: number };
type TopUsedWordsType = { title: string; data: TopUsedWordType[] };

export type LearnMoreType = {
  title?: string;
  urlTitle?: string;
  url?: string;
  linkToLatestSpeech?: boolean;
  lightboxUrl?: string;
};

export type NextStepsType = {
  goal?: NextStepGoal;
  threshold?: number;
  positive?: string;
  negative?: string;
  neutral?: string;
  random?: string[];
};
export type HoistedAnalytic = {
  focusArea: FocusArea;
  identifier: DashboardAnalyticId;
  label: string;
  definition: string;
  customValueTransformer?: (value: number | string) => string;
  customThresholdTransformer?: (value: number | string) => string;
  changeOverTime?: (prevAvg: number, currAvg: number) => { change: number; displayChange: string };
  topUsedWords?: TopUsedWordsType;
  learnMore?: LearnMoreType;
  nextSteps?: NextStepsType;
  data?: GraphData[];
  threshold?: number | number[];
  counted?: string;
  currAvg?: number;
  prevAvg?: number;
};

// Insight Types
export interface Insights {
  celeb?: { speech: db.SampleSpeech; hadAbove: boolean } | undefined; // may be undefined if the speech doesn't have a name, which shouldn't happen but is allowed by the db types
  mostSentenceStarter?: MostSentenceStarter | undefined;
  poodliAlertCounts?: PoodliAlertCounts | undefined;
  topCorrelation?: CorrelationResult | undefined;
}

export const analyticExplainerVideos = {
  [db.AggregateAnalyticEnum.CONCISENESS]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Conciseness.mp4",
  [db.AggregateAnalyticEnum.FILLER]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/FillerWords.mp4",
  [db.AggregateAnalyticEnum.WEAK]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/WeakWords.mp4",
  [db.AggregateAnalyticEnum.REPETITION]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Repetition.mp4",
  [db.AggregateAnalyticEnum.EYE_CONTACT]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/EyeContact.mp4",
  [db.AggregateAnalyticEnum.CENTERING]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Centering.mp4",
  [db.AggregateAnalyticEnum.PACING]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Pacing.mp4",
  [db.AggregateAnalyticEnum.TALK_TIME]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/TalkTime.mp4",
  [db.AggregateAnalyticEnum.MONOLOGUES]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Monologues.mp4",
  [db.AggregateAnalyticEnum.SENSITIVE]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/NonInclusiveness.mp4",
  [db.AggregateAnalyticEnum.SENTENCE_STARTERS]:
    "https://storage.googleapis.com/yoodli-public/misc-product-videos/analytics-explainers/Sentence_Starters_on_Yoodli.webm",
};

export enum DashboardInsightCategories {
  MOST_SENTENCE_STARTER = "Most Sentence Starter",
  POODLI_ALERT_COUNTS = "Poodli Alert Counts",
  CELEB = "Celebrity Comparison",
  TOP_CORRELATION = "Analytic Correlation", // TODO halp copy
}

export enum DashboardInsightCTA {
  MOST_SENTENCE_STARTER = "Check out our free course on speech delivery",
  POODLI_ALERT_COUNTS = "Learn about coaching alerts",
  CELEB = "View {{celeb}}'s speech summary",
  TOP_CORRELATION = "Learn more about {{analytic}}",
}

export const FOCUS_AREAS: FocusAreas = {
  authority: {
    label: "Authority",
    identifier: FocusAreasEnum.AUTHORITY,
    icon: <AuthorityIcon />,
    color: getDynamicColor("focusArea.authority"),
    analytics: ["filler", "weak", "repetition"],
    definition:
      "Come off as confident, knowledgable, and skilled when speaking by keeping filler words, weak words, and repetition to a minimum.",
  },
  presence: {
    label: "Presence",
    identifier: FocusAreasEnum.PRESENCE,

    icon: <PresenceIcon />,
    color: getDynamicColor("focusArea.presence"),
    analytics: ["eyeContact", "centering", "pacing", "conciseness"],
    definition:
      "Engage your audience by being fully present. Maintain eye contact, center in your space, and speak at a good pace to connect in any setting.",
  },
  listening: {
    label: "Listening",
    identifier: FocusAreasEnum.LISTENING,

    icon: <ListeningIcon />,
    color: getDynamicColor("focusArea.listening"),
    analytics: ["talkTime", "monologues", "questions"],
    definition:
      "Good communication is a two-way street! Listen well by paying attention to talk time, avoiding taking over a conversation with monologues, and asking relevant or clarifying questions.",
  },

  usage: {
    label: "Usage",
    identifier: FocusAreasEnum.USAGE,

    icon: <UsageIcon />,
    color: getDynamicColor("focusArea.usage"),
    analytics: [
      db.UnitCountAnalyticEnum.NUM_YOODLIS,
      db.UnitCountAnalyticEnum.NUM_COMMENTS_GIVEN,
      db.UnitCountAnalyticEnum.NUM_COMMENTS_RECEIVED,
      db.UnitCountAnalyticEnum.NUM_SHARED_WITH_ME,
    ],
    definition:
      "Good communication is a team sport! Practice together using Yoodli, practicing speaking exercise, and sharing your Yoodlis with mentors or peers for feedback.",
  },
};

export enum NextStepGoal {
  LOW = "low",
  HIGH = "high",
}

// Note: commented out properties are not yet implemented/missing the required copy/data
export const FOCUS_ANALYTICS = {
  [db.AggregateAnalyticEnum.FILLER]: {
    identifier: db.AggregateAnalyticEnum.FILLER,
    label: "Filler Words",
    definition:
      "Filler words are words or vocalizations like “um” or “uh” used to fill pauses during speech.",
    threshold: 3,
    focusArea: FOCUS_AREAS.authority,
    nextSteps: {
      goal: NextStepGoal.LOW,
      positive: "Great job! You use very few filler words and pause to think when you speak.",
      negative: "If you need time to think, try slowing down and pausing instead!",
    },
  },
  [db.AggregateAnalyticEnum.WEAK]: {
    identifier: db.AggregateAnalyticEnum.WEAK,
    label: "Weak Words",
    definition:
      "Words like “kind of” or “just” are often used to add emphasis or indicate uncertainty, but add little meaning.",
    threshold: 3,
    focusArea: FOCUS_AREAS.authority,
    nextSteps: {
      goal: NextStepGoal.LOW,
      positive: "Great job! You hardly use any weak words.",
      negative:
        "Take notice of when you use weak words or phrases and replace them with firm, decisive language.",
    },
  },
  [db.AggregateAnalyticEnum.REPETITION]: {
    identifier: db.AggregateAnalyticEnum.REPETITION,
    label: "Repetition",
    definition:
      "When used for emphasis, it can be very, very effective. Sometimes, repetition is a word stumble or is used to fill silence.",
    threshold: 2,
    focusArea: FOCUS_AREAS.authority,
    nextSteps: {
      goal: NextStepGoal.LOW,
      positive: "Great job! You hardly ever repeat yourself.",
      negative:
        "Try slowing down to avoid stumbling over your words and only repeat words or phrases intentionally.",
    },
  },
  [db.AggregateAnalyticEnum.EYE_CONTACT]: {
    identifier: db.AggregateAnalyticEnum.EYE_CONTACT,
    label: "Eye Contact",
    definition:
      "Good eye contact communicates respect, interest, and confidence. Online, this equates to looking at the camera.",
    threshold: [35, 100],
    focusArea: FOCUS_AREAS.presence,
    nextSteps: {
      threshold: 35,
      goal: NextStepGoal.HIGH,
      positive: "Great job using eye contact to engage your virtual audience!",
      negative: "Pretend your camera is your audience’s eyes.",
    },
    learnMore: {
      title: "Learn about the Triangle Method",
      urlTitle: "Read “What is the Triangle Method?”",
      url: `${baseUrl}/blog/what-is-the-triangle-method-how-to-avoid-awkward-eye-contact`,
    },
  },
  [db.AggregateAnalyticEnum.CENTERING]: {
    identifier: db.AggregateAnalyticEnum.CENTERING,
    label: "Centering",
    definition: "Centering yourself on the screen projects confidence and allows you to.",
    threshold: [80, 100],
    focusArea: FOCUS_AREAS.presence,
    nextSteps: {
      threshold: 51,
      goal: NextStepGoal.HIGH,
      positive: "Way to find your center 🙏",
      negative: "Keep practicing! Turn on the positioning guide when recording a practice speech!",
    },
    // learnMore: {
    //   title: "Learn about the Triangle Method",
    //   urlTitle: "Read “What is the Triangle Method?”",
    //   url: "https://app.yoodli.ai/blog/what-is-the-triangle-method-how-to-avoid-awkward-eye-contact",
    // },
  },
  [db.AggregateAnalyticEnum.PACING]: {
    identifier: db.AggregateAnalyticEnum.PACING,
    label: "Pacing",
    definition: "The average rate at which you speak, measured in words per minute.",
    threshold: [115, 170],
    focusArea: FOCUS_AREAS.presence,
    customValueTransformer: (value: number): string =>
      `${roundToPrecision(Math.abs(value), 1)} WPM`,
    // nextSteps: {
    //   positive: "",
    //   negative: "",
    // },
    learnMore: {
      urlTitle: "Watch an explainer video",
      lightboxUrl: analyticExplainerVideos[db.AggregateAnalyticEnum.PACING],
    },
  },
  [db.AggregateAnalyticEnum.TALK_TIME]: {
    identifier: db.AggregateAnalyticEnum.TALK_TIME,
    label: "Talk Time",
    definition:
      "A percentage breakdown of how long participants talked during calls. Best used for conversational group meetings or one-on-one calls.",
    threshold: undefined,
    focusArea: FOCUS_AREAS.listening,
    learnMore: {
      urlTitle: "Watch an explainer video",
      lightboxUrl: analyticExplainerVideos[db.AggregateAnalyticEnum.TALK_TIME],
    },
    nextSteps: {
      threshold: 0.75,
      goal: NextStepGoal.LOW,
      positive: "Pay attention to the dynamics and flow of the conversation.",
      negative: "Pausing to leave space for questions or thoughts encourages participation.",
    },
  },
  [db.AggregateAnalyticEnum.MONOLOGUES]: {
    identifier: db.AggregateAnalyticEnum.MONOLOGUES,
    label: "Monologues",
    definition:
      "Monologues can occur when one person talks for a long time without giving anyone else a chance to speak in conversational settings. If you’re talking for over 2 minutes straight, we’ll let you know!",
    threshold: undefined,
    focusArea: FOCUS_AREAS.listening,
    customValueTransformer: (value: number): string => `${secondsToHms(value)}`,
    learnMore: {
      urlTitle: "Watch an explainer video",
      lightboxUrl: analyticExplainerVideos[db.AggregateAnalyticEnum.MONOLOGUES],
    },
    nextSteps: {
      threshold: 0,
      goal: NextStepGoal.LOW,
      positive: "Way to be concise and leave space for others!",
      negative: "Encourage dialogue by pausing to leave space for questions.",
    },
  },
  [db.AggregateAnalyticEnum.QUESTIONS]: {
    identifier: db.AggregateAnalyticEnum.QUESTIONS,
    label: "Questions Asked",
    definition:
      "Asking questions is a crucial element of active listening and shows that you have been paying attention and have an interest in others.",
    threshold: undefined,
    focusArea: FOCUS_AREAS.listening,
    learnMore: {
      title: "Learn More",
      urlTitle: "How to Improve Your Listening Skills",
      url: `${baseUrl}/blog/how-to-improve-your-listening-skills`,
    },
    nextSteps: {
      threshold: 1,
      goal: NextStepGoal.HIGH,
      positive: "Great job asking questions!",
      negative:
        "Even if you think you don’t have questions, asking for elaboration is always a good way to show interest!",
    },
  },
  [db.UnitCountAnalyticEnum.NUM_YOODLIS]: {
    identifier: db.UnitCountAnalyticEnum.NUM_YOODLIS,
    label: "Usage",
    definition:
      "Consistency over time leads to improvement. Use Yoodli for practice, warm-up and game time!",
    threshold: undefined,
    focusArea: FOCUS_AREAS.usage,
    nextSteps: {
      random: [
        "Connect your calendar and use the desktop app on your next call",
        "Play Spin a Yarn to warm up before your next call",
        "Check out the analytics on your latest Yoodli",
      ],
    },
  },
  [db.UnitCountAnalyticEnum.NUM_COMMENTS_GIVEN]: {
    identifier: db.UnitCountAnalyticEnum.NUM_COMMENTS_GIVEN,
    label: "Comments Given",
    definition:
      "Feedback is essential to improvement! Share your thoughts with timestamped comments.",
    threshold: undefined,
    focusArea: FOCUS_AREAS.usage,
    // nextSteps: {
    //   positive: "",
    //   negative: "",
    // },
  },
  [db.UnitCountAnalyticEnum.NUM_COMMENTS_RECEIVED]: {
    identifier: db.UnitCountAnalyticEnum.NUM_COMMENTS_RECEIVED,
    label: "Comments Received",
    definition:
      "Feedback is essential to improvement! Check out comments from your peers and the AI Speech Coach.",
    threshold: undefined,
    focusArea: FOCUS_AREAS.usage,
    // nextSteps: {
    //   positive: "",
    //   negative: "",
    // },
  },
  [db.UnitCountAnalyticEnum.NUM_SHARED_WITH_ME]: {
    identifier: db.UnitCountAnalyticEnum.NUM_SHARED_WITH_ME,
    label: "Shared With Me",
    definition:
      "Better communication is a team sport. View your peers’ speeches and leave feedback for next time!",
    threshold: undefined,
    focusArea: FOCUS_AREAS.usage,
    // nextSteps: {
    //   positive: "",
    //   negative: "",
    // },
  },
  [db.AggregateAnalyticEnum.CONCISENESS]: {
    identifier: db.AggregateAnalyticEnum.CONCISENESS,
    label: "Conciseness",
    definition:
      "The percentage of excess words that have been said. The best leaders say less, but with impact.",
    threshold: CONCISENESS_THRESHOLD,
    focusArea: FOCUS_AREAS.presence,
    customValueTransformer: (value: number): string => {
      return `${Math.round((1 - value) * 100)}% Excess`;
    },
    customThresholdTransformer: (value: number): string => {
      return `<${Math.round((1 - value) * 100)}% Excess`;
    },
    changeOverTime: (
      prevAvg: number,
      currAvg: number
    ): { change: number; displayChange: string } => {
      const change = 1 - currAvg - (1 - prevAvg);
      const percent = Math.round(change * 100);
      return { change: percent, displayChange: `${Math.abs(percent)}%` };
    },
    learnMore: {
      urlTitle: "Watch an explainer video",
      lightboxUrl: analyticExplainerVideos[db.AggregateAnalyticEnum.CONCISENESS],
    },
    nextSteps: {
      random: ["Tip: jot down a few points before your call starts to help you stay on track!"],
    },
  },
};

export const DEFAULT_FOCUS_ANALYTICS: {
  coachOrWhitelabel: HoistedAnalytic[];
  endUser: HoistedAnalytic[];
} = {
  coachOrWhitelabel: Object.entries(FOCUS_ANALYTICS)
    .filter((entry) => {
      const analyticIdentifier = entry[0] as AggregateAnalyticEnum;
      return [
        db.AggregateAnalyticEnum.PACING,
        db.AggregateAnalyticEnum.TALK_TIME,
        db.AggregateAnalyticEnum.QUESTIONS,
      ].includes(analyticIdentifier);
    })
    .map((entry) => entry[1]),
  endUser: Object.entries(FOCUS_ANALYTICS)
    .filter((entry) => {
      const analyticIdentifier = entry[0] as AggregateAnalyticEnum;
      return [
        db.AggregateAnalyticEnum.FILLER,
        db.AggregateAnalyticEnum.PACING,
        db.AggregateAnalyticEnum.QUESTIONS,
      ].includes(analyticIdentifier);
    })
    .map((entry) => entry[1]),
};

export const WORD_COUNT_ANALYTICS: { identifier: DashboardAnalyticId; title: string }[] = [
  { identifier: db.AggregateAnalyticEnum.FILLER, title: "Most used filler words" },
  { identifier: db.AggregateAnalyticEnum.WEAK, title: "Most used weak words" },
  // TODO: Once backend is complete, uncomment this to show "Repeated Frequently: 'I did, I did'" in the AnalyticDetail
  // { identifier: db.AggregateAnalyticEnum.REPETITION, title: "Repeated frequently" },
];
