// Modules
import React from "react";
import { useHistory } from "react-router";

// Components
import { Switch, styled, SwitchProps, Stack, Button, Box } from "@mui/material";

// Utils
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import PlanAndBilling from "./PlanAndBilling/PlanAndBilling";
import useIsPricingEnabled from "lib-frontend/hooks/useIsPricingEnabled";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SettingsTab } from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

const TAB_STYLES = {
  fontWeight: 600,
  fontSize: 14,
  borderBottom: "2px solid",
  borderRadius: 0,
  cursor: "pointer",
  transition: "all 0.2s ease-out",
};

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} sx={props.sx} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: getDynamicColor("light1"),
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : getDynamicColor("primary"),
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: getDynamicColor("primary"),
      border: `6px solid ${getDynamicColor("light1")}`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: getDynamicColor(theme.palette.mode === "light" ? "dark1" : "dark6"),
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Settings(): JSX.Element {
  const history = useHistory();
  const isPricingEnabled = useIsPricingEnabled();
  const isBilling = new URLSearchParams(history.location.search).get("tab") === SettingsTab.BILLING;
  const HEADER_HEIGHT = useIsSmallScreen() ? 60 : 80;

  // state
  const [activeTab, setActiveTab] = React.useState<SettingsTab>(SettingsTab.GENERAL);

  // effects
  React.useLayoutEffect(() => {
    if (isBilling) {
      setActiveTab(SettingsTab.BILLING);
    }
  }, [isBilling]);

  // handlers
  const handleTabClick = (tab: SettingsTab) => {
    setActiveTab(tab);
    let settingsTab = SettingsTab.GENERAL;
    if (tab === SettingsTab.BILLING) {
      history.replace(`?tab=${SettingsTab.BILLING}`);
      settingsTab = SettingsTab.BILLING;
    } else {
      history.replace(WebServerExternalPath.ACCOUNT);
    }
    Instrumentation.logSettingsPageViewed(settingsTab);
  };

  // renderers
  const renderTabContent = () => {
    let content = <GeneralSettings />;
    if (isPricingEnabled && activeTab === SettingsTab.BILLING) {
      content = <PlanAndBilling headerHeight={HEADER_HEIGHT} />;
    }
    return (
      <Box
        sx={{
          maxWidth: "100%",
          mr: "auto",
        }}
      >
        {content}
      </Box>
    );
  };
  return (
    <Stack
      sx={{
        backgroundColor: getDynamicColor("light1"),
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        gap={{ xs: 2, md: 4 }}
        sx={{
          height: HEADER_HEIGHT,
          width: "100%",
          background: getDynamicColor("light2"),
          py: 2,
          pb: { xs: 1.5, md: 2 },
          px: { xs: 2, md: 4 },
          mx: "auto",
        }}
        alignItems="flex-end"
      >
        <Button
          variant="text_small"
          sx={{
            ...TAB_STYLES,
            color:
              activeTab === SettingsTab.GENERAL
                ? getDynamicColor("primary")
                : getDynamicColor("purple3"),
            borderColor:
              activeTab === SettingsTab.GENERAL ? getDynamicColor("primary") : "transparent",
          }}
          onClick={() => handleTabClick(SettingsTab.GENERAL)}
        >
          General Settings
        </Button>
        {isPricingEnabled && (
          <Button
            variant="text_small"
            sx={{
              ...TAB_STYLES,
              color:
                activeTab === SettingsTab.BILLING
                  ? getDynamicColor("primary")
                  : getDynamicColor("purple3"),
              borderColor:
                activeTab === SettingsTab.BILLING ? getDynamicColor("primary") : "transparent",
            }}
            onClick={() => handleTabClick(SettingsTab.BILLING)}
          >
            Plan & Billing
          </Button>
        )}
      </Stack>
      {renderTabContent()}
    </Stack>
  );
}

export default Settings;
