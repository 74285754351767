import React from "react";

// Components
import { CheckCircleRounded as CheckIcon } from "@mui/icons-material";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { BotProcessingState } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotTypes";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Utils
import { COACHBOT_INFO, CoachBotInfoRow } from "../../CoachBotInfoRow";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type YoureAllSetProps = {
  coachBotName: string;
  botProcessingState: BotProcessingState;
};
// TODO @dwiegand: start a timer on mount for 10 minutes so we can actually change the est. time remaining.
// it's a bit weird to say "x minutes remaining" and never update it IMO

export const YoureAllSet = ({
  // coachBotName,
  botProcessingState,
}: YoureAllSetProps): JSX.Element => {
  // include readyToProcess in processing state since it starts processing right away
  const isProcessing = ["processing", "readyToProcess"].includes(botProcessingState);
  const renderProcessingState = () => {
    return (
      <Stack
        direction="column"
        alignItems="center"
        gap={{ xs: 2, md: 4 }}
        sx={{
          px: { xs: 3, md: 0 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={isProcessing ? 2 : 1}
          justifyContent="center"
          alignItems="center"
        >
          {isProcessing ? (
            <CircularProgress
              sx={{
                height: { xs: "30px !important", md: "20px !important" },
                width: { xs: "30px !important", md: "20px !important" },
              }}
            />
          ) : (
            <CheckIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
                fontSize: { xs: "32px", md: "24px" },
              }}
            />
          )}
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: 400,
              fontFamily: "poppins",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            PROCESSING
            {isProcessing ? (
              <>
                <span>: </span>
                <strong>10 Minutes</strong>
                <span> (est. remaining)</span>
              </>
            ) : (
              " COMPLETE"
            )}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "poppins",
            maxWidth: 500,
            textAlign: "center",
          }}
        >
          You can leave this page and come back later.
          {isProcessing ? " We'll send you an email once processing is complete" : ""}
        </Typography>
      </Stack>
    );
  };
  return (
    <Stack
      direction="column"
      alignItems="center"
      gap={4}
      sx={{
        py: { xs: 2, md: 6 },
      }}
    >
      <YoureAllSetCopy />
      {renderProcessingState()}
      <Stack
        direction="column"
        gap={{ xs: 3, md: 6 }}
        sx={{
          px: { xs: 3, md: 0 },
        }}
      >
        {COACHBOT_INFO.done.map((info, index) => (
          <CoachBotInfoRow key={index} icon={info.icon} copy={info.copy} />
        ))}
      </Stack>
    </Stack>
  );
};

export const YoureAllSetCopy = (): JSX.Element => {
  return (
    <Box
      sx={{
        fontSize: 30,
        fontWeight: 700,
        fontFamily: "poppins",
        px: { xs: 1, md: 2 },
        textAlign: "center",
      }}
    >
      🎉{" "}
      <AnimatedGradientText
        animationspeeds={3}
        style={{
          display: "inline-block",
          fontFamily: "poppins",
        }}
      >
        You’re all set!
      </AnimatedGradientText>
    </Box>
  );
};
