// Components
import { Button, Stack, Typography } from "@mui/material";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Utils
import InterviewBank from "./InterviewBank";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsExtraSmallScreen, useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";

/**
 * Name: Actually Hub or Organization Name (not just Hub)
 * InterviewBanks: All Interview Banks available under the Hub/Organization
 * SetModalStatus: When editing or creating an interview bank, set the status to
 *  determine which modal view to show
 * SetSelectedInterviewBank: Select the interview bank to tell the modal which interview bank it's updating (since that's own by the OrgSettings or Hubs page)
 * canUpdateBank: Whether the interview banks are read-only or not.
 * showBankHubsDetails: Whether to show the hubs associated with an interview bank
 *
 */
type InterviewsTabProps = {
  interviewBanks: InterviewBankResponse[];
  setModalStatus: (val: HubModalStatus) => void;
  setSelectedInterviewBank: (val: InterviewBankResponse) => void;
  entityName: string;
  canUpdateBank: boolean;
  showBankHubsDetails: boolean;
  renderLoader?: () => JSX.Element;
};

export function InterviewsTab({
  interviewBanks,
  setModalStatus,
  setSelectedInterviewBank,
  entityName,
  canUpdateBank,
  showBankHubsDetails,
  renderLoader,
}: InterviewsTabProps): JSX.Element {
  // Selected interview bank is only used for calls made from modals, so no need to set unless a modal or menu is triggered
  // Modals and menus are treated the same way, so this just keeps doing that.
  const handleSetInterviewBankModal = (
    bank: InterviewBankResponse,
    modalStatus: HubModalStatus
  ) => {
    setSelectedInterviewBank(bank);
    setModalStatus(modalStatus);
  };

  // Used to know whether to put the hub details in the accordion or in the header.
  const isSmallScreen = useIsSmallScreen();
  const isExtraSmallScreen = useIsExtraSmallScreen();

  const renderAddNewTemplateButton = () => {
    return (
      <Button
        onClick={() => setModalStatus(HubModalStatus.CREATE_INTERVIEW_BANK)}
        variant="contained"
        size="xlarge"
        sx={{
          fontSize: 14,
          whiteSpace: "nowrap",
        }}
      >
        Add{isExtraSmallScreen ? " New" : " Interview Bank"}
      </Button>
    );
  };

  if (!interviewBanks && renderLoader) {
    return renderLoader();
  }

  return (
    <Stack
      gap={3}
      sx={{ alignItems: "center", maxWidth: "xl", p: { xs: 3, md: 0 } }}
      direction="column"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          sx={{
            fontFamily: "poppins",
            py: interviewBanks?.length === 0 ? { xs: 0, md: 4 } : 0,
          }}
        >
          {interviewBanks?.length === 0 ? (
            <AnimatedGradientText
              sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Create New Interview Bank
            </AnimatedGradientText>
          ) : (
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              My Interview Banks
            </Typography>
          )}

          {interviewBanks?.length === 0 && (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 600,
              }}
            >
              {`When members of ${entityName} start an interview practice in Yoodli, they will be able to
        choose a question bank defined here.`}
            </Typography>
          )}
        </Stack>
        {renderAddNewTemplateButton()}
      </Stack>

      {interviewBanks.map((bank, index) => (
        // Could do the set selected interview bank on click here but ¯\_(ツ)_/¯
        // Small screen should be handled in global state, but still better to only calc once than on all interview banks themselves
        <InterviewBank
          isSmallScreen={isSmallScreen}
          key={`bank-${index}`}
          index={index}
          interviewBank={bank}
          canUpdateBank={canUpdateBank}
          handleBankUpdateModalSelection={(modalStatus: HubModalStatus) =>
            handleSetInterviewBankModal(bank, modalStatus)
          }
          showBankHubsDetails={showBankHubsDetails}
        />
      ))}
    </Stack>
  );
}
