// Components
import { Box, Tooltip, Stack, Typography } from "@mui/material";
import { TopUsedWordType } from "components/Dashboard/DashboardTypes";
import { ReactComponent as TopUsedIcon } from "images/icons/icon-speech-bubble.svg";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DetailTitle } from "./AnalyticDetail";

type TopUsedProps = {
  topUsedWords: { title: string; data: TopUsedWordType[] };
  numDays: number;
};
const TopUsed = ({ topUsedWords, numDays }: TopUsedProps): JSX.Element => {
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            width: 30,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 18,
              width: 18,
              backgroundColor: getDynamicColor("purple3"),
              borderRadius: "50%",
              svg: { fill: getDynamicColor("light1"), height: 10, width: 10 },
            }}
          >
            <TopUsedIcon />
          </Stack>
        </Box>
        <DetailTitle title={topUsedWords.title} />
      </Stack>
      <Stack direction="row" gap={2}>
        {topUsedWords.data.map((word) => (
          <Tooltip
            title={`You used the word "${word.word}" \n ${word.count} times in the last ${numDays} days`}
            key={word.word}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "10px !important",
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: "pre-line",
                  textAlign: "center",
                },
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                backgroundColor: getDynamicColor("dark1"),
                color: getDynamicColor("dark6"),
                borderRadius: "4px",
                px: 0.5,
                py: 0.25,
                lineHeight: 1.4,
                cursor: "default",
              }}
            >
              "{word.word}"
            </Typography>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
};

export default TopUsed;
