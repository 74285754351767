import React from "react";

// Components
import { Box, Button, Stack, Typography, CircularProgress } from "@mui/material";
import {
  CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_MAX_SCORE,
  CUSTOM_GOAL_MIN_SCORE,
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
  CreateConvoScenarioStepId,
  GoalHooks,
} from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { MinMaxScoreSelector } from "./MinMaxScoreSelector";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CreateCustomGoalRequest,
  CreateScoreGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { ScoreGoal } from "lib-fullstack/db";
import { getGoalRephrase } from "utils/GPTUtils";

export const CreateRatedGoalSteps = (
  ratedGoal: CreateScoreGoalRequest,
  setRatedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks
): WizardStep[] => {
  const handleUpdateRatedGoal = (key: keyof ScoreGoal, val: string | number) => {
    setRatedGoal((prev) => ({ ...prev, [key]: val }));
  };

  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <ConvoScenarioStepWrapper
        title={goalEditId ? "Update Rated Goal" : "Create a Rated Goal"}
        subTitle="Name this goal and add a short description"
      >
        <Stack
          gap={6}
          sx={{
            position: "relative",
          }}
        >
          <YoodliLabeledInput
            label="Name"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                autoFocus
                maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                InputLabelProps={{ shrink: true }}
                placeholder="e.g. Seek feedback"
                value={ratedGoal.name}
                onChange={(e) => handleUpdateRatedGoal("name", e.target.value)}
              />
            }
          />
          <YoodliLabeledInput
            label="Add a member-facing description (optional)"
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <YoodliTextfield
                multiline
                minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                maxChars={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
                InputProps={{
                  className: "blockEnterToNavigate",
                }}
                placeholder="e.g. Ask for constructive feedback during your conversation."
                InputLabelProps={{ shrink: true }}
                value={ratedGoal.userDescription ?? ""}
                onChange={(e) => handleUpdateRatedGoal("userDescription", e.target.value)}
              />
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
    ),
    validate: () => ratedGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: true,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: true,
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <ConvoScenarioStepWrapper
        title={goalEditId ? "Update Rated Goal" : "Create a Rated Goal"}
        subTitle={
          <>
            <Typography component="div">
              Explain the goal to the AI, then set and define the scoring scale.
            </Typography>
            <Typography component="div">All fields are required.</Typography>
          </>
        }
      >
        <Stack gap={4} sx={{ color: getDynamicColor("purple3") }}>
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  Explanation
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalDescriptionLoading(true);
                    const rephrase = await getGoalRephrase(
                      ratedGoal.aiDescription,
                      "description",
                      ratedGoal.name,
                      ""
                    );
                    handleUpdateRatedGoal("aiDescription", rephrase);
                    goalHooks.setGoalDescriptionLoading(false);
                  }}
                >
                  {ratedGoal.aiDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalDescriptionLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  InputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  maxChars={CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS}
                  placeholder="e.g. Seeking feedback can consist of asking follow-up questions, asking open-ended questions, or asking for examples where something was done well or poorly."
                  InputLabelProps={{ shrink: true }}
                  value={ratedGoal.aiDescription}
                  onChange={(e) => handleUpdateRatedGoal("aiDescription", e.target.value)}
                  disabled={goalHooks.goalDescriptionLoading}
                />
              </Box>
            }
          />
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Score
            </Typography>
            <Stack
              direction="row"
              gap={3}
              sx={{ justifyContent: "space-between", flexWrap: "wrap" }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  fontWeight: 400,
                  color: getDynamicColor("dark5"),
                  maxWidth: { sm: "100%", md: "400px" },
                }}
              >
                Set the max score members can achieve for this goal (max 10). Define failure (lowest
                possible score) and success (highest possible score) below.
              </Typography>
              <MinMaxScoreSelector
                min={{ value: CUSTOM_GOAL_MIN_SCORE, disabled: true }}
                max={{
                  value: ratedGoal.maxScore,
                  minValue: CUSTOM_GOAL_MIN_SCORE,
                  maxValue: CUSTOM_GOAL_MAX_SCORE,
                  onChange: (value: string) => {
                    if (value) {
                      localStorage.setItem("customGoalMaxScore", value);
                    }
                    handleUpdateRatedGoal("maxScore", value ? Number(value) : undefined);
                  },
                }}
              />
            </Stack>
          </Stack>
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  {`Define a score of ${CUSTOM_GOAL_MIN_SCORE} (minimum score)`}
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalLowScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      ratedGoal.aiDescription,
                      "low",
                      ratedGoal.name,
                      ratedGoal.aiDescription
                    );
                    handleUpdateRatedGoal("lowScoreDescription", rephrase);
                    goalHooks.setGoalLowScoreLoading(false);
                  }}
                >
                  {ratedGoal.lowScoreDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalLowScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  InputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  placeholder="e.g. The member never asks any open-ended or follow-up questions asking for feedback, and does not receive direction and advice."
                  InputLabelProps={{ shrink: true }}
                  value={ratedGoal.lowScoreDescription}
                  onChange={(e) => handleUpdateRatedGoal("lowScoreDescription", e.target.value)}
                  disabled={goalHooks.goalLowScoreLoading}
                />
              </Box>
            }
          />
          <YoodliLabeledInput
            label={
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    color: getDynamicColor("dark5"),
                    mb: 0.5,
                  }}
                >
                  {`Define a score of ${ratedGoal.maxScore ?? ""} (maximum score)`}
                </Typography>
                <Button
                  sx={{ fontSize: 12, color: getDynamicColor("dark4"), ml: "auto" }}
                  onClick={async () => {
                    goalHooks.setGoalHighScoreLoading(true);
                    const rephrase = await getGoalRephrase(
                      ratedGoal.aiDescription,
                      "high",
                      ratedGoal.name,
                      ratedGoal.aiDescription
                    );
                    handleUpdateRatedGoal("highScoreDescription", rephrase);
                    goalHooks.setGoalHighScoreLoading(false);
                  }}
                >
                  {ratedGoal.highScoreDescription.length > 0 ? "Rephrase" : "Generate"}
                </Button>
              </Stack>
            }
            labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
            inputEl={
              <Box sx={{ position: "relative" }}>
                {goalHooks.goalHighScoreLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <YoodliTextfield
                  multiline
                  minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                  maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                  InputProps={{
                    className: "blockEnterToNavigate",
                  }}
                  placeholder="e.g. The member asked open-ended and/or follow-up questions asking for feedback, and receives direction and advice."
                  InputLabelProps={{ shrink: true }}
                  value={ratedGoal.highScoreDescription}
                  onChange={(e) => handleUpdateRatedGoal("highScoreDescription", e.target.value)}
                  disabled={goalHooks.goalHighScoreLoading}
                />
              </Box>
            }
          />
        </Stack>
      </ConvoScenarioStepWrapper>
    ),
    validate: () =>
      ratedGoal.aiDescription.length > 0 &&
      ratedGoal.highScoreDescription.length > 0 &&
      ratedGoal.lowScoreDescription.length > 0 &&
      ratedGoal.maxScore > 1,
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(ratedGoal);
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: true,
  } as WizardStep;
  return [descStep, evaluationStep];
};
