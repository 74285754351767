import firebase from "firebase/app";
import { doLogout } from "modules/FirebaseModule";
import React from "react";
import { useLocation } from "react-router";

// Components
import { Button, Stack, Typography, Box } from "@mui/material";

// Assets
import YoodliLogoText from "../../../images/logos/yoodli_logo.svg";

// Utils
import { getOrgAccessInfo } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { AuthQueryParams } from "lib-frontend/utils/queryParams";
import { UITestId } from "lib-fullstack/utils/enums";

const VERIFY_EMAIL_RELEASE_DATE = new Date("2023-02-28").toISOString();

type EmailSentProps = {
  sendVerificationEmail: React.MouseEventHandler<HTMLButtonElement>;
  emailSent: boolean;
  setEmailSent: (val: boolean) => void;
};

export default function EmailSent({
  sendVerificationEmail,
  emailSent,
  setEmailSent,
}: EmailSentProps): JSX.Element {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteConf = getStaticFullSiteConf();
  const userCreatedDate = new Date(firebase.auth().currentUser.metadata.creationTime).toISOString();
  const isNewUser = userCreatedDate > VERIFY_EMAIL_RELEASE_DATE;

  const [orgLogo, setOrgLogo] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 3000);
    }
  }, [emailSent]);

  React.useEffect(() => {
    const fetchOrgLogo = async () => {
      if (queryParams.has(AuthQueryParams.HUB_ID)) {
        const orgLogo = (await getOrgAccessInfo({ hubId: queryParams.get(AuthQueryParams.HUB_ID) }))
          .org_logo_url;
        setOrgLogo(orgLogo);
      }
    };

    fetchOrgLogo().catch((error) => console.error("Error fetching org logo", error));
  }, []);

  const handleDoLogout = () => {
    doLogout().catch(console.error);
  };
  const handleResendEmailClick = (e) => {
    sendVerificationEmail(e);
  };
  return (
    <Stack gap={2} alignItems="center">
      <img src={orgLogo ?? siteConf?.logo?.url ?? YoodliLogoText} width={100} alt="Yoodli logo" />
      <Typography
        fontSize="32px"
        fontWeight={500}
        textAlign="center"
        data-testid={UITestId.VerifyYourEmailLabel}
      >
        Verify your email
      </Typography>
      <Typography fontSize="16px" fontWeight={500} textAlign="center" maxWidth={485}>
        {isNewUser
          ? "You should receive an email to the following address shortly!"
          : "Confirm your email address to keep Yoodlin'!"}{" "}
        We recommend checking your spam folder just in case.
      </Typography>
      <Box
        sx={{
          backgroundColor: getDynamicColor("light2"),
          textAlign: "center",
          py: 2,
          px: 3,
          width: "min(100%, 340px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography data-logrocket-hidden fontWeight={700}>
          {firebase.auth().currentUser.email}
        </Typography>
      </Box>

      <Stack
        sx={{
          width: "100%",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ mx: "auto", width: "min(100%, 300px)" }}
          onClick={handleResendEmailClick}
          data-testid={UITestId.ResendEmailButton}
        >
          {emailSent ? "Email sent!" : "Resend email"}
        </Button>
        <Button sx={{ mx: "auto", width: "min(100%, 300px)", py: 1 }} onClick={handleDoLogout}>
          Sign out
        </Button>
      </Stack>
    </Stack>
  );
}
