import React from "react";

// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

type ConvoScenarioStepWrapperProps = {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
};

export const ConvoScenarioStepWrapper = ({
  title,
  subTitle,
  children,
}: React.PropsWithChildren & ConvoScenarioStepWrapperProps): JSX.Element => {
  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        width: "100%",
        maxWidth: "md",
        mx: "auto",
        pt: { xs: 2, md: 4 },
        flexGrow: 1,
        pb: { xs: `${WIZARD_FOOTER_HEIGHT}px`, md: 8 },
        px: { xs: 2, md: 0 },
      }}
      gap={4}
    >
      {(title || subTitle) && (
        <Stack gap={2}>
          <Stack gap={1}>
            {title && (
              <Typography
                component="div"
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: 700,
                  color: getDynamicColor("purple3"),
                }}
              >
                {title}
              </Typography>
            )}
            {subTitle && (
              <Typography
                component="div"
                sx={{
                  fontSize: 12,
                  color: getDynamicColor("purple3"),
                  fontWeight: 500,
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
      {children}
    </Stack>
  );
};
