import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, keyframes } from "@mui/material";
import { GoalLabels, distributeWeightEvenly } from "components/ConvoScenarios/convoScenarioUtils";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Assets
import { ReactComponent as LockIcon } from "images/icons/icon-lock-locked.svg";
import { ReactComponent as UnlockIcon } from "images/icons/icon-lock-unlocked.svg";

// Utils
import { GoalItem } from "./GoalSelector";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { DefaultGoal } from "lib-fullstack/db";
import { reorder } from "utils/Utilities";

type GoalWeightSelectorProps = {
  goals: (DefaultGoal | string)[];
  goalWeights: Record<string, number>;
  customGoals: CustomGoalItemWithUsage[];
  customGoalsLoading: boolean;
  goalOrder: (string | DefaultGoal)[];
  lockedWeightGoalIds: (string | DefaultGoal)[];
  handleUpdateLockedWeightGoalIds: (lockedWeightGoalIds: (string | DefaultGoal)[]) => void;
  handleUpdateGoalOrder: (goals: (DefaultGoal | string)[]) => void;
  handleUpdateGoalWeights: (weights: Record<string, number>) => void;
};

const DRAGGABLE_GOAL_STYLES = {
  display: "flex",
  flexDirection: "row",
  gap: 3,
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 1.5,
  backgroundColor: getDynamicColor("light1"),
  border: `1px solid ${getDynamicColor("dark4")}`,
  padding: 2,
  svg: {
    color: getDynamicColor("primary"),
    p: 0.1,
    height: "20px",
    width: "20px",
  },
  "> p": {
    width: "100%",
  },
  // use mb not gap here so the placeholder while dragging renders correctly
  mb: 1,
};

type LockWeightButtonProps = {
  locked: boolean;
  toggleLock: (locked: boolean) => void;
  disableInputHover: (disabled: boolean) => void;
  canLock: boolean;
};

const LockWeightButton = ({
  locked,
  toggleLock,
  disableInputHover,
  canLock,
}: LockWeightButtonProps): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isShaking, setIsShaking] = React.useState(false);

  const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
`;

  return (
    <IconButton
      sx={{
        p: 0,
        position: "absolute",
        left: -14,
        zIndex: 1,
        animation: isShaking ? `${shake} 0.3s` : "none",
        transition: "background-color 0.3s ease",
      }}
      onClick={() => {
        if (canLock || locked) {
          toggleLock(!locked);
          disableInputHover(true);
        } else {
          setIsShaking(true);
          setTimeout(() => {
            setIsShaking(false);
          }, 500);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && locked ? (
        <UnlockIcon
          style={{
            height: 26,
            width: 26,
          }}
        />
      ) : (
        <LockIcon
          style={{
            color: !canLock ? getDynamicColor("redError") : getDynamicColor("primary"),
            transition: "color 0.3s ease",
            height: 26,
            width: 26,
          }}
        />
      )}
    </IconButton>
  );
};

type GoalWeightInputProps = {
  inputsMap: Record<string, HTMLInputElement | null>;
  setInputRef: (el: HTMLInputElement | null) => void;
  lockedWeightGoalIds: (string | DefaultGoal)[];
  goalWeights: Record<string, number>;
  goal: GoalItem;
  updateLockedWeight: (goalId: string, locked: boolean) => void;
  updateWeight: (goalId: string, weight: string) => void;
  error: boolean;
  remainingWeight: number;
  focusedGoalId: string;
  setFocusedGoalId: (goalId: string) => void;
  setHoveringGoalId: (goalId: string) => void;
};
const GoalWeightInput = ({
  setHoveringGoalId,
  inputsMap,
  setInputRef,
  lockedWeightGoalIds,
  goal,
  goalWeights,
  updateLockedWeight,
  updateWeight,
  remainingWeight,
  error,
  focusedGoalId,
  setFocusedGoalId,
}: GoalWeightInputProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoverDisabled, setHoverDisabled] = React.useState(false);

  const restrictValueToRange = (value: string, min: number, max: number) => {
    if (value) {
      if (Number(value) < min) {
        return min;
      }
      if (Number(value) > max) {
        return max;
      }
      return value.split(".")[0];
    }
    return value;
  };
  const locked = React.useMemo(() => {
    return lockedWeightGoalIds.includes(goal.id);
  }, [lockedWeightGoalIds, goal]);

  return (
    <Stack direction="row">
      <YoodliTextfield
        onMouseEnter={() => {
          setIsHovered(true);
          setHoveringGoalId(goal.id);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setHoverDisabled(false);
          setHoveringGoalId(undefined);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onBlur={() => {
          if (error && remainingWeight) {
            updateWeight(goal.id, (remainingWeight + goalWeights[goal.id]).toString());
          }
          if (focusedGoalId) {
            updateLockedWeight(goal.id, true);
          }
        }}
        error={error}
        sx={{
          "& .MuiOutlinedInput-root": {
            pl: "4px",
          },
          "& .Mui-disabled, .Mui-disabled > fieldset": {
            borderColor: `${getDynamicColor("dark4")} !important`,
            WebkitTextFillColor: getDynamicColor("dark4"),
          },
        }}
        inputProps={{
          className: "blockEnterToNavigate",
          sx: {
            width: "36px",
            textAlign: "right",
            fontWeight: 700,
            fontSize: 16,
            pl: 0,
            py: "10px",
            position: "relative",
          },
        }}
        InputProps={{
          inputRef: (el) => {
            setInputRef(el);
          },
          sx: {
            pr: "8px",
          },
          startAdornment: (locked || (isHovered && !hoverDisabled)) && (
            <LockWeightButton
              canLock={!error}
              disableInputHover={(disabled) => {
                setHoverDisabled(disabled);
              }}
              locked={locked}
              toggleLock={() => updateLockedWeight(goal.id, !locked)}
            />
          ),
          endAdornment: (
            <Box
              onClick={() => {
                // this is just to make it so the clicking on the percent
                // acts like its part of the input
                const inputEl = inputsMap[goal.id];
                if (inputEl) {
                  const length = inputEl.value.length;
                  inputEl.focus();
                  inputEl.setSelectionRange(length, length);
                }
              }}
              sx={{ fontWeight: 700, fontSize: 16 }}
            >
              %
            </Box>
          ),
        }}
        value={
          restrictValueToRange(goalWeights[goal.id]?.toString(), 0, 100) ?? (locked ? "0" : "")
        }
        onChange={(e) => {
          const regex = /^[0-9]*$/;
          if (!regex.test(e.target.value)) {
            return;
          }
          setFocusedGoalId(goal.id);
          updateWeight(goal.id, restrictValueToRange(e.target.value, 0, 100).toString());
        }}
      />
    </Stack>
  );
};

export const GoalWeightSelector = ({
  goals,
  customGoals,
  customGoalsLoading,
  goalWeights,
  lockedWeightGoalIds,
  handleUpdateLockedWeightGoalIds,
  handleUpdateGoalWeights,
  goalOrder,
  handleUpdateGoalOrder,
}: GoalWeightSelectorProps): JSX.Element => {
  const [goalItems, setGoalItems] = React.useState<GoalItem[]>([]);
  const [errorGoalId, setErrorGoalId] = React.useState<string>();
  const [focusedGoalId, setFocusedGoalId] = React.useState<string>();
  const [hoveringGoalId, setHoveringGoalId] = React.useState<string>();
  const inputRefs = React.useRef<Record<string, HTMLInputElement | null>>({});

  const setRef = React.useCallback(
    (element: HTMLInputElement | null, id: string) => {
      if (element) {
        inputRefs.current[id] = element;
      }
    },
    [inputRefs]
  );

  const handleBlur = (goalId: string) => {
    if (inputRefs.current) {
      inputRefs.current[goalId]?.blur();
    }
  };

  React.useEffect(() => {
    if (customGoalsLoading) {
      return;
    }
    const orderedGoalItems = goals.map((goalId) => {
      if (GoalLabels[goalId]) {
        return {
          id: goalId,
          name: GoalLabels[goalId],
        };
      }
      const customGoalName = customGoals?.find((goal) => goal.id === goalId)?.name;
      if (customGoalName) {
        return {
          id: goalId,
          name: customGoalName,
        };
      }
    });
    // also removing undefined values i.e. goals that don't exist in either the predefined or custom goals
    const cleanedOrderedGoalItems = orderedGoalItems.filter(Boolean);
    cleanedOrderedGoalItems.sort((a, b) => {
      return goalOrder.indexOf(a.id) - goalOrder.indexOf(b.id);
    });
    setGoalItems(cleanedOrderedGoalItems);
  }, [goals, customGoalsLoading]);

  const handleGoalDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedGoals = reorder(goalItems, result.source.index, result.destination.index);
    setGoalItems(reorderedGoals);
    handleUpdateGoalOrder(reorderedGoals.map((goal) => goal.id));
  };

  const handleWeightChange = (goalId: string, weight: string) => {
    const otherUnlockedWeights = [];
    let currRemainingWeight = 100;
    Object.keys(goalWeights).forEach((currGoalId) => {
      if (!lockedWeightGoalIds.includes(currGoalId)) {
        if (goalId !== currGoalId) {
          otherUnlockedWeights.push(currGoalId);
        }
      }
      // get remaining weight for even distribution amongst unlocked goals
      else {
        if (goalId !== currGoalId) {
          currRemainingWeight -= goalWeights[currGoalId];
        }
      }
    });
    const evenlyDistributedUnlockedWeights = distributeWeightEvenly(
      otherUnlockedWeights,
      currRemainingWeight - Number(weight)
    );
    const newGoalWeights = {
      ...goalWeights,
      ...evenlyDistributedUnlockedWeights,
      [goalId]: Number(weight),
    };
    handleUpdateGoalWeights(newGoalWeights);
    const totalRemainingWeight =
      100 - Object.values(newGoalWeights).reduce((acc, curr) => acc + curr, 0);
    if (totalRemainingWeight !== 0) {
      setErrorGoalId(goalId);
    } else {
      setErrorGoalId(undefined);
    }
  };

  const updateLockedWeight = (goalId: string, isLocked: boolean) => {
    if (isLocked) {
      handleUpdateLockedWeightGoalIds([...lockedWeightGoalIds, goalId]);
    } else {
      handleUpdateLockedWeightGoalIds(
        lockedWeightGoalIds.filter((lockedGoal) => lockedGoal !== goalId)
      );
    }
  };

  const getRemainingWeight = () => {
    const totalWeight = Object.values(goalWeights).reduce((acc, curr) => acc + curr, 0);
    return 100 - totalWeight;
  };

  return (
    <Stack sx={{ gap: 1 }}>
      <DragDropContext onDragEnd={handleGoalDrag}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} direction="column">
              {goalItems.map((goal, index) => {
                if (goal) {
                  return (
                    <Draggable
                      key={index}
                      draggableId={index.toString()} // According to docs, this needs to be a string
                      index={index}
                      isDragDisabled={goal?.id === hoveringGoalId}
                    >
                      {(provided) => (
                        <Box
                          key={goal?.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...DRAGGABLE_GOAL_STYLES,
                          }}
                          onMouseDown={() => handleBlur(focusedGoalId)}
                        >
                          {provided.placeholder}
                          <DragIndicatorIcon style={{ cursor: "grab" }} />
                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: getDynamicColor("purple3"),
                              fontWeight: "600",
                            }}
                          >
                            {goal.name}
                          </Typography>
                          <GoalWeightInput
                            setHoveringGoalId={setHoveringGoalId}
                            inputsMap={inputRefs.current}
                            setInputRef={(el) => setRef(el, goal.id)}
                            focusedGoalId={focusedGoalId}
                            setFocusedGoalId={setFocusedGoalId}
                            lockedWeightGoalIds={lockedWeightGoalIds}
                            goal={goal}
                            goalWeights={goalWeights}
                            updateLockedWeight={updateLockedWeight}
                            updateWeight={handleWeightChange}
                            error={errorGoalId === goal.id}
                            remainingWeight={getRemainingWeight()}
                          />
                        </Box>
                      )}
                    </Draggable>
                  );
                }
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      {(errorGoalId || getRemainingWeight() !== 0) && (
        <Typography sx={{ color: getDynamicColor("redError"), fontWeight: 600, fontSize: 14 }}>
          The sum of all weights must equal 100%
        </Typography>
      )}
    </Stack>
  );
};
