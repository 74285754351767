import React from "react";

// Components
import { YoureAllSet } from "./ProcessingStateComponents/YoureAllSet";
import { Stack, Typography } from "@mui/material";

// Utils
import { BotProcessingState } from "../CoachBotTypes";

// Utils
export type CoachBotDoneProps = {
  coachBotName: string;
  botProcessingState: BotProcessingState;
};

export const CoachBotDone = ({
  coachBotName,
  botProcessingState,
}: CoachBotDoneProps): JSX.Element => {
  const renderContent = () => {
    switch (botProcessingState) {
      case "readyToProcess":
      case "processing":
      case "done":
        return <YoureAllSet coachBotName={coachBotName} botProcessingState={botProcessingState} />;
      case "error":
        return (
          <Typography variant="body1" align="center">
            Error
          </Typography>
        );
      default:
        <Typography>Invalid Processing State</Typography>;
    }
  };
  return (
    <Stack
      direction="column"
      gap={{ xs: 0, md: 6 }}
      sx={{
        position: "relative",
        pt: { xs: 3, md: 6 },
      }}
    >
      {renderContent()}
    </Stack>
  );
};
