import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";

/**
 * Uses firestore as a bus/buffer to stream events to BigQuery. This lets us,
 *  e.g., make sure the events are not lost due to adblockers, minimize latency,
 *  and have more control/redundancy.
 */
export function bqStreamingLogger(
  event_type: "GA_EVENT" | "USER_PROPERTIES",
  payload: { [k: string]: unknown }
): void {
  const data = {
    ts: db.value("serverDate") as unknown as string, // will become string after insert
    userId: firebase.auth().currentUser?.uid,
    rowType: event_type,
    jsonPayload: JSON.stringify(payload),
    siteId: getSiteId("unknown-site-id"),
  };
  db.add(db.bqRows(getSiteId("unknown-site-id")), data).catch(console.error);
}
