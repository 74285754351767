import React from "react";

// Components
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { CoachBotDone } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotDone/CoachBotDone";
import { CoachBotGetStarted } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotGetStarted/CoachBotGetStarted";
import { CoachBotNaming } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotNaming/CoachBotNaming";
import { CoachBotUploadContent } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/CoachBotUploadContent";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { CoachBotSectionStatus } from "../CoachBotLibrary";
import { useUpdateCoachBot } from "../useUpdateCoachBot";
import { CoachBotScenarios } from "./CoachBotScenarios/CoachBotScenarios";
import { CoachBotSetDefault } from "./CoachBotSetDefault/CoachBotSetDefault";
import { BotProcessingState, CoachBotUploadedFile, CoachBotStepId } from "./CoachBotTypes";
import { useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavDrawerOffset, useStateRef } from "lib-frontend/hooks";
import {
  createCoachBot,
  patchCoachBotContent,
  updateCoachBot,
} from "lib-frontend/modules/AxiosInstance";
import { currentUserEmail as getCurrentUserEmail } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CoachBotWithScenarioInfo, GetCoachBotResponse } from "lib-fullstack/api/hubApiTypes";

export const FOOTER_HEIGHT = 86;

type SelfServeCoachBotWizardProps = {
  coachbotsList: CoachBotWithScenarioInfo[];
  showIntroStep?: boolean;
  setSectionStatus: (status: CoachBotSectionStatus) => void;
  setSelectedBot: (bot: CoachBotWithScenarioInfo) => void;
};

export const HEADER_HEIGHT_SX = { xs: 116, md: 132 };

export const SelfServeCoachBotWizard = ({
  coachbotsList,
  showIntroStep,
  setSectionStatus,
  setSelectedBot,
}: SelfServeCoachBotWizardProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const { handleUpdateCoachBot } = useUpdateCoachBot();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [coachBotId, setCoachBotId] = React.useState<string>(undefined);
  const [coachBotName, setCoachBotName] = React.useState<string>("");
  const [coachBotNameCollision, setCoachBotNameCollision] = React.useState<boolean>(false);
  const [currStepIndex, setCurrStepIndex] = React.useState<number>(0);
  const [uploadedFiles, setUploadedFiles, uploadedFilesRef] = useStateRef<CoachBotUploadedFile[]>(
    []
  );
  const [selectedScenarioIds, setSelectedScenarioIds] = React.useState<string[]>([]);
  const [makeDefault, setMakeDefault] = React.useState<boolean>(true);

  const { navDrawerOffset, modalStyles } = useNavDrawerOffset();

  const filesUploading = React.useMemo(() => {
    return (
      uploadedFiles?.length && uploadedFiles?.filter((file) => file.uploadStatus === "uploading")
    );
  }, [uploadedFiles]);

  const [botProcessingState, setBotProcessingState] =
    React.useState<BotProcessingState>("readyToProcess");

  const hasDefault = coachbotsList.some((bot) => bot.isDefault);

  const handleUpdateCoachBotContentFilename = async (contentId: string, filename: string) => {
    try {
      await patchCoachBotContent(defaultOrg?.id, coachBotId, contentId, { filename });
    } catch (er) {
      console.error(`Error updating coachbot content for org ${defaultOrg?.id}: ${er}`);
    }
  };

  const introStep = React.useMemo(
    () => ({
      label: "Get Started",
      id: CoachBotStepId.GET_STARTED,
      component: <CoachBotGetStarted entityName={defaultOrg?.name} />,
      validate: () => true,
      enterToNavigate: true,
      hideProgressMarker: true,
      nextCopy: "Get started",
    }),
    [defaultOrg]
  );

  const nameStep = React.useMemo(
    () => ({
      label: "Name",
      id: CoachBotStepId.NAMING,
      component: (
        <CoachBotNaming
          coachBotNameCollision={coachBotNameCollision}
          coachBotName={coachBotName}
          setCoachBotName={setCoachBotName}
          setCoachBotNameCollision={setCoachBotNameCollision}
          coachbotsList={coachbotsList}
        />
      ),
      validate: () => {
        return coachBotName?.length > 0 && !coachBotNameCollision;
      },
      next: async () => {
        try {
          // actually create the coachbot here, before moving to next step
          setLoading(true);
          const res = await createCoachBot(
            defaultOrg?.id,
            coachBotName,
            getCurrentUserEmail(),
            true
          );
          if (!res?.botId) {
            throw new Error(
              `Error creating coachbot for org: ${defaultOrg?.id}. No botId returned from API.`
            );
          }
          setCoachBotId(res.botId);
          setLoading(false);
        } catch (er) {
          console.error(
            `Error creating coachbot ${coachBotName} for org ${
              defaultOrg?.id
            } by user email ${getCurrentUserEmail()}: ${er}`
          );
        }
      },
      prev: () => {
        setSectionStatus(CoachBotSectionStatus.Library);
      },
      enterToNavigate: true,
      showProgressTracker: true,
    }),
    [defaultOrg, coachBotName, coachBotNameCollision, coachbotsList]
  );

  const uploadStep = React.useMemo(
    () => ({
      label: "Upload",
      id: CoachBotStepId.UPLOAD_CONTENT,
      component: (
        <CoachBotUploadContent
          coachBotName={coachBotName}
          setLoading={setLoading}
          uploadedFiles={uploadedFilesRef.current}
          setUploadedFiles={setUploadedFiles}
          orgId={defaultOrg?.id}
          botId={coachBotId}
          getCurrentUploadedFiles={() => uploadedFilesRef.current}
          handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilename}
        />
      ),
      // TODO: potentially check file sizes/types/other validation steps on files here
      validate: () => {
        return (
          uploadedFiles?.length > 0 && uploadedFiles.every((file) => file.uploadStatus === "done")
        );
      },
      next: () => {
        if (botProcessingState === "readyToProcess") {
          setBotProcessingState("processing");
        }
      },
      showProgressTracker: true,
    }),
    [uploadedFiles, botProcessingState, coachBotId, coachBotName, defaultOrg]
  );

  const setAsDefaultStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.SET_AS_DEFAULT,
      component: <CoachBotSetDefault makeDefault={makeDefault} setMakeDefault={setMakeDefault} />,
      initialStep: true,
      validate: () => true,
      next: async () => {
        try {
          setLoading(true);
          const updates: Partial<GetCoachBotResponse> = {};
          if (makeDefault) {
            updates.isDefault = true;
          }
          if (updates.isDefault) {
            await updateCoachBot(defaultOrg?.id, coachBotId, updates);
          }
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrg?.id}: ${er}`);
        }
        setLoading(false);
      },
      showProgressTracker: true,
    }),
    [makeDefault, coachBotId, defaultOrg]
  );

  const assignScenariosStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.ASSIGN_SCENARIOS,
      component: (
        <CoachBotScenarios
          coachBotList={coachbotsList}
          selectedScenarioIds={selectedScenarioIds}
          setSelectedScenarioIds={setSelectedScenarioIds}
          orgId={defaultOrg?.id}
        />
      ),
      initialStep: true,
      validate: () => true,
      next: async () => {
        try {
          setLoading(true);
          const updates: Partial<GetCoachBotResponse> = {};
          if (selectedScenarioIds?.length) {
            updates.scenarioIds = selectedScenarioIds;
          }
          if (updates.scenarioIds) {
            await handleUpdateCoachBot({
              orgId: defaultOrg?.id,
              botId: coachBotId,
              updateOptions: { scenarioIds: updates.scenarioIds },
            });
          }
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrg?.id}: ${er}`);
        }
        setLoading(false);
      },
      showProgressTracker: true,
    }),
    [defaultOrg, coachBotId, selectedScenarioIds, coachbotsList]
  );

  const reviewStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.DONE,
      component: (
        <CoachBotDone coachBotName={coachBotName} botProcessingState={botProcessingState} />
      ),
      validate: () => {
        return true;
      },
      next: async () => {
        try {
          setLoading(true);
          await updateCoachBot(defaultOrg?.id, coachBotId, { isActive: true });
          await queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrg?.id],
          });
          const createdBot = coachbotsList.find((bot) => bot.botId === coachBotId);
          setSelectedBot(createdBot);
          setSectionStatus(CoachBotSectionStatus.Details);
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrg?.id}: ${er}`);
          setBotProcessingState("error");
        } finally {
          setLoading(false);
        }
      },
      prev: () => null,
      showProgressTracker: true,
    }),
    [coachBotName, botProcessingState, defaultOrg, coachBotId, coachbotsList]
  );

  const COACHBOT_STEPS: Array<WizardStep> = [
    showIntroStep ? introStep : null,
    nameStep,
    uploadStep,
    !hasDefault ? setAsDefaultStep : null,
    hasDefault || !makeDefault ? assignScenariosStep : null,
    reviewStep,
  ]
    .filter(Boolean)
    .map((x, i) => ({ ...x, stepIdx: i, hidePrev: showIntroStep && i === 0 }));

  let routeLeavingGuardBody = `${
    uploadedFiles?.length
      ? "You have uploaded content that we are still processing!"
      : "Are you sure you want to close this window?"
  } You can pick up where you left off later, or choose cancel if you want to continue building your bot now`;
  if (filesUploading) {
    routeLeavingGuardBody =
      "Are you sure you want to close this window? Uploads in progress will not be saved!";
  }

  const renderHeaderComponent = () => (
    <Stack
      direction="row"
      sx={{
        ...defaultHeaderSx,
        alignItems: "center",
        px: { xs: 2, md: 3, lg: 4, xl: 5 },
      }}
    >
      <Button
        variant="text"
        sx={{
          color: getDynamicColor("light1"),
          fontSize: 14,
        }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => {
          setSectionStatus(CoachBotSectionStatus.Library);
        }}
      >
        Back to Coach Bot Library
      </Button>
    </Stack>
  );

  return (
    <Wizard
      containerSx={{
        position: "absolute",
        zIndex: 50,
        backgroundColor: getDynamicColor("light1"),
        top: 0,
        left: 0,
        width: "100%",
      }}
      controlsSx={{
        left: navDrawerOffset,
        transition: modalStyles.transition,
      }}
      steps={COACHBOT_STEPS}
      routeLeavingGuardUIProps={{
        title: filesUploading ? "Stop uploads" : "Exit bot creation",
        body: routeLeavingGuardBody,
        // When should shouldBlockNavigation be invoked, simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when: !!coachBotId && currStepIndex !== COACHBOT_STEPS.length - 1,
      }}
      loading={loading}
      setLoading={setLoading}
      currStepIndex={currStepIndex}
      setCurrStepIndex={setCurrStepIndex}
      headerComponent={renderHeaderComponent()}
    />
  );
};
