import firebase from "firebase/app";
import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

// Components
import AuthInput from "../components/AuthInput";
import { Box, Stack, Button, Typography, Grid } from "@mui/material";

// Assets
import yoodli_logo from "../../images/logos/yoodli_logo.svg";
import { ReactComponent as EmailSentIcon } from "../images/email_sent.svg";
import { ReactComponent as ForgotPasswordIcon } from "../images/forgot_password.svg";

// Utils
import authUrls from "../../auth/config/authUrls";
import { validateEmail } from "../utils/validator";
import { sendPasswordResetEmail } from "lib-frontend/modules/AxiosInstance";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { determineHomePagePath, getReturnPath } from "utils/Utilities";

function PasswordReset(props: {
  handlePasswordReset: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  return (
    <Stack gap={2} alignItems="center" textAlign="center">
      <EmailSentIcon />
      <Typography fontSize="32px">Check your email</Typography>
      <Typography fontSize="16px">
        If you made an account with us, you should receive an email shortly! We recommend checking
        your spam folder just in case.
      </Typography>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={props.handlePasswordReset}>
        Resend email
      </Button>
    </Stack>
  );
}

export default function ForgotPassword(): JSX.Element {
  const [email, setEmail] = React.useState("");
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const emailError = validateEmail(email);

  const history = useHistory();
  const location = useLocation();
  const siteConf = getStaticFullSiteConf();

  if (firebase.auth()?.currentUser?.emailVerified) {
    history.replace(getReturnPath(location) ?? determineHomePagePath());
  }

  React.useEffect(() => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.FORGOT_PASSWORD_PAGE_LOADED);
  }, []);

  const handlePasswordReset = (e) => {
    e.preventDefault();
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.FORGOT_PASSWORD_RESET_LINK_CLICKED);
    if (email) {
      sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          setIsEmailSent(true);
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
        });
    }
  };

  const handleChange = (e) => {
    setError(undefined);
    setEmail(e.target.value);
  };

  return (
    <>
      <Box position="absolute" sx={{ top: 0, left: 0 }} p={2} m={2}>
        <img
          src={siteConf?.logo?.url ?? yoodli_logo}
          style={{
            width: 150,
          }}
          alt="Yoodli logo"
        />
      </Box>
      <Stack width="100%" alignItems="center">
        <Grid
          px={{ xs: "24px", md: "84px" }}
          py={10}
          container
          border="1px solid #F5F5F5"
          borderRadius="12px"
          boxShadow="1px 2px 5px rgba(33, 37, 41, 0.16)"
          justifyContent="center"
          sm={12}
          md={6}
        >
          {isEmailSent ? (
            <PasswordReset handlePasswordReset={handlePasswordReset} />
          ) : (
            <Box>
              <form onSubmit={handlePasswordReset}>
                <Stack gap={2} px={5} py={2} alignItems="center" textAlign="center">
                  <ForgotPasswordIcon />
                  <Typography fontSize="32px">Forgot password?</Typography>
                  <Typography fontSize="16px">
                    Type the email associated with your{" "}
                    {isWhiteLabel() ? siteConf.title + " Yoodli" : "Yoodli"} account and we’ll send
                    a link to reset your password
                  </Typography>
                  <AuthInput
                    fullWidth
                    value={email}
                    placeholder="Email"
                    label={email ? "Email" : undefined}
                    type="email"
                    errorText={emailError}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button onClick={handlePasswordReset} variant="contained" fullWidth>
                    Send reset link
                  </Button>
                  <Link
                    style={{
                      textDecoration: "none",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    to={authUrls.signin}
                  >
                    <Button variant="text">Back to Sign in</Button>
                  </Link>
                </Stack>
              </form>
              {error && <Typography color="error">{error}</Typography>}
            </Box>
          )}
        </Grid>
      </Stack>
    </>
  );
}
