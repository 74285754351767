export enum PrescriptiveType {
  GOOD = "GOOD",
  POOR = "POOR",
  DEFAULT = "DEFAULT",
}

// Note: these values are considered "good" [inclusive]. I.e. 4% is good filler word percent but 5% is bad.
export const WPM_LOW_THRESHOLD = 120;
export const WPM_HIGH_THRESHOLD = 170;
export const DOUBLE_WORD_THRESHOLD = 4; // Percent
export const FILLER_WORD_THRESHOLD = 4; // Percent
export const HEDGING_THRESHOLD = 4; // Percent
export const NON_INCLUSIVE_THRESHOLD = 0;
export const NUM_MONOLOGUES_THRESHOLD = 0;
export const TALK_TIME_THRESHOLD = 50; // Percent
export const NUM_PAUSES_THRESHOLD = 1;
export const NUM_QUESTIONS_THRESHOLD = 5;
export const CONCISENESS_THRESHOLD = 0.7;
export const SENTENCE_STARTERS_THRESHOLD = 1;
export const MIN_MONOLOGUE_LENGTH_S = 60;
