// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";
/**
 * Helper functions (oneOf, oneOfEnum, oneOfIntEnum) for enum definition,
 * and several enum definitions.
 *
 * This file is a good location to define enums that are used across
 * multiple layers in our system, e.g. used by both database and API.
 *
 * Note: If enums are used only in one layer, it is probably
 * a better choice to define them in a file insides that layer.
 * This is quite deliberate design choice.)
 *
 * We have not migrated all enums to this file.
 * It is recommended to move existing definitions in other files
 * into this file opportunistically.
 */
import { Literal, Union } from "runtypes";
import { LiteralBase } from "runtypes/lib/types/literal";

//#region Helper Functions
type Tuple<T> = [T, ...T[]];

type Literals<T extends Tuple<LiteralBase>> = {
  [K in keyof T]: T[K] extends LiteralBase ? Literal<T[K]> : never;
};

/**
 * oneOf('a', 'b') is kind of like 'a'|'b'.
 */
export function oneOf<T extends Tuple<LiteralBase>>(...args: T): Union<[...Literals<T>]> {
  const literals = args.map((arg) => Literal(arg)) as Literals<T>;
  return Union(...literals);
}
//#endregion Helper Functions

//#region Project wide enums

/**
 * Environment where backend code is running and frontend code interacts with.
 */
export enum AppEnv {
  Local = "local",
  Development = "development",
  Staging = "staging",
  Production = "production",
}
export const RTAppEnv = RTStringEnum(AppEnv);

/**
 * Cloud environments (i.e. AppEnvironments excluding local) as readonly array
 * which is used as toolbox CLI argument choices.
 */
export const cloudEnvChoices: ReadonlyArray<string> = [
  AppEnv.Development,
  AppEnv.Staging,
  AppEnv.Production,
];

/**
 * Environment where GCP resources exist.
 * Actual values are yoodli-web-dev, yoodli-web, yoodli-web-prod.
 */
export enum GcpEnv {
  Development = "yoodli-web-dev",
  Staging = "yoodli-web",
  Production = "yoodli-web-prod",
}
export const GcpEnvType = RTStringEnum(GcpEnv);

/**
 * Define a set of true and false for API query parameters.
 * Express.js + runtypes cannot define them as boolean.
 */
export enum BooleanString {
  True = "true",
  False = "false",
}
export const BooleanStringType = RTStringEnum(BooleanString);

/**
 * A reason why API operation fails.
 * They are defined when UI needs to show specific error messages to them.
 * It is not necessarily needed that every single reason is defined here.
 */
export enum ApiErrorCode {
  QuotaExceeded = "quota_exceeded",
  IsLastHub = "is_last_hub",
  UsedByProgram = "used_by_program",
  UsedByPublishedProgram = "used_by_published_program",
  MaximumPlanStepsReached = "maximum_plan_steps_reached",
  InviteNotFound = "invite_not_found",
  UserAlreadyInOrg = "user_already_in_org",
  CannotUnshareEvaluators = "cannot_unshare_evaluators",
  EvaluationNotNeeded = "evaluation_not_needed",
  EvaluationAlreadyFinalized = "evaluation_already_finalized",
  IncompatibleScoreData = "incompatible_score_data",
  NoMoreLicense = "no_more_license",
}
export const ApiErrorCodeType = RTStringEnum(ApiErrorCode);

//#endregion Project wide enums

//#region User related

export enum OBQ1Option {
  INTERVIEW = "INTERVIEW",
  MEETINGS = "MEETINGS",
  SPEECH = "SPEECH",
  COACH = "COACH",
  EXTERNAL_DOWNLOAD = "EXTERNAL_DOWNLOAD",
  SKIPPED = "SKIPPED",
  SALES = "SALES",
  /**
   * deprecated and accidentally overwritten on some users
   * should be cleaned up to be EXTERNAL_DOWNLOAD via a migration in the future
   */
  DEPRECATED_LP_PDL = "lp_pdl_v1.0",
  /**
   * deprecated probably way before, but some users have this entry
   * in user main doc and caused log spam at getUserDocReadOnly().
   */
  DEPRECATED_EXPLORING = "EXPLORING",
}
export const RTOBQ1Option = RTStringEnum(OBQ1Option);

//#endregion User related

//#region Roles

/**
 * This type is used by API permission check, but not by database schema
 */
export enum SiteRole {
  ADMIN = "site_admin",
  PRODUCT_ADMIN = "product_admin",
}
export const RTSiteRole = RTStringEnum(SiteRole);

export enum OrgRole {
  OWNER = "org_owner",
  ADMIN = "org_admin",
  /**
   * This  role is code internal. It is not exposed to API or stored in database.
   */
  EFFECTIVE_MEMBER = "effective_member",
}
export const RTOrgRole = RTStringEnum(OrgRole);

export enum HubRole {
  ADMIN = "hub_admin",
  MEMBER = "hub_member",
}
export const RTHubRole = RTStringEnum(HubRole);

export enum EffectiveRole {
  ORG_OWNER = "org_owner",
  ORG_ADMIN = "org_admin",
  HUB_ADMIN = "hub_admin",
  HUB_MEMBER = "hub_member",
}
export const RTEffectiveRole = RTStringEnum(EffectiveRole);

/**
 * Program roles are not stored in the database yet.
 * It's used only in the code.
 */
export enum ProgramRole {
  LEADER = "program_leader",
  MEMBER = "program_member",
}
export const RTProgramRole = RTStringEnum(ProgramRole);

//#endregion Roles

//#region Org related

export enum OrgSubscriptionType {
  PAYG = "payg",
  PREPAID = "prepaid",
}
export const RTOrgSubscriptionType = RTStringEnum(OrgSubscriptionType);

export enum OrgCreationMethod {
  SELFSERVE = "selfserve",
  SALES = "sales",
}
export const RTOrgCreationMethod = RTStringEnum(OrgCreationMethod);

export enum OrgCustomerType {
  COACH = "coach",
  EDUCATION = "education",
  ENTERPRISE = "enterprise",
  OTHER = "other",
}
export const RTOrgCustomerType = RTStringEnum(OrgCustomerType);

export enum LLMChoices {
  ANTHROPIC = "anthropic",
  OPENAI = "openai",
}
export const RTLLMChoices = RTStringEnum(LLMChoices);

export enum InviteStatus {
  ALREADY_INVITED = "already_invited",
  ALREADY_MEMBER = "already_member",
}
export const RTInviteStatus = RTStringEnum(InviteStatus);

export enum OrgSettingType {
  EMAIL_BRANDING = "emailBranding",
  SIGN_UP_NOTICE = "signUpNotice",
}
export const RTIOrgSettingType = RTStringEnum(OrgSettingType);

export enum ContentVideoState {
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  PROCESSING = "processing",
  READY = "ready",
  ERROR = "error",
  DELETED = "deleted", // maybe unused, define for now anyway
}
export const RTContentVideoState = RTStringEnum(ContentVideoState);

export enum ContentVideoType {
  DEMO = "demo",
  COURSE = "course",
}
export const RTContentVideoType = RTStringEnum(ContentVideoType);

//#endregion Org related

//#region Program

export enum ProgramState {
  Draft = "draft",
  Published = "published",
  Archived = "archived",
  Deleted = "deleted",
}
export const RTProgramState = RTStringEnum(ProgramState);

export enum ProgramProvisioningState {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}
export const RTProgramProvisioningState = RTStringEnum(ProgramProvisioningState);

export enum ProgramRecordState {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}
export const RTProgramRecordState = RTStringEnum(ProgramRecordState);

export enum PlanStepType {
  Scenario = "scenario",
  Unknown = "unknown", // Single-element type causes Python test failure. Put a place holder.
}
export const RTPlanStepType = RTStringEnum(PlanStepType);

export enum PlanStepState {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}
export const RTPlanStepState = RTStringEnum(PlanStepState);

export enum HumanEvaluationState {
  NotNeeded = "not_needed",
  Incomplete = "incomplete",
  Completed = "completed",
}
export const RTHumanEvaluationState = RTStringEnum(HumanEvaluationState);

export enum TalkingPointResult {
  Hit = "hit",
  Partial = "partial",
  Miss = "miss",
}
export const RTTalkingPointResult = RTStringEnum(TalkingPointResult);

//#endregion Program

//#region Dashboards and reports

/**
 * Dashboard types. Dashboards are pre-rendered reports.
 */
export enum DashboardType {
  TestWithoutParameters = "test_without_parameters_dashboard",
  TestWithParameters = "test_with_parameters_dashboard",
  EngagementOverview = "engagement_overview_dashboard",
  ProgramOverallImprovement = "program_overall_improvement_dashboard",
}
export const RTDashboardType = RTStringEnum(DashboardType);
export const AllDashboardTypes: DashboardType[] = Object.values(DashboardType) as DashboardType[];

/**
 * Report types. Analytic reports are rendered on demand.
 */
export enum ReportType {
  TestWithoutSql = "test_without_sql_report",
  TestWithoutParameters = "test_without_parameters_report",
  TestWithParameters = "test_with_parameters_report",
  TestWithoutRows = "test_without_rows_report",
  OrgUserEngagement = "org_user_engagement_report",
  GroupUserEngagement = "group_user_engagement_report",
  OrgUserGoalImprovement = "org_user_goal_improvement_report",
  GroupUserGoalImprovement = "group_user_goal_improvement_report",
  OrgFeatureUse = "org_feature_use_report",
  GroupFeatureUse = "group_feature_use_report",
  ProgramScenarioStep = "program_scenario_step_report",
  OrgScenarioEngagement = "org_scenario_engagement_report",
  GroupScenarioEngagement = "group_scenario_engagement_report",
}
export const RTReportType = RTStringEnum(ReportType);
export const AllReportTypes: ReportType[] = Object.values(ReportType) as ReportType[];

/**
 * Union of DashboardType and ReportType.
 */
export type AnyReportType = DashboardType | ReportType;
export const RTAnyReportType = RTDashboardType.Or(RTReportType);
export const AllAnyReportTypes: AnyReportType[] = [
  ...Object.values(DashboardType),
  ...Object.values(ReportType),
] as AnyReportType[];

/**
 * State of a given report.
 */
export enum ReportState {
  /**
   * Report has been created; no data has been rendered.
   */
  Created = "created",
  /**
   * Report has been rendered.
   */
  Rendered = "rendered",
  /**
   * Report has expired and should not be used.
   */
  Expired = "expired",
}
export const RTReportState = RTStringEnum(ReportState);
export const AllReportStates: ReportState[] = Object.values(ReportState) as ReportState[];

/**
 * Destination where the rendered report data is stored.
 */
export enum ReportRenderDestination {
  /**
   * Data is stored in the report object in Firestore.
   */
  Firestore = "firestore",
  /**
   * Data is stored in GCS in the report bucket.
   */
  GCS = "gcs",
}
export const RTReportRenderDestination = RTStringEnum(ReportRenderDestination);
export const AllReportRenderDestinations: ReportRenderDestination[] = Object.values(
  ReportRenderDestination
) as ReportRenderDestination[];

/**
 * Allowed report parameters. These enum values must match the parameter names
 * used in the report-generating SQL queries; see reportSql.ts.
 */
export enum ReportParameter {
  /**
   * Hub or group ID.
   */
  HubId = "hubId",
  /**
   * Nonce.
   */
  Nonce = "nonce",
  /**
   * Organization ID.
   */
  OrgId = "orgId",
  /**
   * Program ID.
   */
  ProgramId = "programId",
  /**
   * Program plan step index.
   */
  ProgramPlanStepIndex = "programPlanStepIndex",
  /**
   * Scenario ID.
   */
  ScenarioId = "scenarioId",
}
export const RTReportParameter = RTStringEnum(ReportParameter);
export const AllReportParameters: ReportParameter[] = Object.values(
  ReportParameter
) as ReportParameter[];

//#endregion Dashboards and reports

//#region Auth

/**
 * Authentication provider ID used in our user doc
 * which are different from the ones used in Firebase.
 * We store Firebase as-is value other than these.
 */
export enum AuthProvider {
  GOOGLE = "google", // Firebase uses "google.com" instead
  MICROSOFT = "microsoft", // Firebase uses "microsoft.com" instead
  FACEBOOK = "facebook", // #11985: Remove this after migration
  EMAIL_PASSWORD = "email", // Firebase uses "password" instead
  KF = "saml.kornferryadvance", // #11985: Remove this after migration
  TMI = "tmi", // Firebase has empty provider ID
}

export enum SsoType {
  /** SSO which is handled by Firebase auth mechanism (SAML and OIDC) */
  Firebase = "firebase",
  /** Proprietary SSO (For future extension. Not used now) */
  Other = "other",
}
export const RTSsoType = RTStringEnum(SsoType);

//#endregion Auth

//#region UI element test IDs

/**
 * These are used for the `data-testid` attribute on an element in the UI.
 * These test IDs are created by Yoodli.
 */
export enum UITestId {
  AcceptToSButton = "accept-tos-button",
  AcceptToSCheckbox = "accept-tos-checkbox",
  AcceptToSContent = "accept-tos-content",
  AccountAvatar = "account-avatar",
  BinaryGradeSelect = "binary-grade-select",
  ContinueWithWorkEmailButton = "continue-with-work-email-button",
  DefaultPreference = "default-preference",
  EmailSentError = "email-sent-error",
  FTUXCTAButton = "ftux-cta-button",
  GetYoodliButton = "get-yoodli-button",
  GradingTab = "grading-tab",
  LeftButton = "left-button",
  LoadingAnimation = "loading-animation",
  ModalPrimaryButton = "modal-primary-button",
  ModalSecondaryButton = "modal-secondary-button",
  MyLearningProgramCardButton = "my-learning-program-card-button",
  MyLearningProgramInfoBackButton = "my-learning-program-info-back-button",
  MyLearningProgramInfoPracticeButton = "my-learning-program-info-practice-button",
  MyLearningProgramInfoTitle = "my-learning-program-info-title",
  MyLearningProgramsSubtitle = "my-learning-programs-subtitle",
  MyLearningTabs = "my-learning-tabs",
  MyLearningTitle = "my-learning-title",
  NameTextField = "name-text-field",
  NavItemDashboard = "nav-item-dashboard",
  NavItemExercises = "nav-item-exercises",
  NavItemHome = "nav-item-home",
  NavItemLibrary = "nav-item-library",
  NavItemMemberDashboard = "nav-item-member-dashboard",
  NavItemMemberExercises = "nav-item-member-exercises",
  NavItemMemberHome = "nav-item-member-home",
  NavItemMemberLearning = "nav-item-member-learning",
  NavItemMemberLibrary = "nav-item-member-library",
  NavItemOrgCustomize = "nav-item-org-customize",
  NavItemOrgGroups = "nav-item-org-groups",
  NavItemOrgMembers = "nav-item-org-members",
  NavItemOrgOverview = "nav-item-org-overview",
  NavItemOrgPrograms = "nav-item-org-programs",
  NavItemOrgSettings = "nav-item-org-settings",
  NavItemSettings = "nav-item-settings",
  NavItemSharedWithMe = "nav-item-shared-with-me",
  NavItemSupport = "nav-item-support",
  NextButton = "next-button",
  OBQ1CTAButton = "obq1-cta-button",
  OBQ1CTASlideTitle = "obq1-cta-slide-title",
  OBQ1NonCTASlideTitle = "obq1-non-cta-slide-title",
  OBQ1OptionButton = "obq1-option-button",
  PasswordTextField = "password-text-field",
  PracticeButton = "practice-button",
  PracticeRecorderChooseScenarioButton = "practice-recorder-choose-scenario-button",
  PracticeRecorderConvoPersonaSelector = "practice-recorder-convo-persona-selector",
  PracticeRecorderConvoScenarioSelector = "practice-recorder-convo-scenario-selector",
  PracticeRecorderHeaderDescription = "practice-recorder-header-description",
  PracticeRecorderHeaderTitle = "practice-recorder-header-title",
  PracticeRecorderSessionHelperInterviewPracticeQuestions = "practice-recorder-session-helper-interview-practice-questions",
  PracticeRecorderSessionHelperPersonalize = "practice-recorder-session-helper-personalize",
  ProgramCarouselCard = "program-carousel-card",
  ProgramCarouselCardPracticeButton = "program-carousel-card-practice-button",
  ProgramCarouselTitle = "program-carousel-title",
  ResendEmailButton = "resend-email-button",
  RightButton = "right-button",
  ScenarioProgressMenu = "scenario-progress-menu",
  ScoreGradeInput = "score-grade-input",
  SignInButton = "sign-in-button",
  SignInWithWorkEmailButton = "sign-in-with-work-email-button",
  SignOut = "sign-out",
  SignUpButton = "sign-up-button",
  SignUpLink = "sign-up-link",
  SkipButton = "skip-button",
  SpeechLink = "speech-link",
  StartButton = "start-button",
  StartGrading = "start-grading",
  SubmitGrading = "submit-grading",
  TourpointConfirm = "tourpoint-confirm",
  VerifyYourEmailLabel = "verify-your-email-label",
  WorkEmailTextField = "work-email-text-field",
}

/**
 * These are used for the `data-testid` attribute on an element in the UI.
 * These test IDs are created by 3rd-party software.
 */
export enum ExternalUITestId {
  PersonIcon = "PersonIcon",
}

//#endregion UI element test IDs
