/**
 * Definition of data schema used by Hubs an Organizations API
 */
import { Demeanor, GoalKind, GoalKindType } from "../db";
import { RTPartial } from "../utils/runtypesHelpers";
import {
  Boolean,
  Number,
  Array as RTArray,
  Record,
  Static,
  String,
  Optional,
  Literal,
  Null,
  Union,
} from "runtypes";

export const CreatePersonaRequest = Record({
  name: String,
  job_title: String,
  voice_id: String,
  additional_background: RTArray(String),
  demeanor: Demeanor,
  profile_picture_id: Optional(String),
});
export type CreatePersonaRequest = Static<typeof CreatePersonaRequest>;

export const PersonaResponse = Record({
  id: String,
  name: String,
  job_title: String,
  voice_id: String,
  additional_background: RTArray(String),
  demeanor: Demeanor,
  is_active: Boolean,
  is_template: Boolean,
  used_by_n_scenarios: Optional(Number),
  profile_picture_signed_url: Optional(String),
  profile_picture_id: Optional(String),
  is_user_persona: Boolean,
});
export type PersonaResponse = Static<typeof PersonaResponse>;

export const PublicPersonaProfilePictureResponse = Record({
  profile_picture_signed_url: String,
  profile_picture_id: String,
});
export type PublicPersonaProfilePictureResponse = Static<
  typeof PublicPersonaProfilePictureResponse
>;

export const PersonasResponse = Record({
  personas: RTArray(PersonaResponse),
});
export type PersonasResponse = Static<typeof PersonasResponse>;

export const PersonaMemberViewResponse = Record({
  id: String,
  name: String,
  job_title: String,
  demeanor: Demeanor,
  profile_picture_signed_url: Optional(String),
  profile_picture_id: Optional(String),
  is_user_persona: Boolean,
});

export type PersonaMemberViewResponse = Static<typeof PersonaMemberViewResponse>;

export const PersonasMemberViewResponse = Record({
  personas: RTArray(PersonaMemberViewResponse),
});

export type PersonasMemberViewResponse = Static<typeof PersonasMemberViewResponse>;

export const UpdatePersonaRequest = Record({
  id: Optional(String),
  name: Optional(String),
  job_title: Optional(String),
  voice_id: Optional(String),
  additional_background: Optional(RTArray(String)),
  demeanor: Optional(Demeanor),
  profile_picture_id: Optional(String),
  is_active: Optional(Boolean),
});
export type UpdatePersonaRequest = Static<typeof UpdatePersonaRequest>;

export const CreatePersonaProfilePictureRequest = Record({
  persona_id: Union(String, Null),
  extension: String,
});
export type CreatePersonaProfilePictureRequest = Static<typeof CreatePersonaProfilePictureRequest>;

export const CreatePersonaProfilePictureResponse = Record({
  upload_url: String,
  profile_picture_id: String,
});
export type CreatePersonaProfilePictureResponse = Static<
  typeof CreatePersonaProfilePictureResponse
>;

export const PersonaProfilePicturesResponse = Record({
  profilePictures: RTArray(
    Record({
      id: String,
      signedUrl: String,
      isCustom: Boolean,
      usedByNPersonas: Number,
    })
  ),
});
export type PersonaProfilePicturesResponse = Static<typeof PersonaProfilePicturesResponse>;

export const CustomGoalItem = Record({
  id: String,
  name: String,
  goalKind: GoalKindType,
  userDescription: Union(String, Null), // user facing
});
export type CustomGoalItem = Static<typeof CustomGoalItem>;

export const BinaryGoalResponse = CustomGoalItem.extend({
  goalKind: Literal(GoalKind.BinaryGoal),
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
});
export type BinaryGoalResponse = Static<typeof BinaryGoalResponse>;

export const ScoreGoalResponse = CustomGoalItem.extend({
  goalKind: Literal(GoalKind.ScoreGoal),
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
  maxScore: Number,
});
export type ScoreGoalResponse = Static<typeof ScoreGoalResponse>;

export const CompoundGoalResponse = CustomGoalItem.extend({
  goalKind: Literal(GoalKind.CompoundGoal),
  maxScore: Number,
  subGoals: RTArray(
    Record({
      name: String,
      aiDescription: String,
      lowScoreDescription: String,
      highScoreDescription: String,
    })
  ),
});
export type CompoundGoalResponse = Static<typeof CompoundGoalResponse>;

export const CustomGoalResponse = BinaryGoalResponse.Or(ScoreGoalResponse).Or(CompoundGoalResponse);
export type CustomGoalResponse = Static<typeof CustomGoalResponse>;

export function responseIsBinaryGoalResponse(
  response: CustomGoalResponse
): response is BinaryGoalResponse {
  return response.goalKind === GoalKind.BinaryGoal;
}
export function responseIsScoreGoalResponse(
  response: CustomGoalResponse
): response is ScoreGoalResponse {
  return response.goalKind === GoalKind.ScoreGoal;
}
export function responseIsCompoundGoalResponse(
  response: CustomGoalResponse
): response is CompoundGoalResponse {
  return response.goalKind === GoalKind.CompoundGoal;
}

// for a list of custom goals, we don't need any of the type-specific fields
export const CustomGoalItemWithUsage = CustomGoalItem.extend({
  usage: Number,
});
export type CustomGoalItemWithUsage = Static<typeof CustomGoalItemWithUsage>;
export const CustomGoalsResponse = Record({
  goals: RTArray(CustomGoalItemWithUsage),
});
export type CustomGoalsResponse = Static<typeof CustomGoalsResponse>;

export const CustomGoalsMemberViewResponse = Record({
  customGoals: RTArray(CustomGoalItem),
});
export type CustomGoalsMemberViewResponse = Static<typeof CustomGoalsMemberViewResponse>;

export const CreateCustomGoalBase = Record({
  name: String,
  goalKind: GoalKindType,
  userDescription: Union(String, Null), // user facing
});
export type CreateCustomGoalBase = Static<typeof CreateCustomGoalBase>;

export const CreateBinaryGoalRequest = CreateCustomGoalBase.extend({
  goalKind: Literal(GoalKind.BinaryGoal),
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
});
export type CreateBinaryGoalRequest = Static<typeof CreateBinaryGoalRequest>;

export const CreateScoreGoalRequest = CreateCustomGoalBase.extend({
  goalKind: Literal(GoalKind.ScoreGoal),
  aiDescription: String,
  lowScoreDescription: String,
  highScoreDescription: String,
  maxScore: Number,
});
export type CreateScoreGoalRequest = Static<typeof CreateScoreGoalRequest>;

export const CreateCompoundGoalRequest = CreateCustomGoalBase.extend({
  goalKind: Literal(GoalKind.CompoundGoal),
  maxScore: Number,
  subGoals: RTArray(
    Record({
      name: String,
      aiDescription: String,
      lowScoreDescription: String,
      highScoreDescription: String,
    })
  ),
});
export type CreateCompoundGoalRequest = Static<typeof CreateCompoundGoalRequest>;

export const CreateCustomGoalRequest =
  CreateBinaryGoalRequest.Or(CreateScoreGoalRequest).Or(CreateCompoundGoalRequest);
export type CreateCustomGoalRequest = Static<typeof CreateCustomGoalRequest>;

// note you MUST pass the kind of goal you are updating
export const UpdateBinaryGoalRequest = RTPartial(CreateBinaryGoalRequest).extend({
  goalKind: Literal(GoalKind.BinaryGoal),
});
export type UpdateBinaryGoalRequest = Static<typeof UpdateBinaryGoalRequest>;

export const UpdateScoreGoalRequest = RTPartial(CreateScoreGoalRequest).extend({
  goalKind: Literal(GoalKind.ScoreGoal),
});
export type UpdateScoreGoalRequest = Static<typeof UpdateScoreGoalRequest>;

export const UpdateCompoundGoalRequest = RTPartial(CreateCompoundGoalRequest).extend({
  goalKind: Literal(GoalKind.CompoundGoal),
});
export type UpdateCompoundGoalRequest = Static<typeof UpdateCompoundGoalRequest>;

export const UpdateCustomGoalRequest =
  UpdateBinaryGoalRequest.Or(UpdateScoreGoalRequest).Or(UpdateCompoundGoalRequest);
export type UpdateCustomGoalRequest = Static<typeof UpdateCustomGoalRequest>;

export const CopyScenarioRequest = Record({
  orgToCopyToId: String,
});
export type CopyScenarioRequest = Static<typeof CopyScenarioRequest>;
