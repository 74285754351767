import { db } from "lib-fullstack";
import moment from "moment-timezone";

// Components
import {
  FOCUS_ANALYTICS,
  HoistedAnalytic,
  WORD_COUNT_ANALYTICS,
} from "components/Dashboard/DashboardTypes";

// Utils
import { requestDashboardAnalytics } from "lib-frontend/modules/AxiosInstance";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { DashboardSuccess, DashboardResponse } from "lib-fullstack/api/apiTypes";
import { DashboardAnalyticId } from "lib-fullstack/db";
import { isCanonicalUtcDateTimeString } from "lib-fullstack/utils/dateUtils";
import { roundToPrecision } from "lib-fullstack/utils/helperFunctions";

export const formatAggAnalyticValue = (value: number, analytic: HoistedAnalytic): string => {
  const roundedValue = roundToPrecision(Math.abs(value), 1);
  if (analytic.customValueTransformer) {
    return analytic.customValueTransformer(value);
  } else if (analytic.counted === "pct") {
    return `${roundedValue}%`;
  } else if (analytic.counted === "wpm") {
    return `${roundedValue} WPM`;
  }
  return roundedValue.toString();
};

export const mapAnalyticsToMetadata = (
  analytics: DashboardSuccess["analytics"]
): HoistedAnalytic[] => {
  const analyticsWithData = Object.entries(analytics ?? {}).map(([id, analytic]) => {
    const data = analytic?.graphData
      .sort((a, b) => {
        return new Date(a.x).getTime() - new Date(b.x).getTime();
      })
      // map threshold to each data point so that graphing is a lot easier down the line
      .map((x) => {
        return {
          ...x,
          threshold: FOCUS_ANALYTICS[id].threshold,
        };
      });

    const currAvg = data.reduce((acc, curr) => acc + curr.y, 0) / data.length;

    // map the top used words if necessary
    let topUsedWords;
    const wordCountAnalytic = WORD_COUNT_ANALYTICS.find((x) => x.identifier === id);

    // if it's a word count analytic, map that data to it
    if (wordCountAnalytic) {
      const wordMap = data.reduce((acc, curr) => {
        if (curr.wordCounts) {
          Object.entries(curr.wordCounts).forEach(([word, count]) => {
            acc[word] = acc[word] ? acc[word] + count : count;
          });
        }
        return acc;
      }, {});
      topUsedWords = {
        title: wordCountAnalytic.title,
        data: Object.entries(wordMap)
          .sort((a, b) => {
            return a[1] > b[1] ? -1 : 1;
          })
          .slice(0, 3)
          .map(([word, count]) => ({ word, count })),
      };
    }

    const returnVal = {
      ...FOCUS_ANALYTICS[id],
      counted: analytic.counted,
      data,
      topUsedWords,
      currAvg,
    };

    return returnVal;
  });
  return analyticsWithData ?? [];
};

export const fetchAggAnalytics = async (
  analyticsToFetch: DashboardAnalyticId[],
  startDate: string,
  endDate: string,
  targetUserId?: string,
  tagFilter?: { key: db.TagType; value: string }
): Promise<DashboardResponse> => {
  try {
    // Type Guard for checking that the start and end date are valid ISO strings
    if (!isCanonicalUtcDateTimeString(startDate) || !isCanonicalUtcDateTimeString(endDate)) {
      throw new Error("Invalid date range provided");
    }
    const userDocMain = getLiveUserDocMain();
    // fetch using the timezone in userdocmain, or if that doesn't exist, use the browser's timezone (ignoring cache)
    const tz = userDocMain?.homeTimezone ?? moment.tz.guess(true);
    const response = await requestDashboardAnalytics(
      tz,
      startDate,
      endDate,
      analyticsToFetch,
      targetUserId,
      tagFilter
    );
    return response;
  } catch (er) {
    console.error("Error fetching aggregate analytics", er);
  }
};
