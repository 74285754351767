import React from "react";
import { useHistory, useLocation } from "react-router";

// Components
import { Box, Button, Stack, Typography } from "@mui/material";

// Utils
import EmptyCourseView from "./EmptyCourseView";
import CourseContent from "./CourseContentSection";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CourseResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";

type CoursesSectionProps = {
  setModalStatus?: (modalStatus: HubModalStatus) => void;
  courses: CourseResponse[];
  setSelectedCourse?: (course: CourseResponse) => void;
  selectedOrgId: string;
  isHubAdmin?: boolean;
};

export default function CoursesSection({
  setModalStatus,
  courses,
  setSelectedCourse,
  selectedOrgId,
  isHubAdmin,
}: CoursesSectionProps): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  React.useEffect(() => {
    if (courses?.length && location.hash) {
      const el = document.getElementById(location.hash.substring(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        // clear the hash after scrolling so it doesn't keep scrolling when the courses change
        history.replace({
          pathname: location.pathname,
          search: location.search,
        });
      }
    }
  }, [courses, location]);
  const courseCount = `${courses?.length} course${courses?.length > 1 ? "s" : ""}`;

  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: { xs: 16, md: 18 },
            fontWeight: 700,
            fontFamily: "Poppins",
          }}
        >
          Courses
          <Box
            component="span"
            sx={{ fontSize: { xs: 14, md: 16 }, ml: 2, color: getDynamicColor("dark4") }}
          >
            {courses?.length > 0 && courseCount}
          </Box>
        </Typography>
        {isHubAdmin && (
          <Button variant="outlined" onClick={() => setModalStatus(HubModalStatus.CREATE_COURSE)}>
            + Add a new course
          </Button>
        )}
      </Stack>
      {courses?.length ? (
        <Stack gap={1}>
          {courses.map((course) => (
            <div
              id={`course-${course.id}`}
              key={course.id}
              style={{
                paddingTop: "16px",
              }}
            >
              <CourseContent
                course={course}
                setModalStatus={setModalStatus}
                setSelectedCourse={setSelectedCourse}
                selectedOrgId={selectedOrgId}
                isHubAdmin={isHubAdmin}
              />
            </div>
          ))}
        </Stack>
      ) : (
        <EmptyCourseView setModalStatus={setModalStatus} isHubAdmin={isHubAdmin} />
      )}
    </Stack>
  );
}
