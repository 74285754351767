import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import React from "react";

// Components
import { Button, CircularProgress, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import CreateOrgSectionSelect from "components/Admin/CreateOrgSectionSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { valdiatePositiveInt, validateDate } from "auth/utils/validator";
import { adminUpdateOrgData } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  AdminUpdateOrgRequest,
  AdminUpdateOrgSubscriptionRequestType,
} from "lib-fullstack/api/adminApiTypes";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";

export const UpdateSubscriptionForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  // Hooks
  const [requestType, setRequestType] = React.useState<AdminUpdateOrgSubscriptionRequestType>(
    AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE
  );
  const [licensedSeats, setLicensedSeats] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.SEATS_LICENSED).data?.toString()
  );
  const [licenseEndDate, setLicenseEndDate] = React.useState<Moment | null>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.LICENSE_END_DATE).data as Moment
  );

  const subscriptionType = card.items.find(
    (item) => item.label === OrgDetailCardItemLabel.SUBSCRIPTION_TYPE
  ).data as OrgSubscriptionType;

  const requestTypeToString = {};
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.CREATE_PAYG_FREE_TRIAL] =
    "Create PAYG free trial";
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.UPGRADE_PAYG_TO_PREPAID] =
    "Upgrade PAYG to prepaid";
  requestTypeToString[AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE] =
    "Update PREPAID license";

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");

  const errors = {
    licensedSeats: valdiatePositiveInt(licensedSeats),
    licenseEndDate: validateDate(licenseEndDate),
  };

  const isFormValid = () => {
    let validRequest = false;

    if (
      subscriptionType === OrgSubscriptionType.PAYG &&
      [
        AdminUpdateOrgSubscriptionRequestType.CREATE_PAYG_FREE_TRIAL,
        AdminUpdateOrgSubscriptionRequestType.UPGRADE_PAYG_TO_PREPAID,
      ].includes(requestType)
    ) {
      validRequest = true;
    } else if (
      subscriptionType === OrgSubscriptionType.PREPAID &&
      [AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE].includes(requestType)
    ) {
      validRequest = true;
    }

    return validRequest && ![...Object.values(errors)].some((error) => !!error);
  };

  const handleUpdateOrg = async (e) => {
    e.preventDefault();

    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);

      const params: AdminUpdateOrgRequest = {
        subscription: {
          requestType,
          seatsLicensed: parseInt(licensedSeats),
          licenseEndDate: licenseEndDate?.toISOString(),
        },
      };

      await adminUpdateOrgData(orgId, params);
      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  return (
    <Stack width="100%" gap={2} p={3}>
      {errorText && (
        <Typography p={1} color="error" fontWeight={600} fontSize="12px">
          {errorText}
        </Typography>
      )}
      <form style={{ width: "100%" }} onSubmit={async (e) => await handleUpdateOrg(e)}>
        <Stack gap={3}>
          <Stack>
            <InputLabel
              htmlFor="request-type-select"
              sx={{ pb: 0.5, ml: 2, fontFamily: "poppins", fontSize: "16px", fontWeight: 500 }}
            >
              Subscription change request type
            </InputLabel>
            <CreateOrgSectionSelect
              value={requestType}
              onChange={(e) => {
                setRequestType(e.target.value as AdminUpdateOrgSubscriptionRequestType);
              }}
            >
              {Object.entries(AdminUpdateOrgSubscriptionRequestType).map(([key, type]) => {
                return (
                  <MenuItem
                    key={key}
                    value={AdminUpdateOrgSubscriptionRequestType[key]}
                    sx={{
                      px: 3,
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    {requestTypeToString[type]}
                  </MenuItem>
                );
              })}
            </CreateOrgSectionSelect>
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="seats-licensed-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Number of seats to license
            </InputLabel>
            <YoodliTextfield
              id="seats-licensed-select"
              type="number"
              placeholder="Number of seats to license"
              value={licensedSeats ?? "N/A"}
              required={true}
              onChange={(e) => setLicensedSeats(e.target.value)}
              error={!!errors.licensedSeats}
              helperText={errors.licensedSeats}
              fullWidth
              InputProps={{
                sx: {
                  fontSize: "16px",
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="license-end-date-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Subscription license end date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
              <DatePicker
                disablePast
                slotProps={{
                  textField: {
                    required: true,
                    helperText: errors.licenseEndDate,
                    error: !!errors.licenseEndDate,
                    InputProps: {
                      sx: {
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                      },
                    },
                  },
                }}
                // Setting value or defaultValue causes a runtime exception:
                // Failed prop type: Invalid prop `defaultValue` of type `string` supplied to `ForwardRef(DatePicker)`, expected `object`.
                // defaultValue={licenseEndDate}
                onChange={setLicenseEndDate}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        startIcon={
          loadingText.length > 0 ? (
            <CircularProgress
              size={20}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          ) : (
            <></>
          )
        }
        onClick={async (e) => {
          await handleUpdateOrg(e);
        }}
        variant="gradient"
        disabled={!isFormValid() || !!loadingText}
        sx={{
          fontFamily: "poppins",
          fontSize: "16px",
          marginTop: 3,
          fontWeight: 700,
          "&:disabled": {
            color: getDynamicColor("light1"),
            background: getDynamicColor("dark4"),
          },
        }}
      >
        {loadingText.length == 0 ? `Update ${card.label}` : loadingText}
      </Button>
    </Stack>
  );
};
