export enum MyScenariosSection {
  Default = "Default",
  CreateScenario = "CreateScenario",
}

export enum MyScenariosTab {
  Scenarios = "Scenarios",
  Personas = "Personas",
}

export enum MyScenariosQueryKeys {
  UserScenarios = "UserScenarios",
  OrgScenarios = "OrgScenarios",
  UserPersonas = "UserPersonas",
}
