// Utils
// These types are shared by database and API sufrace.
// We will need to separate out when we change the underneath database in far future.
import {
  AnalyticProcessingState,
  OrgScenario,
  ScenarioTemplate,
  ScenarioTypeId,
  RTScenarioTemplateSubType,
  ScenarioUXType,
  DefaultGoalType,
} from "../db";
import { ContentVideoState, InviteStatus } from "lib-fullstack/utils/enums";

import { RTOrgRole, RTHubRole } from "lib-fullstack/utils/enums";
import { NullableOptional, RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Definition of data schema used by Hubs an Organizations API
 */
import {
  Boolean,
  Null,
  Number,
  Optional,
  Array as RTArray,
  Record,
  Static,
  String,
  Union,
  Dictionary,
} from "runtypes";
import { PersonaMemberViewResponse, PersonaResponse } from "./scenarioApiTypes";

export const HubV2CreateRequest = Record({
  name: String,
});
export type HubV2CreateRequest = Static<typeof HubV2CreateRequest>;

export const HubUpdateRequest = Record({
  name: String,
});
export type HubUpdateRequest = Static<typeof HubUpdateRequest>;

export const HubUserUpsetRequest = Record({
  role: RTHubRole,
});
export type HubUserUpsetRequest = Static<typeof HubUserUpsetRequest>;

export const HubInviteRequest = Record({
  /** Only null is accepted */
  email: Union(String, Null),
  /** Only hub_member is accepted */
  role: RTHubRole,
  /** Retired */
  ignoreDuplicateInvites: Optional(Boolean),
});
export type HubInviteRequest = Static<typeof HubInviteRequest>;

/**
 * TODO: 2024-03-27
 * temporarily marking all values as optional and adding a status so if the invite is already created,
 * i can return a valid HubInviteResponse but include a status of "already_invited" or "already_member"
 */
export const HubInviteResponse = Record({
  id: Optional(String),
  email: Optional(Union(String, Null)),
  role: Optional(RTHubRole),
  hub_name: Optional(String),
  date_invited: Optional(String),
  inviter_name: Optional(Union(String, Null)),
  inviter_email: Optional(Union(String, Null)),
  date_accepted: Optional(Union(String, Null)),
  invite_path: Optional(String),
  is_org_full: Optional(Boolean),
  status: Optional(RTStringEnum(InviteStatus)),
});
export type HubInviteResponse = Static<typeof HubInviteResponse>;

export const HubInviteActionRequest = Record({
  action: String,
});
export type HubInviteActionRequest = Static<typeof HubInviteActionRequest>;

export const OrgUserUpsetRequest = Record({
  role: Union(RTOrgRole, Null),
});
export type OrgUserUpsetRequest = Static<typeof OrgUserUpsetRequest>;

export const CreateDemoVideoRequest = Record({
  title: String,
  description: String,
  extension: String,
});
export type CreateDemoVideoRequest = Static<typeof CreateDemoVideoRequest>;

export const CreateDemoVideoResponse = Record({
  id: String,
  upload_url: String,
});
export type CreateDemoVideoResponse = Static<typeof CreateDemoVideoResponse>;

export const DemoVideoResponse = Record({
  id: String,
  title: String,
  description: String,
  state: RTStringEnum(ContentVideoState),
  available_hubs: Union(RTArray(String), Null), // Some API does not provide this
  view_url: Union(String, Null),
  thumbnail_url: Union(String, Null),
});
export type DemoVideoResponse = Static<typeof DemoVideoResponse>;

export const UpdateDemoVideoRequest = Record({
  title: Optional(String),
  description: Optional(String),
  state: Optional(RTStringEnum(ContentVideoState)),
  available_hubs: Optional(RTArray(String)),
});
export type UpdateDemoVideoRequest = Static<typeof UpdateDemoVideoRequest>;

export const CourseVideoResponse = Record({
  id: String,
  title: String,
  description: String,
  state: RTStringEnum(ContentVideoState),
  view_url: Union(String, Null),
  thumbnail_url: Union(String, Null),
});
export type CourseVideoResponse = Static<typeof CourseVideoResponse>;

export const CourseResponse = Record({
  id: String,
  title: String,
  description: String,
  videos: RTArray(CourseVideoResponse),
  available_hubs: Union(RTArray(String), Null), // Some API does not provide this
});
export type CourseResponse = Static<typeof CourseResponse>;

export const CreateCourseRequest = Record({
  title: String,
  description: String,
  available_hubs: RTArray(String),
});
export type CreateCourseRequest = Static<typeof CreateCourseRequest>;

export const UpdateCourseRequest = Record({
  title: Optional(String),
  description: Optional(String),
  video_ids: Optional(RTArray(String)),
  available_hubs: Optional(RTArray(String)),
});
export type UpdateCourseRequest = Static<typeof UpdateCourseRequest>;

export const CreateCourseVideoRequest = Record({
  title: String,
  description: String,
  extension: String,
});
export type CreateCourseVideoRequest = Static<typeof CreateCourseVideoRequest>;

export const CreateCourseVideoResponse = Record({
  id: String,
  upload_url: String,
});
export type CreateCourseVideoResponse = Static<typeof CreateCourseVideoResponse>;

export const UpdateCourseVideoRequest = Record({
  title: Optional(String),
  description: Optional(String),
  state: Optional(RTStringEnum(ContentVideoState)),
});
export type UpdateCourseVideoRequest = Static<typeof UpdateCourseVideoRequest>;

export const ContentsViewResponse = Record({
  demo_videos: RTArray(DemoVideoResponse),
  courses: RTArray(CourseResponse),
});
export type ContentsViewResponse = Static<typeof ContentsViewResponse>;

export const CreateOrgLogoRequest = Record({
  extension: String,
});
export type CreateOrgLogoRequest = Static<typeof CreateOrgLogoRequest>;

export const CreateOrgLogoResponse = Record({
  upload_url: String,
});
export type CreateOrgLogoResponse = Static<typeof CreateOrgLogoResponse>;

export const InterviewBankResponse = Record({
  id: String,
  name: String,
  interview_questions: RTArray(String),
  available_hubs: Optional(Union(RTArray(String), Null)), // Omitted for hubs level access
  org_name: String,
});
export type InterviewBankResponse = Static<typeof InterviewBankResponse>;

export const CreateInterviewBankRequest = Record({
  name: String,
  interview_questions: RTArray(String),
  available_hubs: RTArray(String),
});
export type CreateInterviewBankRequest = Static<typeof CreateInterviewBankRequest>;

export const UpdateInterviewBankRequest = Record({
  name: Optional(String),
  interview_questions: Optional(RTArray(String)),
  available_hubs: Optional(RTArray(String)),
});
export type UpdateInterviewBankRequest = Static<typeof UpdateInterviewBankRequest>;

export const ListInterviewBanksResponse = Record({
  interview_banks: RTArray(InterviewBankResponse),
});
export type ListInterviewBanksResponse = Static<typeof ListInterviewBanksResponse>;

export const UpsetPaygOrgSubscriptionRequest = Record({
  num_seats: Number,
  success_url: Optional(Union(String, Null)),
  cancel_url: Optional(Union(String, Null)),
});
export type UpsetPaygOrgSubscriptionRequest = Static<typeof UpsetPaygOrgSubscriptionRequest>;

export enum UpsetPaygOrgSubscriptionResult {
  REDIRECT = "redirect",
  SUBSCRIPTION_UPDATED = "subscription_updated",
  FAIL_NOT_ACCEPTABLE = "fail_not_acceptable",
  FAIL_OTHER = "fail_other",
}

export const UpsetPaygOrgSubscriptionResponse = Record({
  result: RTStringEnum(UpsetPaygOrgSubscriptionResult),
  redirect_url: Union(String, Null),
  error_info: Union(String, Null),
});
export type UpsetPaygOrgSubscriptionResponse = Static<typeof UpsetPaygOrgSubscriptionResponse>;

export const UpdateOrgPaymentMethodRequest = Record({
  success_url: Optional(Union(String, Null)),
  cancel_url: Optional(Union(String, Null)),
});
export type UpdateOrgPaymentMethodRequest = Static<typeof UpdateOrgPaymentMethodRequest>;

export const UpdateOrgPaymentMethodResponse = Record({
  redirect_url: String,
});
export type UpdateOrgPaymentMethodResponse = Static<typeof UpdateOrgPaymentMethodResponse>;

export const OrgInviteRequest = Record({
  email: String,
  ignoreDuplicateInvites: Optional(Boolean),
});
export type OrgInviteRequest = Static<typeof OrgInviteRequest>;

/**
 * TODO: 2024-03-27
 * temporarily marking all values as optional and adding a status so if the invite is already created,
 * i can return a valid HubInviteResponse but include a status of "already_invited" or "already_member"
 */
export const OrgInviteResponse = Record({
  id: Optional(String),
  email: Optional(String),
  date_invited: Optional(String),
  invite_path: Optional(String),
  date_accepted: Optional(Union(String, Null)),
  is_org_full: Optional(Boolean),
  org_name: Optional(String),
  inviter_name: Optional(Union(String, Null)),
  inviter_email: Optional(Union(String, Null)),
  status: Optional(RTStringEnum(InviteStatus)),
});
export type OrgInviteResponse = Static<typeof OrgInviteResponse>;

export const OrgInviteListResponse = Record({
  invites: RTArray(OrgInviteResponse),
});
export type OrgInviteListResponse = Static<typeof OrgInviteListResponse>;

export const UpsetOrgEmailBrandingRequest = Record({
  welcome_message: String,
  goodbye_message: String,
  disable_goodbye_email: Optional(Boolean),
});
export type UpsetOrgEmailBrandingRequest = Static<typeof UpsetOrgEmailBrandingRequest>;

export const OrgEmailBrandingResponse = Record({
  welcome_message: Optional(String),
  goodbye_message: Optional(String),
  disable_goodbye_email: Boolean,
});
export type OrgEmailBrandingResponse = Static<typeof OrgEmailBrandingResponse>;

export const UpsetOrgSignUpNoticeRequest = Record({
  message: String,
});
export type UpsetOrgSignUpNoticeRequest = Static<typeof UpsetOrgSignUpNoticeRequest>;

export const OrgSignUpNoticeResponse = Record({
  message: Optional(String),
});
export type OrgSignUpNoticeResponse = Static<typeof OrgSignUpNoticeResponse>;

export const GetScenarioLimitedResponse = Record({
  id: String,
  path: String,
  scenarioTypeId: ScenarioTypeId,
  templateSubType: RTScenarioTemplateSubType,
  defaultPersonaId: Optional(String),
  title: String,
  description: String,
  enabled: Boolean,
  isTemplate: Boolean,
  coachBotName: Optional(String),
  scenarioUX: Optional(ScenarioUXType),
  targetTimeS: Optional(Number),
  talkingPoints: Optional(RTArray(String)),
  goalIds: Optional(RTArray(Union(DefaultGoalType, String))),
  goalWeights: Optional(Dictionary(Number, String)),
  lockedWeightGoalIds: Optional(RTArray(Union(DefaultGoalType, String))),
  goalRenderOrder: Optional(RTArray(Union(DefaultGoalType, String))),
  autoEndEnabled: Optional(Boolean),
  isUserScenario: Boolean,
  createdAt: NullableOptional(String), // doesn't exist for default scenarios
  modifiedAt: NullableOptional(String), // doesn't exist for default scenarios
  persona: Union(PersonaMemberViewResponse, Null),
});
export type GetScenarioLimitedResponse = Static<typeof GetScenarioLimitedResponse>;

const GetScenarioExtraData = {
  path: String,
  id: String,
  coachBotName: Optional(String),
  persona: Union(PersonaResponse, Null),
  usedByPrograms: Boolean,
};

export const GetScenarioResponse = Union(
  OrgScenario.extend(GetScenarioExtraData),
  ScenarioTemplate.extend(GetScenarioExtraData)
); // I should be able to do this more cleanly from the higher level Scenario type, but runtypes is not cooperating
export type GetScenarioResponse = Static<typeof GetScenarioResponse>;

export const GetAllScenariosRequest = Record({
  getAllData: Optional(Boolean),
});
export type GetAllScenariosRequest = Static<typeof GetAllScenariosRequest>;

export const ListScenariosMemberViewResponse = Record({
  contentArray: RTArray(GetScenarioLimitedResponse),
});
export type ListScenariosMemberViewResponse = Static<typeof ListScenariosMemberViewResponse>;

export const ListScenariosResponse = Record({
  contentArray: RTArray(GetScenarioResponse),
});
export type ListScenariosResponse = Static<typeof ListScenariosResponse>;

export const PatchScenarioRequest = Record({
  enabled: Optional(Boolean),
  description: Optional(String),
  title: Optional(String),
  templateDefiningPromptDetails: Optional(String),
  userProvidedContext: Optional(String),
  activeHubs: Optional(RTArray(String)),
  talkingPoints: Optional(RTArray(String)),
  targetTimeS: Optional(Number),
  aiConcerns: Optional(RTArray(String)),
  conversationPartnerDemeanor: Optional(String),
  conversationPartnerName: Optional(String),
  conversationPartnerRole: Optional(String),
  defaultPersonaId: Optional(String),
  goalIds: Optional(RTArray(Union(DefaultGoalType, String))),
  goalWeights: Optional(Dictionary(Number, String)),
  lockedWeightGoalIds: Optional(RTArray(Union(DefaultGoalType, String))),
  goalRenderOrder: Optional(RTArray(Union(DefaultGoalType, String))),
  autoEndEnabled: Optional(Boolean),
  botId: Optional(String),
});
export type PatchScenarioRequest = Static<typeof PatchScenarioRequest>;

// can't create from scratch yet
export const CreateScenarioRequest = Record({
  title: String,
  creatorEmail: String,
  scenarioIdToCopy: String,
});
export type CreateScenarioRequest = Static<typeof CreateScenarioRequest>;

export const CreateScenarioResponse = Record({
  scenarioId: String,
});
export type CreateScenarioResponse = Static<typeof CreateScenarioResponse>;

export const BotContentFile = Record({ filename: String, fileType: String });
export type BotContentFile = Static<typeof BotContentFile>;

export const CreateCoachBotContentRequest = Record({
  files: RTArray(BotContentFile),
});
export type CreateCoachBotContentRequest = Static<typeof CreateCoachBotContentRequest>;

export const CreateCoachBotContentResponse = Record({
  botContent: RTArray(
    Record({ id: String, uploadUrl: String, filename: String, fileType: String })
  ),
});
export type CreateCoachBotContentResponse = Static<typeof CreateCoachBotContentResponse>;

export const PatchCoachBotContentRequest = Record({
  status: Optional(RTStringEnum(AnalyticProcessingState)),
  isActive: Optional(Boolean),
  filename: Optional(String),
});
export type PatchCoachBotContentRequest = Static<typeof PatchCoachBotContentRequest>;

export const CreateCoachBotRequest = Record({
  name: String,
  creatorEmail: String,
  isActive: Optional(Boolean),
  isDefault: Optional(Boolean),
  scenarioIds: Optional(RTArray(String)),
});
export type CreateCoachBotRequest = Static<typeof CreateCoachBotRequest>;

export const CreateCoachBotResponse = Record({
  botId: String,
});
export type CreateCoachBotResponse = Static<typeof CreateCoachBotResponse>;

export const GetCoachBotResponse = Record({
  botId: String,
  name: String,
  isActive: Boolean,
  activeOrgWide: Optional(Boolean),
  createdAt: String,
  isDefault: Optional(Boolean),
  scenarioIds: Optional(RTArray(String)),
  status: RTStringEnum(AnalyticProcessingState),
});
export type GetCoachBotResponse = Static<typeof GetCoachBotResponse>;

export const PatchCoachBotRequest = Record({
  name: Optional(String),
  isActive: Optional(Boolean),
  activeOrgWide: Optional(Boolean),
  isDefault: Optional(Boolean),
  scenarioIds: Optional(RTArray(String)),
});
export type PatchCoachBotRequest = Static<typeof PatchCoachBotRequest>;

export const GetCoachBotContentResponse = Record({
  status: RTStringEnum(AnalyticProcessingState),
  isActive: Boolean,
  filename: String,
  fileType: String,
  id: String,
  createdAt: String,
  signedUrl: String,
});
export type GetCoachBotContentResponse = Static<typeof GetCoachBotContentResponse>;

export const GetAllCoachBotContentResponse = Record({
  botContent: RTArray(GetCoachBotContentResponse),
});
export type GetAllCoachBotContentResponse = Static<typeof GetAllCoachBotContentResponse>;

export const GetBotBrandingResponse = Record({
  name: String,
});
export type GetBotBrandingResponse = Static<typeof GetBotBrandingResponse>;

export const CoachBotWithScenarioInfo = GetCoachBotResponse.extend({
  scenarioInfo: Optional(RTArray(GetScenarioResponse)),
});
export type CoachBotWithScenarioInfo = Static<typeof CoachBotWithScenarioInfo>;

export const ListCoachBotsResponse = Record({
  coachBots: RTArray(CoachBotWithScenarioInfo),
});
export type ListCoachBotsResponse = Static<typeof ListCoachBotsResponse>;
