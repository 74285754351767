import React from "react";
import { useHistory, useLocation } from "react-router";

// Components
import { Button, Stack, Typography } from "@mui/material";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { OrgProgramsQueryKeys, OrgProgramsSectionStatus } from "./OrgPrograms";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgProgramsQueryParams } from "lib-frontend/utils/queryParams";
import { ProgramState } from "lib-fullstack/utils/enums";

type CreateProgramProps = {
  handleBack: () => void;
};

export const CreateProgram = ({ handleBack }: CreateProgramProps): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");

  const createProgramMutation = useMutation({
    mutationFn: () => createOrgProgram(defaultOrg.id, { name, description }),
    onSuccess: async (response) => {
      Instrumentation.logProgramCreated(defaultOrg.id, response.id, false);
      await queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrg?.id],
      });
      const qp = new URLSearchParams(location.search);
      qp.set(OrgProgramsQueryParams.TAB, ProgramState.Draft);
      qp.set(OrgProgramsQueryParams.PROGRAM_ID, response.id);
      qp.set(OrgProgramsQueryParams.SECTION, OrgProgramsSectionStatus.ProgramInfo);
      history.replace({ search: qp.toString() });
    },
  });

  return (
    <OrgSectionWrapper
      backCopy="Programs"
      handleBack={handleBack}
      loading={createProgramMutation.isPending}
      ctas={
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: { xs: 3, md: 6 },
          }}
        >
          <Button onClick={handleBack}>Exit</Button>
          <Button
            variant="contained"
            disabled={name.length === 0 || createProgramMutation.isPending}
            onClick={() => createProgramMutation.mutate()}
          >
            Next
          </Button>
        </Stack>
      }
      backCtaSx={{ fontSize: 16, fontWeight: 600, letterSpacing: 0 }}
    >
      <Stack gap={4} sx={{ maxWidth: "535px", height: "100%", px: 2, mx: "auto" }}>
        <Stack gap={1}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Program details
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            Add a name and brief description of your program. This will be visible to all enrolled
            members
          </Typography>
        </Stack>
        <YoodliLabeledInput
          label="Name"
          labelSx={{
            fontSize: "12px",
          }}
          inputEl={
            <YoodliTextfield
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g. Hooli sales training"
              maxChars={100}
            />
          }
        />
        <YoodliLabeledInput
          label="Description"
          labelSx={{
            fontSize: "12px",
          }}
          inputEl={
            <YoodliTextfield
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minRows={3}
              multiline
              placeholder="This is a short description of the goal of this program and should not exceed the character count"
              maxChars={300}
            />
          }
        />
      </Stack>
    </OrgSectionWrapper>
  );
};
