import React from "react";

// Components
import {
  Dialog,
  DialogProps,
  Drawer,
  SwipeableDrawer,
  SwipeableDrawerProps,
  SxProps,
} from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { useIsScreenSmallerThanPx, useIsSmallScreen } from "lib-frontend/utils/themeUtils";

interface DialogDrawerProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  breakpointPx?: number; // Use this breakpoint to determine if the drawer should be used, otherwise will use isSmallScreen
  drawerProps?: {
    sx?: SxProps;
    anchor?: SwipeableDrawerProps["anchor"];
    PaperProps?: SwipeableDrawerProps["PaperProps"];
  };
  dialogProps?: {
    sx?: SxProps;
    disableScrollLock?: DialogProps["disableScrollLock"];
    PaperProps?: DialogProps["PaperProps"];
  };
  swipeable?: boolean;
  hideBackdrop?: boolean;
}

export const DialogDrawer = ({
  children,
  open,
  onOpen,
  onClose,
  drawerProps,
  dialogProps,
  breakpointPx,
  swipeable = true,
  hideBackdrop = false,
}: React.PropsWithChildren<DialogDrawerProps>): JSX.Element => {
  const shouldUseDrawer = breakpointPx
    ? useIsScreenSmallerThanPx(breakpointPx)
    : useIsSmallScreen();
  const sharedProps = {
    open,
    onClose,
  };
  const { modalStyles } = useNavDrawerOffset();

  if (shouldUseDrawer) {
    const DrawerComponent = swipeable ? SwipeableDrawer : Drawer;
    return (
      <DrawerComponent
        {...sharedProps}
        {...drawerProps}
        hideBackdrop={hideBackdrop}
        onOpen={onOpen}
      >
        {children}
      </DrawerComponent>
    );
  }

  return (
    <Dialog
      {...sharedProps}
      {...dialogProps}
      hideBackdrop={hideBackdrop}
      sx={{
        ...modalStyles,
        ...dialogProps?.sx,
      }}
    >
      {children}
    </Dialog>
  );
};
