// Utils
import { siteIdFor, userIdFor } from "./db_paths";
import * as db from "./index";
/**
 * TYPESAURUS collection definitions for easy DB access.
 *   This is the closest we currently have to defining the structure
 *   (collection/doc hierarchy) of our DB.
 *
 * Using this interface makes Typescript work really well, but of course
 *   we don't *really* need it.
 *
 * Long-term, we will probably wrap these fxns and add some custom code
 *   for run-time validation, versioning, migrations, etc.
 */
// noinspection JSUnusedGlobalSymbols
import * as ts from "typesaurus";

export const sites = ts.collection<db.Site>("sites");
export const analyticNorms = ts.collection<db.AnalyticNorm>("analyticNorms");
export const siteDocs = ts.subcollection<db.SiteDoc, db.Site>("docs", sites);
export const courses = ts.subcollection<db.CourseInfo, db.Site>("courses", sites);
export const skillFocusSets = ts.subcollection<db.SkillFocus, db.Site>("skillFocuses", sites);
export const skillFocuses = ts.subcollection<db.SkillFocus, db.SkillFocusSet, db.Site>(
  "skillFocus",
  skillFocusSets
);

export const siteInterviewPrompts = ts.subcollection<db.InterviewQuestion, db.Site>(
  "interviewPrompts",
  sites
);

export const courseList = ts.subcollection<db.CourseInfo, db.Site>("courses", sites);

export const notificationJobs = ts.subcollection<db.NotificationJob, db.Site>(
  "notificationJobs",
  sites
);

export const bqRows = ts.subcollection<db.BQRow, db.Site>("bqRows", sites);

export const users = ts.subcollection<db.User, db.Site>("users", sites);

export async function userFor(doc: ts.Doc<unknown>): Promise<ts.Doc<db.User> | null> {
  return ts.get(users(siteIdFor(doc)), userIdFor(doc));
}

export const oneTimeCodes = ts.subcollection<db.OneTimeCode, db.Site>("oneTimeCodes", sites);

export const sampleSpeeches = ts.subcollection<db.SampleSpeech, db.Site>("sampleSpeeches", sites);

export const sampleVideos = ts.subcollection<db.SampleVideo, db.Site>("sampleVideos", sites);

export const interviewPrompts = ts.collection<db.InterviewQuestion>("interviewPrompts");

/**
 * userSpeeches([siteId, userId]) -> a 'speeches' collection
 */
export const userSpeeches = ts.subcollection<db.Speech, db.User, db.Site>("speeches", users);

/**
 * userGames([siteId, userId]) -> a 'games' collection (containing various types of games)
 */
export const userGames = ts.subcollection<db.AbstractGame, db.User, db.Site>("games", users);

/**
 * userDocs([siteId, userId]) -> a 'docs' collection
 */
export const userDocs = ts.subcollection<db.UserDoc, db.User, db.Site>("docs", users);

/**
 * userShares([siteId, userId]) -> a 'shares' collection
 */
export const userShares = ts.subcollection<db.Share, db.User, db.Site>("shares", users);

/**
 * userTalkingPointTemplates([siteId, userId]) -> a 'talkingPointTemplates' collection
 */
export const userTalkingPointTemplates = ts.subcollection<
  db.TalkingPointTemplate,
  db.User,
  db.Site
>("talkingPointTemplates", users);

/**
 * userSelfConfidenceEvals([siteId, userId]) -> a 'selfConfidenceEvals' collection
 */
export const userSelfConfidenceEvals = ts.subcollection<db.SelfConfidenceEval, db.User, db.Site>(
  "selfConfidenceEvals",
  users
);

/**
 * recallZoomBots([siteId, userId]) -> a 'recallZoomBots' collection
 */
export const recallZoomBots = ts.subcollection<db.RecallZoomBot, db.User, db.Site>(
  "recallZoomBots",
  users
);

/**
 * userCalendars([siteId, userId]) -> a 'calendars' collection
 */
export const userCalendars = ts.subcollection<db.Calendar, db.User, db.Site>("calendars", users);

/**
 * speechAnalyticsV2([siteId, userId, speechId]) -> an 'analyticsV2' collection
 */
export const speechAnalyticsV2 = ts.subcollection<
  db.AnalyticsDocV2,
  db.Speech,
  db.User,
  [string, string] // Site
>("analyticsV2", userSpeeches);

/**
 * comments([siteId, userId, speechId]) -> a 'comments' collection
 */
export const comments = ts.subcollection<
  db.SpeechComment,
  db.Speech,
  db.User,
  [string, string] // Site
>("comments", userSpeeches);

/**
 * speechTags([siteId, userId, speechId]) -> a 'speechTags' collection
 */
export const speechTags = ts.subcollection<
  db.SpeechTags,
  db.Speech,
  db.User,
  [string, string] // Site
>("tags", userSpeeches);

/**
 * remarks([siteId, userId, speechId]) -> a 'remarks' collection
 *  (remarks are comments that are made by the AI speech coach)
 */
export const remarks = ts.subcollection<
  db.Remark,
  db.Speech,
  db.User,
  [string, string] // Site
>("remarksV2", userSpeeches);

export const goalResults = ts.subcollection<
  db.GoalResult,
  db.Speech,
  db.User,
  [string, string] // Site
>("goalResults", userSpeeches);

export const emailNotifications = ts.collection<db.EmailNotification>("emailNotifications");

export const speechLabels = ts.subcollection<
  db.SpeechLabel,
  db.Speech,
  db.User,
  [string, string] // Site
>("labels", userSpeeches);

export const zoomUsers = ts.collection<db.ZoomUser>("zoomUsers");

export const aggregateAnalytics = ts.subcollection<db.AggregateAnalytics, db.User, db.Site>(
  "aggregateAnalytics",
  users
);

/**
 * calendarEvents([siteId, userId, calendarId]) -> an 'events' collection
 */
export const calendarEvents = ts.subcollection<
  db.CalendarEvent,
  db.Calendar,
  db.User,
  [string, string] // Site
>("events", userCalendars);

/**
 * recurrences([siteId, userId, calendarId]) -> a 'recurrences' collection
 */
export const calendarRecurrences = ts.subcollection<
  db.CalendarRecurrences,
  db.Calendar,
  db.User,
  [string, string] // Site
>("recurrences", userCalendars);

/**
 * orgs([siteId]) -> an Organization collection
 */
export const orgs = ts.subcollection<db.Org, db.Site>("orgs", sites);

/**
 * orgMemberships([siteId, orgId]) -> a OrgMembership collection
 */
export const orgMemberships = ts.subcollection<db.OrgMembership, db.Org, db.Site>(
  "orgMemberships",
  orgs
);

/**
 * orgSettings([siteId, orgId]) -> a OrgSetting collection
 */
export const orgSettings = ts.subcollection<db.OrgSettingDoc, db.Org, db.Site>("orgSettings", orgs);

/**
 * orgInvitesV2([siteId, orgId]) -> an OrgInviteV2 collection
 */
export const orgInvitesV2 = ts.subcollection<db.OrgInviteV2, db.Org, db.Site>("orgInvitesV2", orgs);

/**
 * hubs([siteId, orgId]) -> a Hub collection
 */
export const hubs = ts.subcollection<db.Hub, db.Org, db.Site>("hubs", orgs);

/**
 * hubMembershipsV2([siteId, orgId, userId]) -> a HubMembershipV2 collection
 */
export const hubMembershipsV2 = ts.subcollection<
  db.HubMembershipV2,
  db.OrgMembership,
  db.Org,
  [string, string] // Site
>("hubMembershipsV2", orgMemberships);

/**
 * hubInvites([siteId, orgId, hubId]) -> a HubInvitation collection
 */
export const hubInvites = ts.subcollection<
  db.HubInvite,
  db.Hub,
  db.Org,
  [string, string] // Site
>("hubInvites", hubs);

/**
 * contentVideos([siteId, orgId]) -> a ContentVideo collection
 */
export const contentVideos = ts.subcollection<db.ContentVideo, db.Org, db.Site>(
  "contentVideos",
  orgs
);

/**
 * orgCourses([siteId, orgId]) -> a Course collection
 */
export const orgCourses = ts.subcollection<db.Course, db.Org, db.Site>("orgCourses", orgs);

/**
 * interviewBanks([siteId, orgId]) -> an InterviewBank collection
 */
export const interviewBanks = ts.subcollection<db.InterviewBank, db.Org, db.Site>(
  "interviewBanks",
  orgs
);

/**
 * userSkillFocuses([siteId, userId]) -> a UserSkillFocus collection
 */
export const userSkillFocuses = ts.subcollection<db.UserSkillFocus, db.User, db.Site>(
  "userSkillFocuses",
  users
);

export const coachBots = ts.subcollection<db.CoachBot, db.Org, db.Site>("coachBots", orgs);

export const botContent = ts.subcollection<db.BotContent, db.CoachBot, db.Org, [string, string]>(
  "botContent",
  coachBots
);
export const botContentExtracts = ts.subcollection<
  db.BotContentExtract,
  db.BotContent,
  db.CoachBot,
  [string, string, string]
>("botContentExtracts", botContent);

export const scenarios = ts.subcollection<db.Scenario, db.Org, db.Site>("scenarios", orgs);
export const scenarioTemplates = ts.subcollection<db.ScenarioTemplate, db.Site>("scenarios", sites);
export const userScenarios = ts.subcollection<db.Scenario, db.User, db.Site>("scenarios", users);

export const personas = ts.subcollection<db.Persona, db.Org, db.Site>("personas", orgs);
export const defaultPersonas = ts.subcollection<db.Persona, db.Site>("personas", sites);
export const userPersonas = ts.subcollection<db.Persona, db.User, db.Site>("personas", users);

export const personaProfilePictures = ts.subcollection<db.PersonaProfilePicture, db.Org, db.Site>(
  "personaProfilePictures",
  orgs
);
export const defaultPersonaProfilePictures = ts.subcollection<db.PersonaProfilePicture, db.Site>(
  "personaProfilePictures",
  sites
);
export const userPersonaProfilePictures = ts.subcollection<
  db.PersonaProfilePicture,
  db.User,
  db.Site
>("personaProfilePictures", users);
export const customGoals = ts.subcollection<db.CustomGoal, db.Org, db.Site>("customGoals", orgs);

export const ssoOptions = ts.subcollection<db.SsoOption, db.Site>("ssoOptions", sites);

export const programs = ts.subcollection<db.Program, db.Org, db.Site>("programs", orgs);
export const programRecords = ts.subcollection<
  db.ProgramRecord,
  db.Program,
  db.Org,
  [string, string]
>("programRecords", programs);

export const reports = ts.subcollection<db.Report, db.Org, db.Site>("reports", orgs);

// collection groups for global lookup of speeches and games and other things
// (e.g., by their slug's.)
export const aggregateAnalyticsGroup = ts.group("aggregateAnalytics", [aggregateAnalytics]);
export const calendarsGroup = ts.group("calendars", [userCalendars]);
export const contentVideosGroup = ts.group("contentVideos", [contentVideos]);
export const customGoalsGroup = ts.group("customGoals", [customGoals]);
export const gamesGroup = ts.group("games", [userGames]);
export const hubInvitesGroup = ts.group("hubInvites", [hubInvites]); // This is v1!
export const hubMembershipsV2Group = ts.group("hubMembershipsV2", [hubMembershipsV2]);
export const hubsGroup = ts.group("hubs", [hubs]);
export const interviewBanksGroup = ts.group("interviewBanks", [interviewBanks]);
export const orgCoursesGroup = ts.group("orgCourses", [orgCourses]);
export const orgInvitesV2Group = ts.group("orgInvitesV2", [orgInvitesV2]);
export const orgMembershipsGroup = ts.group("orgMemberships", [orgMemberships]);
export const orgsGroup = ts.group("orgs", [orgs]);
export const personaProfilePicturesGroup = ts.group("personaProfilePictures", [
  personaProfilePictures,
  defaultPersonaProfilePictures,
  userPersonaProfilePictures,
]);
export const scenariosGroup = ts.group("scenarios", [scenarios, scenarioTemplates, userScenarios]);
export const personasGroup = ts.group("personas", [personas, defaultPersonas, userPersonas]);
export const programRecordsGroup = ts.group("programRecords", [programRecords]);
export const programsGroup = ts.group("programs", [programs]);
export const recallZoomBotsGroup = ts.group("recallZoomBots", [recallZoomBots]);
export const reportsGroup = ts.group("reports", [reports]);
export const speechesGroup = ts.group("speeches", [userSpeeches]);
export const userDocsGroup = ts.group("docs", [userDocs]);
export const usersGroup = ts.group("users", [users]);
export const userSkillFocusesGroup = ts.group("userSkillFocuses", [userSkillFocuses]);
