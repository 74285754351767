import React from "react";

// Components
import { Switch, SxProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type Props = {
  checked: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | Promise<void>;
  color?: string;
  sxProps?: SxProps;
};

export const YoodliSwitch: React.FC<Props> = ({ checked, onClick, color, sxProps }: Props) => {
  return (
    <Switch
      sx={{
        py: 0,

        width: "unset",
        display: "flex",
        alignItems: "center",
        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: 2,
          width: "unset",
          "& .MuiSwitch-root": {
            width: "unset",
          },
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(20px)",
            color: getDynamicColor("light1"),
            "& + .MuiSwitch-track": {
              backgroundImage: color ? "none" : getDynamicColor("gradient.default"),
              opacity: 1,
              border: 0,
              backgroundColor: color ? color : "transparent",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "white",
            border: "6px solid #fff",
          },

          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.3,
          },
        },
        "& .MuiSwitch-input": {
          top: -8,
          height: 22,
          left: checked ? "calc(-200% + 5px) !important" : -5,
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 14,
          height: 14,
          marginTop: -0.5,
        },
        "& .MuiSwitch-track": {
          position: "relative",
          zIndex: -1,
          borderRadius: 26 / 2,
          backgroundColor: getDynamicColor("dark3"),
          opacity: 1,
          width: 44,
          height: 22,
          transition: 0.5,
        },
        ...sxProps,
      }}
      onChange={(e, checked) => onClick(e, checked)}
      checked={checked}
    />
  );
};
