// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { MemberProgramItem } from "./MemberProgramItem";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { UITestId } from "lib-fullstack/utils/enums";

type MemberProgramLibraryProps = {
  programs: ProgramMemberViewItem[];
  handleViewProgram: (programId: string) => void;
};

export const MemberProgramLibrary = ({
  programs,
  handleViewProgram,
}: MemberProgramLibraryProps): JSX.Element => {
  return (
    <Stack gap={3} sx={{ py: { xs: 5, md: 7 }, px: { xs: 2, md: 7 } }}>
      <Typography
        data-testid={UITestId.MyLearningProgramsSubtitle}
        sx={{
          fontFamily: "poppins",
          fontSize: "18px",
          fontWeight: 700,
          color: getDynamicColor("purple3"),
        }}
      >
        My programs
      </Typography>
      {programs.length ? (
        programs.map((program) => (
          <MemberProgramItem
            key={program.id}
            program={program}
            cta={
              <Button
                data-testid={`${UITestId.MyLearningProgramCardButton}-${program.id}`}
                onClick={() => handleViewProgram(program.id)}
                variant="outlined"
                sx={{ fontSize: "12px" }}
              >
                View program
              </Button>
            }
          />
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: "14px",
            color: getDynamicColor("dark4"),
            fontWeight: 600,
          }}
        >
          You are not currently enrolled in any programs
        </Typography>
      )}
    </Stack>
  );
};
