/**
 * List of available voices
 */

/**
 * Metadata of individual voices
 * This type definition helps automatic interpolation in IDE.
 */
export type VoiceMetadata = {
  voiceId: string;
  name: string;
  sampleUrl: string;
  defaultProfilePictureId: string;
};

/**
 * List of voices as a map
 * Current implementation uses the same ID for our ID (key of the map)
 * and the provider's voice ID (stored in the metadata)
 */
export const voices = new Map<string, VoiceMetadata>([
  [
    "iP95p4xoKVk53GoZ742B",
    {
      voiceId: "iP95p4xoKVk53GoZ742B",
      name: "Chris",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/chris.mp3",
      defaultProfilePictureId: "avatar_person12",
    },
  ],
  [
    "kW8nczNUtnUazCRdJWnf",
    {
      voiceId: "kW8nczNUtnUazCRdJWnf",
      name: "Esha",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/esha.mp3",
      defaultProfilePictureId: "priyaAnand",
    },
  ],
  [
    "pFZP5JQG7iQjIQuC4Bku",
    {
      voiceId: "pFZP5JQG7iQjIQuC4Bku",
      name: "Lily",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/lily.mp3",
      defaultProfilePictureId: "agnesPotts",
    },
  ],
  [
    "EXAVITQu4vr4xnSDxMaL",
    {
      voiceId: "EXAVITQu4vr4xnSDxMaL",
      name: "Sarah",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/sarah.mp3",
      defaultProfilePictureId: "avatar_person8",
    },
  ],
  [
    "x3gYeuNB0kLLYxOZsaSh",
    {
      voiceId: "x3gYeuNB0kLLYxOZsaSh",
      name: "Shrey",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/shrey.mp3",
      defaultProfilePictureId: "kevinBrown",
    },
  ],

  [
    "fw990JFw2cwzIf7uWevV",
    {
      voiceId: "fw990JFw2cwzIf7uWevV",
      name: "Summer",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/summer.mp3",
      defaultProfilePictureId: "avatar_person1",
    },
  ],
]);
