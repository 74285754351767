import firebase from "firebase/app";
import React from "react";
import { useHistory, useLocation } from "react-router";

// Components
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Box, FormControlLabel, Stack, Typography } from "@mui/material";
import {
  CreateConvoScenarioStepId,
  MAX_AI_CONCERNS,
  MAX_DND_INPUT_LENGTH,
  TEMPLATE_SUB_TYPE_DATA,
  GoalTalkTime,
  convertGoalTalkTimeToS,
  CustomizePracticeQueryKey,
  WizardState,
  binaryGoalBase,
  compoundGoalBase,
  ratedGoalBase,
  CUSTOM_GOAL_FLOW_LENGTH,
  distributeWeightEvenly,
  filterAndMergeArr,
  normalizeWeights,
  GoalHooks,
  GoalLabels,
} from "components/ConvoScenarios/convoScenarioUtils";
import { MyScenariosQueryKeys } from "components/MyScenarios/MyScenariosTypes";
import { SelectHub } from "components/Orgs/BulkInviteByCSVWizard/SelectHub";
import { GoalSelector } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/GoalSelector";
import { PersonaSelector } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/PersonaSelector";
import {
  AdditionalContextDetails,
  ScenarioAdditionalContext,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/ScenarioAdditionalContext";
import {
  ScenarioDescriptors,
  ScenarioPreview,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/ScenarioPreview";
import { WizardAddDraggableItems } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/WizardAddDraggableItems";
import { YoureAllSetCopy } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotDone/ProcessingStateComponents/YoureAllSet";
import {
  OrgProgramsQueryKeys,
  OrgProgramsSectionStatus,
} from "components/Orgs/Programs/OrgPrograms";
import { ProgramInfoTab } from "components/Orgs/Programs/ProgramInfo";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { YoodliAutocomplete } from "lib-frontend/components/YoodliComponents/YoodliAutocomplete";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { YoodliSwitch } from "lib-frontend/components/YoodliSwitch";

// Assets
import { ReactComponent as LockIcon } from "images/icons/icon-lock-locked.svg";

// Utils
import { useUpdateCoachBot } from "../../../ManageCoachBotTab/useUpdateCoachBot";
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { CreateBinaryGoalSteps } from "./CreateBinaryGoalSteps";
import { CreateCompoundGoalSteps } from "./CreateCompoundGoalSteps";
import { CreateRatedGoalSteps } from "./CreateRatedGoalSteps";
import { GoalWeightSelector } from "./GoalWeightSelector";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createCustomGoal,
  createPersona,
  createUserPersona,
  deleteCustomGoal,
  getAllCoachBots,
  getAllCustomGoals,
  getCustomGoalbyId,
  patchScenario,
  patchUserScenario,
  updateCustomGoal,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgContentQueryParams, OrgProgramsQueryParams } from "lib-frontend/utils/queryParams";
import { isEqual } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse, PatchScenarioRequest } from "lib-fullstack/api/hubApiTypes";
import {
  CreatePersonaRequest,
  PersonaMemberViewResponse,
  CreateCustomGoalRequest,
  CreateBinaryGoalRequest,
  CustomGoalItemWithUsage,
  CreateScoreGoalRequest,
  CreateCompoundGoalRequest,
  CustomGoalResponse,
} from "lib-fullstack/api/scenarioApiTypes";
import {
  DefaultGoal,
  GoalKind,
  ScenarioTemplateSubType,
  ScenarioTypeIdEnum,
} from "lib-fullstack/db";
import { ProgramState } from "lib-fullstack/utils/enums";
import { WebServerInternalPath } from "utils/paths";

export enum WizardType {
  Org = "org",
  User = "user",
}

type CreateConvoScenarioWizardProps = {
  closeWizard: () => void;
  wizardState: WizardState;
  templateSelected: GetScenarioResponse;
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  setShouldBlockNav?: React.Dispatch<React.SetStateAction<boolean>>;
  wizardType: WizardType;
};

export const CreateConvoScenarioWizard = ({
  closeWizard,
  wizardState,
  templateSelected,
  handleTemplateSelected,
  setShouldBlockNav,
  wizardType,
}: CreateConvoScenarioWizardProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  const queryClient = useQueryClient();
  const { handleUpdateCoachBot } = useUpdateCoachBot();
  const { defaultOrg, defaultOrgLoading } = React.useContext(UserOrgContext);
  const templateSubType = templateSelected.templateSubType as ScenarioTemplateSubType;
  const [error, _setError] = React.useState<string | null>(null);
  const [currStepIndex, setCurrStepIndex] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [goalDescriptionLoading, setGoalDescriptionLoading] = React.useState(false);
  const [goalHighScoreLoading, setGoalHighScoreLoading] = React.useState(false);
  const [goalLowScoreLoading, setGoalLowScoreLoading] = React.useState(false);

  const goalHooks: GoalHooks = {
    goalDescriptionLoading,
    setGoalDescriptionLoading,
    goalHighScoreLoading,
    setGoalHighScoreLoading,
    goalLowScoreLoading,
    setGoalLowScoreLoading,
  };

  // Coachbots query
  const { data: coachbotsData, isPending: coachbotsDataPending } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrg?.id],
    queryFn: () => getAllCoachBots(defaultOrg?.id),
    enabled: wizardType === WizardType.Org && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });

  const defaultCoachbot = coachbotsData?.coachBots.find((bot) => bot.isDefault);

  const perScenarioCoachbots = coachbotsData?.coachBots.filter((bot) => !bot.isDefault);
  // Temporary variable to enable the "See Example" functionality once it's built
  const exampleEnabled = false;

  // Prompt Step
  const [prompt, setPrompt] = React.useState<string>(templateSelected.description);

  // Preview / "Describe Your Scenario" Step
  const [scenarioDescriptors, setScenarioDescriptors] = React.useState<ScenarioDescriptors>({
    title: templateSelected.title,
    description: templateSelected.description,
  });
  // Goals Step
  const [goals, setGoals] = React.useState<(DefaultGoal | string)[]>(
    (templateSelected.goalIds as (DefaultGoal | string)[]) ?? [DefaultGoal.ActiveListening]
  );
  const [talkingPoints, setTalkingPoints] = React.useState<string[]>(
    templateSelected.talkingPoints ?? []
  );

  const [goalTalkTime, setGoalTalkTime] = React.useState<GoalTalkTime>(
    templateSelected.targetTimeS
      ? {
          minutes: Math.floor(templateSelected.targetTimeS / 60),
          seconds: templateSelected.targetTimeS % 60,
        }
      : {
          minutes: 3,
          seconds: 0,
        }
  );
  const [editingGoalTalkTime, setEditingGoalTalkTime] = React.useState<boolean>(false);
  // Custom Goal Creation Steps (if they exist)
  const [customGoalType, setCustomGoalType] = React.useState<GoalKind>(undefined);
  const [discardGoalModalOpen, setDiscardGoalModalOpen] = React.useState<boolean>(false);
  const [goalEditId, setGoalEditId] = React.useState<string>(undefined);
  const [binaryGoal, setBinaryGoal] = React.useState<CreateBinaryGoalRequest>(binaryGoalBase);
  const [ratedGoal, setRatedGoal] = React.useState<CreateScoreGoalRequest>(ratedGoalBase);
  const [compoundGoal, setCompoundGoal] =
    React.useState<CreateCompoundGoalRequest>(compoundGoalBase);
  // Goal Custom Weights Steps (if more than one goal is selected)
  const [goalWeights, setGoalWeights] = React.useState<{ [key: string]: number }>(
    templateSelected.goalWeights
  );
  const [lockedWeightGoalIds, setLockedWeightGoalIds] = React.useState<string[]>(
    templateSelected.lockedWeightGoalIds ?? []
  );
  const [goalOrder, setGoalOrder] = React.useState<string[]>(templateSelected.goalRenderOrder);
  // Objections / AI Concerns Step
  const [aiConcerns, setAiConcerns] = React.useState<string[]>(templateSelected.aiConcerns ?? []);
  // Additional Context Step
  const [additionalContext, setAdditionalContext] = React.useState<AdditionalContextDetails>({
    userProvidedContext: templateSelected.userProvidedContext,
    templateDefiningPromptDetails: templateSelected.templateDefiningPromptDetails,
  });
  // Persona Step
  const [showCreatePersona, setShowCreatePersona] = React.useState(false);

  const personasQueryState =
    wizardType === WizardType.Org
      ? queryClient.getQueryState([CustomizePracticeQueryKey.Personas, defaultOrg?.id])
      : queryClient.getQueryState([
          MyScenariosQueryKeys.UserPersonas,
          firebase.auth().currentUser?.uid,
        ]);
  const personas = personasQueryState?.data as PersonaMemberViewResponse[] | undefined;
  const [defaultPersona, setDefaultPersona] = React.useState<PersonaMemberViewResponse | null>(
    personas?.find((p) => templateSelected?.defaultPersonaId === p.id)
  );
  const [autoEndEnabled, setAutoEndEnabled] = React.useState<boolean>(
    templateSelected.autoEndEnabled ?? false
  );
  const [personaToCreate, setPersonaToCreate] = React.useState<CreatePersonaRequest | null>(null);
  React.useEffect(() => {
    setShouldBlockNav?.(true);
    return () => {
      setShouldBlockNav?.(false);
    };
  }, []);
  React.useEffect(() => {
    if (!defaultPersona && personasQueryState?.status === "success" && personas?.length > 0) {
      const defaultPersona = personas?.find((p) => p.id === templateSelected.defaultPersonaId);
      setDefaultPersona(defaultPersona ?? personas[0]);
    }
  }, [defaultPersona, templateSelected.defaultPersonaId, personas]);

  React.useEffect(() => {
    if (showCreatePersona) {
      setShowCreatePersona(false);
    }
  }, [currStepIndex]);

  // Goals Step
  // Custom goals query
  const { data: customGoals, isPending: orgCustomGoalsLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
    queryFn: () => getAllCustomGoals(defaultOrg?.id),
    enabled: wizardType === WizardType.Org && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });
  const customGoalsLoading = wizardType === WizardType.Org && orgCustomGoalsLoading;

  React.useEffect(() => {
    if (customGoals && goals) {
      const cleanedGoals = goals.filter(
        (goalId) => !!GoalLabels[goalId] || customGoals?.goals?.find((goal) => goal.id === goalId)
      );
      if (cleanedGoals.length !== goals.length) {
        setGoals(cleanedGoals);
      }
    }
  }, [goals, customGoals]);

  // Hub Details
  const [selectedHubIds, setSelectedHubIds] = React.useState<string[]>(
    templateSelected?.activeHubs ?? (defaultOrg?.hubs ?? []).map((hub) => hub.id)
  );
  // Coachbot Step
  const [coachbotInputValue, setCoachbotInputValue] = React.useState<string>("");
  const [selectedCoachbot, setSelectedCoachbot] = React.useState<YoodliSelectOption>(undefined);
  React.useEffect(() => {
    const selCoachBot = perScenarioCoachbots?.find((bot) => bot.botId === templateSelected.botId);
    if (selCoachBot) {
      setSelectedCoachbot({ value: selCoachBot?.botId, label: selCoachBot?.name });
    } else if (defaultCoachbot) {
      setSelectedCoachbot({
        value: defaultCoachbot.botId,
        label: `${defaultCoachbot.name} (default)`,
      });
    }
  }, [templateSelected.botId, coachbotsDataPending]);

  // Restart wizard if default org changes through org switcher
  React.useEffect(() => {
    if (defaultOrgLoading) {
      closeWizard();
    }
  }, [defaultOrgLoading]);

  // Handlers
  const handleUpdateGoals = (newGoals: (DefaultGoal | string)[]) => {
    setGoals(newGoals);
  };
  const handleUpdateCustomGoalType = (type: GoalKind) => {
    setCustomGoalType(type);
  };
  const handleSetDefaultPersona = (value: string) => {
    const newPersona = personas.find((p) => p.id === value);
    setDefaultPersona(newPersona);
  };
  const handleUpdateScenarioDetails = (key: keyof ScenarioDescriptors, value: string) => {
    setScenarioDescriptors((prev) => ({ ...prev, [key]: value }));
  };
  const handleSetAdditionalContext = (key: keyof AdditionalContextDetails, value: string) => {
    setAdditionalContext((prev) => ({ ...prev, [key]: value }));
  };
  const handleUpdateTalkingPoints = (newTalkingPoints: string[]) => {
    setTalkingPoints(newTalkingPoints);
  };
  const handleUpdateGoalTalkTime = (newGoalTalkTime: GoalTalkTime) => {
    setGoalTalkTime(newGoalTalkTime);
  };
  const handleUpdateAIConcerns = (newAIConcerns: string[]) => {
    setAiConcerns(newAIConcerns);
  };

  const patchScenarioMutation = useMutation({
    mutationFn: (body: PatchScenarioRequest) =>
      wizardType === WizardType.Org
        ? patchScenario(defaultOrg?.id, templateSelected.id, body)
        : patchUserScenario(templateSelected.id, body),
    onError: (error) => {
      console.error("Error updating scenario details in the db", error);
    },
  });

  const handleEditCustomGoal = async (goal: CustomGoalItemWithUsage) => {
    try {
      const fullGoal = await getCustomGoalbyId(defaultOrg?.id, goal.id);
      setGoalEditId(fullGoal.id);
      setCustomGoalState(fullGoal.goalKind, fullGoal);
    } catch (er) {
      console.error("Error fetching custom goal details", er);
    }
  };

  // Mutations (for custom goals)
  const { mutateAsync: handleCustomGoalCreation } = useMutation({
    mutationFn: async (goal: CreateCustomGoalRequest) => {
      if (goalEditId) {
        const updatedGoalId = await updateCustomGoal(defaultOrg?.id, goalEditId, goal);
        return updatedGoalId;
      }
      const createdGoalId = await createCustomGoal(defaultOrg?.id, goal);
      return createdGoalId;
    },
    onSuccess: async (goalId, goal) => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
      });
      if (!goalEditId) {
        setGoals((prev) => {
          return [...prev, goalId];
        });
      }
      handleUpdateCustomGoalType(undefined);
      setCustomGoalState(goal.goalKind);
      setCurrStepIndex((prev) => prev - CUSTOM_GOAL_FLOW_LENGTH);
    },
    onError: (error) => {
      console.error("Error creating/updating custom goal: ", error);
    },
  });

  const { mutateAsync: handleCustomGoalDeletion, isPending: customGoalDeletionLoading } =
    useMutation({
      mutationFn: async (goalId: string) => {
        const deletedGoalId = await deleteCustomGoal(defaultOrg?.id, goalId);
        return deletedGoalId;
      },
      onSuccess: async (deletedGoalId: string) => {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
        });
        handleUpdateCustomGoalType(undefined);
        setGoals((prev) => {
          return [...prev.filter((goal) => goal !== deletedGoalId)];
        });
      },
      onError: (error) => {
        console.error("Error deleting custom goal: ", error);
      },
    });

  const validateTalkingPointsGoal = () => {
    if (goals.includes(DefaultGoal.HitTalkingPoints) && talkingPoints.length === 0) {
      return false;
    }
    return true;
  };
  const validateTalkTimeGoal = () => {
    if (
      goals.includes(DefaultGoal.HitTimeTarget) &&
      ((!goalTalkTime?.minutes && !goalTalkTime.seconds) || editingGoalTalkTime)
    ) {
      return false;
    }
    return true;
  };
  const updateGoalWeightingStates = (): {
    newGoalWeights: { [key: string]: number };
    newGoalOrder: string[];
  } => {
    // this is a new scenario, set good defaults
    let newGoalOrder = [...goals];
    let rebalancedGoalWeights = distributeWeightEvenly(goals, 100);
    if (!goalWeights) {
      setGoalWeights(rebalancedGoalWeights);
      setGoalOrder([...goals]);
    }
    // this is an existing scenario with updated goals so weights, order, locks need to be updated
    else if (
      Object.keys(goalWeights).length !== goals.length ||
      !Object.keys(goalWeights).every((goalId) => goals.includes(goalId))
    ) {
      let newGoalWeights: { [key: string]: number } = {};
      goals.forEach((goal) => {
        if (!goalWeights[goal]) {
          newGoalWeights[goal] = 0;
        } else {
          newGoalWeights[goal] = goalWeights[goal] ?? 0;
        }
      });
      const currentLockedWeightIds = lockedWeightGoalIds.filter((id) => goals.includes(id));
      // a goal was removed and there's no unlocked goals to give the extra weight to
      if (
        Object.keys(goalWeights).length > goals.length &&
        currentLockedWeightIds.length === Object.keys(newGoalWeights).length
      ) {
        newGoalWeights = normalizeWeights(newGoalWeights);
      }
      const otherUnlockedWeights = [];
      let currRemainingWeight = 100;
      Object.keys(newGoalWeights).forEach((currGoalId) => {
        if (!currentLockedWeightIds.includes(currGoalId)) {
          otherUnlockedWeights.push(currGoalId);
        }
        // get remaining weight for even distribution amongst unlocked goals
        else {
          currRemainingWeight -= goalWeights[currGoalId];
        }
      });
      const evenlyDistributedUnlockedWeights = distributeWeightEvenly(
        otherUnlockedWeights,
        currRemainingWeight
      );
      rebalancedGoalWeights = {
        ...newGoalWeights,
        ...evenlyDistributedUnlockedWeights,
      };
      const totalSum = Object.entries(rebalancedGoalWeights).reduce(
        (sum, [, value]) => sum + value,
        0
      );
      if (totalSum !== 100) {
        rebalancedGoalWeights = normalizeWeights(rebalancedGoalWeights);
      }
      newGoalOrder = filterAndMergeArr(goalOrder, goals);
      setGoalWeights(rebalancedGoalWeights);
      setGoalOrder(newGoalOrder);
      if (currentLockedWeightIds !== lockedWeightGoalIds) {
        setLockedWeightGoalIds(currentLockedWeightIds);
      }
    }

    return { newGoalWeights: rebalancedGoalWeights, newGoalOrder };
  };

  // Either set current state to selected goal to update or reset custom goal state to base state
  const setCustomGoalState = (goalKind: GoalKind, goalToUpdate?: CustomGoalResponse) => {
    switch (goalKind) {
      case GoalKind.BinaryGoal:
        setBinaryGoal((goalToUpdate as CreateBinaryGoalRequest) ?? { ...binaryGoalBase });
        break;
      case GoalKind.ScoreGoal:
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          ratedGoalBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        setRatedGoal((goalToUpdate as CreateScoreGoalRequest) ?? { ...ratedGoalBase });
        break;
      case GoalKind.CompoundGoal:
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          compoundGoalBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        setCompoundGoal((goalToUpdate as CreateCompoundGoalRequest) ?? { ...compoundGoalBase });
        break;
    }
  };

  const MAX_PROMPT_LENGTH = 150;
  const promptStep = React.useMemo(
    () => ({
      label: "Start",
      initialStep: true,
      id: CreateConvoScenarioStepId.PROMPT,
      component: (
        <ConvoScenarioStepWrapper
          title="Pitch prompt"
          subTitle="Write a clear and concise pitch topic description. Ensure it's easy for the presenter to read and understand."
        >
          <YoodliTextfield
            autoFocus
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            multiline
            maxChars={MAX_PROMPT_LENGTH}
            minRows={2}
            placeholder="e.g. Give a 3 minute pitch about why we should include services when selling Google Cloud"
            sx={{
              width: "100%",
            }}
            inputProps={{
              className: "blockEnterToNavigate",
            }}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !!prompt && !error,
      next: async () => {
        if (prompt !== templateSelected.description) {
          handleUpdateScenarioDetails("description", prompt);
          patchScenarioMutation.mutate({
            description: prompt,
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    }),
    [prompt, currStepIndex, error, templateSelected]
  );

  const scenarioContextStep = React.useMemo(() => {
    const youInstruction =
      'For the best results, write this in the form of directions to the AI. E.g. "You know that..." or "You should..."';
    const productCallSubTitle = (
      <>
        Add specific information about the product or service that will be discussed during the
        conversation. This is not shown to users.
        <br />
        {youInstruction}
      </>
    );

    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "What’s the context of this sales call?",
        [ScenarioTypeIdEnum.COLD_CALL]: "What’s the context of this cold sales call?",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: "What’s the context of this review?",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "What’s the context of this coaching call?",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "What’s the context of this interview?",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]:
          "What’s the context of this customer enablement call?",
        [ScenarioTypeIdEnum.PITCH]: "What’s the context of this pitch for the AI?",
        [ScenarioTypeIdEnum.SMALL_TALK]: "What’s the context of this conversation?",
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: "What is the medical context of this counseling?",
        [ScenarioTypeIdEnum.NETWORKING]: "What’s the context of this networking conversation?",
      },
      subTitle: {
        [ScenarioTypeIdEnum.SALES_CALL]: productCallSubTitle,
        [ScenarioTypeIdEnum.COLD_CALL]: productCallSubTitle,
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: productCallSubTitle,
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: (
          <>
            Write a short description giving the AI partner context for how it should behave in this
            performance review.
            <br />
            {youInstruction}
            <br />
            Questions to consider:
            <br />
            <br />
            <i>
              Why are you having this review?
              <br />
              Is the tone positive or negative?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            coaching call.
            <br />
            {youInstruction}
            <br />
            Here are a few coaching topics to consider:
            <br />
            <br />
            <i>
              Conflict with a team member
              <br />
              Dealing with a difficult internal stakeholder
              <br />
              Conversation with a difficult customer
              <br />
              Conducting customer discovery
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: (
          <>
            Write a short description giving the AI context for how it should behave as it gives
            this interview.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the interview about?
              <br />
              Who is the interviewee?
              <br />
              What is the goal of the interview?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.GENERIC]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the conversation about?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.PITCH]:
          "Write a short description telling the AI persona what role it will play. Consider the example as a guide for your version",
        [ScenarioTypeIdEnum.SMALL_TALK]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the conversation about?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            counseling.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What sort of symptoms is the patient experiencing?
              <br />
              Are you speaking to a patient or a caregiver?
              <br />
              What is the goal of the counseling? Are you trying to diagnose, educate, comfort, or
              gain consent?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.NETWORKING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            networking conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              Is this at a networking event or is it a one-on-one conversation?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
      },
      placeholderCopy: {
        [ScenarioTypeIdEnum.SALES_CALL]: "e.g. Yoodli is an AI platform for sales training",
        [ScenarioTypeIdEnum.COLD_CALL]: "e.g. Yoodli is an AI platform for sales training",
        [ScenarioTypeIdEnum.PITCH]:
          "e.g. You (an audience member) are being pitched to as part of a pitch contest on the advantages of cloud vs edge computing. The presenter’s time is limited to 3 minutes",
      },
      // all except pitch should say "Tell the AI what it already knows"
      userProvidedContextTitle: Object.values(ScenarioTypeIdEnum).reduce((acc, key) => {
        if (key !== ScenarioTypeIdEnum.PITCH) {
          acc[key] = "Tell the AI what it already knows";
        }
        return acc;
      }, {}),

      showPromptDetails: {
        [ScenarioTypeIdEnum.SALES_CALL]: true,
        [ScenarioTypeIdEnum.COLD_CALL]: false,
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: false,
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: false,
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: false,
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: false,
        [ScenarioTypeIdEnum.PITCH]: false,
        [ScenarioTypeIdEnum.SMALL_TALK]: false,
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: false,
        [ScenarioTypeIdEnum.NETWORKING]: false,
      },
    };
    return {
      label: "Start",
      initialStep: true,
      id: CreateConvoScenarioStepId.ADDITIONAL_CONTEXT,
      component: (
        <ConvoScenarioStepWrapper
          title={dataMap.title[templateSelected.scenarioTypeId] ?? "Scenario Details"}
          subTitle={
            dataMap.subTitle[templateSelected.scenarioTypeId] ??
            "Add specific information about the scenario."
          }
        >
          <ScenarioAdditionalContext
            additionalContext={additionalContext}
            handleSetAdditionalContext={handleSetAdditionalContext}
            templateSubType={templateSubType}
            showPromptDetails={dataMap.showPromptDetails[templateSelected.scenarioTypeId]}
            userProvidedContextTitle={
              dataMap.userProvidedContextTitle[templateSelected.scenarioTypeId]
            }
            placeholderCopy={dataMap.placeholderCopy[templateSelected.scenarioTypeId]}
            scenarioId={templateSelected.scenarioTypeId}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (
          additionalContext.userProvidedContext !== templateSelected.userProvidedContext ||
          additionalContext.templateDefiningPromptDetails !==
            templateSelected.templateDefiningPromptDetails
        ) {
          patchScenarioMutation.mutate({
            userProvidedContext: additionalContext.userProvidedContext,
            templateDefiningPromptDetails: additionalContext.templateDefiningPromptDetails,
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [additionalContext, error, templateSelected]);

  const goalsStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      subTitleSubject: {
        [ScenarioTypeIdEnum.SALES_CALL]: "sales call",
        [ScenarioTypeIdEnum.COLD_CALL]: "cold sales call",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]:
          "Select goal(s) members should try to achieve during the performance review. Yoodli will measure the completion and provide feedback against those goals.",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "skills training session",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "media training session",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: "customer enablement call",
        [ScenarioTypeIdEnum.PITCH]: "pitch",
        [ScenarioTypeIdEnum.SMALL_TALK]: "conversation",
        [ScenarioTypeIdEnum.GENERIC]: "scenario",
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.GOALS,
      component: (
        <ConvoScenarioStepWrapper
          title="Create a rubric"
          subTitle={`Add goal(s) to a rubric for this ${
            dataMap.subTitleSubject[templateSelected.scenarioTypeId]
          }. Yoodli will measure completion and provide feedback to members according to this rubric.`}
        >
          <GoalSelector
            goals={goals}
            handleUpdateGoals={handleUpdateGoals}
            handleUpdateCustomGoalType={setCustomGoalType}
            talkingPoints={talkingPoints}
            handleUpdateTalkingPoints={handleUpdateTalkingPoints}
            goalTalkTime={goalTalkTime}
            editingGoalTalkTime={editingGoalTalkTime}
            setEditingGoalTalkTime={setEditingGoalTalkTime}
            handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
            scenarioTypeId={templateSelected.scenarioTypeId}
            customGoals={customGoals?.goals}
            customGoalsLoading={customGoalsLoading}
            setCurrStepIndex={setCurrStepIndex}
            handleEditCustomGoal={handleEditCustomGoal}
            handleCustomGoalDeletion={handleCustomGoalDeletion}
            customGoalDeletionLoading={customGoalDeletionLoading}
            wizardType={wizardType}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error && validateTalkingPointsGoal() && validateTalkTimeGoal(),
      next: async () => {
        const { newGoalWeights, newGoalOrder } = updateGoalWeightingStates();
        if (
          convertGoalTalkTimeToS(goalTalkTime) !== templateSelected.targetTimeS ||
          !isEqual(talkingPoints, templateSelected.talkingPoints) ||
          !isEqual(goals, templateSelected.goalIds) ||
          !isEqual(newGoalWeights, templateSelected.goalWeights) ||
          !isEqual(lockedWeightGoalIds, templateSelected.lockedWeightGoalIds) ||
          !isEqual(newGoalOrder, templateSelected.goalRenderOrder)
        ) {
          patchScenarioMutation.mutate({
            talkingPoints,
            targetTimeS: convertGoalTalkTimeToS(goalTalkTime),
            goalIds: goals,
            goalRenderOrder: newGoalOrder,
            goalWeights: newGoalWeights
              ? Object.fromEntries(Object.entries(newGoalWeights).map(([k, v]) => [k, v]))
              : {},
          });
        }
      },
      nextHelperText: goals.length > 0 && (
        <Typography sx={{ fontSize: "12px", fontWeight: 500, color: getDynamicColor("dark4") }}>
          {goals.length} goals selected
        </Typography>
      ),
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [
    goals,
    goalWeights,
    lockedWeightGoalIds,
    goalOrder,
    talkingPoints,
    currStepIndex,
    error,
    templateSelected,
    goalTalkTime,
    editingGoalTalkTime,
    customGoals,
    customGoalsLoading,
    customGoalDeletionLoading,
  ]);

  const goalWeightsStep = React.useMemo(() => {
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.GOALS,
      component: (
        <ConvoScenarioStepWrapper
          title="Weight your rubric (optional)"
          subTitle={
            <Stack sx={{ gap: 0.5 }}>
              <Typography>
                Customize the scoring weight for each goal of your rubric. Weighting will be
                auto-adjusted so that the total is always 100%.
              </Typography>
              <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
                <Box sx={{ width: "24px", height: "24px" }}>
                  <LockIcon
                    style={{
                      color: getDynamicColor("primary"),
                    }}
                  />
                </Box>
                <Typography>
                  Locked weights will not auto-adjust. To change the value, simply click the lock.
                </Typography>
              </Stack>
            </Stack>
          }
        >
          <GoalWeightSelector
            goals={goals}
            customGoals={customGoals?.goals}
            customGoalsLoading={customGoalsLoading}
            goalWeights={goalWeights}
            handleUpdateGoalWeights={(newGoalWeights) => {
              setGoalWeights(newGoalWeights);
            }}
            lockedWeightGoalIds={lockedWeightGoalIds}
            handleUpdateLockedWeightGoalIds={setLockedWeightGoalIds}
            goalOrder={goalOrder}
            handleUpdateGoalOrder={setGoalOrder}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () =>
        !goalWeights || Object.values(goalWeights).reduce((acc, curr) => acc + curr, 0) === 100,
      next: async () => {
        patchScenarioMutation.mutate({
          goalRenderOrder: goalOrder,
          goalWeights: goalWeights
            ? Object.fromEntries(Object.entries(goalWeights).map(([k, v]) => [k, v]))
            : {},
          lockedWeightGoalIds: lockedWeightGoalIds,
        });
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [goals, customGoals, customGoalsLoading, goalWeights, goalOrder, lockedWeightGoalIds]);

  const objectionsStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "AI partner objections (optional)",
        [ScenarioTypeIdEnum.COLD_CALL]: "AI partner objections (optional)",
        [ScenarioTypeIdEnum.PITCH]: "AI Q&A (optional)",
      },
      subTitle: {
        [ScenarioTypeIdEnum.SALES_CALL]: `Add up to ${MAX_AI_CONCERNS} top of mind objections that the AI will raise as follow-up questions if not addressed.`,
        [ScenarioTypeIdEnum.COLD_CALL]: `Add up to ${MAX_AI_CONCERNS} top of mind objections that the AI will raise as follow-up questions if not addressed.`,
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.OBJECTIONS,
      component: (
        <ConvoScenarioStepWrapper
          title={dataMap.title[templateSelected.scenarioTypeId] ?? "AI partner concerns (optional)"}
          subTitle={
            dataMap.subTitle[templateSelected.scenarioTypeId] ??
            `Add up to ${MAX_AI_CONCERNS} top of mind concerns that the AI will raise as follow-up questions if not addressed.`
          }
        >
          <WizardAddDraggableItems
            currStepIndex={currStepIndex}
            items={aiConcerns}
            handleUpdateItems={handleUpdateAIConcerns}
            inputPlaceholder="e.g. Why should I buy from you?"
            maxItems={MAX_AI_CONCERNS}
            maxInputLength={MAX_DND_INPUT_LENGTH}
            itemLabel="AI partner concerns"
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (!isEqual(aiConcerns, templateSelected.aiConcerns)) {
          patchScenarioMutation.mutate({
            aiConcerns,
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [aiConcerns, currStepIndex, error, templateSelected]);

  const hasValidPersonaToCreate = () => {
    return (
      showCreatePersona &&
      personaToCreate?.name &&
      personaToCreate?.job_title &&
      personaToCreate?.voice_id &&
      personaToCreate?.demeanor &&
      personaToCreate.profile_picture_id
    );
  };
  const personaStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "Who is this sales call with?",
        [ScenarioTypeIdEnum.COLD_CALL]: "Who is this cold sales call with?",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: "Who is this performance review with?",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "Who is this skills coaching session with?",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "Who is interviewing you?",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: "Who is this customer enablement call with?",
        [ScenarioTypeIdEnum.PITCH]: "Who is being pitched to?",
      },
      subTitle: {
        [ScenarioTypeIdEnum.PITCH]: showCreatePersona
          ? "Create a default persona for this scenario. This will be the only persona available to members."
          : "Select the default persona for this scenario. You can also create your own. The persona will respond with AI follow up questions.",
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.PERSONA,
      component: (
        <ConvoScenarioStepWrapper
          title={
            dataMap.title[templateSelected.scenarioTypeId] ??
            `Who is this ${TEMPLATE_SUB_TYPE_DATA[
              templateSelected.templateSubType
            ]?.label?.toLowerCase()} with?`
          }
          subTitle={
            dataMap.subTitle[templateSelected.scenarioTypeId] ??
            `${
              showCreatePersona ? "Create a" : "Select the"
            } default persona for this scenario. Members will be able to choose a different persona if others are available.`
          }
        >
          <Stack gap={{ xs: 3, md: 6 }}>
            <PersonaSelector
              showCreatePersona={showCreatePersona}
              setShowCreatePersona={setShowCreatePersona}
              personas={personas}
              loadingPersonas={personasQueryState?.status === "pending"}
              defaultPersonaId={defaultPersona?.id}
              handleSetDefaultPersona={handleSetDefaultPersona}
              currStepIndex={currStepIndex}
              setPersonaToCreate={setPersonaToCreate}
            />
            {templateSelected?.scenarioTypeId !== ScenarioTypeIdEnum.PITCH && (
              <FormControlLabel
                label={
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: getDynamicColor("purple3"),
                        fontWeight: 600,
                        fontFamily: "poppins",
                      }}
                    >
                      Allow the AI to end the conversation
                    </Typography>
                    <YoodliTooltip
                      placement="bottom-start"
                      title="The AI will end the call when the conversation reaches a natural conclusion."
                    >
                      <InfoIcon
                        sx={{
                          height: 20,
                          width: 20,
                          color: getDynamicColor("purple3"),
                        }}
                      />
                    </YoodliTooltip>
                  </Stack>
                }
                labelPlacement="start"
                sx={{
                  width: "fit-content",
                  gap: { xs: 2, md: 4 },
                  ml: 0,
                }}
                control={
                  <YoodliSwitch
                    checked={autoEndEnabled}
                    onClick={(e) => setAutoEndEnabled(e.target.checked)}
                    color={getDynamicColor("primary")}
                  />
                }
              />
            )}
          </Stack>
        </ConvoScenarioStepWrapper>
      ),
      validate: () =>
        !error && ((!!defaultPersona?.id && !showCreatePersona) || hasValidPersonaToCreate()),
      // if im currently creating a persona, dont go back a step idx but just close the creation form
      skipPrevIndexUpdate: showCreatePersona,
      prev: () => {
        if (showCreatePersona) {
          setShowCreatePersona(false);
          setPersonaToCreate(null);
        }
      },
      next: async () => {
        let personaId = defaultPersona?.id;
        // if im making a new
        if (personaToCreate) {
          personaId =
            wizardType === WizardType.Org
              ? await createPersona(defaultOrg?.id, personaToCreate)
              : await createUserPersona(personaToCreate);
          setDefaultPersona({
            ...personaToCreate,
            id: personaId,
            is_user_persona: false,
          });
          setPersonaToCreate(null);
        }
        if (
          personaId !== templateSelected.defaultPersonaId ||
          autoEndEnabled !== templateSelected.autoEndEnabled
        ) {
          patchScenarioMutation.mutate({
            defaultPersonaId: personaId,
            autoEndEnabled,
          });

          // invalidate the personas from the customize practice personas tab so the "used by" number updates
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
          });
        }
      },

      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [
    queryClient,
    defaultPersona,
    personas,
    error,
    personaToCreate,
    showCreatePersona,
    autoEndEnabled,
    currStepIndex,
    hasValidPersonaToCreate,
  ]);

  const assignHubsStep = React.useMemo(
    () => ({
      label: "Customize",
      id: CreateConvoScenarioStepId.ASSIGN_TO_HUBS,
      component: (
        <ConvoScenarioStepWrapper
          title="Assign to groups (optional)"
          subTitle="Select / deselect groups you want to assign this scenario to. You can also do this later in your organization settings page"
        >
          <SelectHub
            hideTitle
            hubSelectorSx={{
              width: "100%",
            }}
            allHubs={defaultOrg?.hubs}
            selectedHubIds={selectedHubIds}
            setSelectedHubIds={setSelectedHubIds}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (!isEqual(selectedHubIds, templateSelected.activeHubs)) {
          patchScenarioMutation.mutate({
            activeHubs: selectedHubIds,
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    }),
    [selectedHubIds, error, templateSelected]
  );

  const assignCoachbotsStep = React.useMemo(
    () => ({
      label: "Customize",
      id: CreateConvoScenarioStepId.ASSIGN_COACHBOT,
      component: (
        <ConvoScenarioStepWrapper
          title="Assign Coach Bot (optional)"
          subTitle={`Select a coachbot you want to associate with this scenario. You can change this later in your scenario or Coach Bot settings page. ${
            defaultCoachbot
              ? "If none is selected, this scenario will use the existing default Coach Bot."
              : "If none is selected, no Coach Bot will be assigned to this scenario."
          }`}
        >
          <YoodliAutocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            placeholder={!defaultCoachbot ? `No Coach Bot selected` : undefined}
            value={selectedCoachbot ?? null}
            disablePortal={false}
            onChange={(event, newValue: YoodliSelectOption | null) => {
              setSelectedCoachbot(newValue);
            }}
            inputValue={coachbotInputValue}
            onInputChange={(event, newInputValue) => {
              setCoachbotInputValue(newInputValue);
            }}
            options={coachbotsData?.coachBots?.map((bot) => ({
              value: bot.botId,
              label: bot.name,
            }))}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (selectedCoachbot) {
          const coachbot = perScenarioCoachbots.find((bot) => bot.botId === selectedCoachbot.value);
          if (coachbot && !coachbot?.scenarioIds?.includes(templateSelected.id)) {
            await handleUpdateCoachBot({
              orgId: defaultOrg?.id,
              botId: selectedCoachbot.value,
              updateOptions: {
                scenarioIds: (coachbot.scenarioIds ?? []).concat(templateSelected.id),
              },
            });
          }
        }
        if (!selectedCoachbot && templateSelected.botId) {
          const coachbot = perScenarioCoachbots.find((bot) => bot.botId === templateSelected.botId);
          await handleUpdateCoachBot({
            orgId: defaultOrg?.id,
            botId: templateSelected.botId,
            updateOptions: {
              scenarioIds: coachbot.scenarioIds.filter((id) => id !== templateSelected.id),
            },
          });
        }
      },
      showProgressTracker: true,
    }),
    [error, selectedCoachbot, perScenarioCoachbots, coachbotInputValue]
  );

  const reviewStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      description: {
        [ScenarioTypeIdEnum.PITCH]: "Pitch prompt",
      },
      descriptionPlaceholder: {
        [ScenarioTypeIdEnum.PITCH]: "Describe your pitch",
      },
      descriptionRequired: {
        [ScenarioTypeIdEnum.PITCH]: true,
      },
      maxDescriptionLength: {
        [ScenarioTypeIdEnum.PITCH]: MAX_PROMPT_LENGTH,
      },
    };
    return {
      label: "Review",
      id: CreateConvoScenarioStepId.REVIEW,
      component: (
        <ConvoScenarioStepWrapper
          title={`Name this ${TEMPLATE_SUB_TYPE_DATA[
            templateSelected.templateSubType
          ]?.scenarioTypeLabel?.toLowerCase()} scenario`}
          subTitle={`
        Edit the title and description of your scenario below. This is what the user will see when deciding which scenario to use.${
          exampleEnabled
            ? " Hit “See example” to see a simulated text transcript of the conversation"
            : ""
        }`}
        >
          <ScenarioPreview
            templateSubType={templateSubType}
            title={scenarioDescriptors.title}
            description={scenarioDescriptors.description}
            descriptionLabel={dataMap.description[templateSelected.scenarioTypeId]}
            decriptionPlaceholder={dataMap.descriptionPlaceholder[templateSelected.scenarioTypeId]}
            maxDescriptionLength={dataMap.maxDescriptionLength[templateSelected.scenarioTypeId]}
            handleUpdateScenarioDetails={handleUpdateScenarioDetails}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () =>
        !error &&
        scenarioDescriptors?.title &&
        (dataMap.descriptionRequired[templateSelected.scenarioTypeId]
          ? !!scenarioDescriptors.description
          : true),
      nextCopy: "Publish",
      nextButtonVariant: "gradient",
      next: async () => {
        // always patch here (instead of making sure title/desc changed) so we can be SURE the scenario is enabled
        patchScenarioMutation.mutate({
          enabled: true,
          title: scenarioDescriptors.title,
          description: scenarioDescriptors.description,
        });
        // invalidate the personas from the customize practice personas tab so the "used by" number updates
        // (it relies on the enabled flag which is why im setting it here if it's a newly enabled scenario)
        if (wizardState === "create" || templateSelected.enabled === false) {
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [error, scenarioDescriptors, templateSelected]);
  const doneStep = React.useMemo(
    () => ({
      label: "Done",
      id: CreateConvoScenarioStepId.DONE,
      hideProgressMarker: true,
      progressTrackerComplete: true,
      component: (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{
            my: "auto",
            pt: { xs: 6, md: 8 },
            pb: 12,
          }}
        >
          <YoureAllSetCopy />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: getDynamicColor("dark5"),
            }}
          >
            Your scenario has been saved and is now live! hit "Done" to go back to your library.
          </Typography>
        </Stack>
      ),
      validate: () => !error,
      enterToNavigate: true,
      next: () => {
        if (wizardState === "create" && qp.has(OrgContentQueryParams.PROGRAM_ID)) {
          void queryClient.invalidateQueries({
            queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrg?.id],
          });
          history.push(
            `${WebServerInternalPath.ORG_PROGRAMS}?${OrgProgramsQueryParams.TAB}=${
              ProgramState.Draft
            }&${OrgProgramsQueryParams.SECTION}=${OrgProgramsSectionStatus.ProgramInfo}&${
              OrgProgramsQueryParams.PROGRAM_ID
            }=${qp.get(OrgContentQueryParams.PROGRAM_ID)}&${OrgProgramsQueryParams.PROGRAM_TAB}=${
              ProgramInfoTab.Scenarios
            }&${OrgProgramsQueryParams.SCENARIO_ID}=${templateSelected.id}`
          );
        } else {
          closeWizard();
        }
      },
      nextCopy: "Done",
      showProgressTracker: true,
    }),
    [error]
  );

  const customGoalSteps = () => {
    switch (customGoalType) {
      case GoalKind.BinaryGoal:
        return CreateBinaryGoalSteps(
          binaryGoal,
          setBinaryGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks
        );
      case GoalKind.ScoreGoal:
        return CreateRatedGoalSteps(
          ratedGoal,
          setRatedGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks
        );
      case GoalKind.CompoundGoal:
        return CreateCompoundGoalSteps(
          compoundGoal,
          setCompoundGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks
        );
      default:
        if (goalEditId) {
          setGoalEditId(undefined);
        }
        return [];
    }
  };

  const mapStepIdx = (x: WizardStep, i: number) => ({
    ...x,
    stepIdx: i,
    hidePrev: x.hidePrev === true || i === 0,
  });

  const includePromptStep = [ScenarioTypeIdEnum.PITCH].includes(
    templateSelected.scenarioTypeId as ScenarioTypeIdEnum
  );

  const CONVO_SCENARIO_STEPS: Array<WizardStep> = [
    includePromptStep ? promptStep : null,
    scenarioContextStep,
    goalsStep,
    ...customGoalSteps(),
    wizardType === WizardType.Org && goals.length > 1 && goalWeightsStep,
    objectionsStep,
    personaStep,
    wizardType === WizardType.Org && assignHubsStep,
    perScenarioCoachbots?.length > 0 ? assignCoachbotsStep : null,
    reviewStep,
    doneStep,
  ]
    .filter(Boolean)
    .map(mapStepIdx);

  return (
    <>
      <Wizard
        routeLeavingGuardUIProps={{
          title: "Exit scenario creation?",
          body: "Any progress you have made so far will not be saved. Are you sure you want to exit?",
          when: currStepIndex < CONVO_SCENARIO_STEPS.length - 1,
          okText: "Yes, I'm sure",
          onNavigate: () => {
            handleTemplateSelected(null);
          },
        }}
        shouldUseNavDrawerOffset
        steps={CONVO_SCENARIO_STEPS}
        currStepIndex={currStepIndex}
        setCurrStepIndex={setCurrStepIndex}
        loading={loading}
        setLoading={setLoading}
        containerSx={{
          display: "flex",
          flexDirection: "column",

          // TODO: move these to a higher level theme, or even just add to the global theme
          // but to keep things scoped do it here for now
          // defaults for the inputs inside fo the wizard
          fontFamily: "poppins",
          ".MuiFormControl-root > *": {
            fontFamily: "poppins !important",
          },
          ".Mui-disabled, .Mui-disabled > fieldset": {
            borderColor: `${getDynamicColor("dark3", 0.5)} !important`,
            WebkitTextFillColor: getDynamicColor("dark3"),
          },
          ".MuiButton-root.Mui-disabled": {
            color: getDynamicColor("dark4"),
            WebkitTextFillColor: getDynamicColor("dark4"),
          },
          ".MuiTextField-root, fieldset, fieldset &.Mui-disabled": {
            borderRadius: "8px",
            borderColor: getDynamicColor("purple1"),
            borderWidth: "1px !important",
          },
          "*::-webkit-input-placeholder": {
            fontWeight: 400,
            fontFamily: "poppins",
          },
        }}
      />
      <YoodliCtaModal
        ctaBody={{
          title: "This goal has not been saved",
          subtitle: 'Click "Keep editing" and then finish your goal in order to save',
        }}
        open={discardGoalModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setDiscardGoalModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Discard changes",
              handler: () => {
                const goalKind = customGoalType;
                handleUpdateCustomGoalType(undefined);
                setCustomGoalState(goalKind);
                setCurrStepIndex((prev) => prev - 1);
              },
            },
            secondary: { text: "Keep editing", handler: () => setDiscardGoalModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
